import { IPivotItemProps } from "@fluentui/react";
import {
    TAdvValueBindingParams,
    useAdvValueBinderNoDataType,
} from "@hooks/dynamic/useAdvValueBinder";
import { useT } from "@hooks/language/useTranslation";
import { EAdvValueDataTypes } from "@utils/data-types";

export const AdvPivotLinkImpl = (
    { headerTextBindingParams, headerTranslationContext }: TAdvTabItemProps,
    defaultRender: (props?: IPivotItemProps) => React.JSX.Element | null,
    { headerText, ...props }: IPivotItemProps,
) => {
    const [headerTextValue] = useAdvValueBinderNoDataType(
        headerTextBindingParams,
        headerText,
        EAdvValueDataTypes.Any,
        0,
    );
    const { t, hasErr } = useT(headerTextValue, headerTranslationContext);
    return defaultRender({
        ...props,
        headerText: t,
        // Style-Property zu überschreiben bringt hier scheinbar nichts, deshalb
        // zumindest ein "?" anzeigen wenn wir schon nicht unterstreichen können...
        itemIcon: hasErr ? "StatusCircleQuestionMark" : props.itemIcon,
    });
};

export type TAdvTabItemProps = {
    headerTextBindingParams?: TAdvValueBindingParams;
    headerTranslationContext?: string;
};

/**
 * @summary Wrapper für ``PivotItem`` link rendering, da FluentUI nur echte PivotItems erlaubt
 * @link https://developer.microsoft.com/en-us/fluentui#/controls/web/pivot
 */
const AdvPivotOnRenderWithBinds = (
    extraProps: TAdvTabItemProps,
    props?: IPivotItemProps,
    defaultRender?: (props?: IPivotItemProps) => React.JSX.Element | null,
) => {
    if (props != undefined && defaultRender != undefined) {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const AdvPivotLink = AdvPivotLinkImpl.bind(null, extraProps, defaultRender);
        return <AdvPivotLink {...props}></AdvPivotLink>;
    }
    return <></>;
};

export default AdvPivotOnRenderWithBinds;

import "./designable";
