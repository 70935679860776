import AdvMessageBar from "@components/other/message-bar";
import { recoilPageState } from "@data/dynamic-page";
import { LAN } from "@data/language/strings";
import {
    buildPageIDForVariableID,
    recoilPageVariables,
    recoilParameters,
    TPageParameterValue,
    TParameterID,
} from "@data/parameters";
import { MessageBarType } from "@fluentui/react";
import { useT } from "@hooks/language/useTranslation";
import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import { useAdvEffect } from "@hooks/react-overload/useAdvEffect";
import {
    TAdvTransactionInterface,
    useAdvRecoilTransaction,
} from "@hooks/recoil-overload/useAdvRecoilTransaction";
import useAdvRecoilValue from "@hooks/recoil-overload/useAdvRecoilValue";
import useAdvComponent from "@hooks/useAdvComponent";
import { EAdvValueDataTypes } from "@utils/data-types";
import { deepCompareJSXProps } from "@utils/deep-compare";
import { str_format } from "@utils/string";
import { useRouter } from "next/router";
import { ParsedUrlQuery } from "querystring";
import React, { useMemo } from "react";
import { atom, atomFamily } from "recoil";

import deepCopy from "@utils/deep-copy";
import { gAdvParameterMappingKey, IAdvParameterMapping } from "./types";

const pageParamHostErr = atomFamily<string, TParameterID>({
    key: "pageParamHostErr",
    default: "",
});

export const recoilPageVariablesUniqueness = atomFamily<
    { query: string; params: string },
    TParameterID
>({
    key: "recoilPageVariablesUniqueness",
    default: { query: "", params: "" },
});

export const resetPageParameters = (tb: TAdvTransactionInterface) => (variableID: TParameterID) => {
    tb.reset(recoilPageVariables(variableID));
    tb.reset(pageParamHostErr(variableID));
    tb.reset(recoilPageVariablesUniqueness(variableID));
};

/**
 * pages read this result register into a automatically created
 * page variable. it should be cleared in that moment
 */
const gResultRegister = atom<any>({
    key: "pageResultRegister",
    default: undefined,
});

export const setResultRegisterTrans = (tb: TAdvTransactionInterface) => (val: any) => {
    tb.set(gResultRegister, val);
};

export const gPageParamResultAutoVar = "__pageCallResult";

const PageParameterHostComp = ({ isDesigner = false, ...props }: { isDesigner?: boolean }) => {
    useAdvComponent(PageParameterHostComp, props);

    const router = useRouter();
    const variableID = useMemo(
        () => buildPageIDForVariableID(router.pathname, router.query),
        [router.pathname, router.query],
    );
    const pageParams = useAdvRecoilValue(recoilParameters(variableID));
    const err = useAdvRecoilValue(pageParamHostErr(variableID));

    const { t: reqNotFound } = useT(
        LAN.COULD_NOT_FIND_REQUIRED_PAGE_VAR.text,
        LAN.COULD_NOT_FIND_REQUIRED_PAGE_VAR.context,
    );

    const checkPageParameters = useAdvCallback(
        (tb: TAdvTransactionInterface) =>
            (pageParams: IAdvParameterMapping, variableID: TParameterID, query: ParsedUrlQuery) => {
                const curUniqueness = tb.get(recoilPageVariablesUniqueness(variableID));
                const queryUnique = JSON.stringify(query);
                const paramUnique = JSON.stringify(pageParams.params);
                const pageParamKeys = Object.keys(pageParams.params);
                let isMissing = false;
                const registerVal = tb.get(gResultRegister);
                if (
                    curUniqueness.query != queryUnique ||
                    curUniqueness.params != paramUnique ||
                    registerVal != undefined
                ) {
                    const isSamePage = curUniqueness.query == queryUnique;
                    tb.set(recoilPageVariablesUniqueness(variableID), {
                        query: queryUnique,
                        params: paramUnique,
                    });
                    const pageParamsValues: TPageParameterValue[] = [];
                    const queryKeys = Object.keys(query);
                    tb.reset(pageParamHostErr(variableID));
                    let pageParamIndex = 0;
                    while (
                        pageParamKeys.includes(gAdvParameterMappingKey + pageParamIndex.toString())
                    ) {
                        const pageParamVal =
                            pageParams.params[gAdvParameterMappingKey + pageParamIndex.toString()];
                        const pageParamName = pageParamVal.value;
                        let shouldCheckIfReq = false;
                        let hasVal = true;
                        if (queryKeys.includes(pageParamName)) {
                            const paramGET = query[pageParamName];
                            if (typeof paramGET == "string") {
                                pageParamsValues.push({
                                    pageParamName: pageParamName,
                                    value: paramGET,
                                    dataType: EAdvValueDataTypes.String,
                                });
                            } else if (typeof paramGET == "undefined") {
                                shouldCheckIfReq = true;
                                hasVal = false;
                            } else {
                                pageParamsValues.push({
                                    pageParamName: pageParamName,
                                    value: paramGET[0],
                                    dataType: EAdvValueDataTypes.String,
                                });
                            }
                        } else {
                            hasVal = false;
                            shouldCheckIfReq = true;
                        }
                        if (!hasVal) {
                            const optionKeys = Object.keys(pageParamVal.options);
                            if (
                                (!optionKeys.includes("constant") ||
                                    pageParamVal.options["constant"] != true) &&
                                optionKeys.includes("defVal")
                            ) {
                                const defVal = pageParamVal.options["defVal"];
                                if (defVal != undefined && defVal != "")
                                    pageParamsValues.push({
                                        pageParamName: pageParamName,
                                        value: defVal,
                                        dataType: EAdvValueDataTypes.String,
                                    });
                            }
                        }
                        if (shouldCheckIfReq) {
                            // check if page variable is required
                            const optionKeys = Object.keys(pageParamVal.options);
                            if (
                                optionKeys.includes("required") &&
                                pageParamVal.options["required"] === true
                            ) {
                                isMissing = true;
                                tb.set(
                                    pageParamHostErr(variableID),
                                    str_format(
                                        reqNotFound ?? LAN.COULD_NOT_FIND_REQUIRED_PAGE_VAR.text,
                                        pageParamName,
                                    ),
                                );
                            }
                        }
                        ++pageParamIndex;
                    }
                    const resultVal =
                        typeof registerVal == "object" ? deepCopy(registerVal) : registerVal;
                    // directly clear the register, it's only used to move the inner value
                    tb.set(gResultRegister, undefined);
                    if (isSamePage && resultVal == undefined) {
                        // load the previous val, if existend
                        const prevVars = tb.get(recoilPageVariables(variableID));
                        pageParamsValues.push(
                            deepCopy(
                                prevVars.find(
                                    (v) => v.pageParamName == gPageParamResultAutoVar,
                                ) ?? {
                                    pageParamName: gPageParamResultAutoVar,
                                    dataType: EAdvValueDataTypes.Any,
                                    value: resultVal,
                                },
                            ),
                        );
                    } else {
                        pageParamsValues.push({
                            pageParamName: gPageParamResultAutoVar,
                            dataType: EAdvValueDataTypes.Any,
                            value: resultVal,
                        });
                    }
                    tb.set(recoilPageVariables(variableID), pageParamsValues);
                }

                // Wenn kein "required" Parameter fehlt und wir PageParameter haben
                if (!isMissing && pageParamKeys.length > 0) {
                    tb.set(recoilPageState(variableID), (oldValue) => {
                        return { ...oldValue, ParametersLoaded: true };
                    });
                }
            },
        [reqNotFound],
    );

    const checkPageParametersTrans = useAdvRecoilTransaction(checkPageParameters, [
        checkPageParameters,
    ]);

    useAdvEffect(() => {
        if (router.isReady) checkPageParametersTrans(pageParams, variableID, router.query);
    }, [checkPageParametersTrans, pageParams, router.isReady, router.query, variableID]);

    const rendered = useMemo(() => {
        return err != "" && !isDesigner ? (
            <AdvMessageBar type={MessageBarType.error}>{err}</AdvMessageBar>
        ) : (
            <></>
        );
    }, [err, isDesigner]);

    return rendered;
};

const PageParameterHost = React.memo(PageParameterHostComp, deepCompareJSXProps);
export default PageParameterHost;
