import AdvText, { TAdvTextStyles, TAdvTextTokens } from "@components/data/text/text";
import AdvGridItemDesignable from "@components/layout/grid/grid-item/designable";
import AdvStack, { TAdvStackStyles, TAdvStackTokens } from "@components/layout/stack";
import AdvStackItem from "@components/layout/stack/stack-item/stack-item";
import {
    AdvCommonComponentAttributes,
    AdvThemeProviderProperties,
    TAdvCommonProperties,
} from "@components/other/common-properties";
import { LAN } from "@data/language/strings";
import { DefaultComponentCategory } from "@feature/Designer/types/category";
import { TAdvDesignerComponentProps } from "@feature/Designer/types/component-props";
import { EComponentTypeData } from "@feature/Designer/types/component-type";
import {
    AdvProperty,
    getSelectedComponentStyle,
    registerDesignableComponent,
} from "@feature/Designer/utils";
import { IPalette, IStackProps, ITextProps } from "@fluentui/react";
import { faSquareFull } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    IsValueBindingTrivial,
    TAdvValueBindingParams,
    useAdvValueBinderNoDataType,
} from "@hooks/dynamic/useAdvValueBinder";
import { toAdvText, useT, useTranslation } from "@hooks/language/useTranslation";
import useAdvComponent from "@hooks/useAdvComponent";
import useAdvTheme from "@hooks/useAdvTheme";
import { ProgressbarIcon } from "@themes/icons";
import { IStylesFunctionOrObject } from "@typings/fluent";
import { EAdvValueDataTypes } from "@utils/data-types";
import { deepCompareJSXProps } from "@utils/deep-compare";
import { combineStylesWithToken } from "@utils/styling";
import React, { useMemo } from "react";

export type TAdvProgressBarDataDefinition = {
    value: number;
    /** Anzeigename der Daten (nur sichtbar wenn showAsLabel) */
    label: string;
    valueBindingParams?: TAdvValueBindingParams; //number;
    /** Daten als Balken anzeigen? */
    showAsBar?: boolean;
    /** Daten in Legende anzeigen? */
    showAsLabel?: boolean;
    /** Palette-Key für Farbe der Daten (sichtbar als Balken und im Label) */
    themePaletteKey: keyof IPalette;
};
export type TAdvProgressBarDataDefinitions = TAdvProgressBarDataDefinition[];

type TAdvProgressBarPartProps = TAdvCommonProperties & {
    data: TAdvProgressBarDataDefinition;
    minHeight: string;
    dataArrayIndex?: number;
};
const AdvProgressBarPartComp = ({
    data,
    minHeight,
    dataArrayIndex = 1,
}: TAdvProgressBarPartProps) => {
    const [valueBindingValue] = useAdvValueBinderNoDataType(
        data.valueBindingParams,
        data.value,
        EAdvValueDataTypes.Number,
        dataArrayIndex,
    );
    const theme = useAdvTheme();
    return (
        <AdvStackItem
            minHeight={minHeight}
            grow={valueBindingValue}
            shrink={0}
            align="stretch"
            styles={{
                root: {
                    background: theme.palette[data.themePaletteKey],
                    marginLeft: "0px !important",
                },
            }}
        >
            <div />
        </AdvStackItem>
    );
};

type TAdvProgressBarLabelProps = {
    data: TAdvProgressBarDataDefinition;
    dataArrayIndex?: number;
    ignoreTranslation?: boolean;
    iconSize?: number;
};
const AdvProgressBarLabelComp = ({
    data,
    dataArrayIndex = 1,
    ignoreTranslation,
    iconSize = 12,
}: TAdvProgressBarLabelProps) => {
    const [valueBindingValue] = useAdvValueBinderNoDataType(
        data.valueBindingParams,
        data.value,
        EAdvValueDataTypes.Number,
        dataArrayIndex,
    );
    const theme = useAdvTheme();
    const { t: translatedLabel } = useTranslation(data.label, "", ignoreTranslation);
    return (
        <AdvStackItem>
            <AdvText variant="medium">
                <FontAwesomeIcon
                    icon={faSquareFull}
                    style={{
                        color: theme.palette[data.themePaletteKey],
                        backgroundColor: theme.palette[data.themePaletteKey],
                        height: iconSize,
                        width: iconSize,
                        fontSize: iconSize,
                        marginLeft: 6,
                        marginRight: 3,
                    }}
                />
                {translatedLabel} ({valueBindingValue})
            </AdvText>
        </AdvStackItem>
    );
};

type TAdvProgressBarStyles = {
    stack?: IStylesFunctionOrObject<IStackProps, TAdvStackTokens, TAdvStackStyles>;
    label?: IStylesFunctionOrObject<ITextProps, TAdvTextTokens, TAdvTextStyles>;
};
type TAdvProgressBarProps = TAdvCommonProperties &
    TAdvDesignerComponentProps & {
        label: string;
        ignoreTranslation?: boolean;
        translationContext?: string;
        minHeight: string;

        dataDefinitions?: TAdvProgressBarDataDefinitions;
    };
const AdvProgressBarComp = ({
    label,
    minHeight,
    dataArrayIndex = 1,
    designerData,
    ignoreTranslation,
    dataDefinitions = [],
    ...props
}: TAdvProgressBarProps) => {
    useAdvComponent(AdvProgressBarComp, props);

    const theme = useAdvTheme();
    const { t: translatedLabel, hasErr } = useT(label, "", ignoreTranslation);
    const styles = useMemo(() => {
        const styles: TAdvProgressBarStyles = {
            stack: { root: { minHeight: 20, marginLeft: 6, marginRight: 6 } },
        };
        if (hasErr)
            styles.label = combineStylesWithToken(styles.label, {
                root: { ...theme.custom.textNotTranslated },
            });
        if ((designerData?.isSelected ?? false) && (designerData?.renderAsDesigner ?? false))
            styles.stack = combineStylesWithToken(styles.stack, {
                root: getSelectedComponentStyle(theme, true),
            });
        return styles;
    }, [designerData?.isSelected, designerData?.renderAsDesigner, hasErr, theme]);

    const labelComponents = useMemo(() => {
        const ret: React.ReactElement[] = [];
        dataDefinitions.forEach((data, index) => {
            if ((data.label ?? "").trim() != "" && (data.showAsLabel ?? true))
                ret.push(
                    <AdvProgressBarLabelComp
                        data={data}
                        dataArrayIndex={dataArrayIndex}
                        key={`pbpc_${index}`}
                    />,
                );
        });
        return ret;
    }, [dataArrayIndex, dataDefinitions]);

    const progressComponents = useMemo(() => {
        const ret: React.ReactElement[] = [];
        dataDefinitions.forEach((data, index) => {
            if ((data.showAsBar ?? true) == false) return;
            if (!IsValueBindingTrivial(data.valueBindingParams) || data.value >= 0)
                ret.push(
                    <AdvProgressBarPartComp
                        data={data}
                        dataArrayIndex={dataArrayIndex}
                        minHeight={minHeight}
                        key={`pbpc_${index}`}
                    />,
                );
        });
        return ret;
    }, [dataArrayIndex, dataDefinitions, minHeight]);

    return (
        <AdvStack styles={styles.stack} {...props}>
            {(translatedLabel ?? "").trim() != "" ? (
                <AdvStackItem>
                    <AdvText variant="small" styles={styles.label}>
                        {translatedLabel}
                    </AdvText>
                </AdvStackItem>
            ) : null}
            <AdvStackItem align="stretch">
                <AdvStack horizontal horizontalAlign="stretch">
                    {progressComponents}
                </AdvStack>
            </AdvStackItem>
            <AdvStackItem align="stretch">
                <AdvStack horizontal horizontalAlign="center" wrap>
                    {labelComponents}
                </AdvStack>
            </AdvStackItem>
        </AdvStack>
    );
};

const AdvProgressBar = React.memo(AdvProgressBarComp, deepCompareJSXProps);
export default AdvProgressBar;

registerDesignableComponent({
    staticData: {
        name: LAN.PROGRESSBAR.text,
        translationContext: LAN.PROGRESSBAR.context,
        type: EComponentTypeData.Progressbar,
        supportsChildren: false,
        category: DefaultComponentCategory.Display,
        icon: ProgressbarIcon,
    },
    properties: [
        AdvProperty.Object.createProgressData(
            toAdvText(LAN.PROGRESSBAR_DATA),
            "dataDefinitions",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.PROGRESSBAR_DATA_DESCR),
            [],
        ),
        AdvProperty.Text.createSuggestion(
            toAdvText(LAN.LABEL),
            "label",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.PROGRESSBAR_LABEL_DESCR),
            "Progressbar-Label",
        ),
        AdvProperty.Text.create(
            toAdvText(LAN.MIN_HEIGHT),
            "minHeight",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.MIN_HEIGHT),
            "10px",
        ),
        ...AdvCommonComponentAttributes,
        ...AdvThemeProviderProperties,
        // ...AdvStackDesignable.CommonProperties,
        ...AdvGridItemDesignable.CommonProperties,
    ],
    propertiesBuilders: [],
    presets: [],
});
