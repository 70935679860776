import { TAdvActionBase } from "@data/action";
import { TAdvTranslationText } from "@hooks/language/useTranslation";
import { advlog } from "@utils/logging";

export type TActionMappedValue = { Name: string; Value: any };
export type TActionMappedValues = Array<TActionMappedValue>;

/**
 * Parameter für Aufruf einer Action im Server
 */
export type TActionParameters = {
    User: string; // Key der Aufrufende Komponente (z.B. Button oder Nav-Bar)
    UserIndex: number; // Index der Komponente, falls diese mehrfach existiert (durch Copycat)
    Parameters: TActionMappedValues; // Nutzdaten/Argumente für die Action
};

/**
 * Repsonse von Server für Action-Aufruf
 */
export type TAdvExecWebActionRes = {
    Success: boolean; // War die Action erfolgreich
    Msg: string; // Response-Nachricht, in der Regel nur im Fehlerfall gefüllt
    ActionToken: string; // Token der ausgeführten Action (interessant für OpenPage)
    Extra: Array<any>; // Extra Daten, abhängig vom Event
};

export type TActionExecFunc = (
    ActionParams: TActionParameters,
    SuccessKey: string,
    SuccessExtra: Array<{ Name: string; Val: any }>,
) => void;
export type TActionAskServerFunc = (
    aBereich: string,
    aName: string,
    aData: any,
    aIgnoreLoginState?: boolean,
) => Promise<any>;
export type TActionErrorFunc = (aErr: string) => void;

export class CWebAction {
    ServerFunc: TActionAskServerFunc;
    ErrorFunc: TActionErrorFunc;
    Exec: TActionExecFunc;
    Description: TAdvTranslationText;

    constructor(
        serverFunc: TActionAskServerFunc,
        errorFunc: TActionErrorFunc,
        aFunction: TActionExecFunc,
        aDescription: TAdvTranslationText,
    ) {
        this.ServerFunc = serverFunc;
        this.ErrorFunc = errorFunc;
        this.Exec = aFunction;
        this.Description = aDescription;
    }
}

const gWebActions = new Map<string, CWebAction>();

export function RegisterWebAction(actionName: string, actionObj: CWebAction) {
    gWebActions.set(actionName, actionObj);
}

export function UnregisterWebAction(actionName: string) {
    gWebActions.delete(actionName);
}

export function GetWebActions(): { Name: string; Val: CWebAction }[] {
    const returnActions = new Array<{ Name: string; Val: CWebAction }>();
    gWebActions.forEach((value: CWebAction, key: string) => {
        returnActions.push({ Name: key, Val: value });
    });

    return returnActions;
}

export function ExecuteWebActions(action: TAdvActionBase, yourParams: TActionParameters) {
    if (gWebActions.has(action.LogicName)) {
        const webAct = gWebActions.get(action.LogicName);
        if (webAct != undefined) {
            webAct
                .ServerFunc("webaction", "exec", {
                    ActionName: action.Name,
                    User: yourParams.User,
                    UserIndex: yourParams.UserIndex,
                    // Aufbau der Parameter für Struktur im Server anpassen
                    Args: yourParams.Parameters.map((val) => {
                        return { Name: val.Name, Value: val.Value };
                    }),
                })
                .then((success: TAdvExecWebActionRes) => {
                    if (success.Success) {
                        webAct.Exec(
                            {
                                User: yourParams.User,
                                UserIndex: yourParams.UserIndex,
                                Parameters: yourParams.Parameters,
                            },
                            success.ActionToken,
                            success.Extra,
                        );
                    } else {
                        webAct.ErrorFunc(success.Msg);
                        console.error("Server denied request to execute this action.");
                    }
                })
                .catch((r) => {
                    advlog("Could not execute action '" + action.Name + "'", r);
                });
        }
    } else {
        advlog("Could not find web action with name: " + action.LogicName);
    }
}
