import "./designable";

import { AdvHeadlineSmall } from "@components/data/text";
import { getDesignerModeComponentStyle, getSelectedComponentStyle } from "@feature/Designer/utils";
import {
    IsValueBindingTrivial,
    useAdvValueBinderNoDataType,
} from "@hooks/dynamic/useAdvValueBinder";
import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import { useAdvEffect } from "@hooks/react-overload/useAdvEffect";
import { useAdvMemoWithUpdater } from "@hooks/useAdvMemoWithUpdater";
import { DownButton, LeftButton, RightButton, UpButton } from "@themes/icons";
import { EAdvValueDataTypes } from "@utils/data-types";
import { combineStyles, mergeObjects } from "@utils/styling";
import React, { useMemo, useState } from "react";

import { AdvButtonPure, TAdvButtonProps } from "@components/inputs/button/button-pure";
import { cacheGet, cacheSet } from "@data/cache/cache";
import { useAdvWebAction } from "@hooks/dynamic/useAdvWebAction";
import useAdvComponent from "@hooks/useAdvComponent";
import useAdvTheme, { TAdvAppliedTheme } from "@hooks/useAdvTheme";
import { deepCompareJSXProps } from "@utils/deep-compare";
import deepCopy from "@utils/deep-copy";
import { advcatch } from "@utils/logging";
import { nanoid } from "nanoid";
import AdvGrid from "../grid";
import AdvGridItem from "../grid/grid-item";
import { defaultGroupboxStyles } from "./styles";
import { EAdvGroupboxCollapseDir, TAdvGroupboxProps } from "./types";

export const onRenderText = (
    theme: TAdvAppliedTheme,
    props?: TAdvButtonProps,
): React.JSX.Element | null => {
    return (
        <AdvHeadlineSmall
            ignoreTranslation={props?.ignoreTranslation}
            styles={defaultGroupboxStyles.headingText(theme)}
            key={nanoid()}
        >
            {props?.text}
        </AdvHeadlineSmall>
    );
};

const gGroupBoxIsOpenCacheKey = "advgroupbox_isopen_";
type TGroupBoxIsOpen = { isOpen: boolean | undefined };
const getGroupBoxIsOpen = async (key: string | undefined): Promise<TGroupBoxIsOpen> => {
    if (key == undefined) return { isOpen: undefined };
    const cacheItem = await cacheGet<TGroupBoxIsOpen>(gGroupBoxIsOpenCacheKey + key);
    if (cacheItem.item == null) return { isOpen: undefined };
    return cacheItem.item;
};

const AdvGroupboxImplComp = ({
    advhide,
    isOpen = true,
    ignoreDefaultStyle = false,
    contentAsFlexContainer = false,
    heading,
    additionalHeading,
    translationContext,
    children,
    onStateChange,
    actionBar,
    setIsOpenInternal,
    showHeading = true,
    canCollapse = true,
    collapseDir = EAdvGroupboxCollapseDir.Top,
    styles: propStyles,
    withBorder = true,
    gridProps,
    designerData,
    designerProps,
    onClick,
    ignoreTranslation,
    keyRef,
    ignoreCache = false,
    onClickAction,
    dataArrayIndex = 0,
    shrink,
    grow,
    align,
    minWidth,
    maxWidth,
    minHeight,
    maxHeight,
    basis,
    ...props
}: TAdvGroupboxProps & { setIsOpenInternal: (newVal: boolean | undefined) => void }) => {
    useAdvComponent(AdvGroupboxImplComp, props);

    const [, , actionFunc, shouldUseBoundValue] = useAdvWebAction(
        keyRef ?? "",
        dataArrayIndex,
        onClickAction,
    );

    const theme = useAdvTheme();

    const [isOpenCache, setIsOpenCache] = useState<boolean | undefined>(undefined);
    useAdvEffect(() => {
        if (keyRef != undefined && keyRef != "")
            getGroupBoxIsOpen(keyRef)
                .then((val) => {
                    setIsOpenCache(val.isOpen);
                })
                .catch(advcatch);
    }, [keyRef]);

    const isOpenCacheChecked = useMemo(() => {
        if (!ignoreCache && isOpenCache != undefined) return isOpenCache;
        return isOpen;
    }, [ignoreCache, isOpen, isOpenCache]);

    useAdvEffect(() => {
        if (typeof onStateChange != "undefined") onStateChange(isOpenCacheChecked);
    }, [onStateChange, isOpenCacheChecked]);

    const handleHeaderClick = useAdvCallback(() => {
        if (designerProps) {
            if (designerProps.onClick != undefined) designerProps.onClick();
            return;
        }

        const isOpenNew = !isOpenCacheChecked;
        if (keyRef != undefined && keyRef != "")
            cacheSet(gGroupBoxIsOpenCacheKey + keyRef, { isOpen: isOpenNew }, 0).catch(advcatch);
        setIsOpenInternal(isOpenNew);
        setIsOpenCache(isOpenNew);
    }, [designerProps, isOpenCacheChecked, keyRef, setIsOpenInternal]);

    const headingElement = useMemo((): React.JSX.Element => {
        if (showHeading == false) return <></>;
        const fullHeading =
            heading +
            (isOpenCacheChecked || additionalHeading == undefined || additionalHeading == ""
                ? ""
                : " (" + additionalHeading + ")");
        if (canCollapse == false) {
            if (fullHeading == "") return <></>;
            else
                return (
                    <AdvHeadlineSmall
                        styles={defaultGroupboxStyles.headingText(theme)}
                        translationContext={translationContext}
                        ignoreTranslation={ignoreTranslation}
                    >
                        {fullHeading}
                    </AdvHeadlineSmall>
                );
        } else {
            if (collapseDir == EAdvGroupboxCollapseDir.Left)
                return (
                    <AdvButtonPure
                        text={fullHeading}
                        translationContext={translationContext}
                        ignoreTranslation={ignoreTranslation}
                        onClick={handleHeaderClick}
                        checked={isOpenCacheChecked}
                        iconName={isOpenCacheChecked ? LeftButton.iconName : UpButton.iconName}
                        styles={
                            isOpenCacheChecked
                                ? defaultGroupboxStyles.headingButton
                                : {
                                      ...defaultGroupboxStyles.headingButton,
                                      ...{
                                          root: {
                                              ...(defaultGroupboxStyles.headingButton
                                                  .root as Object),
                                              display: "flex",
                                              transformOrigin: "left top",
                                              minWidth: "",
                                              width: "5000px",
                                              transform: "rotate(+90deg) translateY(-100%)",
                                          },
                                      },
                                  }
                        }
                        onRenderText={onRenderText.bind(null, theme)}
                    />
                );
            else if (collapseDir == EAdvGroupboxCollapseDir.Right)
                return (
                    <AdvButtonPure
                        text={fullHeading}
                        translationContext={translationContext}
                        ignoreTranslation={ignoreTranslation}
                        onClick={handleHeaderClick}
                        checked={isOpenCacheChecked}
                        iconName={isOpenCacheChecked ? RightButton.iconName : UpButton.iconName}
                        styles={
                            isOpenCacheChecked
                                ? defaultGroupboxStyles.headingButton
                                : {
                                      ...defaultGroupboxStyles.headingButton,
                                      ...{
                                          root: {
                                              ...(defaultGroupboxStyles.headingButton
                                                  .root as Object),
                                              display: "flex",
                                              transformOrigin: "left top",
                                              minWidth: "",
                                              width: "5000px",
                                              justifyContent: "end",
                                              transform: "rotate(-90deg) translateX(-100%)",
                                          },
                                      },
                                  }
                        }
                        onRenderText={onRenderText.bind(null, theme)}
                    />
                );
            else if (collapseDir == EAdvGroupboxCollapseDir.Bottom)
                return (
                    <AdvButtonPure
                        text={fullHeading}
                        translationContext={translationContext}
                        ignoreTranslation={ignoreTranslation}
                        onClick={handleHeaderClick}
                        checked={isOpenCacheChecked}
                        iconName={isOpenCacheChecked ? DownButton.iconName : UpButton.iconName}
                        styles={defaultGroupboxStyles.headingButton}
                        onRenderText={onRenderText.bind(null, theme)}
                    />
                );
            else
                return (
                    <AdvButtonPure
                        text={fullHeading}
                        translationContext={translationContext}
                        ignoreTranslation={ignoreTranslation}
                        onClick={handleHeaderClick}
                        checked={isOpenCacheChecked}
                        iconName={isOpenCacheChecked ? UpButton.iconName : DownButton.iconName}
                        styles={defaultGroupboxStyles.headingButton}
                        onRenderText={onRenderText.bind(null, theme)}
                    />
                );
        }
    }, [
        showHeading,
        canCollapse,
        heading,
        additionalHeading,
        theme,
        translationContext,
        ignoreTranslation,
        collapseDir,
        isOpenCacheChecked,
        handleHeaderClick,
    ]);

    const styles = useMemo(() => {
        let styles = propStyles;
        if ((designerData?.isSelected ?? false) && (designerData?.renderAsDesigner ?? false))
            styles = mergeObjects(styles ?? {}, {
                root: { root: getSelectedComponentStyle(theme, true) },
            });
        if (designerData?.renderAsDesigner ?? false)
            styles = mergeObjects(styles ?? {}, {
                root: { root: getDesignerModeComponentStyle(theme) },
            });
        return styles;
    }, [designerData?.isSelected, designerData?.renderAsDesigner, propStyles, theme]);

    const rootStyles = useMemo(() => {
        let resStyles = ignoreDefaultStyle
            ? styles
            : combineStyles(
                  defaultGroupboxStyles.groupboxContainer(theme, withBorder),
                  styles?.root,
              );
        if (shouldUseBoundValue) {
            resStyles = mergeObjects(resStyles ?? {}, {
                root: {
                    cursor: "pointer",
                },
            });
        }

        let zwGrow = grow;
        if (isOpenCacheChecked == false) {
            zwGrow = 0;
        }

        let width = basis;
        //wenn vertikale Groupbox geschlossen, dann width festgelegt (wegen Rotation notwendig)
        if (
            isOpenCacheChecked == false &&
            (collapseDir == EAdvGroupboxCollapseDir.Left ||
                collapseDir == EAdvGroupboxCollapseDir.Right)
        ) {
            width = "44px";
        }

        resStyles = mergeObjects(resStyles ?? {}, {
            root: {
                flexShrink: shrink as string,
                flexGrow: zwGrow as string,
                alignContent: align,
                minWidth: minWidth != "" ? minWidth : undefined,
                maxHeight: maxHeight != "" ? maxHeight : undefined,
                minHeight: minHeight != "" ? minHeight : undefined,
                maxWidth: maxWidth != "" ? maxWidth : undefined,
                flexBasis: basis,
                height: basis,
                width: width,
                overflow:
                    isOpenCacheChecked == false &&
                    (collapseDir == EAdvGroupboxCollapseDir.Left ||
                        collapseDir == EAdvGroupboxCollapseDir.Right)
                        ? "hidden"
                        : undefined, //wegen riesigem Button notwendig
            },
        });

        return resStyles;
    }, [
        ignoreDefaultStyle,
        styles,
        theme,
        withBorder,
        shouldUseBoundValue,
        grow,
        isOpenCacheChecked,
        shrink,
        align,
        minWidth,
        maxHeight,
        minHeight,
        maxWidth,
        basis,
        collapseDir,
    ]);
    const headContainerStyles = useMemo(() => {
        const headContainerStyle = deepCopy(defaultGroupboxStyles.headContainer);
        if (isOpenCacheChecked == false) {
            //wenn vertikale und geschlossen, dann x-overflow: visible und width = 0?
            if (isOpenCacheChecked == false && collapseDir == EAdvGroupboxCollapseDir.Right) {
                (headContainerStyle.root as any).display = "flex";
                (headContainerStyle.root as any).overflowX = "visible";
            } else if (isOpenCacheChecked == false && collapseDir == EAdvGroupboxCollapseDir.Left) {
                (headContainerStyle.root as any).display = "flex";
                (headContainerStyle.root as any).overflowX = "visible";
            }
        }

        return combineStyles(headContainerStyle, styles?.headerContainer);
    }, [collapseDir, isOpenCacheChecked, styles?.headerContainer]);
    const contentContainerStyles = useMemo(() => {
        const contentContainerStyle = deepCopy(defaultGroupboxStyles.contentContainer);
        // Kein Padding wenn Groupbox geschlossen, da Groupbox sonst unnötig groß
        if (isOpenCacheChecked == false) (contentContainerStyle.root as any).padding = 0;
        let res = combineStyles(
            designerData ? defaultGroupboxStyles.contentContainerDesigner : contentContainerStyle,
            styles?.contentContainer,
        );
        if (contentAsFlexContainer) res = combineStyles(res, { root: { display: "flex" } });
        return res;
    }, [contentAsFlexContainer, designerData, isOpenCacheChecked, styles?.contentContainer]);

    const gridItemKeys = useMemo(() => {
        return [nanoid(), nanoid(), nanoid()];
    }, []);

    const onClickWrapper = useAdvCallback(
        (ev: React.MouseEvent<HTMLDivElement>) => {
            if (onClick != undefined) onClick(ev);
            if (shouldUseBoundValue) actionFunc();
        },
        [actionFunc, onClick, shouldUseBoundValue],
    );

    const divProps = useMemo(() => {
        return { onClick: onClickWrapper, ...designerProps };
    }, [designerProps, onClickWrapper]);

    const visRowGap = isOpenCacheChecked ? gridProps?.rowGap : "0"; // Kein Gap wenn Groupbox geschlossen

    const gridComp = useMemo(() => {
        const filteredProps = { ...props };
        delete filteredProps["advhideBindingParams"];
        return (
            <AdvGrid
                {...filteredProps}
                {...gridProps}
                rowGap={visRowGap}
                columns={
                    isOpenCacheChecked ? "[headline] 1fr [actionbar] max-content" : "[headline] 1fr"
                }
                rows={isOpenCacheChecked ? "[head] min-content [content] 1fr" : "[head] 1fr"}
                styles={rootStyles}
                divProps={divProps}
            >
                <AdvGridItem
                    column="headline"
                    row="head"
                    styles={headContainerStyles}
                    key={gridItemKeys[0]}
                >
                    {headingElement}
                </AdvGridItem>
                <AdvGridItem
                    column="actionbar"
                    row="head"
                    styles={headContainerStyles}
                    key={gridItemKeys[1]}
                >
                    {actionBar}
                </AdvGridItem>
                <AdvGridItem
                    column="span 2"
                    row="content"
                    styles={contentContainerStyles}
                    key={gridItemKeys[2]}
                >
                    {isOpenCacheChecked && (children ?? <></>)}
                </AdvGridItem>
            </AdvGrid>
        );
    }, [
        actionBar,
        children,
        contentContainerStyles,
        divProps,
        gridItemKeys,
        gridProps,
        headContainerStyles,
        headingElement,
        isOpenCacheChecked,
        props,
        rootStyles,
        visRowGap,
    ]);

    if (advhide === true && designerProps === undefined) return <></>;
    return gridComp;
};
const AdvGroupboxImpl = React.memo(AdvGroupboxImplComp, deepCompareJSXProps);

const AdvGroupboxSimple = ({ isOpen, ...props }: TAdvGroupboxProps) => {
    const [isOpenInternal, , setIsOpenInternal] = useAdvMemoWithUpdater(() => isOpen, [isOpen]);

    return (
        <AdvGroupboxImpl
            {...props}
            isOpen={isOpenInternal}
            setIsOpenInternal={setIsOpenInternal}
        ></AdvGroupboxImpl>
    );
};

const AdvGroupboxComplex = ({
    isOpenBindingParams,
    headingBindingParams,
    additionalHeadingBindingParams,
    isOpen,
    heading,
    additionalHeading,
    advhide,
    advhideBindingParams,
    dataArrayIndex = 0,
    shrink,
    grow,
    align,
    minWidth,
    maxWidth,
    maxHeight,
    minHeight,
    basis,
    shrinkBindingParams,
    growBindingParams,
    alignBindingParams,
    minWidthBindingParams,
    maxWidthBindingParams,
    minHeightBindingParams,
    maxHeightBindingParams,
    basisBindingParams,
    ...props
}: TAdvGroupboxProps) => {
    const [isOpenInternal, , setIsOpenInternal] = useAdvMemoWithUpdater(() => isOpen, [isOpen]);

    const [isOpenValue] = useAdvValueBinderNoDataType(
        isOpenBindingParams,
        isOpenInternal,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );
    const [headingValue] = useAdvValueBinderNoDataType(
        headingBindingParams,
        heading,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );
    const [additionalHeadingValue] = useAdvValueBinderNoDataType(
        additionalHeadingBindingParams,
        additionalHeading,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );
    const [shouldHide] = useAdvValueBinderNoDataType(
        advhideBindingParams,
        advhide,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );
    const [shrinkValue] = useAdvValueBinderNoDataType(
        shrinkBindingParams,
        shrink,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );
    const [growValue] = useAdvValueBinderNoDataType(
        growBindingParams,
        grow,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );
    const [alignValue] = useAdvValueBinderNoDataType(
        alignBindingParams,
        align,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );
    const [minWidthValue] = useAdvValueBinderNoDataType(
        minWidthBindingParams,
        minWidth,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );
    const [maxWidthValue] = useAdvValueBinderNoDataType(
        maxHeightBindingParams,
        maxWidth,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );
    const [minHeightValue] = useAdvValueBinderNoDataType(
        minHeightBindingParams,
        minHeight,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );
    const [maxHeightValue] = useAdvValueBinderNoDataType(
        maxWidthBindingParams,
        maxHeight,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );
    const [basisValue] = useAdvValueBinderNoDataType(
        basisBindingParams,
        basis,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );
    return (
        <AdvGroupboxImpl
            {...props}
            isOpen={isOpenValue}
            heading={headingValue}
            additionalHeading={additionalHeadingValue}
            isOpenBindingParams={isOpenBindingParams}
            headingBindingParams={headingBindingParams}
            additionalHeadingBindingParams={additionalHeadingBindingParams}
            advhide={shouldHide}
            advhideBindingParams={advhideBindingParams}
            setIsOpenInternal={setIsOpenInternal}
            dataArrayIndex={dataArrayIndex}
            shrink={shrinkValue}
            grow={growValue}
            align={alignValue}
            minWidth={minWidthValue}
            maxWidth={maxWidthValue}
            minHeight={minHeightValue}
            maxHeight={maxHeightValue}
            basis={basisValue}
        ></AdvGroupboxImpl>
    );
};

/**
 * @summary Eine Groupbox wie man sie kennt. Kein Wrapper sondern eine eigene Komponente.
 * @important Hält seinen eigenen State bzgl. geöffnet / geschlossen (open).
 */
const AdvGroupboxComp = ({
    isOpenBindingParams,
    headingBindingParams,
    additionalHeadingBindingParams,
    advhideBindingParams,
    isOpen = true,
    shrinkBindingParams,
    growBindingParams,
    alignBindingParams,
    minWidthBindingParams,
    maxWidthBindingParams,
    minHeightBindingParams,
    maxHeightBindingParams,
    basisBindingParams,
    ...props
}: TAdvGroupboxProps) => {
    if (
        IsValueBindingTrivial(isOpenBindingParams) &&
        IsValueBindingTrivial(headingBindingParams) &&
        IsValueBindingTrivial(additionalHeadingBindingParams) &&
        IsValueBindingTrivial(advhideBindingParams)
    )
        return (
            <AdvGroupboxSimple
                {...props}
                isOpenBindingParams={isOpenBindingParams}
                headingBindingParams={headingBindingParams}
                additionalHeadingBindingParams={additionalHeadingBindingParams}
                advhideBindingParams={advhideBindingParams}
                shrinkBindingParams={shrinkBindingParams}
                growBindingParams={growBindingParams}
                alignBindingParams={alignBindingParams}
                minWidthBindingParams={minWidthBindingParams}
                maxWidthBindingParams={maxWidthBindingParams}
                minHeightBindingParams={minHeightBindingParams}
                maxHeightBindingParams={maxHeightBindingParams}
                basisBindingParams={basisBindingParams}
                isOpen={isOpen}
            ></AdvGroupboxSimple>
        );
    else
        return (
            <AdvGroupboxComplex
                {...props}
                isOpenBindingParams={isOpenBindingParams}
                headingBindingParams={headingBindingParams}
                additionalHeadingBindingParams={additionalHeadingBindingParams}
                advhideBindingParams={advhideBindingParams}
                shrinkBindingParams={shrinkBindingParams}
                growBindingParams={growBindingParams}
                alignBindingParams={alignBindingParams}
                minWidthBindingParams={minWidthBindingParams}
                maxWidthBindingParams={maxWidthBindingParams}
                minHeightBindingParams={minHeightBindingParams}
                maxHeightBindingParams={maxHeightBindingParams}
                basisBindingParams={basisBindingParams}
                isOpen={isOpen}
            ></AdvGroupboxComplex>
        );
};

const AdvGroupbox = React.memo(AdvGroupboxComp, deepCompareJSXProps);
export default AdvGroupbox;
