import { TAdvTranslationText } from "@hooks/language/useTranslation";
import { IAdvIcon } from "@themes/icons_base";
import { IDesignableComponentCategory, IPropertyCategory } from "./category";
import { EComponentType } from "./component-type";
import { IDesignableComponentPreset } from "./preset";
import { EPropertyType, IProperty } from "./property";

export enum EDesignablePropertyBuilderTypes {
    Grid,
}

/**
 * Komponente, die im Designer vom Anwender benutzt werden kann.
 * Besitzt *noch* keine Children und keinen eindeutigen Schlüssel (siehe {@link IDesignerComponent}).
 * Beinhält wichtigste Eckdaten für solch eine Komponente.
 */
export interface IDesignableComponent {
    /** Essentielle Daten für die Designer-Komponente, wie z.B. der Name. */
    staticData: IDesignableStaticData;
    /** Eigenschaften der Komponente (Label, Value, ...) */
    properties: IDesignableProperty<any>[];
    /**
     * Spezielle Funktionen die eine Komponente aufbauen können.
     * z.B. ein für Grid wäre das die Anzahl der Spalten/Zeilen. Die größe davon etc.
     */
    propertiesBuilders: EDesignablePropertyBuilderTypes[];
    /** Vorlagen für diese Komponente */
    presets: IDesignableComponentPreset[];
}

/**
 * Essentielle Daten für eine Designer-Komponente, die immer gleich sind (pro Komponente).
 */
export interface IDesignableStaticData {
    /** Name der Komponente (wird im Designer angezeigt) */
    name: string;
    translationContext?: string;
    /**
     * Art der Komponente (Button, Text, ...)
     * @see {@link AdvComponentType}
     */
    type: EComponentType;
    /**
     * Ist die Komponente eine Layout-Komponente bzw. können Children eingefügt werden?
     * Dann werden automatisch zusätzlich DropAreas als Children hinzugefügt.
     * Kann über {@link hideDropAreas} ausgeschaltet werden.
     */
    supportsChildren: boolean;
    /**
     * Sollen Drop-Areas für diese Komponente versteckt werden?
     * Setzt vorraus, dass {@link supportsChildren} ``TRUE`` ist.
     * Default: ``FALSE`` (DropAreas sichtbar)
     */
    hideDropAreas?: boolean;
    /** Setzt diese Komponente die NestingDepth zurück? (GroupBox) */
    resetNestingDepth?: boolean;
    /**
     * Diese DesignableComponent nicht (*links im Ui-Designer*) zur Auswahl stellen?
     * Default: ``FALSE`` (auswählbar im Ui-Designer)
     */
    hidden?: boolean;
    /** Kategorie zur Gruppierung im Ui-Designer */
    category: IDesignableComponentCategory;
    icon?: IAdvIcon;
}

export interface IDesignableProperty<TType extends EPropertyType = any, TValue = any>
    extends IProperty<TType, TValue> {
    /** Anzeigename der Property (im Ui-Designer) */
    displayName: TAdvTranslationText;
    /** Beschreibung der Property (im UI-Designer) */
    description: TAdvTranslationText;
    /** Unsichtbar im Designer? (Ist dann nicht änderbar) Standard: FALSE */
    hidden: boolean;
    /** Kategorie für den Ui-Designer */
    category: IPropertyCategory;
    valueWriteable: boolean;
}
