import { EFieldSettingsFieldTypes } from "@components/dynamic/data-provider/types";
import AdvGridItemDesignable from "@components/layout/grid/grid-item/designable";
import AdvStackItemDesignable from "@components/layout/stack/stack-item/designable";
import {
    AdvCommonComponentAttributes,
    AdvThemeProviderProperties,
    TAdvCommonProperties,
} from "@components/other/common-properties";
import { LAN } from "@data/language/strings";
import { DefaultComponentCategory } from "@feature/Designer/types/category";
import { TAdvDesignerComponentProps } from "@feature/Designer/types/component-props";
import { EComponentTypeData } from "@feature/Designer/types/component-type";
import {
    AdvProperty,
    getDesignerModeComponentStyle,
    getSelectedComponentStyle,
    registerDesignableComponent,
} from "@feature/Designer/utils";
import { ITextProps, ITextStyles, ITextTokens, Shimmer, Text } from "@fluentui/react";
import {
    IsValueBindingTrivial,
    TAdvValueBindingParams,
    useAdvValueBinderNoDataType,
} from "@hooks/dynamic/useAdvValueBinder";
import { toAdvText, useT } from "@hooks/language/useTranslation";
import useAdvComponent from "@hooks/useAdvComponent";
import useAdvTheme from "@hooks/useAdvTheme";
import { TextBoxIcon } from "@themes/icons";
import { IStylesFunctionOrObject } from "@typings/fluent";
import { EAdvValueDataTypes } from "@utils/data-types";
import { ServerStrToLocalDateStr, ServerStrToLocalDateTimeStr } from "@utils/date";
import { deepCompareJSXProps } from "@utils/deep-compare";
import { combineStylesWithToken } from "@utils/styling";
import React, { ReactNode, useMemo } from "react";

import { defaultTextStyles } from "./styles";

export type TAdvTextStyles = ITextStyles; /* do not change */
export type TAdvTextTokens = ITextTokens; /* do not change */

export type TAdvTextProps = Omit<ITextProps, "styles" | "label"> &
    TAdvDesignerComponentProps &
    TAdvCommonProperties & {
        styles?: IStylesFunctionOrObject<ITextProps, TAdvTextTokens, TAdvTextStyles>;
        children?: ReactNode | string;
        label?: string;
        labelBindingParams?: TAdvValueBindingParams;
        // a context specializing the translation used
        translationContext?: string;
        variantBindingParams?: TAdvValueBindingParams;

        ignoreTranslation?: boolean;

        allowMultiline?: boolean;
        allowMultilineBindingParams?: TAdvValueBindingParams;
    };

const AdvTextImplComp = ({
    styles: propStyles,
    children,
    translationContext,
    ignoreTranslation = false,
    label,
    allowMultiline = false,
    designerData,
    designerProps,
    advhide,
    ...props
}: TAdvTextProps) => {
    useAdvComponent(AdvTextImplComp, props);

    const theme = useAdvTheme();

    const { t, hasErr } = useT(
        label ?? (typeof children == "string" ? children : undefined),
        translationContext,
        ignoreTranslation,
    );

    const styles = useMemo(() => {
        let styles = propStyles;
        if (allowMultiline)
            styles = combineStylesWithToken(propStyles, { root: { whiteSpace: "pre-wrap" } });
        if (hasErr)
            styles = combineStylesWithToken(styles, {
                root: { ...theme.custom.textNotTranslated },
            });
        if ((designerData?.isSelected ?? false) && (designerData?.renderAsDesigner ?? false))
            styles = combineStylesWithToken(styles, {
                root: getSelectedComponentStyle(theme, true),
            });
        if (designerData?.renderAsDesigner ?? false)
            styles = combineStylesWithToken(styles, { root: getDesignerModeComponentStyle(theme) });
        return styles;
    }, [
        allowMultiline,
        designerData?.isSelected,
        designerData?.renderAsDesigner,
        hasErr,
        propStyles,
        theme,
    ]);

    if (advhide === true && designerProps === undefined) return <></>;
    return (
        <Text {...props} {...designerProps} styles={styles}>
            {t ?? children}
        </Text>
    );
};
const AdvTextImpl = React.memo(AdvTextImplComp, deepCompareJSXProps);

const AdvTextSimple = (props: TAdvTextProps) => {
    return <AdvTextImpl {...props}></AdvTextImpl>;
};

const AdvTextComplex = ({
    labelBindingParams: textBindingParams,
    variantBindingParams,
    label: text,
    variant,
    advhide = false,
    advhideBindingParams,
    allowMultiline,
    allowMultilineBindingParams,
    dataArrayIndex = 0,
    ...props
}: TAdvTextProps) => {
    const [labelText, , attributes] = useAdvValueBinderNoDataType(
        textBindingParams,
        text,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );
    const [variantValue] = useAdvValueBinderNoDataType(
        variantBindingParams,
        variant,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );
    const [shouldHide] = useAdvValueBinderNoDataType(
        advhideBindingParams,
        advhide,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );
    const [isMultilineAllowed] = useAdvValueBinderNoDataType(
        allowMultilineBindingParams,
        allowMultiline,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );

    const convertedText = useMemo(() => {
        if (attributes.fieldType == EFieldSettingsFieldTypes.date) {
            return ServerStrToLocalDateStr(labelText ?? "");
        } else if (attributes.fieldType == EFieldSettingsFieldTypes.datetime) {
            return ServerStrToLocalDateTimeStr(labelText ?? "");
        }
        return labelText;
    }, [attributes.fieldType, labelText]);

    if (attributes.isLoading) return <Shimmer width="max(100%, 50px)" />;
    return (
        <AdvTextImpl
            {...props}
            label={convertedText}
            variant={variantValue}
            labelBindingParams={textBindingParams}
            variantBindingParams={variantBindingParams}
            advhide={shouldHide}
            advhideBindingParams={advhideBindingParams}
            dataArrayIndex={dataArrayIndex}
            allowMultiline={isMultilineAllowed}
            allowMultilineBindingParams={allowMultilineBindingParams}
        ></AdvTextImpl>
    );
};

/**
 * @summary Wrapper für ``Text``
 * @summary Es kann die ``Text`` oder ``Children`` Property genutzt werden.
 * @link https://developer.microsoft.com/en-us/fluentui#/controls/web/text
 */
const AdvTextComp = ({
    labelBindingParams: textBindingParams,
    variantBindingParams,
    advhideBindingParams,
    allowMultilineBindingParams,
    ...props
}: TAdvTextProps) => {
    if (
        IsValueBindingTrivial(textBindingParams) &&
        IsValueBindingTrivial(variantBindingParams) &&
        IsValueBindingTrivial(advhideBindingParams) &&
        IsValueBindingTrivial(allowMultilineBindingParams)
    )
        return (
            <AdvTextSimple
                {...props}
                labelBindingParams={textBindingParams}
                variantBindingParams={variantBindingParams}
                advhideBindingParams={advhideBindingParams}
                allowMultilineBindingParams={allowMultilineBindingParams}
            ></AdvTextSimple>
        );
    else
        return (
            <AdvTextComplex
                {...props}
                labelBindingParams={textBindingParams}
                variantBindingParams={variantBindingParams}
                advhideBindingParams={advhideBindingParams}
                allowMultilineBindingParams={allowMultilineBindingParams}
            ></AdvTextComplex>
        );
};

const AdvText = React.memo(AdvTextComp, deepCompareJSXProps);
export default AdvText;

registerDesignableComponent({
    staticData: {
        name: LAN.TEXT.text,
        translationContext: LAN.TEXT.context,
        type: EComponentTypeData.Text,
        supportsChildren: false,
        category: DefaultComponentCategory.Display,
        icon: TextBoxIcon,
    },
    properties: [
        AdvProperty.Text.createSuggestion(
            toAdvText(LAN.TEXT),
            "label",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.TEXT_TEXT_DESCR),
            "Text",
        ),
        // AdvProperty.Text.create(
        //     toAdvText(LAN.TRANSLATION_CONTEXT),
        //     "translationContext",
        //     toAdvText(LAN.GENERAL),
        //     toAdvText(LAN.TRANSLATION_CONTEXT_DESCR),
        //     "",
        //     false
        // ),
        AdvProperty.Boolean.create(
            toAdvText(LAN.ALLOW_MULTILINE),
            "allowMultiline",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.ALLOW_MULTILINE_DESCR),
            false,
        ),
        AdvProperty.Boolean.create(
            toAdvText(LAN.IGNORE_TRANSLATION),
            "ignoreTranslation",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.IGNORE_TRANSLATION_DESCR),
            false,
        ),
        AdvProperty.Text.createSelect(
            toAdvText(LAN.VARIANT),
            "variant",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.TEXT_VARIANTE_DESCR),
            4,
            true,
            "tiny",
            "xSmall",
            "small",
            "smallPlus",
            "medium",
            "mediumPlus",
            "large",
            "xLarge",
            "xLargePlus",
            "xxLarge",
            "xxLargePlus",
            "superLarge",
            "mega",
        ),
        ...AdvCommonComponentAttributes,
        ...AdvThemeProviderProperties,
        ...AdvStackItemDesignable.CommonProperties,
        ...AdvGridItemDesignable.CommonProperties,
    ],
    propertiesBuilders: [],
    presets: [
        {
            name: LAN.TEXT_FIELD_PRESET.text,
            translationContext: LAN.TEXT_FIELD_PRESET.context,
            properties: [
                { name: "ignoreTranslation", value: true },
                { name: "align", value: "center" },
            ],
        },
    ],
});

type TAdvHeadlineProps = Omit<TAdvTextProps, "variant">;
export const AdvHeadline = ({ styles, children, ...props }: TAdvHeadlineProps) => {
    return (
        <AdvText
            {...props}
            styles={combineStylesWithToken(defaultTextStyles, styles)}
            variant={"xxLarge"}
        >
            {children}
        </AdvText>
    );
};

type TAdvHeadlineSmallProps = Omit<TAdvTextProps, "variant">;
export const AdvHeadlineSmall = ({ styles, children, ...props }: TAdvHeadlineSmallProps) => {
    return (
        <AdvText
            {...props}
            styles={combineStylesWithToken(defaultTextStyles, styles)}
            variant={"xLarge"}
        >
            {children}
        </AdvText>
    );
};
