import { TCommonValueProps } from "@components/inputs";
import { ISearchBoxStyleProps, ISearchBoxStyles, IStyleFunctionOrObject } from "@fluentui/react";
import { FluentProvider } from "@fluentui/react-components";
import { createV9Theme } from "@fluentui/react-migration-v8-v9";
import { SearchBox, SearchBoxProps } from "@fluentui/react-search-preview";
import { useT } from "@hooks/language/useTranslation";
import useAdvComponent from "@hooks/useAdvComponent";
import useAdvTheme from "@hooks/useAdvTheme";
import { useAdvValue } from "@hooks/useAdvValue";
import { deepCompareJSXProps } from "@utils/deep-compare";
import { combineStyles } from "@utils/styling";
import React, { useMemo } from "react";

export type TAdvSearchInputStyles = ISearchBoxStyles; /* do not change */
export type TAdvSearchInputStyleProps = ISearchBoxStyleProps; /* do not change */

export type TAdvSearchInputProps = SearchBoxProps &
    TCommonValueProps<string> & {
        styles?: IStyleFunctionOrObject<TAdvSearchInputStyleProps, TAdvSearchInputStyles>;
        placeholder: string;
        placeholderTranslationContext?: string;
        placeholderIgnoreTranslation?: boolean;
    };
/**
 * @summary Wrapper für ``SearchBox``
 * @description Alternative zu einem AdvTextInput
 * @important Hier gibt es nützliche Events: onClear, onEscape, onSearch
 * @link https://developer.microsoft.com/en-us/fluentui#/controls/web/SearchBox
 */
const AdvSearchInputComp = React.forwardRef(
    (
        {
            value,
            placeholder,
            placeholderTranslationContext,
            placeholderIgnoreTranslation = false,
            onValueChanged,
            styles,
            ...props
        }: TAdvSearchInputProps,
        ref: any,
    ) => {
        useAdvComponent(AdvSearchInputComp, props);

        const { internalValue, onInternalChange } = useAdvValue(value, onValueChanged);

        const { t: placeholderT, hasErr } = useT(
            placeholderIgnoreTranslation ? undefined : placeholder,
            placeholderTranslationContext,
        );

        const theme = useAdvTheme();
        const myStyles = useMemo(() => {
            if (hasErr) return combineStyles(styles, { field: theme.custom.textNotTranslated });
            else return styles;
        }, [hasErr, styles, theme.custom.textNotTranslated]);

        return (
            <FluentProvider theme={createV9Theme(theme)} style={{ backgroundColor: "transparent" }}>
                <SearchBox
                    {...props}
                    ref={ref}
                    style={(myStyles as any)?.field}
                    value={internalValue}
                    onChange={(ev, data) => onInternalChange(ev as any, data.value)}
                    placeholder={placeholderIgnoreTranslation ? placeholder : placeholderT}
                    dismiss={<></>}
                ></SearchBox>
            </FluentProvider>
        );
    },
);
AdvSearchInputComp.displayName = "AdvSearchInputComp";

const AdvSearchInput = React.memo(AdvSearchInputComp, deepCompareJSXProps);
export default AdvSearchInput;
