import AdvGridItemDesignable from "@components/layout/grid/grid-item/designable";
import AdvStackItemDesignable from "@components/layout/stack/stack-item/designable";
import {
    AdvCommonComponentAttributes,
    AdvThemeProviderProperties,
    TAdvCommonProperties,
} from "@components/other/common-properties";
import { AdvRenderLabelTextInput } from "@components/other/info-label";
import { LAN } from "@data/language/strings";
import { DefaultComponentCategory } from "@feature/Designer/types/category";
import { TAdvDesignerComponentProps } from "@feature/Designer/types/component-props";
import { EComponentTypeInput } from "@feature/Designer/types/component-type";
import {
    AdvProperty,
    getDesignerModeComponentStyle,
    getSelectedComponentStyle,
    registerDesignableComponent,
} from "@feature/Designer/utils";
import { ComboBox, IComboBoxOption, IComboBoxProps, IComboBoxStyles } from "@fluentui/react";
import {
    IsValueBindingTrivial,
    TAdvValueBindingParams,
    useAdvValueBinderAsArrayNoDataType,
    useAdvValueBinderNoDataType,
} from "@hooks/dynamic/useAdvValueBinder";
import { TAdvTranslationText, toAdvText, useT } from "@hooks/language/useTranslation";
import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import useAdvComponent from "@hooks/useAdvComponent";
import { useAdvMemoWithUpdater } from "@hooks/useAdvMemoWithUpdater";
import useAdvTheme from "@hooks/useAdvTheme";
import { ComboboxIcon } from "@themes/icons";
import { EAdvValueDataTypes } from "@utils/data-types";
import { deepCompareJSXProps } from "@utils/deep-compare";
import { mergeObjects } from "@utils/styling";
import { nanoid } from "nanoid";
import React, { useMemo } from "react";

import { TCommonValueProps } from "..";

export type TAdvComboBoxItem = IComboBoxOption;
export type TAdvComboBoxStyles = IComboBoxStyles; /* do not change */

export type TAdvComboBoxProps = Omit<
    IComboBoxProps,
    | "options"
    | "styles"
    | "label"
    | "key"
    | "selectedKey"
    | "selectedKeys"
    | "onChange"
    | "onValueChange"
    | "text"
> &
    TAdvDesignerComponentProps &
    TAdvCommonProperties &
    TCommonValueProps<string> & {
        label: string;
        labelBindingParams?: TAdvValueBindingParams;
        // a context specializing the translation used
        translationContext?: string;
        valueBindingParams?: TAdvValueBindingParams;
        options: TAdvComboBoxItem[];
        styles?: Partial<TAdvComboBoxStyles>;

        allowFreeform?: boolean;
        autoComplete?: "on" | "off";

        // more complex option building, text and data splitted
        optionsText?: string[];
        optionsTextBindingParams?: TAdvValueBindingParams;
        optionsData?: any[];
        optionsDataBindingParams?: TAdvValueBindingParams;

        multiSelectBindingParams?: TAdvValueBindingParams;

        /**
         * Zeigt ein Infoicon, welches detalierte Informationen gibt, sobald der User mit dem Cursor drüber geht
         */
        info?: TAdvTranslationText;
    };

const AdvComboBoxImplComp = ({
    label,
    advhide,
    translationContext,
    styles: propStyles,
    value,
    info,
    onRenderLabel,
    designerData,
    designerProps,
    ...props
}: TAdvComboBoxProps & Pick<IComboBoxProps, "onChange" | "selectedKey">) => {
    useAdvComponent(AdvComboBoxImplComp, props);

    const theme = useAdvTheme();
    const { t, hasErr } = useT(label, translationContext);

    const styles = useMemo(() => {
        let styles = propStyles;
        if (hasErr)
            styles = mergeObjects(styles ?? {}, {
                label: { ...theme.custom.textNotTranslated },
            });
        if ((designerData?.isSelected ?? false) && (designerData?.renderAsDesigner ?? false))
            styles = mergeObjects(styles ?? {}, { root: getSelectedComponentStyle(theme, true) });
        if (designerData?.renderAsDesigner ?? false)
            styles = mergeObjects(styles ?? {}, { root: getDesignerModeComponentStyle(theme) });
        return styles;
    }, [designerData?.isSelected, designerData?.renderAsDesigner, hasErr, propStyles, theme]);

    if (advhide === true && designerProps === undefined) return <></>;
    return (
        <ComboBox
            {...props}
            {...designerProps}
            text={value}
            styles={styles}
            label={t}
            onRenderLabel={
                info != undefined
                    ? AdvRenderLabelTextInput.bind(null, info, onRenderLabel)
                    : onRenderLabel
            }
        />
    );
};
const AdvComboBoxImpl = React.memo(AdvComboBoxImplComp, deepCompareJSXProps);

function setMultiSelectComboBoxValue(
    old: string | undefined,
    option: IComboBoxOption | undefined,
    value: string | undefined,
) {
    const values = (old?.length ?? 0) > 0 ? old?.split(",") ?? [] : [];
    const foundIndex = values.findIndex((v) => v === option?.text);
    if (foundIndex != -1) {
        if (value == undefined || option?.selected !== true) values.splice(foundIndex, 1);
        return values.join(",");
    } else if (value != undefined) {
        values.push(value);
        return values.join(",");
    }
    return old;
}

const AdvComboBoxSimple = ({
    options,
    optionsData,
    optionsText,
    onValueChanged,
    multiSelect,
    value,
    ...props
}: TAdvComboBoxProps) => {
    const optionsSplitted = useMemo<{ text: any[]; data: any[] }>(() => {
        const res: { text: any[]; data: any[] } = { data: [], text: [] };
        if (optionsData == undefined && optionsText == undefined) {
            res.data = options.map((val) => {
                const data = val.data != undefined ? val.data : val.text;
                return data;
            });
            res.text = options.map((val) => {
                return val.text;
            });
        } else {
            if (optionsData == undefined && optionsText != undefined) {
                res.data = optionsText.map((val) => val);
                res.text = optionsText.map((val) => val);
            } else if (optionsData != undefined) {
                res.data = optionsData.map((val) => val);
                if (optionsText != undefined) res.text = optionsText.map((val) => val);
                else res.text = optionsData.map((val) => val);
                // make them equally sized
                while (res.text.length < res.data.length) res.text.push(res.data[res.text.length]);
            }
        }
        return res;
    }, [options, optionsData, optionsText]);

    const optionKeys = useMemo(() => {
        const res: (string | number)[] = [];
        if (optionsData == undefined && optionsText == undefined) {
            options.forEach((val) => res.push(val.key));
        } else {
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let i = 0; i < optionsSplitted.data.length; ++i) res.push(nanoid());
        }
        return res;
    }, [options, optionsData, optionsSplitted.data.length, optionsText]);

    const [curVal, , setCurVal] = useAdvMemoWithUpdater(() => value, [value]);

    const optionsCached = useMemo<IComboBoxOption[]>(() => {
        const values: string[] = [];
        if (multiSelect === true && curVal != undefined) {
            values.push(...curVal.split(","));
        }

        let res: IComboBoxOption[] = [];
        res = optionsSplitted.data.map((val, index) => {
            let isSelected: boolean | undefined = undefined;
            if (values.includes(val)) isSelected = true;
            return {
                key: optionKeys[index],
                text: optionsSplitted.text[index],
                data: val,
                selected: isSelected,
            };
        });
        return res;
    }, [multiSelect, optionKeys, optionsSplitted.data, optionsSplitted.text, curVal]);

    const handleChange = useAdvCallback(
        (event: any, option?: IComboBoxOption, index?: number, value?: string) => {
            if (multiSelect !== true) {
                setCurVal(value);
            } else {
                setCurVal((old) => {
                    return setMultiSelectComboBoxValue(old, option, value);
                });
            }
            if (typeof onValueChanged != "undefined") onValueChanged(value, event);
        },
        [multiSelect, onValueChanged, setCurVal],
    );

    return (
        <AdvComboBoxImpl
            {...props}
            onChange={handleChange}
            value={curVal}
            options={optionsCached}
            // maintain ourself, bcs fluent ui else selects the first option even if value does not fit
            selectedKey={optionsCached.findIndex((val) => val.data === curVal)}
            multiSelect={multiSelect}
        ></AdvComboBoxImpl>
    );
};

const AdvComboBoxComplex = ({
    disabled,
    allowFreeform,
    optionsText,
    optionsTextBindingParams,
    optionsData,
    optionsDataBindingParams,
    valueBindingParams,
    labelBindingParams,
    advhide,
    advhideBindingParams,
    options,
    value,
    label,
    multiSelect,
    multiSelectBindingParams,
    onValueChanged,
    dataArrayIndex = 0,
    ...props
}: TAdvComboBoxProps) => {
    const [isMultiSelect] = useAdvValueBinderNoDataType(
        multiSelectBindingParams,
        multiSelect ?? false,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );

    const optionsSplitted = useMemo<{ text: any[]; data: any[] }>(() => {
        const res: { text: any[]; data: any[] } = { data: [], text: [] };
        if (optionsData == undefined && optionsText == undefined) {
            res.data = options.map((val) => {
                const data = val.data != undefined ? val.data : val.text;
                return data;
            });
            res.text = options.map((val) => {
                return val.text;
            });
        } else {
            if (optionsData == undefined && optionsText != undefined) {
                res.data = optionsText.map((val) => val);
                res.text = optionsText.map((val) => val);
            } else if (optionsData != undefined) {
                res.data = optionsData.map((val) => val);
                if (optionsText != undefined) res.text = optionsText.map((val) => val);
                else res.text = optionsData.map((val) => val);
                // make them equally sized
                while (res.text.length < res.data.length)
                    res.text.push(res.data[res.text.length - 1]);
            }
        }
        return res;
    }, [options, optionsData, optionsText]);

    const optionKeys = useMemo(() => {
        const res: (string | number)[] = [];
        if (optionsData == undefined && optionsText == undefined) {
            options.forEach((val) => res.push(val.key));
        } else {
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let i = 0; i < optionsSplitted.data.length; ++i) res.push(nanoid());
        }
        return res;
    }, [options, optionsData, optionsSplitted.data.length, optionsText]);

    const [currentOptionsText] = useAdvValueBinderAsArrayNoDataType(
        optionsTextBindingParams,
        optionsSplitted.text,
        EAdvValueDataTypes.Any,
    );
    const [currentOptionsData] = useAdvValueBinderAsArrayNoDataType(
        optionsDataBindingParams,
        optionsSplitted.data,
        EAdvValueDataTypes.Any,
    );
    const [currentValue, setCurrentValue, attributes] = useAdvValueBinderNoDataType(
        valueBindingParams,
        value != undefined ? value : "",
        multiSelect === true
            ? EAdvValueDataTypes.ArrayAsCommaSeperatedString
            : EAdvValueDataTypes.Any,
        dataArrayIndex,
    );
    const [currentLabel] = useAdvValueBinderNoDataType(
        labelBindingParams,
        label,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );

    const optionsCached = useMemo<IComboBoxOption[]>(() => {
        const values: string[] = [];
        if (multiSelect === true && currentValue != undefined) {
            values.push(...currentValue.split(","));
        }

        let res: IComboBoxOption[] = [];
        const dataUniqueness = new Map<string, { data: any; index: number }>();
        currentOptionsData.forEach((val, index) =>
            dataUniqueness.set(JSON.stringify(val), { data: val, index: index }),
        );
        res = currentOptionsData
            .filter((val, index) => (dataUniqueness.get(JSON.stringify(val))?.index ?? -1) == index)
            .map((val) => {
                const index = dataUniqueness.get(JSON.stringify(val))?.index ?? -1;

                let isSelected: boolean | undefined = undefined;
                if (values.includes(val)) isSelected = true;

                return {
                    key: optionKeys[index],
                    text:
                        index < currentOptionsText.length
                            ? currentOptionsText[index]
                            : val.toString(),
                    data: val,
                    selected: isSelected,
                };
            });
        return res;
    }, [multiSelect, currentValue, currentOptionsData, optionKeys, currentOptionsText]);

    const handleChange = useAdvCallback(
        (event: any, option?: IComboBoxOption, index?: number, value?: string) => {
            let canSet = true;
            if (value != undefined)
                canSet = setCurrentValue(
                    isMultiSelect
                        ? setMultiSelectComboBoxValue(currentValue, option, value)
                        : option?.data?.toString() ?? value,
                );
            if (canSet && typeof onValueChanged != "undefined") onValueChanged(value, event);
        },
        [currentValue, isMultiSelect, onValueChanged, setCurrentValue],
    );

    const [shouldHide] = useAdvValueBinderNoDataType(
        advhideBindingParams,
        advhide,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );

    if (attributes.isVisible)
        return (
            <AdvComboBoxImpl
                {...props}
                optionsText={optionsText}
                optionsData={optionsData}
                optionsTextBindingParams={optionsTextBindingParams}
                optionsDataBindingParams={optionsDataBindingParams}
                valueBindingParams={valueBindingParams}
                labelBindingParams={labelBindingParams}
                advhide={shouldHide}
                advhideBindingParams={advhideBindingParams}
                dataArrayIndex={dataArrayIndex}
                options={optionsCached}
                value={currentValue}
                label={currentLabel}
                onChange={handleChange}
                onInputValueChange={(text) => {
                    if (allowFreeform === true) handleChange(undefined, undefined, undefined, text);
                }}
                allowFreeform={allowFreeform}
                disabled={(disabled ?? false) || !attributes.isEditable}
                // maintain ourself, bcs fluent ui else selects the first option even if value does not fit
                selectedKey={optionsCached.findIndex((val) => val.data === currentValue)}
                multiSelect={isMultiSelect}
                multiSelectBindingParams={multiSelectBindingParams}
            ></AdvComboBoxImpl>
        );
    else return <></>;
};

/**
 * Eine ``AdvComboBox`` ermöglicht auch eigene Text-Eingaben ({@link TAdvComboBoxProps.allowFreeform}) sowie Auto-Complete ({@link TAdvComboBoxProps.autoComplete}).
 * Das {@link value} ist entsprechend ein ``string`` und kein ``AdvComboBoxItem``.
 *
 * @summary Wrapper für ``ComboBox``
 * @link https://developer.microsoft.com/en-us/fluentui#/controls/web/ComboBox
 */
const AdvComboBoxComp = ({
    optionsTextBindingParams,
    optionsDataBindingParams,
    valueBindingParams,
    labelBindingParams,
    advhideBindingParams,
    multiSelectBindingParams,
    ...props
}: TAdvComboBoxProps) => {
    if (
        IsValueBindingTrivial(optionsTextBindingParams) &&
        IsValueBindingTrivial(optionsDataBindingParams) &&
        IsValueBindingTrivial(valueBindingParams) &&
        IsValueBindingTrivial(labelBindingParams) &&
        IsValueBindingTrivial(advhideBindingParams) &&
        IsValueBindingTrivial(multiSelectBindingParams)
    )
        return (
            <AdvComboBoxSimple
                {...props}
                optionsTextBindingParams={optionsTextBindingParams}
                optionsDataBindingParams={optionsDataBindingParams}
                valueBindingParams={valueBindingParams}
                labelBindingParams={labelBindingParams}
                advhideBindingParams={advhideBindingParams}
                multiSelectBindingParams={multiSelectBindingParams}
            ></AdvComboBoxSimple>
        );
    else
        return (
            <AdvComboBoxComplex
                {...props}
                optionsTextBindingParams={optionsTextBindingParams}
                optionsDataBindingParams={optionsDataBindingParams}
                valueBindingParams={valueBindingParams}
                labelBindingParams={labelBindingParams}
                advhideBindingParams={advhideBindingParams}
                multiSelectBindingParams={multiSelectBindingParams}
            ></AdvComboBoxComplex>
        );
};

const AdvComboBox = React.memo(AdvComboBoxComp, deepCompareJSXProps);
export default AdvComboBox;

registerDesignableComponent({
    staticData: {
        name: LAN.COMBOBOX.text,
        translationContext: LAN.COMBOBOX.context,
        type: EComponentTypeInput.Combobox,
        supportsChildren: false,
        category: DefaultComponentCategory.Input,
        icon: ComboboxIcon,
    },
    properties: [
        AdvProperty.Text.createSuggestion(
            toAdvText(LAN.LABEL),
            "label",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.COMBOBOX_LABEL_DESCR),
            "ComboBox-Label",
        ),
        AdvProperty.Text.create(
            toAdvText(LAN.TRANSLATION_CONTEXT),
            "translationContext",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.TRANSLATION_CONTEXT_DESCR),
            "",
            false,
        ),
        AdvProperty.Text.create(
            toAdvText(LAN.VALUE),
            "value",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.COMBOBOX_VALUE_DESCR),
            "TestValue",
            undefined,
            undefined,
            true,
        ),
        AdvProperty.Boolean.create(
            toAdvText(LAN.COMBOBOX_ALLOW_FREEFORM),
            "allowFreeform",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.COMBOBOX_ALLOWFREEFORM_DESCR),
            false,
        ),
        AdvProperty.Boolean.create(
            toAdvText(LAN.MULTI_SELECT),
            "multiSelect",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.COMBOBOX_MULTI_SELECT_DESCR),
            false,
        ),
        AdvProperty.List.create(
            toAdvText(LAN.DROPDOWN_DATA_ITEMS),
            toAdvText(LAN.DATA),
            "optionsData",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.COMBOBOX_OPTIONS_DATA_DESCR),
            [],
        ),
        AdvProperty.Object.createConst("options", []),
        ...AdvCommonComponentAttributes,
        ...AdvThemeProviderProperties,
        ...AdvStackItemDesignable.CommonProperties,
        ...AdvGridItemDesignable.CommonProperties,
    ],
    propertiesBuilders: [],
    presets: [],
});
