// helper string to easily find text that is not part of this list
const tHelp = "";
// prettier-ignore
export const L4N_EXTERNAL = { // L4N damit es nicht beim tippen vorgeschlagen wird
    WELCOME: { text: tHelp + "Startseite", context: "" },
    GENERAL: { text: tHelp + "Allgemeines", context: "" },
    THEME_OVERLOAD: { text: tHelp + "Theme-Änderung", context: "" },
    SPECIAL: { text: tHelp + "Besondere", context: "" },
    PROTOTYP: { text: tHelp + "Prototyp", context: "" },
    ADVANCED: { text: tHelp + "Fortgeschritten", context: "" },
    DESIGNER: { text: tHelp + "Designer", context: "" },
    UI_DESIGNER: { text: tHelp + "UI-Designer", context: "" },
    DATASOURCES: { text: tHelp + "Datenquellen", context: "" },
    WEBACTION: { text: tHelp + "Webaktion", context: "" },
    ACTIONS: { text: tHelp + "Aktionen", context: "" },
    THEMES: { text: tHelp + "Themes", context: "" },
    MISCELLANEOUS: { text: tHelp + "Sonstiges", context: "" },
    SETTINGS: { text: tHelp + "Einstellungen", context: "" },
    ADMIN_SETTINGS: { text: tHelp + "Admin-Einstellungen", context: "" },
    CONTROLS: { text: tHelp + "Bedienung", context: "" },

    COPY_VALUE: { text: tHelp + "Wert kopieren", context: "" },
    CONFIG_SESSION_PERM: { text: tHelp + "Berechtigungen der Sitzung konfigurieren", context: "" },
    ADD_NEWS: { text: tHelp + "Neuigkeiten hinzufügen", context: "" },

    // UI-Designer
    UI_DESIGNER_ERR: { text: tHelp + "Es ist ein Fehler aufgetreten!", context: "" },

    APPL_MASS: { text: tHelp + "Änderungsmaß", context: "" },
    APPL_PLATZ: { text: tHelp + "Platz", context: "" },

    NEWS: { text: tHelp + "News", context: "" },
    UNKNOWN_ARTGROE: { text: tHelp + "(unbekannt)", context: "" },
    WEARER_ARTICLE: { text: tHelp + "Trägerartikel", context: "" },
    ADD_MORE_WEARER_ARTICLE: { text: tHelp + "Weiteren Trägerartikel hinzufügen", context: "" },
    RELOAD: { text: tHelp + "Neu laden", context: "" },

    EXCEL_EXPORT: { text: tHelp + "Excel-Export", context: "" },
    RESET_TABLE_VIEW: { text: tHelp + "Darstellung zurücksetzen", context: "" },
    FORM_REQUIRED_ERROR: { text: tHelp + "Fehlende Eingabe", context: "" },

    // Themes
    DARK_THEME_DEFAULT: { text: tHelp + "Dunkler Modus|[Standard]", context: "" },
    LIGHT_THEME_DEFAULT: { text: tHelp + "Heller Modus|[Standard]", context: "" },
    COLOR_PREVIEW: { text: tHelp + "Farbvorschau", context: "" },
    PRIMARY_COLOR: { text: tHelp + "Primäre Farbe", context: "" },
    TEXT_COLOR: { text: tHelp + "Textfarbe", context: "" },
    BACKGROUND_COLOR: { text: tHelp + "Hintergrundfarbe", context: "" },
    HOVER_COLOR: { text: tHelp + "Hover-Farbe", context: "" },
    TOPBAR_NAV_PAGE_MODE: { text: tHelp + "Aufgeteilter Modus", context: "" },
    EXTENDED_MODE: { text: tHelp + "Erweiterter Modus", context: "" },
    EASY_MODE: { text: tHelp + "Vereinfachter Modus", context: "" },
    PAGE_CONTENT_COLOR: { text: tHelp + "Farben des Seiteninhalts", context: "" },
    TOPBAR_COLOR: { text: tHelp + "Farben der Kopfzeile", context: "" },
    NAV_COLOR: { text: tHelp + "Farben der Navigationsleiste", context: "" },
    THEME_PREVIEW: { text: tHelp + "Themevorschau", context: "" },
    ENABLE_THEME: { text: tHelp + "Theme aktivieren", context: "" },
    DISABLE_THEME: { text: tHelp + "Theme deaktivieren", context: "" },
    ICONS_THEME: { text: tHelp + "Icons", context: "" },
    ICON_COLOR_AND_TYPE: { text: tHelp + "Icons", context: "" },
    RESET_COLORS: { text: tHelp + "Farben zurücksetzen", context: "" },
    CHOOSE_FILE: { text: tHelp + "Datei auswählen", context: "" },
    DELETE_LOGO: { text: tHelp + "Logo löschen", context: "" },
    NAV_GROUP1: { text: tHelp + "Kunde", context: "" },
    NAV_GROUP2: { text: tHelp + "Kleidung", context: "" },

    LOGO: { text: tHelp + "Logo", context: "" },
    LOGO_TOPBAR: { text: tHelp + "Logo der Kopfzeile", context: "" },
    LOGO_LOGIN: { text: tHelp + "Logo der Anmeldung", context: "" },
    BACKGROUND_LOGIN: { text: tHelp + "Hintergrund der Anmeldung", context: "" },

    THEME_INLINE: { text: tHelp + "Theme", context: "inline" },

    ICON_SET_TYPE: { text: tHelp + "Variante", context: "" },
    ICON_PRIMARY_COLOR: { text: tHelp + "Primäre Farbe", context: "" },
    ICON_SECONDARY_COLOR: { text: tHelp + "Sekundäre Farbe|(nur für Duotone Variante)", context: "" },
    ICON_PRIMARY_HOVER_COLOR: { text: tHelp + "Hover|Primäre Farbe", context: "" },
    ICON_SECONDARY_HOVER_COLOR: { text: tHelp + "Hover|Sekundäre Farbe|(nur für Duotone Variante)", context: "" },
    ICON_PRIMARY_DISABLED_COLOR: { text: tHelp + "Deaktiviert|Primäre Farbe", context: "" },
    ICON_SECONDARY_DISABLED_COLOR: { text: tHelp + "Deaktiviert|Sekundäre Farbe|(nur für duotone Variante)", context: "" },

    // News
    HEADLINE: { text: tHelp + "Überschrift", context: "" },
    TOPLINE: { text: tHelp + "Kategorie", context: "" },
    SHORTTEXT: { text: tHelp + "Zusammenfassung", context: "" },
    MEDIA: { text: tHelp + "Medien", context: "" },
    MEDIA_INFO: { text: tHelp + "Das Thumbnail ist 250x150 groß", context: "" },
    DATE: { text: tHelp + "Datum", context: "" },
    CONTENT: { text: tHelp + "Inhalt", context: "" },
    EXTERNAL_LINK_OPT: { text: tHelp + "Externer Link (optional)", context: "" },

    // nav
    NAVS: { text: tHelp + "Navigationen", context: "inline" },
    NAV_INLINE: { text: tHelp + "Navigation", context: "inline" },

    NAV_GROUP_COLLAPSED: { text: tHelp + "Gruppe standardmäßig geschlossen", context: "" },
    NAV_GROUP_ICON: { text: tHelp + "Icon ändern", context: "" },
    NAV_GROUP_ICON_RESET: { text: tHelp + "Icon löschen", context: "" },
    NAV_GROUP_ADD: { text: tHelp + "Gruppe hinzufügen", context: "" },
    NAV_GROUP_ITEM_ADD: { text: tHelp + "Eintrag hinzufügen", context: "" },
    NAV_ITEM_ACTION: { text: tHelp + "Eintrag", context: "" },

    // Usersettings
    USERSETTINGS: { text: tHelp + "Benutzerdaten", context: "" },
    ANREDE: { text: tHelp + "Anrede", context: "" },
    TITEL: { text: tHelp + "Titel", context: "" },
    VORNAME: { text: tHelp + "Vorname", context: "" },
    NACHNAME: { text: tHelp + "Nachname", context: "" },
    EMAIL: { text: tHelp + "E-Mail", context: "" },
    CHANGE_PASSWORD_HEADLINE: { text: tHelp + "Passwort ändern", context: "" },
    OLD_PASSWORD: { text: tHelp + "Altes Passwort", context: "" },
    NEW_PASSWORD_CONFIRM: { text: tHelp + "Neues Passwort bestätigen", context: "" },
    CHANGE_PASSWORD: { text: tHelp + "Passwort ändern", context: "" },

    // Misc
    NEW: { text: tHelp + "Neu", context: "" },
    SAVE: { text: tHelp + "Speichern", context: "" },
    CANCEL: { text: tHelp + "Abbrechen", context: "" },
    COPY: { text: tHelp + "Kopieren", context: "" },
    AUTOSAVED: { text: tHelp + "Automatisch gespeichert", context: "" },
    DELETE: { text: tHelp + "Löschen", context: "" },
    LOGOUT: { text: tHelp + "Abmelden", context: "" },
    UNDO: { text: tHelp + "Undo", context: "" },
    REDO: { text: tHelp + "Redo", context: "" },
    RESET: { text: tHelp + "Zurücksetzen", context: "" },
    CONTINUE: { text: tHelp + "Weiter", context: "" },
    PAGE_NOT_FOUND: { text: tHelp + "Seite nicht gefunden", context: "" },
    EMPTY: { text: tHelp + "Leer", context: "" },
    GUIDE: { text: tHelp + "Anleitung", context: "" },
    HEADLINE_ERROR: { text: tHelp + "Fehlermeldung", context: "" },

    STEP_1: { text: tHelp + "Schritt 1", context: "" },
    STEP_2: { text: tHelp + "Schritt 2", context: "" },
    STEP_3: { text: tHelp + "Schritt 3", context: "" },
    STEP_4: { text: tHelp + "Schritt 4", context: "" },

    // Login (2FA, Password Reset)
    LOGIN: { text: tHelp + "Anmelden", context: "" },
    LOGIN_HEADER: { text: tHelp + "Anmeldung", context: "" },
    STAY_LOGGED_IN: { text: tHelp + "Angemeldet bleiben", context: "" },
    USERNAME: { text: tHelp + "Benutzername", context: "" },
    PASSWORD: { text: tHelp + "Passwort", context: "" },
    FORGOT_PASSWORD: { text: tHelp + "Passwort vergessen?", context: "" },
    TWOFACTOR_CONFIGURE: { text: tHelp + "2FA konfigurieren", context: "" },
    TWOFACTOR_ACTIVATE: { text: tHelp + "2-Faktor Authentifizierung aktivieren", context: "" },
    ONETIME_CODE: { text: tHelp + "Einmaliger Code", context: "" },
    TWOFACTOR_HEADER: { text: tHelp + "2-Faktor Authentifizierung", context: "" },
    RESET_HEADER: { text: tHelp + "Passwort vergessen", context: "" },
    AUTHENTICATE: { text: tHelp + "Authentifizieren", context: "" },
    USERNAME_OR_EMAIL: { text: tHelp + "Benutzername oder E-Mail", context: "" },
    RESET_TOKEN: { text: tHelp + "Reset-Token", context: "" },
    NEW_PASSWORD: { text: tHelp + "Neues Passwort", context: "" },
    RESET_PASSWORD: { text: tHelp + "Passwort zurücksetzen", context: "" },
    RESET_USING_TOKEN: { text: tHelp + "Token nutzen", context: "" },
    GOOGLEAUTH_DOWNLOAD: { text: tHelp + "Google Authenticator App herunterladen", context: "" },
    GOOGLEAUTH_START: { text: tHelp + "Authenticator starten und das \"+\" Menü", context: "" },
    SCAN_QRCODE: { text: tHelp + "QR-Code scannen", context: "" },
    ENTER_OTP: { text: tHelp + "Die 6 Zahlen aus der Liste in das Textfeld eingeben", context: "" },
    TWOFACTOR_RECOMMENDED: { text: tHelp + "Es wird empfohlen die 2-Faktor Authentifizierung zu aktivieren!", context: "" },
    TWOFACTOR_THEFT: { text: tHelp + "Sie verringert die Wahrscheinlichkeit eines Account-Diebstahls.", context: "" },
    TWOFACTOR_CHECK_APP: { text: tHelp + "Geben Sie den Code aus der Authenticator App ein.", context: "" },
    RESET_EMAIL_SENT: { text: tHelp + "E-Mail versendet!", context: "" },
    RESET_DONE: { text: tHelp + "Erfolgreich zurückgesetzt!", context: "" },
    PASSWORD_GUIDELINE: { text: tHelp + "Passwort-Richtlinien", context: "" },
    PASSWORD_GUIDELINE_LEN: { text: tHelp + "Zwischen 8 und 24 Zeichen lang", context: "" },
    PASSWORD_GUIDELINE_CASE: { text: tHelp + "Groß- und Kleinschreibung enthalten", context: "" },
    PASSWORD_GUIDELINE_NMBER: { text: tHelp + "Zahl enthalten", context: "" },
    PASSWORD_GUIDELINE_SPCL: { text: tHelp + "Sonderzeichen enthalten", context: "" },

    LOGIN_ERR_USERNAME: { text: tHelp + "Benutzername oder Passwort falsch", context: "" },
    LOGIN_ERR_NEED_OTP: { text: tHelp + "OTP Token nötig", context: "" },
    LOGIN_ERR_WRONG_OTP: { text: tHelp + "OTP Token ungültig", context: "" },
    LOGIN_ERR_CONF_OTP: { text: tHelp + "OTP Konfiguration nötig", context: "" },
    LOGIN_ERR_NO_PERM: { text: tHelp + "Keine Berechtigung", context: "" },
    LOGIN_ERR_INACTIVE: { text: tHelp + "Benutzer ist inaktiv", context: "" },

    SEARCH_DOT_DOT_DOT: { text: tHelp + "Suchen...", context: "" },

    DEFAULT_THEME: { text: tHelp + "Standard-Theme", context: "" },
    CURRENT_THEME: { text: tHelp + "Aktuelles Theme", context: "" },
    MARK_MISSING_TRANSLATIONS: { text: tHelp + "Unterstreiche fehlende Übersetzungen", context: "" },
    APP_TITLE: { text: tHelp + "Anwendungstitel", context: "" },
    APP_ICON: { text: tHelp + "Anwendungssymbol", context: "" },
    THEME_AUTO_DETECT_BROWSER: { text: tHelp + "Automatisch durch Browser erkennen", context: "" },
    AUTOMATIC: { text: tHelp + "Automatisch", context: "" },

    LIST_OF_CHANGED_DATA_COLON: { text: tHelp + "Liste der Daten, welche geändert wurden:", context: "" },
    LANGUAGES: { text: tHelp + "Sprachen", context: "" },
    SWITCH_LANGUAGE: { text: tHelp + "Sprache wechseln", context: "" },
    WEBLINK_LANGUAGE: { text: tHelp + "Weitere Links", context: "" },

    PERMISSIONS_CONFIGURE: { text: tHelp + "Berechtigungen konfigurieren", context: "" },
    PERMISSIONS_ACTIONS: { text: tHelp + "Aktionen", context: "" },
    PERMISSIONS_ADD: { text: tHelp + "Berechtigung hinzufügen", context: "" },
    SES_PERMISSIONS_CONFIGURE: { text: tHelp + "Berechtigungen der Sitzung konfigurieren", context: "" },

    SELECT_CUSTOMER: { text: tHelp + "Kunden auswählen", context: "" },
    LOADING_CUSTOMERS: { text: tHelp + "Lade Kunden...", context: "" },
    CONFIRM_CUSTOMERS: { text: tHelp + "Die Seite muss neugeladen werden.|Wirklich fortfahren?", context: "" },
    ALL_CUSTOMERS: { text: tHelp + "(alle Kunden)", context: "" },

    CUSTOMER: { text: tHelp + "Kunde", context: "" },
    CUSTOMER_ID: { text: tHelp + "ID", context: "" },
    CUSTOMER_NAME: { text: tHelp + "Name", context: "" },
    CUSTOMER_NR: { text: tHelp + "Kunden-Nr.", context: "" },

    ARTICLE: { text: tHelp + "Artikel", context: "" },
    ARTICLE_SIZE: { text: tHelp + "Artikelgröße", context: "" },
    ARTICLE_SIZE_SHORT: { text: tHelp + "Gr.", context: "" },
    ARTICLE_COUNT: { text: tHelp + "Menge", context: "" },

    APPLICATION: { text: tHelp + "Applikation", context: "" },
    APPLICATIONS: { text: tHelp + "Applikationen", context: "" },
    ADD_ARTICLE: { text: tHelp + "Artikel hinzufügen", context: "" },

    ALTERATIONTYPE: { text: tHelp + "Änderungsart", context: "" },
    ALTERATION_PLACEMENT: { text: tHelp + "Platzierung", context: "" },
    ALTERATION_MEASURE: { text: tHelp + "Änderungsmaß", context: "" },
    ALTERATION_APPLY: { text: tHelp + "Übernehmen", context: "" },

    ARTITYPE_ALTERATION: { text: tHelp + "Änderung", context: "" },
    ARTITYPE_NAMETAG: { text: tHelp + "Namenschild", context: "" },
    ARTITYPE_EMBLEM: { text: tHelp + "Emblem", context: "" },
    ARTITYPE_UNKNOWN: { text: tHelp + "Unbekannter Typ", context: "" },

    AUFTRAGSNR_SHORT: { text: tHelp + "Auftrags-Nr.", context: "" },
    LIEFERDATUM: { text: tHelp + "Lieferdatum", context: "" },
    URDATUM: { text: tHelp + "Urdatum", context: "" },
    STATUS: { text: tHelp + "Status", context: "" },

    KDNR_SHORT: { text: tHelp + "Kd-Nr.", context: "" },
    VSANR_SHORT: { text: tHelp + "VSA-Nr.", context: "" },
    BEZ_SHORT: { text: tHelp + "Bez.", context: "" },
    BEZ: { text: tHelp + "Bezeichnung", context: "" },
    ANFORDERUNGEN: { text: tHelp + "Anforderungen", context: "" },
    ANF_NIEDR_STATUS: { text: tHelp + "Niedr. Status", context: "" },

    ARTIKELNR_SHORT: { text: tHelp + "Artikel-Nr.", context: "" },
    ARTIKELGROESSE: { text: tHelp + "Artikel-Größe", context: "" },
    AVG_COUNT_DAY: { text: tHelp + "Ø-Menge (Tag)", context: "" },
    AVG_COUNT: { text: tHelp + "Ø-Menge", context: "" },
    VPE_SHORT: { text: tHelp + "VPE", context: "" },
    FESTEMENGE: { text: tHelp + "Feste Menge", context: "" },
    BESTMENGE: { text: tHelp + "Bestellmenge", context: "" },

    WARENKORB_ERROR: { text: tHelp + "Es ist ein Fehler aufgetreten.|Bitte wenden Sie sich an Ihren Kundenservice oder probieren es zu einem späteren Zeitpunkt erneut.", context: "" },
    WARENKORB_CONFIRM_CANCEL_ALL: { text: tHelp + "Wollen Sie wirklich alle nicht erledigten Aufträge dieses Warenkorbs abbrechen?", context: "" },
    WARENKORB_AUFTR_DETAILS: { text: tHelp + "Details zum Web-Auftrag", context: "" },
    WARENKORB_HEADLINE: { text: tHelp + "Aufträge im Warenkorb", context: "" },
    WARENKORB_EMPTY: { text: tHelp + "Warenkorb leer", context: "" },
    WARENKORB_NR: { text: tHelp + "Warenkorb-Nr.", context: "" },
    WARENKORB_STARTED: { text: tHelp + "Offen seit", context: "" },
    WARENKORB_APPROVE: { text: tHelp + "Aufträge erteilen", context: "" },
    WARENKORB_CANCEL_ALL: { text: tHelp + "Alle nicht erledigten Aufträge abbrechen", context: "" },
    WARENKORB_POS_ERROR: { text: tHelp + "Diese Position ist fehlerhaft", context: "" },
    WARENKORB_POS_REQUIRE_APPROVAL: { text: tHelp + "Diese Position benötigt eine Freigabe durch den Kunden-Service", context: "" },
    WARENKORB_POS_CANCEL: { text: tHelp + "Position abbrechen", context: "" },
    WARENKORB_POS_DETAILS: { text: tHelp + "Details einsehen", context: "" },
    WARENKORB_APPROVAL_INFO: { text: tHelp + "Einige Aufträge benötigen eine Freigabe durch den Kunden-Service.|Erst nach der Freigabe sind entsprechende Änderungen ersichtlich.", context: "" },
    WARENKORB_REQUIRE_APPROVAL: { text: tHelp + "Freigabe nötig", context: "" },
    WARENKORB: { text: tHelp + "Warenkorb", context: "" },

    ANPROBE_FREIGEBEN_TITLE: { text: tHelp + "Anprobe freigeben", context: "" },
    ANPROBE_FREIGEBEN_CONFIRM: { text: tHelp + "Möchten Sie die Anprobe wirklich freigeben?", context: "" },
    ANPROBE_FREIGEBEN_SUCCESS: { text: tHelp + "Die Anprobe wurde freigegeben.|Sie erhalten in kürze eine E-Mail!", context: "" },
    ANPROBE_FREIGEBEN_FAILURE: { text: tHelp + "Die Anprobe konnte nicht freigegeben werden.|Grund: ", context: "" },

    YES: { text: tHelp + "Ja", context: "" },
    NO: { text: tHelp + "Nein", context: "" },

    MAIL_SUPPORT: { text: tHelp + "Support", context: ""},
};

import { L4N_INTERNAL } from "./strings_internal";
const LAN = { ...L4N_INTERNAL, ...L4N_EXTERNAL };
export { LAN };
