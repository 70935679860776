import AdvGridItemDesignable from "@components/layout/grid/grid-item/designable";
import AdvStackItemDesignable from "@components/layout/stack/stack-item/designable";
import { EContractEvent, recoilContractEvent } from "@data/dynamic-page";
import { LAN } from "@data/language/strings";
import { buildPageIDForVariableID } from "@data/parameters";
import { DefaultComponentCategory } from "@feature/Designer/types/category";
import { TAdvDesignerComponentProps } from "@feature/Designer/types/component-props";
import { EComponentTypeCustom } from "@feature/Designer/types/component-type";
import {
    AdvProperty,
    getSelectedComponentStyle,
    registerDesignableComponent,
} from "@feature/Designer/utils";
import { useAdvValueBinderNoDataType } from "@hooks/dynamic/useAdvValueBinder";
import { useAdvWebAction } from "@hooks/dynamic/useAdvWebAction";
import { TAdvWebActionParams } from "@hooks/dynamic/useAdvWebAction.types";
import { toAdvText } from "@hooks/language/useTranslation";
import { useAdvEffect } from "@hooks/react-overload/useAdvEffect";
import useAdvRecoilValue from "@hooks/recoil-overload/useAdvRecoilValue";
import useAdvResetRecoilState from "@hooks/recoil-overload/useAdvResetRecoilState";
import useAdvComponent from "@hooks/useAdvComponent";
import useAdvTheme from "@hooks/useAdvTheme";
import { ActionsIcon } from "@themes/icons";
import { EAdvValueDataTypes } from "@utils/data-types";
import { deepCompareJSXProps } from "@utils/deep-compare";
import { combineStyles } from "@utils/styling";
import { useRouter } from "next/router";
import React, { useMemo } from "react";
import {
    AdvCommonComponentAttributes,
    AdvThemeProviderProperties,
    TAdvCommonProperties,
} from "../common-properties";

type TExecutionProps = {
    onContractFulfilledEventActionParams?: TAdvWebActionParams;
    onContractErrorEventActionParams?: TAdvWebActionParams;
    onContractCancelEventActionParams?: TAdvWebActionParams;

    keyRef: string;
};

const ContractExecutionEl = ({
    onContractFulfilledEventActionParams,
    onContractErrorEventActionParams,
    onContractCancelEventActionParams,
    keyRef,
}: TExecutionProps) => {
    const [, , onContractFulfilledFunc, , ,] = useAdvWebAction(
        keyRef,
        0,
        onContractFulfilledEventActionParams,
    );
    const [, , onContractErrorFunc, , ,] = useAdvWebAction(
        keyRef,
        0,
        onContractErrorEventActionParams,
    );
    const [, , onContractCancelFunc, , ,] = useAdvWebAction(
        keyRef,
        0,
        onContractCancelEventActionParams,
    );

    const router = useRouter();
    const variableID = useMemo(
        () => buildPageIDForVariableID(router.pathname, router.query),
        [router.pathname, router.query],
    );

    const contractFulfilledEvent = useAdvRecoilValue(
        recoilContractEvent({ page: variableID, event: EContractEvent.Fulfilled }),
    );
    const resetContractFulfilledEvent = useAdvResetRecoilState(
        recoilContractEvent({ page: variableID, event: EContractEvent.Fulfilled }),
    );
    const contractErrorEvent = useAdvRecoilValue(
        recoilContractEvent({ page: variableID, event: EContractEvent.Error }),
    );
    const resetContractErrorEvent = useAdvResetRecoilState(
        recoilContractEvent({ page: variableID, event: EContractEvent.Error }),
    );

    const contractCancelEvent = useAdvRecoilValue(
        recoilContractEvent({ page: variableID, event: EContractEvent.Cancel }),
    );
    const resetContractCancelEvent = useAdvResetRecoilState(
        recoilContractEvent({ page: variableID, event: EContractEvent.Cancel }),
    );

    useAdvEffect(() => {
        if (contractFulfilledEvent.triggered) {
            onContractFulfilledFunc([contractFulfilledEvent.result]);
            resetContractFulfilledEvent();
        }
    }, [contractFulfilledEvent, onContractFulfilledFunc, resetContractFulfilledEvent]);

    useAdvEffect(() => {
        if (contractErrorEvent.triggered) {
            onContractErrorFunc();
            resetContractErrorEvent();
        }
    }, [contractErrorEvent, onContractErrorFunc, resetContractErrorEvent]);

    useAdvEffect(() => {
        if (contractCancelEvent.triggered) {
            onContractCancelFunc();
            resetContractCancelEvent();
        }
    }, [contractCancelEvent, onContractCancelFunc, resetContractCancelEvent]);

    return <></>;
};

export type TAdvContractActionsProps = TAdvDesignerComponentProps &
    TAdvCommonProperties &
    TExecutionProps;

/**
 * Executes the common actions related to a contract
 * if hidden, the component will NEVER trigger these events
 */
const AdvContractActionsComp = ({
    designerData,
    designerProps,
    advhide = false,
    advhideBindingParams,
    dataArrayIndex = 0,
    onContractFulfilledEventActionParams,
    onContractErrorEventActionParams,
    onContractCancelEventActionParams,
    keyRef,
    ...props
}: TAdvContractActionsProps) => {
    useAdvComponent(AdvContractActionsComp, props);

    const [isHidden] = useAdvValueBinderNoDataType(
        advhideBindingParams,
        advhide,
        EAdvValueDataTypes.Boolean,
        dataArrayIndex,
    );

    const theme = useAdvTheme();
    const styles = useMemo(() => {
        let styles = {};
        if ((designerData?.isSelected ?? false) && (designerData?.renderAsDesigner ?? false))
            styles = combineStyles(styles, { ...getSelectedComponentStyle(theme, true) });
        return styles;
    }, [designerData?.isSelected, designerData?.renderAsDesigner, theme]);

    if (designerProps == undefined && isHidden) return <></>;
    return (
        <>
            <ContractExecutionEl
                keyRef={keyRef}
                onContractErrorEventActionParams={onContractErrorEventActionParams}
                onContractFulfilledEventActionParams={onContractFulfilledEventActionParams}
                onContractCancelEventActionParams={onContractCancelEventActionParams}
            ></ContractExecutionEl>
            {designerProps != undefined && (
                <div
                    style={{ height: 30, backgroundColor: "#ff020240", ...styles }}
                    {...props}
                ></div>
            )}
        </>
    );
};

const AdvContractActions = React.memo(AdvContractActionsComp, deepCompareJSXProps);
export default AdvContractActions;

registerDesignableComponent({
    staticData: {
        name: LAN.CONTRACT_ACTIONS_COMP.text,
        translationContext: LAN.CONTRACT_ACTIONS_COMP.context,
        type: EComponentTypeCustom.ContractActions,
        supportsChildren: false,
        category: DefaultComponentCategory.Misc,
        icon: ActionsIcon,
    },
    properties: [
        AdvProperty.Action.createLogicOnly(
            toAdvText(LAN.EVENTS_CONTRACT_FULFILLED),
            "onContractFulfilledEventActionParams",
            toAdvText(LAN.EVENTS),
            toAdvText(LAN.EVENTS_CONTRACT_FULFILLED_DESCR),
        ),
        AdvProperty.Action.create(
            toAdvText(LAN.EVENTS_CONTRACT_ERROR),
            "onContractErrorEventActionParams",
            toAdvText(LAN.EVENTS),
            toAdvText(LAN.EVENTS_CONTRACT_ERROR_DESCR),
        ),
        AdvProperty.Action.create(
            toAdvText(LAN.EVENTS_CONTRACT_CANCEL),
            "onContractCancelEventActionParams",
            toAdvText(LAN.EVENTS),
            toAdvText(LAN.EVENTS_CONTRACT_CANCEL_DESCR),
        ),
        ...AdvCommonComponentAttributes,
        ...AdvThemeProviderProperties,
        ...AdvStackItemDesignable.CommonProperties,
        ...AdvGridItemDesignable.CommonProperties,
    ],
    propertiesBuilders: [],
    presets: [],
});
