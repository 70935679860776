import { recoilPersistLocalStorage } from "@data/persist/localstorage_legacy";
import { atom } from "recoil";

const { persistAtom } = recoilPersistLocalStorage();

export enum TLoginResultTypes {
    lrtUserPwNOK = 0,
    lrtNeedOTP = 1,
    lrtOtpNOK = 2,
    lrtLoginOK = 3,
    /** Benutzer muss jetzt entscheiden, ob er 2FA aktivieren oder deaktivieren möchte. */
    lrtInitOTP = 4,
    /** Benutzer ist kein LiveUser (Mitarbei.IsLiveUser) */
    lrtNLiveUser = 5,
    /** Benutzer ist nicht aktiv */
    lrtInactive = 6,
}
export enum TLoginResultExtraTypes {
    lrtEmpty = 0,
    lrtInvalidRedirect = 1,
}

export interface IWebLink {
    KundenID: number;
    FirmaID: number;
    Bez: string;
    URL: string;
}

export interface IUserInfo {
    Name: string;
    eMail: string;
    Land: string;
    WebLanguage: string;
    Anrede: string;
    Titel: string;
}

interface ISession {
    GUID?: string;
}

interface ISessionAddInfos {
    Username?: string;
    Vorname?: string;
    Nachname?: string;
    LoginStatus?: TLoginResultTypes;
    LoginValidUntil?: number;
    CanEditPermissions?: boolean;
    LanguageID?: number;
    Navbar?: string;
    Startpage?: string;
    ServiceMail?: string;
    WebLinks?: IWebLink[];
    UserInfos?: IUserInfo;
}

export const defaultStartPage = "welcome";

export const sessionAtom = atom<ISession>({
    key: "sessionAtom",
    default: {},
    effects: [persistAtom],
});

export const sessionAddInfosAtom = atom<ISessionAddInfos>({
    key: "sessionAddInfosAtom",
    default: {},
    effects: [],
});

export const afterLoginTargetAtom = atom<{
    pathname: string;
    query: Record<string, string | string[] | undefined>;
}>({
    key: "afterLoginTargetAtom",
    default: { pathname: "", query: {} },
});
export const rememberLoginAtom = atom<boolean>({
    key: "rememberLoginAtom",
    default: false,
    effects: [persistAtom],
});
