import { IRenderFunction, Icon, Stack } from "@fluentui/react";
import { Button, FluentProvider, Tooltip } from "@fluentui/react-components";
import { createV9Theme } from "@fluentui/react-migration-v8-v9";
import { TAdvTranslationText, useT } from "@hooks/language/useTranslation";
import useAdvTheme from "@hooks/useAdvTheme";
import { CircleInfoIcon } from "@themes/icons";
import { useMemo } from "react";
import { TAdvIconProps } from "./icon";

export const AdvTooltip = ({ info, icon }: { info: TAdvTranslationText; icon: TAdvIconProps }) => {
    //const tooltipID = useId("tooltip");

    const { t } = useT(
        info.ignoreTranslation === true ? undefined : info.text,
        info.translationContext,
    );
    /*return (
        <TooltipHost
            content={t}
            id={tooltipID}
            styles={{ root: { display: "flex", alignItems: "center", overflow: "visible" } }}
        >
            <Icon
                iconName={CircleInfoIcon.iconName}
                aria-describedby={tooltipID}
                styles={{ root: { cursor: "pointer", padding: 1 } }}
            ></Icon>
        </TooltipHost>
    );*/

    const theme = useAdvTheme();
    const v9Theme = useMemo(() => {
        return createV9Theme(theme);
    }, [theme]);
    return (
        <FluentProvider
            theme={v9Theme}
            style={{
                display: "flex",
                alignItems: "center",
                overflow: "visible",
                backgroundColor: "transparent",
            }}
        >
            <Tooltip content={t ?? ""} relationship="description" appearance="normal">
                <Button
                    icon={
                        <Icon
                            iconName={icon.iconName}
                            styles={{ root: { cursor: "pointer", padding: 1 } }}
                        ></Icon>
                    }
                    style={{ border: "none", backgroundColor: "transparent" }}
                ></Button>
            </Tooltip>
        </FluentProvider>
    );
};

export const AdvInfoTooltip = ({ info }: { info: TAdvTranslationText }) => {
    return <AdvTooltip icon={CircleInfoIcon} info={info}></AdvTooltip>;
};

export const AdvRenderLabelTextInput = (
    info: TAdvTranslationText,
    overloadedOnRender: IRenderFunction<any> | undefined,
    props?: any,
    defaultRender?: IRenderFunction<any>,
) => {
    return (
        <Stack horizontal horizontalAlign="space-between" styles={{ root: { width: "100%" } }}>
            {overloadedOnRender != undefined ? (
                overloadedOnRender(props)
            ) : defaultRender != undefined ? (
                defaultRender(props)
            ) : (
                <></>
            )}
            <AdvInfoTooltip info={info}></AdvInfoTooltip>
        </Stack>
    );
};
