enum EPropertyName {
    Label = "label",
    TranslationContext = "translationContext",
    Value = "value",
    OptionsText = "optionsText",
    OptionsData = "optionsData",
}

enum EPropertyCategory {
    Allgemeines = "Allgemeines",
}

enum EPresetName {}
/*
const registeredComponent = registerDesignableComponent({
    staticData: {
        name: LAN.DROPDOWN.text,
        translationContext: LAN.DROPDOWN.context,
        type: EComponentTypeInput.Dropdown,
        supportsChildren: false,
        category: DefaultComponentCategory.Input,
        icon: DropdownIcon,
    },
    properties: [
        AdvProperty.Text.createSuggestion(
            toAdvText(LAN.LABEL),
            EPropertyName.Label,
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.DROPDOWN_LABEL_DESCR),
            "Dropdown-Label",
        ),
        AdvProperty.Text.create(
            toAdvText(LAN.TRANSLATION_CONTEXT),
            EPropertyName.TranslationContext,
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.TRANSLATION_CONTEXT_DESCR),
            "",
            false,
        ),
        AdvProperty.Text.create(
            toAdvText(LAN.VALUE),
            EPropertyName.Value,
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.DROPDOWN_VALUE_DESCR),
            "TestValue",
            undefined,
            undefined,
            true,
        ),
        AdvProperty.Boolean.create(
            toAdvText(LAN.MULTI_SELECT),
            "multiSelect",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.DROPDOWN_MULTI_SELECT_DESCR),
            false,
        ),
        AdvProperty.List.create(
            toAdvText(LAN.DROPDOWN_TEXT_ITEMS),
            toAdvText(LAN.TEXT),
            EPropertyName.OptionsText,
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.DROPDOWN_TEXT_ITEMS_DESCR),
            [],
        ),
        AdvProperty.List.create(
            toAdvText(LAN.DROPDOWN_DATA_ITEMS),
            toAdvText(LAN.DATA),
            EPropertyName.OptionsData,
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.DROPDOWN_DATA_ITEMS_DESCR),
            [],
        ),
        AdvProperty.Boolean.create(
            toAdvText(LAN.FORM_REQUIRED),
            "required",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.FORM_REQUIRED_DESCR),
            false,
        ),
        AdvProperty.Boolean.create(
            toAdvText(LAN.DISABLED),
            "disabled",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.TEXT_INPUT_DISABLED_DESCR),
            false,
        ),
        AdvProperty.Boolean.create(
            toAdvText(LAN.HIDE_WHEN_EMPTY),
            "hideWhenEmpty",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.HIDE_WHEN_EMPTY_DESCR),
            false,
            false,
        ),
        AdvProperty.Object.createConst("options", []),
        AdvProperty.Text.createConst("dropdownWidth", "auto"),
        ...AdvCommonComponentAttributes,
        ...AdvThemeProviderProperties,
        ...AdvStackItemDesignable.CommonProperties,
        ...AdvGridItemDesignable.CommonProperties,
    ],
    propertiesBuilders: [],
    presets: [],
});

const AdvDropdownDesignable = {
    PropertyName: EPropertyName,
    PropertyCategory: EPropertyCategory,
    PresetName: EPresetName,
    Registered: registeredComponent,
};

export default AdvDropdownDesignable;*/
