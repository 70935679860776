import { TAdvCommonProperties } from "@components/other/common-properties";
import { AdvRenderLabelTextInput } from "@components/other/info-label";
import { recoilPageState } from "@data/dynamic-page";
import { LAN } from "@data/language/strings";
import { buildPageIDForVariableID } from "@data/parameters";
import { TAdvDesignerComponentProps } from "@feature/Designer/types/component-props";
import { getDesignerModeComponentStyle, getSelectedComponentStyle } from "@feature/Designer/utils";
import {
    Dropdown,
    IDropdownOption,
    IDropdownProps,
    IDropdownStyleProps,
    IDropdownStyles,
    IRenderFunction,
    IStyleFunctionOrObject,
} from "@fluentui/react";
import { usePrevious } from "@fluentui/react-hooks";
import {
    EAdvValueBinderType,
    IsValueBindingTrivial,
    TAdvValueBindingParams,
    useAdvValueBinder,
    useAdvValueBinderAsArrayNoDataType,
    useAdvValueBinderNoDataType,
} from "@hooks/dynamic/useAdvValueBinder";
import { TAdvTranslationText, useT } from "@hooks/language/useTranslation";
import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import { useAdvEffect } from "@hooks/react-overload/useAdvEffect";
import useAdvRecoilValue from "@hooks/recoil-overload/useAdvRecoilValue";
import useAdvComponent from "@hooks/useAdvComponent";
import { useAdvMemoWithUpdater } from "@hooks/useAdvMemoWithUpdater";
import useAdvTheme from "@hooks/useAdvTheme";
import { EAdvValueDataTypes } from "@utils/data-types";
import { deepCompareJSXProps } from "@utils/deep-compare";
import { combineStyles } from "@utils/styling";
import { nanoid } from "nanoid";
import { useRouter } from "next/router";
import React, { useMemo, useState } from "react";

import { TCommonValueProps } from "..";

export type TAdvDropdownItem<T = any> = IDropdownOption<T> & {
    translationContext?: string;
    ignoreTranslation?: boolean;

    translatedLanguageID?: number;
    originalText?: string;
    translationError?: boolean;
};
export type TAdvSelectableOption<T = any> = Omit<TAdvDropdownItem<T>, "IsSelected">;
export type TAdvDropdownStyles = IDropdownStyles; /* do not change */
export type TAdvDropdownStyleProps = IDropdownStyleProps; /* do not change */

export type TAdvDropdownProps = Omit<
    IDropdownProps,
    | "options"
    | "styles"
    | "label"
    | "key"
    | "selectedKey"
    | "selectedKeys"
    | "onChange"
    | "onValueChange"
    | "onRenderOption"
> &
    TAdvDesignerComponentProps &
    TAdvCommonProperties &
    Omit<TCommonValueProps<TAdvDropdownItem>, "value" | "disabled"> & {
        label: string;
        labelBindingParams?: TAdvValueBindingParams;
        // a context specializing the translation used
        translationContext?: string;
        ignoreTranslation?: boolean;
        options: TAdvDropdownItem[];
        styles?: IStyleFunctionOrObject<TAdvDropdownStyleProps, TAdvDropdownStyles>;
        /** @important Wenn ein String genutzt wird, muss dieser einem Key der {@link TAdvDropdownProps.options} entsprechen! */
        value?: string | TAdvDropdownItem | undefined;
        valueBindingParams?: TAdvValueBindingParams;

        errorMsgTranslationContext?: string;

        // more complex option building, text and data splitted
        optionsText?: string[];
        optionsTextBindingParams?: TAdvValueBindingParams;
        optionsData?: any[];
        optionsDataBindingParams?: TAdvValueBindingParams;

        multiSelectBindingParams?: TAdvValueBindingParams;

        onRenderOption?: IRenderFunction<TAdvSelectableOption>;

        hideWhenEmpty?: boolean;
        hideWhenBelowRecords?: number;

        disabled?: boolean;
        disabledBindingParams?: TAdvValueBindingParams;
        /**
         * Zeigt ein Infoicon, welches detalierte Informationen gibt, sobald der User mit dem Cursor drüber geht
         */
        info?: TAdvTranslationText;
    };

const AdvDropdownItem = (
    { translationContext, ignoreTranslation, text, ...props }: TAdvSelectableOption,
    defaultRender: IRenderFunction<TAdvSelectableOption>,
) => {
    const { t: translatedText, hasErr: hasTextErr } = useT(
        text,
        translationContext,
        ignoreTranslation,
    );
    const theme = useAdvTheme();
    return (
        <div style={hasTextErr ? { ...theme.custom.textNotTranslated } : undefined}>
            {defaultRender({
                ...props,
                text: translatedText ?? text,
            })}
        </div>
    );
};

const AdvDropdownImplComp = ({
    label,
    advhide,
    translationContext,
    ignoreTranslation = false,
    onRenderOption,
    info,
    onRenderLabel,
    styles: propStyles,
    value,
    designerData,
    designerProps,
    multiSelect = false,
    options,
    placeholder,
    errorMessage,
    hideWhenEmpty = false,
    hideWhenBelowRecords = 0,
    ...props
}: TAdvDropdownProps & Pick<IDropdownProps, "onChange">) => {
    useAdvComponent(AdvDropdownImplComp, props);

    const valueAsStrOrInt = useMemo(
        () =>
            typeof value === "string" ||
            typeof value === "bigint" ||
            typeof value === "number" ||
            typeof value === "boolean"
                ? value
                : value != undefined
                ? value.key
                : "",
        [value],
    );
    const selectedKeys = useMemo<string[] | number[] | undefined>(() => {
        if (multiSelect === true) {
            return options
                .map((o) => (o.selected === true ? o.key : typeof o.key == "string" ? "" : -1))
                .filter((i) => i != "" && i != -1) as string[] | number[];
        }
        return undefined;
    }, [multiSelect, options]);

    const router = useRouter();
    const variableID = useMemo(
        () => buildPageIDForVariableID(router.pathname, router.query),
        [router.pathname, router.query],
    );
    const pageState = useAdvRecoilValue(recoilPageState(variableID));
    const [wasInteractedWith, setWasInteractedWith] = useState<boolean>(false);
    const myErrorMessage = useMemo(() => {
        if (errorMessage !== undefined) return errorMessage;
        if (props.disabled !== undefined && props.disabled) return undefined;
        if (props.required === undefined || props.required == false) return undefined;
        if (wasInteractedWith == false && pageState.ShowRequiredErrors == false) return undefined;
        if (
            (multiSelect == false &&
                (valueAsStrOrInt == "" ||
                    options.find((o) => o.key == valueAsStrOrInt) === undefined)) ||
            (multiSelect && (selectedKeys === undefined || selectedKeys.length == 0))
        )
            return LAN.FORM_REQUIRED_ERROR.text;
    }, [
        errorMessage,
        multiSelect,
        options,
        pageState.ShowRequiredErrors,
        props.disabled,
        props.required,
        selectedKeys,
        valueAsStrOrInt,
        wasInteractedWith,
    ]);

    const theme = useAdvTheme();
    const { t: translatedLabel, hasErr: hasLabelError } = useT(
        label,
        translationContext,
        ignoreTranslation,
    );
    const { t: translatedPlaceholder, hasErr: hasPlaceholderError } = useT(
        placeholder ?? (props.required === true ? LAN.PLEASE_SELECT.text : ""),
        translationContext,
        ignoreTranslation,
    );
    const { t: translatedErrorMessage, hasErr: hasErrorMessageError } = useT(
        myErrorMessage,
        props.errorMsgTranslationContext,
        ignoreTranslation,
    );

    const styles = useMemo(() => {
        let styles = propStyles;
        if (hasLabelError)
            styles = combineStyles(styles, {
                label: { ...theme.custom.textNotTranslated },
            });
        if (hasErrorMessageError)
            styles = combineStyles(styles, {
                errorMessage: { ...theme.custom.textNotTranslated },
            });
        if (hasPlaceholderError && valueAsStrOrInt == "")
            styles = combineStyles(styles, {
                title: { ...theme.custom.textNotTranslated },
            });
        if (designerData?.renderAsDesigner ?? false) {
            styles = combineStyles(styles, { root: getDesignerModeComponentStyle(theme) });
            if (designerData?.isSelected ?? false)
                styles = combineStyles(styles, { root: getSelectedComponentStyle(theme, true) });
        }
        //  else if (props.disabled !== undefined && props.disabled) {
        //     styles = combineStyles(styles, { title: { background: "transparent !important" } });
        // }
        if (props.disabled !== undefined && props.disabled) {
            styles = combineStyles(styles, {
                title: { background: theme.palette.white + " !important" },
                label: { color: theme.palette.neutralPrimaryAlt + " !important" },
            });
        }
        return styles;
    }, [
        designerData?.isSelected,
        designerData?.renderAsDesigner,
        hasErrorMessageError,
        hasLabelError,
        hasPlaceholderError,
        propStyles,
        props.disabled,
        theme,
        valueAsStrOrInt,
    ]);

    if (advhide === true && designerProps === undefined) return <></>;
    if (options.length == 0 && hideWhenEmpty && designerProps === undefined) return <></>;
    if (options.length < hideWhenBelowRecords && designerProps === undefined) return <></>;
    return (
        <Dropdown
            {...props}
            {...designerProps}
            selectedKey={multiSelect == true ? undefined : valueAsStrOrInt}
            selectedKeys={selectedKeys}
            styles={styles}
            label={translatedLabel}
            placeholder={translatedPlaceholder}
            errorMessage={translatedErrorMessage}
            onRenderOption={(
                props?: TAdvSelectableOption,
                defaultRender?: IRenderFunction<TAdvSelectableOption>,
            ) => {
                if (onRenderOption != undefined && props != undefined) {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    const ItemComp = AdvDropdownItem.bind(null, props, onRenderOption);
                    return <ItemComp></ItemComp>;
                } else if (defaultRender != undefined && props != undefined) {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    const ItemComp = AdvDropdownItem.bind(null, props, defaultRender);
                    return <ItemComp></ItemComp>;
                }
                return <></>;
            }}
            onRenderLabel={
                info != undefined
                    ? AdvRenderLabelTextInput.bind(null, info, onRenderLabel)
                    : onRenderLabel
            }
            onBlur={(ev) => {
                if (props.onBlur !== undefined) props.onBlur(ev);
                setWasInteractedWith(true);
            }}
            options={options}
            multiSelect={multiSelect}
        />
    );
};
export const AdvDropdownImpl = React.memo(AdvDropdownImplComp, deepCompareJSXProps);

function setMultiSelectDropdownValue(
    old: string | undefined,
    option: IDropdownOption<any> | undefined,
    value: string | undefined,
) {
    const values = (old?.length ?? 0) > 0 ? old?.split(",") ?? [] : [];
    const foundIndex = values.findIndex((v) => v === option?.data.toString());
    if (foundIndex != -1) {
        if (value == undefined || option?.selected !== true) values.splice(foundIndex, 1);
        return values.join(",");
    } else if (value != undefined) {
        values.push(value);
        return values.join(",");
    }
    return old;
}

const AdvDropdownSimple = ({
    options,
    optionsData,
    optionsText,
    multiSelect,
    onValueChanged,
    value,
    ...props
}: TAdvDropdownProps) => {
    const optionsSplitted = useMemo<{
        text: any[];
        data: any[];
        hidden: (boolean | undefined)[];
        disabled: (boolean | undefined)[];
        ignoreTranslation: (boolean | undefined)[];
    }>(() => {
        const res: {
            text: any[];
            data: any[];
            hidden: (boolean | undefined)[];
            disabled: (boolean | undefined)[];
            ignoreTranslation: (boolean | undefined)[];
        } = {
            data: [],
            text: [],
            hidden: [],
            disabled: [],
            ignoreTranslation: [],
        };
        res.ignoreTranslation = options.map((val) => val.ignoreTranslation);
        if (optionsData == undefined && optionsText == undefined) {
            options.forEach((val, index) => {
                res.data[index] = val.data != undefined ? val.data : val.text;
                res.text[index] = val.text;
                res.hidden[index] = val.hidden;
                res.disabled[index] = val.disabled;
            });
        } else {
            if (optionsData == undefined && optionsText != undefined) {
                optionsText.forEach((val, index) => {
                    res.data[index] = val;
                    res.text[index] = val;
                });
            } else if (optionsData != undefined) {
                res.data = optionsData.map((val) => val);
                if (optionsText != undefined) res.text = optionsText.map((val) => val);
                else res.text = optionsData.map((val) => val);
                // make them equally sized
                while (res.text.length < res.data.length) res.text.push(res.data[res.text.length]);
            }
        }
        return res;
    }, [options, optionsData, optionsText]);

    const optionKeys = useMemo(() => {
        const res: (string | number)[] = [];
        if (optionsData == undefined && optionsText == undefined) {
            options.forEach((val) => res.push(val.key));
        } else {
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let i = 0; i < optionsSplitted.data.length; ++i) res.push(nanoid());
        }
        return res;
    }, [options, optionsData, optionsSplitted.data.length, optionsText]);

    const [curVal, , setCurVal] = useAdvMemoWithUpdater(() => value, [value]);

    const optionsCached = useMemo<TAdvDropdownItem<any>[]>(() => {
        const values: string[] = [];
        if (multiSelect === true && curVal != undefined && typeof curVal == "string") {
            values.push(...curVal.split(","));
        }

        let res: TAdvDropdownItem<any>[] = [];
        res = optionsSplitted.data.map((val, index) => {
            return {
                key: optionKeys[index],
                text: optionsSplitted.text[index],
                data: val,
                hidden: optionsSplitted.hidden[index],
                disabled: optionsSplitted.disabled[index],
                ignoreTranslation: optionsSplitted.ignoreTranslation[index],
            };
        });
        return res;
    }, [
        curVal,
        multiSelect,
        optionKeys,
        optionsSplitted.data,
        optionsSplitted.disabled,
        optionsSplitted.hidden,
        optionsSplitted.ignoreTranslation,
        optionsSplitted.text,
    ]);

    const handleChange = useAdvCallback(
        (event: any, option?: IDropdownOption<any>) => {
            if (multiSelect === true)
                setCurVal((old) => {
                    if (typeof old == "string") {
                        return setMultiSelectDropdownValue(old, option, option?.data.toString());
                    }
                    return old;
                });
            else setCurVal(option);
            if (onValueChanged != undefined) onValueChanged(option, event);
        },
        [multiSelect, onValueChanged, setCurVal],
    );

    return (
        <AdvDropdownImpl
            {...props}
            onChange={handleChange}
            value={curVal}
            options={optionsCached}
            multiSelect={multiSelect}
        ></AdvDropdownImpl>
    );
};

const AdvDropdownComplexValues = ({
    optionsText,
    optionsTextBindingParams,
    optionsData,
    optionsDataBindingParams,
    valueBindingParams,
    labelBindingParams,
    options,
    value,
    disabled,
    disabledBindingParams,
    multiSelect,
    onValueChanged,
    dataArrayIndex = 0,
    ...props
}: TAdvDropdownProps) => {
    const optionsSplitted = useMemo<{ text: any[]; data: any[] }>(() => {
        const res: { text: any[]; data: any[] } = { data: [], text: [] };
        if (optionsData == undefined && optionsText == undefined) {
            res.data = options.map((val) => {
                const data = val.data != undefined ? val.data : val.text;
                return data;
            });
            res.text = options.map((val) => {
                return val.text;
            });
        } else {
            if (optionsData == undefined && optionsText != undefined) {
                res.data = optionsText.map((val) => val);
                res.text = optionsText.map((val) => val);
            } else if (optionsData != undefined) {
                res.data = optionsData.map((val) => val);
                if (optionsText != undefined) res.text = optionsText.map((val) => val);
                else res.text = optionsData.map((val) => val);
                // make them equally sized
                while (res.text.length < res.data.length)
                    res.text.push(res.data[res.text.length - 1]);
            }
        }
        return res;
    }, [options, optionsData, optionsText]);

    const [currentOptionsText] = useAdvValueBinderAsArrayNoDataType(
        optionsTextBindingParams,
        optionsSplitted.text,
        EAdvValueDataTypes.Any,
        false,
        dataArrayIndex,
    );
    const [currentOptionsData, setOptionsData] = useAdvValueBinderAsArrayNoDataType(
        optionsDataBindingParams,
        optionsSplitted.data,
        EAdvValueDataTypes.Any,
        false,
        dataArrayIndex,
    );
    const [currentlyDisabled] = useAdvValueBinder<boolean | undefined>(
        disabledBindingParams,
        disabled,
        EAdvValueDataTypes.Boolean,
        dataArrayIndex,
    );

    const optionKeys = useMemo(() => {
        const res: (string | number)[] = [];
        if (optionsData == undefined && optionsText == undefined) {
            options.forEach((val) => res.push(val.key));
        } else {
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let i = 0; i < currentOptionsData.length; ++i) res.push(nanoid());
        }
        return res;
    }, [options, optionsData, currentOptionsData.length, optionsText]);

    const valueAsStr = useMemo(() => {
        if (optionsData == undefined && optionsText == undefined) {
            return typeof value === "string"
                ? value
                : value != undefined
                ? value.key.toString()
                : "";
        } else {
            if (typeof value == "object") return value.data.toString();
            return value;
        }
    }, [optionsData, optionsText, value]);
    const [currentValue, setCurrentValue, attributes] = useAdvValueBinderNoDataType<any>(
        valueBindingParams,
        valueAsStr,
        multiSelect === true
            ? EAdvValueDataTypes.ArrayAsCommaSeperatedString
            : EAdvValueDataTypes.Any,
        dataArrayIndex,
    );

    const optionsCached = useMemo<TAdvDropdownItem<any>[]>(() => {
        const values: string[] = [];
        if (multiSelect === true && currentValue != undefined && currentValue != "") {
            values.push(...currentValue.toString().split(","));
        }

        let res: TAdvDropdownItem<any>[] = [];
        const dataUniqueness = new Map<string, { data: any; index: number }>();
        currentOptionsData.forEach((val, index) =>
            dataUniqueness.set(JSON.stringify(val), { data: val, index: index }),
        );
        res = currentOptionsData
            .filter((val, index) => (dataUniqueness.get(JSON.stringify(val))?.index ?? -1) == index)
            .map((val) => {
                const index = dataUniqueness.get(JSON.stringify(val))?.index ?? -1;

                let isSelected: boolean | undefined = undefined;
                if (values.includes(val.toString())) isSelected = true;

                return {
                    key: optionKeys[index],
                    text:
                        index >= currentOptionsText.length
                            ? typeof val == "string"
                                ? val
                                : JSON.stringify(val)
                            : currentOptionsText[index],
                    data: val,
                    selected: isSelected,
                };
            });
        return res;
    }, [multiSelect, currentValue, currentOptionsData, optionKeys, currentOptionsText]);

    const valueCached = useMemo(() => {
        if (optionsData == undefined && optionsText == undefined) {
            return typeof currentValue;
        } else {
            return optionsCached.find((val) => val.data == currentValue);
        }
    }, [currentValue, optionsCached, optionsData, optionsText]);

    const prevOptionsData = usePrevious(currentOptionsData);
    useAdvEffect(() => {
        if (
            prevOptionsData === undefined ||
            optionsDataBindingParams?.bindingTypeName != EAdvValueBinderType.BinderTypeDataProvider
        )
            return;
        // Prüfen ob wir zum ersten mal Daten bekommen
        if (prevOptionsData.length == 0 && currentOptionsData.length > 0) {
            let _currentValue = currentValue;
            // Wenn nur ein Datensatz vorhanden ist, dann den ersten auswählen (falls noch nicht ausgewählt)
            if (currentOptionsData.length == 1) _currentValue = currentOptionsData[0];
            if (currentValue !== _currentValue) setCurrentValue(currentOptionsData[0]);
            else {
                // Wenn wir Daten aus einem DataProvider bekommen, kann der DropDown den DataProvider positionieren.
                // Das müssen wir nicht nur beim Ändern (handleChange) sondern auch beim initialen Laden der Daten machen.
                if (currentValue !== undefined && currentValue != "") {
                    const index = currentOptionsData.findIndex((oc) => oc === currentValue);
                    if (index >= 0)
                        // Zum Umpositionieren benötigen wir den Index des ausgewählten Items
                        setOptionsData([index]);
                }
            }
        }
    }, [
        currentValue,
        currentOptionsData,
        optionsDataBindingParams?.bindingTypeName,
        prevOptionsData,
        setOptionsData,
        optionsDataBindingParams,
        setCurrentValue,
    ]);

    const handleChange = useAdvCallback(
        (event: any, option?: IDropdownOption<any>) => {
            let canSet = true;
            if (option != undefined) {
                const newValue =
                    multiSelect === true
                        ? setMultiSelectDropdownValue(
                              currentValue?.toString(),
                              option,
                              option.data.toString(),
                          )
                        : option.data;
                canSet = setCurrentValue(newValue);

                // Bei DataProvider: Den DP entsprechend positionieren
                if (
                    canSet &&
                    optionsDataBindingParams?.bindingTypeName ==
                        EAdvValueBinderType.BinderTypeDataProvider
                ) {
                    // Zum Umpositionieren benötigen wir den Index des ausgewählten Items
                    setOptionsData([optionsCached.findIndex((oc) => oc.data === option.data)]);
                }
            }
            if (canSet && onValueChanged != undefined) onValueChanged(option, event);
        },
        [
            currentValue,
            multiSelect,
            onValueChanged,
            optionsCached,
            optionsDataBindingParams,
            setCurrentValue,
            setOptionsData,
        ],
    );

    if (attributes.isVisible)
        return (
            <AdvDropdownImpl
                {...props}
                optionsText={optionsText}
                optionsData={optionsData}
                optionsTextBindingParams={optionsTextBindingParams}
                optionsDataBindingParams={optionsDataBindingParams}
                valueBindingParams={valueBindingParams}
                labelBindingParams={labelBindingParams}
                options={optionsCached}
                value={valueCached}
                multiSelect={multiSelect}
                onChange={handleChange}
                disabled={(currentlyDisabled.val ?? false) || !attributes.isEditable}
                dataArrayIndex={dataArrayIndex}
            ></AdvDropdownImpl>
        );
    else return <></>;
};

const AdvDropdownComplexLabelOrHideOrMultiSelect = ({
    optionsTextBindingParams,
    optionsDataBindingParams,
    valueBindingParams,
    labelBindingParams,
    label,
    advhide,
    advhideBindingParams,
    multiSelect,
    multiSelectBindingParams,
    dataArrayIndex = 0,
    ...props
}: TAdvDropdownProps) => {
    const [labelBindingVal] = useAdvValueBinderNoDataType(
        labelBindingParams,
        label,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );
    const [shouldHide] = useAdvValueBinderNoDataType(
        advhideBindingParams,
        advhide,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );
    const [isMultiSelect] = useAdvValueBinderNoDataType(
        multiSelectBindingParams,
        multiSelect ?? false,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );

    if (
        !IsValueBindingTrivial(optionsTextBindingParams) ||
        !IsValueBindingTrivial(optionsDataBindingParams) ||
        !IsValueBindingTrivial(valueBindingParams)
    ) {
        return (
            <AdvDropdownComplexValues
                {...props}
                optionsTextBindingParams={optionsTextBindingParams}
                optionsDataBindingParams={optionsDataBindingParams}
                valueBindingParams={valueBindingParams}
                labelBindingParams={labelBindingParams}
                advhide={shouldHide}
                advhideBindingParams={advhideBindingParams}
                multiSelect={isMultiSelect}
                multiSelectBindingParams={multiSelectBindingParams}
                dataArrayIndex={dataArrayIndex}
                label={labelBindingVal}
            ></AdvDropdownComplexValues>
        );
    } else {
        return (
            <AdvDropdownImpl
                {...props}
                optionsTextBindingParams={optionsTextBindingParams}
                optionsDataBindingParams={optionsDataBindingParams}
                valueBindingParams={valueBindingParams}
                labelBindingParams={labelBindingParams}
                advhide={shouldHide}
                advhideBindingParams={advhideBindingParams}
                multiSelect={isMultiSelect}
                multiSelectBindingParams={multiSelectBindingParams}
                dataArrayIndex={dataArrayIndex}
                label={labelBindingVal}
            ></AdvDropdownImpl>
        );
    }
};

const AdvDropdownComplex = ({
    labelBindingParams,
    advhideBindingParams,
    multiSelectBindingParams,
    ...props
}: TAdvDropdownProps) => {
    if (
        !IsValueBindingTrivial(labelBindingParams) ||
        !IsValueBindingTrivial(advhideBindingParams) ||
        !IsValueBindingTrivial(multiSelectBindingParams)
    ) {
        return (
            <AdvDropdownComplexLabelOrHideOrMultiSelect
                {...props}
                labelBindingParams={labelBindingParams}
                advhideBindingParams={advhideBindingParams}
                multiSelectBindingParams={multiSelectBindingParams}
            ></AdvDropdownComplexLabelOrHideOrMultiSelect>
        );
    } else {
        return (
            <AdvDropdownComplexValues
                {...props}
                labelBindingParams={labelBindingParams}
                advhideBindingParams={advhideBindingParams}
                multiSelectBindingParams={multiSelectBindingParams}
            ></AdvDropdownComplexValues>
        );
    }
};

/**
 * @summary Wrapper für ``Dropdown``
 * @link https://developer.microsoft.com/en-us/fluentui#/controls/web/dropdown
 */
const AdvDropdownComp = ({
    optionsTextBindingParams,
    optionsDataBindingParams,
    valueBindingParams,
    labelBindingParams,
    advhideBindingParams,
    multiSelectBindingParams,
    ...props
}: TAdvDropdownProps) => {
    if (
        IsValueBindingTrivial(optionsTextBindingParams) &&
        IsValueBindingTrivial(optionsDataBindingParams) &&
        IsValueBindingTrivial(valueBindingParams) &&
        IsValueBindingTrivial(labelBindingParams) &&
        IsValueBindingTrivial(advhideBindingParams) &&
        IsValueBindingTrivial(multiSelectBindingParams)
    )
        return (
            <AdvDropdownSimple
                {...props}
                optionsTextBindingParams={optionsTextBindingParams}
                optionsDataBindingParams={optionsDataBindingParams}
                valueBindingParams={valueBindingParams}
                labelBindingParams={labelBindingParams}
                advhideBindingParams={advhideBindingParams}
                multiSelectBindingParams={multiSelectBindingParams}
            ></AdvDropdownSimple>
        );
    else
        return (
            <AdvDropdownComplex
                {...props}
                optionsTextBindingParams={optionsTextBindingParams}
                optionsDataBindingParams={optionsDataBindingParams}
                valueBindingParams={valueBindingParams}
                labelBindingParams={labelBindingParams}
                advhideBindingParams={advhideBindingParams}
                multiSelectBindingParams={multiSelectBindingParams}
            ></AdvDropdownComplex>
        );
};

const AdvDropdown = React.memo(AdvDropdownComp, deepCompareJSXProps);
export default AdvDropdown;

import "./designable";
