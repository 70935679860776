import { LAN } from "@data/language/strings";
import { DefaultComponentCategory } from "@feature/Designer/types/category";
import { EComponentTypeLayout } from "@feature/Designer/types/component-type";
import { registerDesignableComponent } from "@feature/Designer/utils";
import { TabControlIcon } from "@themes/icons";
import AdvGridItemDesignable from "../grid/grid-item/designable";

import AdvStackItemDesignable from "../stack/stack-item/designable";

enum EPropertyName {}

enum EPropertyCategory {
    Allgemeines = "Allgemeines",
}

enum EPresetName {
    DefaultPreset = "Default",
}

// Stack ist die Basis des Ui-Designers, deshalb hier als Objekt merken.
const registeredComponent = registerDesignableComponent({
    staticData: {
        name: LAN.TAB_CONTROL.text,
        translationContext: LAN.TAB_CONTROL.context,
        type: EComponentTypeLayout.TabControl,
        supportsChildren: true,
        category: DefaultComponentCategory.Layout,
        icon: TabControlIcon,
    },
    properties: [
        ...AdvStackItemDesignable.CommonProperties,
        ...AdvGridItemDesignable.CommonProperties,
    ],
    propertiesBuilders: [],
    presets: [
        {
            name: LAN.DEFAULT_PRESET.text,
            translationContext: LAN.DEFAULT_PRESET.context,
            default: true,
            children: [
                {
                    type: EComponentTypeLayout.TabItem,
                },
                {
                    type: EComponentTypeLayout.TabItem,
                },
            ],
        },
    ],
});

const AdvTabControlDesignable = {
    PropertyName: EPropertyName,
    PropertyCategory: EPropertyCategory,
    PresetName: EPresetName,
    Registered: registeredComponent,
};

export default AdvTabControlDesignable;
