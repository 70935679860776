import { TAdvCommonProperties } from "@components/other/common-properties";
import { TAdvDesignerComponentProps } from "@feature/Designer/types/component-props";
import { getDesignerModeComponentStyle, getSelectedComponentStyle } from "@feature/Designer/utils";
import {
    ActionButton,
    DefaultButton,
    IButtonProps,
    IButtonStyles,
    IIconProps,
    IRenderFunction,
    PrimaryButton,
} from "@fluentui/react";
import { TAdvValueBindingParams } from "@hooks/dynamic/useAdvValueBinder";
import { TAdvWebActionParams } from "@hooks/dynamic/useAdvWebAction.types";
import { useT } from "@hooks/language/useTranslation";
import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import useAdvComponent from "@hooks/useAdvComponent";
import useAdvTheme from "@hooks/useAdvTheme";
import { IAdvIcon } from "@themes/icons_base";
import { deepCompareJSXProps } from "@utils/deep-compare";
import { combineStyles, mergeObjects } from "@utils/styling";
import React, { useMemo } from "react";
import { defaultButtonStyles } from "./styles";

export type TAdvButtonStyles = IButtonStyles;
export enum EAdvButtonType {
    /** Normaler Button */
    Default,
    /** Button in Primary-Farbe (Default: Blau) */
    Primary,
    /** Transparenter Button */
    Action,
}
export enum EAdvButtonIconType {
    Normal,
    Big,
}

export type TAdvButtonProps = Omit<
    IButtonProps,
    | "options"
    | "styles"
    | "iconProps"
    | "buttonType"
    | "label"
    | "text"
    | "primary"
    | "onRenderText"
> &
    TAdvDesignerComponentProps &
    TAdvCommonProperties & {
        styles?: TAdvButtonStyles;
        /** Alternative zu iconProps.iconName */
        iconName?: string;
        iconProps?: IAdvIcon | IIconProps;
        /**
         * Wenn angegeben wird bei einem Klick auf den Button diese Web-Action ausgeführt.
         * @important Ein bestehendes onClick-Event wird dabei überschrieben.
         */
        webActionParams?: TAdvWebActionParams; // the name and parts of the type structure of this field are used in the server code, in case of rename, also do it in the server code
        // force own text, instead of the one provided by the webaction
        forceOwnText?: boolean;
        // force allow the onclick to be executed even if a webaction is set
        // in this case the web action is overwritten
        forceAllowOnClick?: boolean;

        text?: string;
        hideText?: boolean;
        // a context specializing the translation used
        translationContext?: string;
        /** Allgemeines Design des Buttons */
        buttonType?: EAdvButtonType;
        /** Darstellung des Icons */
        iconType?: EAdvButtonIconType;

        primary?: boolean;
        primaryBindingParams?: TAdvValueBindingParams;

        // they key that is sent as identifier, when a webaction triggers
        keyRef?: string;

        titleTranslationContext?: string;
        ignoreTranslation?: boolean;

        /**
         * Ein hotkey, der die hinter diesem Button hinterlegte WebAction ausführt
         */
        hotkey?: string;

        /**
         * Setzt automatisch flex properties, damit der Button wächst
         */
        flexGrow?: boolean;
        flexGrowBindingParams?: TAdvValueBindingParams;

        disabledBindingParams?: TAdvValueBindingParams;
        // TODO: Gedanken machen, wie man das besser lösen kann. Konkret: Icon, Big Icon, Icon / Big Icon + Text, Transpaent,...

        simplified?: boolean;
        simplifiedBindingParams?: TAdvValueBindingParams;

        onRenderText?: IRenderFunction<TAdvButtonProps>;
    };

/**
 * A pure button does not use extra/designer logic
 */
const AdvButtonPureComp = (orgProps: TAdvButtonProps) => {
    useAdvComponent(AdvButtonPureComp, orgProps);

    const {
        text,
        title,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        advhide,
        hideText,
        translationContext,
        titleTranslationContext,
        styles: propStyles,
        iconProps,
        iconName,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        flexGrow = false,
        buttonType = EAdvButtonType.Default,
        iconType = EAdvButtonIconType.Normal,
        designerData,
        designerProps,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        simplified = false,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        ignoreTranslation = false,
        onRenderText,
        ...props
    } = orgProps;

    const theme = useAdvTheme();

    // Icon
    const myIconProps = useMemo(() => {
        const myIconProps: IIconProps = { ...iconProps };
        if (iconName != undefined) myIconProps.iconName = iconName;
        if (iconType == EAdvButtonIconType.Big)
            myIconProps.styles = combineStyles(myIconProps.styles, defaultButtonStyles.bigIconIcon);

        // Primary färbt den Button um, analog also auch das Icon umfärben
        if (props.primary !== undefined && props.primary) {
            myIconProps.styles = combineStyles(myIconProps.styles, {
                root: {
                    color: theme.palette.white,
                    "svg.adv-icon": { color: theme.palette.white },
                },
            });
        }
        return myIconProps;
    }, [iconName, iconProps, iconType, props.primary, theme.palette.white]);

    let t: string | undefined;
    let hasErr: boolean;
    const usedT = useT(text, translationContext, ignoreTranslation);

    if (hideText == true) {
        t = "";
        hasErr = false;
    } else {
        t = usedT.t;
        hasErr = usedT.hasErr;
    }

    const { t: titleT } = useT(title, titleTranslationContext, ignoreTranslation);

    // Styles
    const styles = useMemo(() => {
        let styles = mergeObjects(
            propStyles ?? {},
            flexGrow ? { root: { flexGrow: 1, width: "100%" } } : {},
        );
        if (hasErr)
            styles = mergeObjects(styles, {
                label: { ...theme.custom.textNotTranslated },
            } as TAdvButtonStyles);
        if (iconType == EAdvButtonIconType.Big)
            styles = mergeObjects(defaultButtonStyles.bigIconButton, styles);
        else {
            if (buttonType == EAdvButtonType.Primary || props.primary === true)
                styles = mergeObjects(defaultButtonStyles.primary(theme), styles);
            else styles = mergeObjects(defaultButtonStyles.default, styles);
        }
        if ((designerData?.isSelected ?? false) && (designerData?.renderAsDesigner ?? false))
            styles = mergeObjects(styles, { root: getSelectedComponentStyle(theme, true) });
        if (designerData?.renderAsDesigner ?? false)
            styles = mergeObjects(styles, { root: getDesignerModeComponentStyle(theme, "button") });

        if (simplified) {
            styles = mergeObjects(styles, {
                root: {
                    border: "none",
                },
            });
        }
        if (iconProps?.color === undefined)
            styles = mergeObjects(styles, {
                icon: {
                    color: theme.palette.themePrimary,
                },
            });

        return Object.keys(styles).length == 0 ? undefined : styles;
    }, [
        buttonType,
        designerData?.isSelected,
        designerData?.renderAsDesigner,
        flexGrow,
        hasErr,
        iconProps?.color,
        iconType,
        propStyles,
        props.primary,
        simplified,
        theme,
    ]);

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const MyElementTag =
        buttonType == EAdvButtonType.Primary
            ? PrimaryButton
            : buttonType == EAdvButtonType.Action
            ? ActionButton
            : DefaultButton;

    const myOnRenderText: IRenderFunction<IButtonProps> = useAdvCallback(
        (props?: IButtonProps, defaultRender?: IRenderFunction<IButtonProps>) => {
            if (onRenderText !== undefined) return onRenderText(orgProps);
            if (defaultRender !== undefined) return defaultRender(props);
            return null;
        },
        [onRenderText, orgProps],
    );

    if (advhide === true && designerProps === undefined) return <></>;
    return (
        <MyElementTag
            iconProps={myIconProps}
            styles={styles}
            text={t}
            title={titleT}
            onRenderText={myOnRenderText}
            {...designerProps}
            {...props}
            disabled={designerProps === undefined ? props.disabled : false}
        />
    );
};

export const AdvButtonPure = React.memo(AdvButtonPureComp, deepCompareJSXProps);
