import { afterLoginTargetAtom } from "@data/session";
import { MessageBarType } from "@fluentui/react";
import { useAdvEffect } from "@hooks/react-overload/useAdvEffect";
import useAdvComponent from "@hooks/useAdvComponent";
import { useAdvLogin } from "@hooks/useAdvLogin";
import { useWaitForLogin } from "@hooks/useWaitForLogin";
import { advcatch, advlog } from "@utils/logging";
import { useRouter } from "next/router";
import React, { useMemo, useState } from "react";
import { ReadyState } from "react-use-websocket";

import AdvButton from "@components/inputs/button/button";
import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import useAdvRecoilValue from "@hooks/recoil-overload/useAdvRecoilValue";
import useAdvSetRecoilState from "@hooks/recoil-overload/useAdvSetRecoilState";
import { useIdleTimer } from "react-idle-timer";
import { atom } from "recoil";
import AdvMessageBar from "../message-bar";

// Debugging: 1000 * 10, // 10 seconds
export const sessionTimeoutMS = 1000 * 60 * 15; // 15 minutes
export const sessionTimeoutWarningMS = 1000 * 60 * 5; // 5 minutes
export const autoLogoutAtom = atom<number>({ key: "autoLogoutAtom", default: Date.now() });

let wasConnected = false;
const AdvSessionReconnectorComp = ({
    readyState,
    children,
    ...props
}: {
    readyState: ReadyState;
    children: React.JSX.Element;
}) => {
    useAdvComponent(AdvSessionReconnectorComp, props);

    const { tryRestoreSession, logout } = useAdvLogin();

    const setAutoLogout = useAdvSetRecoilState(autoLogoutAtom);

    const onIdle = useAdvCallback(() => {
        advlog("logged out user, because of inactivity.");
        logout(true).catch(advcatch);
    }, [logout]);

    const onAction = useAdvCallback(() => {
        setAutoLogout(Date.now());
    }, [setAutoLogout]);

    useIdleTimer({
        timeout: sessionTimeoutMS,
        throttle: 1000, // once per second
        onIdle,
        onAction,
    });

    const { disconnectSession, isSessionSet } = useWaitForLogin();
    const router = useRouter();
    const setAfterLoginTarget = useAdvSetRecoilState(afterLoginTargetAtom);

    useAdvEffect(() => {
        if (!isSessionSet() && router.pathname != "/" && router.pathname != "/logout") {
            setAfterLoginTarget({
                pathname: router.pathname,
                query: JSON.parse(JSON.stringify(router.query)),
            });
        }
    }, [isSessionSet, router, setAfterLoginTarget]);

    const curSessionLastActivityTime = useAdvRecoilValue(autoLogoutAtom);
    const [curTime, setCurTime] = useState(Number.MAX_SAFE_INTEGER);
    useAdvEffect(() => {
        const id = setInterval(() => {
            setCurTime(sessionTimeoutMS - (Date.now() - curSessionLastActivityTime));
        }, 1000);
        return () => clearTimeout(id);
    }, [curSessionLastActivityTime]);

    const autoLogoutStr = useMemo(() => {
        const minutesLeft = Math.max(Math.floor(curTime / 1000 / 60), 0);
        const secondsLeft = Math.max(Math.round(curTime / 1000) % 60, 0);
        if (minutesLeft > 0) {
            return (
                minutesLeft.toString() +
                ":" +
                (secondsLeft < 10 ? "0" : "") +
                secondsLeft.toString() +
                "m"
            );
        } else {
            return secondsLeft.toString() + "s";
        }
    }, [curTime]);

    const shouldShowTimeoutWarning = useMemo(() => curTime < sessionTimeoutWarningMS, [curTime]);

    const [res, setRes] = useState(<></>);
    useAdvEffect(() => {
        if (readyState != ReadyState.OPEN) {
            if (readyState == ReadyState.CLOSED)
                setRes(
                    <div style={{ position: "fixed", width: "100%", zIndex: 100 }}>
                        <AdvMessageBar type={MessageBarType.severeWarning}>
                            Verbindung verloren...{" "}
                            <AdvButton
                                text={"Erneut verbinden"}
                                simplified
                                onClick={() => router.reload()}
                                styles={{ root: { backgroundColor: "transparent" } }}
                            ></AdvButton>
                        </AdvMessageBar>
                    </div>,
                );
            else
                setRes(
                    <div style={{ position: "fixed", width: "100%", zIndex: 100 }}>
                        <AdvMessageBar type={MessageBarType.error}>
                            Verbindung wird wiederhergestellt...
                        </AdvMessageBar>
                    </div>,
                );
        } else {
            if (shouldShowTimeoutWarning) {
                setRes(
                    <div style={{ position: "fixed", width: "100%", zIndex: 100 }}>
                        <AdvMessageBar type={MessageBarType.severeWarning}>
                            {"Automatische Abmeldung in " + autoLogoutStr}
                        </AdvMessageBar>
                    </div>,
                );
            } else {
                setRes(<></>);
            }
        }
    }, [autoLogoutStr, readyState, router, shouldShowTimeoutWarning]);

    useAdvEffect(() => {
        if (readyState != ReadyState.OPEN) {
            if (wasConnected) {
                disconnectSession();
            }
            wasConnected = false;
        } else {
            if (router.isReady) {
                if (!wasConnected) {
                    tryRestoreSession().catch(advcatch);
                }
                wasConnected = true;
            }
        }
    }, [disconnectSession, tryRestoreSession, readyState, router.isReady]);

    return (
        <>
            {res}
            {children}
        </>
    );
};

const AdvSessionReconnector = React.memo(AdvSessionReconnectorComp);
export default AdvSessionReconnector;
