import { LAN } from "@data/language/strings";
import { DefaultComponentCategory } from "@feature/Designer/types/category";
import { EComponentTypeLayout } from "@feature/Designer/types/component-type";
import { registerDesignableComponent, AdvProperty } from "@feature/Designer/utils";
import { toAdvText } from "@hooks/language/useTranslation";
import { TabItemIcon } from "@themes/icons";

enum EPropertyName {
    Headline = "headerText",
}

enum EPropertyCategory {
    Allgemeines = "Allgemeines",
}

enum EPresetName {}

const registeredComponent = registerDesignableComponent({
    staticData: {
        name: LAN.TAB_ITEM.text,
        translationContext: LAN.TAB_ITEM.context,
        type: EComponentTypeLayout.TabItem,
        supportsChildren: true,
        category: DefaultComponentCategory.Layout,
        icon: TabItemIcon,
    },
    properties: [
        AdvProperty.Text.create(
            toAdvText(LAN.HEADING),
            EPropertyName.Headline,
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.TAB_ITEM_HEADER_DESCR),
            "Seite X",
            false /* TODO! find way for bindings */,
        ),
    ],
    propertiesBuilders: [],
    presets: [
        {
            name: LAN.DEFAULT_PRESET.text,
            translationContext: LAN.DEFAULT_PRESET.context,
            default: true,
            children: [
                {
                    type: EComponentTypeLayout.Stack,
                },
            ],
        },
    ],
});

const AdvTabItemDesignable = {
    PropertyName: EPropertyName,
    PropertyCategory: EPropertyCategory,
    PresetName: EPresetName,
    Registered: registeredComponent,
};

export default AdvTabItemDesignable;
