import {
    gActionAdminSettings,
    gActionDesignerGroup,
    gActionDesignerRead,
    gActionNavDesignerRead,
    gActionNewsDesignerRead,
    gActionThemeDesignerRead,
} from "@data/designer/file";
import { LAN } from "@data/language/strings";
import {
    AdminIcon,
    CompassDraftingIcon,
    FileNavIcon,
    LogoutIcon,
    NewsIcon,
    SettingsIcon,
    SettingsUserIcon,
    ThemesIcon,
    UiIcon,
} from "@themes/icons";
import { TAdvNavLink } from "./link";

/**
 * Hier sind alle Einträge der Navbar (links im bildschirm) hinterlegt.
 * @todo Dynamische Items (UserPages)
 */
const getDefaultNavGroup = (
    isDesignerGroupExpanded: boolean,
    designerGroupOnClick: TAdvNavLink["onClick"],
): TAdvNavLink[] => [
    {
        name: LAN.DESIGNER.text,
        translationContext: LAN.DESIGNER.context,
        url: "",
        key: "designer",
        onClick: designerGroupOnClick,
        isExpanded: isDesignerGroupExpanded,
        forceAnchor: true,
        actionName: gActionDesignerGroup,
        renderAs: "group",
        icon: CompassDraftingIcon.iconName,
        links: [
            {
                name: LAN.UI_DESIGNER.text,
                translationContext: LAN.UI_DESIGNER.context,
                url: "/designer/ui/",
                key: "ui",
                icon: UiIcon.iconName,
                actionName: gActionDesignerRead,
                links: [],
                renderAs: "item",
            },
            /*{
                name: LAN.DATASOURCES.text,
                translationContext: LAN.DATASOURCES.context,
                url: "/designer/datasources/",
                key: "datasources",
                icon: DataSourceIcon.iconName,
                actionName: gActionDesignerRead,
                links: [],
                renderAs: "item",
            },
            {
                name: LAN.ACTIONS.text,
                translationContext: LAN.ACTIONS.context,
                url: "/designer/actions/",
                key: "actions",
                icon: ActionsIcon.iconName,
                actionName: gActionDesignerRead,
                links: [],
                renderAs: "item",
            },*/
            {
                name: LAN.THEMES.text,
                translationContext: LAN.THEMES.context,
                url: "/designer/theme/",
                key: "theme",
                icon: ThemesIcon.iconName,
                actionName: gActionThemeDesignerRead,
                links: [],
                renderAs: "item",
            },
            {
                name: LAN.NAVS.text,
                translationContext: LAN.NAVS.context,
                url: "/designer/nav/",
                key: "nav",
                icon: FileNavIcon.iconName,
                actionName: gActionNavDesignerRead,
                links: [],
                renderAs: "item",
            },
            {
                name: LAN.NEWS.text,
                translationContext: LAN.NEWS.context,
                url: "/designer/news/",
                key: "news",
                icon: NewsIcon.iconName,
                actionName: gActionNewsDesignerRead,
                links: [],
                renderAs: "item",
            },
        ],
    },
];

export default getDefaultNavGroup;

export const getPersonaNavGroup = (): TAdvNavLink[] => {
    const res: TAdvNavLink[] = [
        {
            name: LAN.SETTINGS.text,
            translationContext: LAN.SETTINGS.context,
            url: "/settings/",
            key: "settings",
            icon: SettingsIcon.iconName,
            links: [],
            renderAs: "item",
        },
        {
            name: LAN.USERSETTINGS.text,
            translationContext: LAN.USERSETTINGS.context,
            url: "/userSettings/",
            key: "userSettings",
            icon: SettingsUserIcon.iconName,
            links: [],
            renderAs: "item",
        },
        {
            name: LAN.ADMIN_SETTINGS.text,
            translationContext: LAN.ADMIN_SETTINGS.context,
            url: "/settingsapp/",
            key: "settingsapp",
            icon: AdminIcon.iconName,
            actionName: gActionAdminSettings,
            links: [],
            renderAs: "item",
        },
        {
            name: LAN.LOGOUT.text,
            translationContext: LAN.LOGOUT.context,
            url: "/logout/",
            key: "logout",
            icon: LogoutIcon.iconName,
            links: [],
            renderAs: "item",
        },
    ];
    if (process.env.NEXT_PUBLIC_ENV == "dev") {
        res.push({
            name: "tests (nur debug)",
            translationContext: LAN.ADMIN_SETTINGS.context,
            url: "/tests/",
            key: "tests",
            icon: AdminIcon.iconName,
            links: [],
            renderAs: "item",
        });
    }
    return res;
};

type TConstPage = {
    name: string;
    url: string;
};

function getConstPagesImpl(pages: TAdvNavLink[], curpages: TConstPage[]) {
    for (const page of pages) {
        if (page.name && page.url) {
            curpages.push({ name: page.name, url: page.url });
        }
        if (page.links != undefined) {
            getConstPagesImpl(page.links, curpages);
        }
    }

    return curpages;
}

export function getConstPages() {
    const pages = getDefaultNavGroup(false, () => {
        // do nothing
    });
    return getConstPagesImpl(pages, []);
}
