// helper string to easily find text that is not part of this list
const tHelp = "";
// prettier-ignore
export const L4N_INTERNAL = { // L4N damit es nicht beim tippen vorgeschlagen wird
    EVENTS: { text: tHelp + "Events", context: "" },
    EVENTS_SHOW: { text: tHelp + "OnShow", context: "" },
    EVENTS_SHOW_DESCR: { text: tHelp + "(SERVER) Aktion wird beim Abrufen der Page ausgeführt. Unterstützt nur Page-Parameter.", context: "" },
    EVENTS_HIDE: { text: tHelp + "OnHide", context: "" },
    EVENTS_HIDE_DESCR: { text: tHelp + "(SERVER) Aktion wird ausgeführt, wenn eine andere Page aufgerufen wird.", context: "" },
    EVENTS_INIT: { text: tHelp + "OnInit", context: "" },
    EVENTS_INIT_DESCR: { text: tHelp + "(SERVER) Aktion wird ausgeführt, wenn die Page initialisiert, also in den Cache geladen wird. Unterstützt nur Page-Parameter.", context: "" },
    EVENTS_DESTROY: { text: tHelp + "OnDestroy", context: "" },
    EVENTS_DESTROY_DESCR: { text: tHelp + "(SERVER) Aktion wird ausgeführt, sobald die Page dauerhaft geschloßen wird, also aus dem Cache entfernt wird. Keine Parameter sind hierbei erlaubt.", context: "" },
    EVENTS_CHANGED: { text: tHelp + "OnChanged", context: "" },
    EVENTS_CHANGED_DESCR: { text: tHelp + "Aktion wird ausgeführt, wenn sich der Wert dieser Komponente geändert hat.", context: "" },
    EVENTS_BEFORE_CHANGED: { text: tHelp + "OnBeforeChanged", context: "" },
    EVENTS_BEFORE_CHANGED_DESCR: { text: tHelp + "Aktion wird ausgeführt, bevor sich der Wert dieser Komponente geändert hat.", context: "" },
    EVENTS_CONTRACT_FULFILLED: { text: tHelp + "OnContractFulfilled", context: "" },
    EVENTS_CONTRACT_FULFILLED_DESCR: { text: tHelp + "Aktion wird ausgeführt, nachdem ein Auftrag fulfilled wurde.", context: "" },
    EVENTS_CONTRACT_ERROR: { text: tHelp + "OnContractError", context: "" },
    EVENTS_CONTRACT_ERROR_DESCR: { text: tHelp + "Aktion wird ausgeführt, nachdem ein Auftrag einen Fehler hatte.", context: "" },
    EVENTS_CONTRACT_CANCEL: { text: tHelp + "OnContractCancel", context: "" },
    EVENTS_CONTRACT_CANCEL_DESCR: { text: tHelp + "Aktion wird ausgeführt, nachdem ein Auftrag abgebrochen wurde.", context: "" },

    LOAD_ALL: { text: tHelp + "Lade alle Daten", context: "" },
    LOAD_AND_SELECT_ALL: { text: tHelp + "Lade und selektiere alle Daten", context: "" },
    DONT_LOAD_OR_SELECT_ALL: { text: tHelp + "Nichts vorladen oder selektieren", context: "" },
    IS_LIVE: { text: tHelp + "Live-Daten (ohne Temp-Table)", context: "" },

    SELECT: { text: tHelp + "Auswählen", context: "" },
    PLEASE_SELECT: { text: tHelp + "Eine Option wählen", context: "" },
    APPEND: { text: tHelp + "Anhängen", context: "" },
    APPEND_NOW: { text: tHelp + "Jetzt anhängen", context: "" },

    GROUP: { text: tHelp + "Gruppierung", context: "" },
    GROUP_NAME: { text: tHelp + "Gruppen-Name", context: "" },
    GROUP_FIELD: { text: tHelp + "Gruppen-Feld", context: "" },
    GROUP_PLACEHOLDER: { text: tHelp + "Platzhalter", context: "" },
    GROUP_DISPLAYTEXT: { text: tHelp + "Anzeigetext", context: "" },

    DATAPROVIDER: { text: tHelp + "Datenanbieter", context: "" },
    DATAPROVIDER_NAME: { text: tHelp + "Datenanbieter-Name", context: "" },
    DATAPROVIDER_FIELD_NAME: { text: tHelp + "Datenanbieter-Feld-Name", context: "" },
    COMPARE_DATAPROVIDER_FIELD_NAME: { text: tHelp + "Datenanbieter-Vergleichsfeld-Name", context: "" },
    DATASOURCE: { text: tHelp + "Datenquelle", context: "" },
    ALWAYS_SHOW_DROPDOWN_AREAS: { text: tHelp + "Drop-Areas", context: "" },
    DESIGNER_MODUS: { text: tHelp + "Designer-Modus", context: "" },
    PREVIEW: { text: tHelp + "Vorschau", context: "" },
    COMPONENTS: { text: tHelp + "Komponenten", context: "" },

    DATAPROVIDER_IS_LIVE: { text: tHelp + "Benutze Live-Daten", context: "" },

    CONTRACTS: { text: tHelp + "Aufträge", context: "" },
    CONTRACT_PROVIDER_NAME: { text: tHelp + "Auftrags-Provider-Name", context: "" },
    CONTRACT_NAME: { text: tHelp + "Auftrags-Name", context: "" },
    CONTRACT_FUNC_NAME: { text: tHelp + "Auftrags-Funktions-Name", context: "" },
    CONTRACT: { text: tHelp + "Auftrag", context: "" },

    DYNAMIC_PAGE: { text: tHelp + "Dynamic Page", context: "" },
    ENDLESS_TABLE: { text: tHelp + "EndlessTable", context: "" },
    LABEL: { text: tHelp + "Label", context: "" },
    PROGRESSBAR_MODE: { text: tHelp + "Modus", context: "" },
    PROGRESSBAR_MODE_SINGLE: { text: tHelp + "Single", context: "" },
    PROGRESSBAR_MODE_MULTI: { text: tHelp + "Multi", context: "" },
    PROGRESSBAR_MODE_DESCR: { text: tHelp + "Ermöglicht die Darstellung mehrerer Daten", context: "" },
    PROGRESSBAR_DATA: { text: tHelp + "Daten", context: "" },
    PROGRESSBAR_DATA_DESCR: { text: tHelp + "Daten", context: "" },
    PROGRESSBAR_DATA_VALUE: { text: tHelp + "Wert (numerisch)", context: "" },
    PROGRESSBAR_DATA_LABEL: { text: tHelp + "Label", context: "" },
    PROGRESSBAR_DATA_COLOR: { text: tHelp + "Farbe", context: "" },
    PROGRESSBAR_DATA_BAR: { text: tHelp + "Balken anzeigen", context: "" },
    PROGRESSBAR_DATA_LEGEND: { text: tHelp + "Legende anzeigen", context: "" },
    TEXTINPUT_DESCRIPTION: { text: tHelp + "Beschreibung", context: "" },
    PLACEHOLDER: { text: tHelp + "Platzhalter", context: "" },
    COLOR: { text: tHelp + "Farbe", context: "" },
    RESOURCE_FILE: { text: tHelp + "Resourcen-Datei", context: "" },
    RESOURCE_HEIGHT: { text: tHelp + "Resourcen-Höhe", context: "" },
    LABELS: { text: tHelp + "Beschriftung", context: "" },
    DATASETS: { text: tHelp + "Datenmenge", context: "" },
    CHART_LABELS: { text: tHelp + "Achsen-Beschriftungen", context: "" },
    CHART_DATASETS: { text: tHelp + "Datenmengen", context: "" },
    CHART_CURRENT_VALUE: { text: tHelp + "Aktueller Wert", context: "" },
    CHART_MAX_VALUE: { text: tHelp + "Maximal-Datenwert", context: "" },
    ORDER_ARTICLE_LIST: { text: tHelp + "Artikel-Liste (nur bindings)", context: "" },
    ORDER_DATE_LIST: { text: tHelp + "Mögliche Datumwerte (nur bindings)", context: "" },
    ORDER_DATE_SELECTED: { text: tHelp + "Ausgewähltes Datum (binding)", context: "" },
    ORDER_DATE_PRESELECTED: { text: tHelp + "Vorausgewähltes Datum (binding)", context: "" },
    AUSSTA_LIST_READER: { text: tHelp + "Variable von der die aktuelle Ausstattung gelesen werden soll", context: "" },
    AUSSTA_LIST_WRITER: { text: tHelp + "Variable in der die aktuelle Ausstattung geschrieben werden soll", context: "" },
    KDARTI_ID_READER: { text: tHelp + "Variable von der die aktuellen KdArti-IDs gelesen werden soll", context: "" },
    KDARTI_BEZ_READER: { text: tHelp + "Variable von der die aktuellen KdArti-Bezs gelesen werden soll", context: "" },
    ART_GROE_READER: { text: tHelp + "Variable von der die aktuellen ArtGroes pro KdArti gelesen werden soll", context: "" },
    DATASET_DATA: { text: tHelp + "Zahlenwert:", context: "" },
    ADD_DATASET_DATA: { text: tHelp + "Neuen Zahlenwert hinzufügen", context: "" },
    CHART: { text: tHelp + "Diagramm", context: "" },
    ICON: { text: tHelp + "Icon", context: "" },
    TEXT: { text: tHelp + "Text", context: "" },
    BUTTON: { text: tHelp + "Button", context: "" },
    CHECKBOX: { text: tHelp + "Checkbox", context: "" },
    PROGRESSBAR: { text: tHelp + "Fortschrittsbalken", context: "" },
    COMBOBOX: { text: tHelp + "Combobox", context: "" },
    CALENDAR: { text: tHelp + "Kalender", context: "" },
    CALENDAR_ORDERS: { text: tHelp + "Kalender (Bestellungen)", context: "" },
    PROVIDER_ORDERS: { text: tHelp + "Provider-Daten (Bestellungen)", context: "" },
    CONTROL_ORDERS: { text: tHelp + "Kontrolle (Bestellungen)", context: "" },
    ENDLESS_TABLE_ARTICLES: { text: tHelp + "Bestellungen: Artikel", context: "" },
    ENDLESS_TABLE_VSA: { text: tHelp + "Bestellungen: VSAs", context: "" },
    ENDLESS_TABLE_ANF: { text: tHelp + "Bestellungen: Anforderungen", context: "" },
    ARTI_LIST: { text: tHelp + "Trägerartikel (TraeArti)", context: "" },
    CONTRACT_ACTIONS_COMP: { text: tHelp + "Contract-Actions", context: "" },
    ANPROBE_ART_LIST: { text: tHelp + "Träger-Anprobe Artikel", context: "" },
    TRAEARTI_ANLAGE_LIST: { text: tHelp + "Trägerartikel Anlage", context: "" },
    AFTER_BENCHMARK_ACTION: { text: tHelp + "Nach Benchmark-Ausführungs Webaktion", context: "" },
    AFTER_BENCHMARK_TIMEOUT: { text: tHelp + "Nach Benchmark-Ausführungs Webaktion-Delay in ms", context: "" },
    BENCHMARK: { text: tHelp + "Benchmark", context: "" },
    COMBOBOX_ITEMS: { text: tHelp + "Combobox-Items", context: "" },
    DROPDOWN: { text: tHelp + "Dropdown", context: "" },
    RESOURCE: { text: tHelp + "Resource", context: "" },
    ADD_RESOURCES: { text: tHelp + "Resourcen hinzufügen", context: "" },
    ADD_RESOURCES_DBSYSTEM: { text: tHelp + "Resourcen hinzufügen (nicht Mandant)", context: "" },
    RESOURCES: { text: tHelp + "Resourcen", context: "" },
    RESOURCES_DBSYSTEM: { text: tHelp + "DB-System Resourcen", context: "" },
    DROPDOWN_TEXT_ITEMS: { text: tHelp + "Dropdown-Text-Items", context: "" },
    DROPDOWN_DATA_ITEMS: { text: tHelp + "Dropdown-Data-Items", context: "" },
    COMBOBOX_ALLOW_FREEFORM: { text: tHelp + "Beliebigen Text erlauben", context: "" },
    MULTI_SELECT: { text: tHelp + "Mehrauswahl", context: "" },
    RADIOBUTTONS: { text: tHelp + "Radiobuttons", context: "" },
    TEXT_INPUT: { text: tHelp + "Text-Input", context: "" },
    DATEPICKER: { text: tHelp + "Datums-Auswahl", context: "" },
    COPY_CAT: { text: tHelp + "Nachahmer", context: "" },
    GRID: { text: tHelp + "Grid", context: "" },
    GRID_ITEM: { text: tHelp + "Layout (Grid)", context: "" },
    GROUPBOX: { text: tHelp + "Groupbox", context: "" },
    STACK: { text: tHelp + "Stack", context: "" },
    STACK_ITEM: { text: tHelp + "Layout (Stack)", context: "" },
    TAB_CONTROL: { text: tHelp + "Tab Control", context: "" },
    TAB_ITEM: { text: tHelp + "Tab Item", context: "" },
    REACT_GRID: { text: tHelp + "ReactGrid", context: "" },
    WRITE_DATE_PRETTY: { text: tHelp + "Ausgewähltes Datum (schreibend, formatiert)", context: "" },
    READ_ONLY: { text: tHelp + "Schreibgeschützt", context: "" },

    WITH_STACK: { text: tHelp + "Mit Stack", context: "" },
    STANDARD_LAYOUT: { text: tHelp + "Standard Layout", context: "" },
    STANDARD_GRID_LAYOUT: { text: tHelp + "Standard Grid-Layout", context: "" },
    ONE_TWO_ONE_GRID_LAYOUT: { text: tHelp + "1-2-1 Grid-Layout", context: "" },
    ONE_TWO_ONE_LAYOUT: { text: tHelp + "1-2-1 Layout", context: "" },
    HORIZONTAL_PRESET_WITH_CHILDREN: { text: tHelp + "Horizontal Preset with Children", context: "" },
    NON_HORIZONTAL_PRESET: { text: tHelp + "Not-Horizontal Preset", context: "" },
    TEXT_FIELD_PRESET: { text: tHelp + "Field Preset", context: "" },
    DEFAULT_PRESET: { text: tHelp + "Default", context: "" },
    PRESET: { text: tHelp + "Vorlage", context: "" },
    PAGE_VARIABLES: { text: tHelp + "Page-Variablen", context: "" },
    PAGE_VARIABLE: { text: tHelp + "Page-Variable", context: "" },
    PAGE_VARIABLE_ITERATABLE: { text: tHelp + "Page-Variable {}", context: "" },
    PASSED_VARIABLE: { text: tHelp + "Logik übergebene Variable", context: "" },
    DISPLAY_MODE: { text: tHelp + "Display mode", context: "" },
    SAVED_PAGE: { text: tHelp + "Seite \"{}\" gespeichert!", context: "" },
    PAGE_VAR_TITLE: { text: tHelp + "Variablen, die von Komponenten der Page benutzt werden können", context: "" },
    DESIGNER_MODE_ALL: { text: tHelp + "Alle", context: "" },
    DESIGNER_MODE_LOCAL: { text: tHelp + "Nur lokale", context: "" },
    DESIGNER_MODE_GLOBAL: { text: tHelp + "Nur globale", context: "" },
    DESIGNER_ACTIONS_MODAL: { text: tHelp + "Aktionen für \"{}\"", context: "" },
    DESIGNER_DATASOURCES_MODAL: { text: tHelp + "Datenquellen für \"{}\"", context: "" },
    NAME: { text: tHelp + "Name", context: "" },
    GENERAL_NAME: { text: tHelp + "Genereller Name", context: "" },
    NAME_TITLE: { text: tHelp + "Bezeichnung", context: "" },
    NAME_SUBTITLE: { text: tHelp + "Sekundär-Bezeichnung", context: "" },
    PROVIDER: { text: tHelp + "Provider", context: "" },
    DO_FILTER: { text: tHelp + "Filtern", context: "" },
    SORT: { text: tHelp + "Sortieren", context: "" },
    COLUMNS: { text: tHelp + "Spalten", context: "" },
    ROWS: { text: tHelp + "Zeilen", context: "" },
    COLUMN: { text: tHelp + "Spalte", context: "" },
    ROW: { text: tHelp + "Zeile", context: "" },
    AS_FLEX_CONTAINER: { text: tHelp + "Als Flex-Container", context: "" },
    CONTENT_AS_FLEX_CONTAINER: { text: tHelp + "Inhalt als Flex-Container", context: "" },
    SELECTION_MODE: { text: tHelp + "Selektier-Modus", context: "" },
    AUTO_GROUPING: { text: tHelp + "Auto-Gruppierung", context: "" },
    DATA_POSITIONING: { text: tHelp + "Datensatz-Positionierung", context: "" },
    TABLE_HEADER: { text: tHelp + "Tabellen-Aktionen", context: "" },
    TABLE_FILTERS: { text: tHelp + "Tabellen-Filter", context: "" },
    TABLE_ADD_FILTER: { text: tHelp + "Tabellen-Filter hinzufügen", context: "" },
    TABLE_DEFAULT_ACTION: { text: tHelp + "Standard-Aktion", context: "" },
    PAGE_IS_REPORT: { text: tHelp + "Auswertungsseite", context: ""},
    PAGE_IS_REPORT_DESCR: { text: tHelp + "Gibt an, ob diese Seite eine Auswertung enthält.", context: ""},
    NEVER_CACHE: { text: tHelp + "Seite nicht cachen", context: "" },
    NEVER_CACHE_DESCR: { text: tHelp + "Der Server verwendet nie den Seiten-Cache, wenn die Seite geöffnet wird", context: "" },
    TITLE: { text: tHelp + "Titel", context: "" },
    SECONDARY_TITLE: { text: tHelp + "Sekundär-Titel", context: "" },
    CATEGORY: { text: tHelp + "Kategorie", context: "" },
    MENU_ICON: { text: tHelp + "Menü-Icon", context: "" },
    NAV_ACTION: { text: tHelp + "Navigations-Webaktion", context: "" },
    TRANSLATION_CONTEXT: { text: tHelp + "Übersetzungskontext", context: "" },
    ALLOW_MULTILINE: { text: tHelp + "Mehrzeilig", context: "" },
    AUTO_ADJUST_HEIGHT: { text: tHelp + "Höhe automatisch anpassen", context: "" },
    FORM_REQUIRED: { text: tHelp + "Pflichtfeld", context: "" },
    IGNORE_TRANSLATION: { text: tHelp + "Nicht übersetzen", context: "" },
    IGNORE_TRANSLATION_PLACEHOLDER: { text: tHelp + "Nicht übersetzen (Platzhalter)", context: "" },
    IGNORE_CACHE: { text: tHelp + "Nicht cachen", context: "" },
    CAN_COLLAPSE: { text: tHelp + "Kann schließen", context: "" },
    COLLAPSE_DIR: { text: tHelp + "Schließrichtung", context: "" },
    ON_CLICK_ACTION: { text: tHelp + "On-Click Aktion", context: "" },
    NORMAL_FONT_WEIGHT: { text: tHelp + "Normale Text-Dicke", context: "" },
    DUPLICATION_AMOUNT: { text: tHelp + "Dublizierungsmenge", context: "" },
    VARIANT: { text: tHelp + "Variante", context: "" },
    PRIMARY: { text: tHelp + "Primär", context: "" },
    AUTO_GROW: { text: tHelp + "Automatisch wachsen", context: "" },
    HOTKEY: { text: tHelp + "Hotkey", context: "" },
    CHECKED: { text: tHelp + "Aktiviert", context: "" },
    DISABLED: { text: tHelp + "Nur lesbar", context: "" },
    SUBMIT_ACTION: { text: tHelp + "Submit-Aktion", context: "" },
    HIDE_WHEN_EMPTY: { text: tHelp + "Verstecken wenn keine Daten", context: "" },
    HIDE_WHEN_BELOW_RECORDS: { text: tHelp + "Verstecken wenn weniger als X Daten", context: "" },
    DISABLE_WHEN_EMPTY: { text: tHelp + "Nur lesbar wenn keine Daten", context: "" },
    SIMPLIFIED_DESIGN: { text: tHelp + "Vereinfachtes Design", context: "" },
    VALUE: { text: tHelp + "Wert", context: "" },
    DATEPICKER_VALUE_FORMAT: { text: tHelp + "Formatierung (Value)", context: "" },
    DATEPICKER_DISPLAY_FORMAT: { text: tHelp + "Formatierung (Anzeige)", context: "" },
    DATEPICKER_RANGE_TYPE: { text: tHelp + "Auswahlmodus", context: "" },
    DATEPICKER_VALUE_TODAY: { text: tHelp + "Standard: Heute", context: "" },
    DATEPICKER_MONTHPICK_VISIBLE: { text: tHelp + "Monate anzeigen", context: "" },
    INPUT_TYPE: { text: tHelp + "Datentyp des Inputs", context: "" },
    VERTICAL_FILL: { text: tHelp + "Fülle vertikal", context: "" },
    HORIZONTAL_FILL: { text: tHelp + "Fülle horizontal", context: "" },
    GRID_ALIGN_ITEMS: { text: tHelp + "Ausrichtung Items", context: "" },
    GRID_ROW_GAP: { text: tHelp + "Zeilenabstand", context: "" },
    GRID_COL_GAP: { text: tHelp + "Spaltenabstand", context: "" },
    DIRECTION: { text: tHelp + "Richtung", context: "" },
    HORIZONTAL: { text: tHelp + "Horizontal", context: "" },
    VERTICAL: { text: tHelp + "Vertikal", context: "" },
    STACKED: { text: tHelp + "Gestapelt", context: "" },
    SHOW_LEGEND: { text: tHelp + "Zeige Legende", context: "" },
    SHOW_X_ACHSIS: { text: tHelp + "Zeige X-Achse", context: "" },
    SHOW_Y_ACHSIS: { text: tHelp + "Zeige Y-Achse", context: "" },
    SHOW_VALUES_AS_EXTRA_Y_ACHSIS: { text: tHelp + "Werte als extra Y-Achse", context: "" },
    CHART_TYPE: { text: tHelp + "Diagramm-Typ", context: "" },
    CHART_RANDOMIZED_COLORS: { text: tHelp + "Zufällige Farbwerte für die Wertemenge nutzen", context: "" },
    HEADING: { text: tHelp + "Überschrift", context: "" },
    ADDITIONAL_HEADING: { text: tHelp + "Zusatzinfos in der Überschrift", context: "" },
    OPENED: { text: tHelp + "Geöffnet", context: "" },
    COLUMN_COUNT: { text: tHelp + "Spaltenanzahl", context: "" },
    ROWHEIGHT: { text: tHelp + "Zeilenhöhe", context: "" },
    LAYOUT: { text: tHelp + "Layout", context: "" },
    ORIENTATION: { text: tHelp + "Ausrichtung", context: "" },
    WRAP: { text: tHelp + "Wrap", context: "" },
    ALIGN_HORIZONTAL: { text: tHelp + "horizontale Ausrichtung", context: "" },
    ALIGN_VERTICAL: { text: tHelp + "vertikale Ausrichtung", context: "" },
    CHILDREN_GAP: { text: tHelp + "Abstand der Kinder-Elemente", context: "" },
    GROW_RATIO: { text: tHelp + "Wachs-Verhältnis", context: "" },
    SHRINK_RATIO: { text: tHelp + "Schrumpf-Verhältnis", context: "" },
    ALIGNMENT: { text: tHelp + "Ausrichtung", context: "" },
    HIDDEN: { text: tHelp + "Versteckt", context: "" },
    FLEX_BASIS: { text: tHelp + "Initial-Platzverbrauch", context: "" },
    MIN_WIDTH: { text: tHelp + "Minimale Breite", context: "" },
    MAX_WIDTH: { text: tHelp + "Maximale Breite", context: "" },
    MIN_HEIGHT: { text: tHelp + "Minimale Höhe", context: "" },
    MAX_HEIGHT: { text: tHelp + "Maximale Höhe", context: "" },
    THEME_SWIZZLE: { text: tHelp + "Farbzuordnungen", context: "" },
    FONT_OVERWRITE: { text: tHelp + "Font-Anpassungen", context: "" },
    FONT_SIZE: { text: tHelp + "Font-Größe", context: "" },
    FONT_WEIGHT: { text: tHelp + "Font-Dicke", context: "" },
    FONT_FAMILY: { text: tHelp + "Schriftart", context: "" },

    HELPER_FUNCTIONS: { text: tHelp + "Hilfs-Funktionen", context: "" },
    GRID_BUILDER: { text: tHelp + "Grid-Designer", context: "" },
    MIN_MAX_WIDTH: { text: tHelp + "Min-,Max-Breite", context: "" },
    MIN_MAX_HEIGHT: { text: tHelp + "Min-,Max-Höhe", context: "" },
    CUSTOM: { text: tHelp + "Individuell", context: "" },

    INPUT: { text: tHelp + "Eingabe", context: "" },
    PRESENTATION: { text: tHelp + "Darstellung", context: "" },

    DATASOURCE_FILTER_MAPPING: { text: tHelp + "Datenquellen-Filterung", context: "" },
    SORTING_FILTER: { text: tHelp + "Standard Datensatzsortierung & -filterung", context: "" },
    PARAMETER: { text: tHelp + "Parameter", context: "" },
    APPLY_VALUE_TO_FILTER: { text: tHelp + "Filter auf Wert anwenden", context: "" },
    ADD_FILTER_PARAM: { text: tHelp + "Füge Filter-Parameter hinzu", context: "" },
    DATAPROVIDER_FILTER_TITLE: { text: tHelp + "Gibt an wie die Datenquellen-Filter auf den serverseitigen Dataprovider angewendet werden.", context: "" },
    DATAPROVIDER_CLIENT_FILTER_TITLE: { text: tHelp + "Die Standard-Sortierung und Filterung, die angewendet wird, sobald der Dataprovider initialisiert wird.", context: "" },

    SAVE_NOT_POSSIBLE_DATA_MISSING: { text: tHelp + "Speichern nicht möglich, da Daten fehlen!", context: "" },

    SEARCH_TEXT: { text: tHelp + "Suchtext", context: "" },
    SORT_FIELD: { text: tHelp + "Sortierungs-Feld", context: "" },
    ASC_SORT: { text: tHelp + "Aufsteigende Sortierung", context: "" },
    DESC_SORT: { text: tHelp + "Absteigende Sortierung", context: "" },
    CONST_SORT: { text: tHelp + "Sortierung ist nicht änderbar", context: "" },

    WEBACTION_NO_PARAMS: { text: tHelp + "Webaktion das nur Page-Variablen als Argumente erlaubt", context: "" },
    WEBACTION_PAGE_CHOOSE: { text: tHelp + "Webaktion-Seite auswählen", context: "" },
    WEBACTION_CATEGORY_CHOOSE: { text: tHelp + "Webaktion-Kategorie auswählen", context: "" },

    BINDING_TYPE: { text: tHelp + "Binding-Typ", context: "" },
    BINDING_NAME: { text: tHelp + "Binding-Name", context: "" },
    BINDING_PARAM: { text: tHelp + "Binding-Parameter 1", context: "" },
    BINDING_LOGIC: { text: tHelp + "Binding-Logik", context: "" },
    BINDING_LOGIC_VALUE: { text: tHelp + "Binding-Logik-Value (als Text)", context: "" },

    BINDING_DATAPROVIDER_TO_BIND: { text: tHelp + "Datenanbieter, der gebunden wird", context: "" },
    BINDING_DATAPROVIDER_FIELD_TO_BIND: { text: tHelp + "Datenanbieter-Feld, das gebunden wird", context: "" },
    BINDING_PAGE_VAR_TO_BIND: { text: tHelp + "Page-Variablen-Name", context: "" },
    BINDING_CONTRACT_TO_BIND: { text: tHelp + "Auftrag, der gebunden wird", context: "" },
    BINDING_CONTRACT_FIELD_TO_BIND: { text: tHelp + "Auftrags-Feld, das gebunden wird", context: "" },

    NO_SELECTION: { text: tHelp + "Keine Auswahl", context: "" },
    CURRENT_SELECTION: { text: tHelp + "Aktuelle Auswahl", context: "" },

    ALL_CATEGORIES: { text: tHelp + "Alle Kategorien", context: "" },

    PAGE_RENAME_INFO: { text: tHelp + "Info: Da Datenquellen und andere Objekte zu dieser Page gehören können, kann das Umbenennen etwas länger dauern.", context: "" },

    PAGE_INLINE: { text: tHelp + "Seite", context: "inline" },

    INPUT_A_NAME: { text: tHelp + "Namen eingeben", context: "" },
    INSERT_NEW_NAME: { text: tHelp + "Neuen Namen eingeben", context: "" },
    CREATE_FILE: { text: tHelp + "{} erstellen", context: "" },
    RENAME_FILE: { text: tHelp + "{} umbenennen", context: "" },
    NO_FILE_SELECTED: { text: tHelp + "Kein(e) {} ausgewählt", context: "" },

    ADD_PAGE_VARIABLE: { text: tHelp + "Füge Page-Variable hinzu", context: "" },
    PAGE_VARIABLE_NAME: { text: tHelp + "Page-Variable-Name", context: "" },
    PAGE_DEFAULT_VALUE: { text: tHelp + "Default-Wert", context: "" },
    REQUIRED: { text: tHelp + "Notwending", context: "" },
    REQUIRED_INFO_TEXT: { text: tHelp + "Notwendinge Page-Variablen sind Variablen die schon bei dem Aufruf der Page existieren und initialisiert worden sein müssen.", context: "" },
    CONST: { text: tHelp + "Konstant", context: "" },
    CONST_PAGE_INFO_TEXT: { text: tHelp + "Konstante Page-Variablen sind sinnvoll, wenn ein Dataprovider mit dem Wert initialisiert werden sollen, oder wenn ein Input-Element nicht auf die Variable schreiben können soll.", context: "" },
    COULD_NOT_FIND_REQUIRED_PAGE_VAR: { text: tHelp + "Could not find required page variable \"{}\"", context: "" },

    WEBACTION_PARAMS: { text: tHelp + "Webaktion-Parameter-Mapping", context: "" },
    WEBACTION_ADD_PARAM: { text: tHelp + "Füge Webaktion-Parameter hinzu", context: "" },
    WEBACTION_PARAM: { text: tHelp + "Webaktion-Parameter", context: "" },

    LIST_ADD_LABEL: { text: tHelp + "Füge einen Eintrag hinzu", context: "" },
    KEY: { text: tHelp + "Key", context: "" },
    DATA: { text: tHelp + "Daten", context: "" },
    LIST_ENTRY: { text: tHelp + "Eintrag", context: "" },
    LIST_ENTRY_ITERATABLE: { text: tHelp + "Eintrag {}", context: "" },

    PARAMETER_TYPE: { text: tHelp + "Parameter-Typ", context: "" },
    MAP_AS_ACTION_PARAM: { text: tHelp + "Als diesen Aktions-Parameter übergeben", context: "" },
    PROVIDER_FIELD: { text: tHelp + "Provider-Feld", context: "" },
    PROVIDER_FIELD_COLON: { text: tHelp + "Provider-Feld", context: "" },
    PROVIDER_COLON: { text: tHelp + "Provider:", context: "" },
    CONST_VALUE: { text: tHelp + "Konstanten Wert", context: "" },
    MAPPED_CONST_VALUE: { text: tHelp + "Übergebener konstanter Wert", context: "" },

    EDIT_NO_DATAPROVIDER: { text: tHelp + "Keinen Datenprovider bearbeiten", context: "" },

    PROVIDER_INTERFACE_MAPPING: { text: tHelp + "Auftrags-Interface-Mapping", context: "" },
    PROVIDER_VALUERANGE_INTERFACE_MAPPING: { text: tHelp + "Auftrags-Wertebreiche-Interface-Mapping", context: "" },

    NONE: { text: tHelp + "Keine", context: "" },
    IGNORE: { text: tHelp + "Ignorieren", context: "" },
    IGNORE_SLASH_NONE: { text: tHelp + "Keines/Ignorieren", context: "" },

    COLUMN_DEFINITION: { text: tHelp + "Spaltendefinition", context: "" },
    ADD_COLUMN: { text: tHelp + "Spalte hinzufügen", context: "" },

    ADD_SORT_COLUMN: { text: tHelp + "Sortier-Spalte hinzufügen", context: "" },

    OPTIONS: { text: tHelp + "Optionen", context: "" },
    SELECT_DISTINCT: { text: tHelp + "Benutze \"SELECT DISTINCT\"", context: "" },
    LIVESICH_DISABLED: { text: tHelp + "Livesich für diese Datenquelle ausschalten", context: "" },
    TOP_AMOUNT: { text: tHelp + "Anzahl an Datensätzen die maximal geholt werden dürfen", context: "" },

    // Properties UI-Designer
    REPL_PAGE_DESCR: { text: tHelp + "Aktuelle Seite beim öffnen ersetzen", context: "" },
    TEXT_TEXT_DESCR: { text: tHelp + "Der anzuzeigende Text", context: "" },
    TEXT_VARIANTE_DESCR: { text: tHelp + "Bestimmt die Art des Textes, z.B. Größe oder Breite des Textes, aus vordefinierten Varianten", context: "" },
    COMP_HIDE: { text: tHelp + "Bestimmt, ob die Komponente sichtbar sein soll", context: "" },
    COMP_COLOR_SWIZZLE: { text: tHelp + "Erlaubt die Zuordnung der Farbwerte des Themes für die unterliegenen Komponenten zu ändern.", context: "" },
    COMP_FONT_OVERWRITE: { text: tHelp + "Erlaubt die das überschreiben von Font-Eigenschaften für eine Komponente und ihre Kinder.", context: "" },
    LABEL_TEXT_DESCR: { text: tHelp + "Der anzuzeigende Text", context: "" },
    CHART_LABEL_DESCR: { text: tHelp + "Der Name des Diagramms", context: "" },
    CHART_LABELS_DESCR: { text: tHelp + "Die Beschriftungen der Verlaufsachse", context: "" },
    CHART_DATASETS_DESCR: { text: tHelp + "Die Datenmengen, welche passend zu den Beschriftungen, Daten geben", context: "" },
    CHART_CURRENT_VALUE_DESCR: { text: tHelp + "Der aktuelle Wert eines Tachometers", context: "" },
    CHART_MAX_VALUE_DESCR: { text: tHelp + "Der maximlar Wert eines Tachometers", context: "" },
    CHART_HORIZONTAL_DESCR: { text: tHelp + "Das Diagramm soll horizontal aufgebaut werden", context: "" },
    CHART_STACKED_DESCR: { text: tHelp + "Das Diagramm Datenmengen auf der Hauptachse überlappen lassen", context: "" },
    CHART_SHOW_LEGEND_DESCR: { text: tHelp + "Legende für das Diagramm anzeigen", context: "" },
    CHART_SHOW_X_ACHSIS_DESCR: { text: tHelp + "X-Achse für das Diagramm anzeigen", context: "" },
    CHART_SHOW_Y_ACHSIS_DESCR: { text: tHelp + "Y-Achse für das Diagramm anzeigen", context: "" },
    CHART_SHOW_VALUES_AS_EXTRA_Y_ACHSIS_DESCR: { text: tHelp + "Zeige die Werte jedes Balkens als extra Y-Achse", context: "" },
    CHART_TYPE_DESCR: { text: tHelp + "Der Typ des Diagramms", context: "" },
    CHART_RANDOMIZE_COLOR_DESCR: { text: tHelp + "Bestimmt, ob zufällige Werte für die Datenmenge genommen werden sollen", context: "" },
    CHECKBOX_LABEL_DESCR: { text: tHelp + "Der Text, der die Checkbox beschreibt", context: "" },
    PROGRESSBAR_LABEL_DESCR: { text: tHelp + "Der Text, der den Fortschrittsbalken beschreibt", context: "" },
    CHECKBOX_VALUE_DESCR: { text: tHelp + "Bestimmt den aktuellen Wert der Checkbox", context: "" },
    CHECKBOX_DISABLED_DESCR: { text: tHelp + "Gibt an, ob die Checkbox nur lesbar ist", context: "" },
    DROPDOWN_DISABLED_DESCR: { text: tHelp + "Gibt an, ob der Dropdown nur lesbar (disabled) ist", context: "" },
    TEXT_INPUT_DISABLED_DESCR: { text: tHelp + "Gibt an, ob das Textfeld nur lesbar ist", context: "" },
    TEXT_INPUT_SUBMIT_ACTION_DESCR: { text: tHelp + "Wenn man die ENTER Taste drückt, wird diese Aktion ausgeführt", context: "" },
    HIDE_WHEN_EMPTY_DESCR: { text: tHelp + "Soll das Element versteckt werden wenn keine Auswahlmöglichkeiten zur Verfügung stehen?", context: "" },
    DISABLED_WHEN_EMPTY_DESCR: { text: tHelp + "Soll das Element nur lesbar sein wenn keine Auswahlmöglichkeiten zur Verfügung stehen?", context: "" },
    RESOURCE_FILE_DESCR: { text: tHelp + "Die Datei, welche in dieser Komponente hinterlegt wird", context: "" },
    RESOURCE_HEIGHT_DESCR: { text: tHelp + "Die Höhe, welche die Resource verbraucht. Leer bedeutet automatisch", context: "" },
    BUTTON_HOTKEY_DESCR: { text: tHelp + "Der Hotkey, welcher die in der Button hinterlegte Webaktion ausführt", context: "" },
    BUTTON_PRIMARY_DESCR: { text: tHelp + "Gibt an, ob der Button als primärer Button gerendert werden soll", context: "" },
    BUTTON_DISABLED_DESCR: { text: tHelp + "Gibt an, ob der Button klickbar sein soll", context: "" },
    BUTTON_SIMPLIFIED_DESCR: { text: tHelp + "Lässt den Button etwas simpler aussehen, z.B. gut für Buttons innerhalb von Endless-Tables", context: "" },
    BUTTON_WEB_ACTION_PARAMS_DESCR: { text: tHelp + "Pflegt die in der Button hinterlegte Webaktion", context: "" },
    BUTTON_GROW_DESCR: { text: tHelp + "Gibt an, ob der Button die volle Breite des Parent-Elements benutzen soll (nützlich z.B. wenn man Buttons unter einander anzeigen will)", context: "" },
    ENDLESS_TABLE_NAME_DESCR: { text: tHelp + "Ein allgemeiner Name, der die Endless-Table beschreibt", context: "" },
    ENDLESS_TABLE_NAME_TITLE_DESCR: { text: tHelp + "Eine Bezeichnung, die spziell für diese Endless-Table greift (z.B. eine Tabelle für einen bestimmten Träger).", context: "" },
    ENDLESS_TABLE_NAME_SUBTITLE_DESCR: { text: tHelp + "Eine sekundäre Bezeichnung, die spziell für diese Endless-Table greift (z.B. eine Tabelle für einen bestimmten Träger).", context: "" },
    ENDLESS_TABLE_PROVIDER_KEY_DESCR: { text: tHelp + "Hinterlegt einen Datenanbieter in der Endless-Table", context: "" },
    ENDLESS_TABLE_CAN_FILTER_DESCR: { text: tHelp + "Bestimmt ob die Endless-Table Filter, z.B. Filter-Dropdowns, benutzt werden sollen", context: "" },
    ENDLESS_TABLE_CAN_SORT_DESCR: { text: tHelp + "Bestimmt ob die Endless-Table eine Suchfunktion hat", context: "" },
    ENDLESS_TABLE_CUSTOM_COLUMN_DEFINITION_DESCR: { text: tHelp + "Lege eine individualisierte Spaltendefinition an, welche Größe, Sichtbarkeit, Reihenfolge etc. bestimmt", context: "" },
    ENDLESS_TABLE_HEADER_DEFINITION_DESCR: { text: tHelp + "Hinterlege eine Liste von Aktionen, die als Buttons im Table-Header angezeigt werden", context: "" },
    ENDLESS_TABLE_FILTERS_DEFINITION_DESCR: { text: tHelp + "Hinterlege eine Liste von Dropdown-Eigenschaften, die als Dropdown-Filter im Table-Header angezeigt werden", context: "" },
    ENDLESS_TABLE_DEFAULT_ACTION_DESCR: { text: tHelp + "Die Aktion, die bei doppelklick auf eine Zeile ausgeführt wird. Markiert auch den entsprechenden Button falls in den Tabellen-Aktionen hinterlegt", context: "" },
    ENDLESS_TABLE_SELECTION_MODE_DESCR: { text: tHelp + "Bestimmt, ob mehrere Einträge in der Endless-Table gleichzeitig ausgewählt werden können", context: "" },
    ENDLESS_TABLE_AUTO_GROUPING_DESCR: { text: tHelp + "Versucht die in der Tabelle angezeigten Daten nach einem Feld zu gruppieren.", context: "" },
    ENDLESS_TABLE_DATA_POSITIONING_DESCR: { text: tHelp + "Versucht, falls diese Seite einen Result-Wert von einer anderen Seite bekommen hat, den Datensatz auf das gewünschte Feld mit Hilfe des Result-Werts zu positionieren.", context: "" },
    ENDLESS_TABLE_EXCEL_EXPORT_DESCR: { text: tHelp + "Legt fest, ob die Daten der Tabelle als Excel-Datei exportiert werden können", context: "" },
    CROSSTABLE_AGGREGATE_SUM: { text: tHelp + "Summe", context: ""},
    CROSSTABLE_AGGREGATE_MAX: { text: tHelp + "Maximum", context: ""},
    CROSSTABLE_AGGREGATE_MIN: { text: tHelp + "Minimum", context: ""},
    ORDERS_ENDLESS_TABLE_PROVIDER_DATA_DESCR: { text: tHelp + "Gibt an woher die Daten für die Endless-Table kommen", context: "" },
    ORDERS_ENDLESS_TABLE_CONTROL_DESCR: { text: tHelp + "Kontrolle statt Tabelle anzeigen", context: "" },
    ORDER_ARTICLES_LIST_DESCR: { text: tHelp + "Nimmt über das hinterlegte Binding die Artikel-Liste aus dem Contract", context: "" },
    ORDER_DATE_LIST_DESCR: { text: tHelp + "Nimmt über das hinterlegte Binding die Datums-Liste aus dem Contract", context: "" },
    ORDER_DATE_SELECTED_DESCR: { text: tHelp + "Ausgewähltes Datum", context: "" },
    ORDER_DATE_PRESELECTED_DESCR: { text: tHelp + "Vorausgewähltes Datum", context: "" },
    AUSSTA_LIST_READER_DESCR: { text: tHelp + "Von dieser Variable wird die aktuelle Ausstattung ausgelesen", context: "" },
    AUSSTA_LIST_WRITER_DESCR: { text: tHelp + "In diese Variable wird die aktuelle Ausstattung geschrieben", context: "" },
    KDARTI_ID_READER_DESCR: { text: tHelp + "Aus dieser Variable soll aus den Bindings die Liste der KdArti-IDs gelesen werden", context: "" },
    KDARTI_BEZ_READER_DESCR: { text: tHelp + "Aus dieser Variable soll aus den Bindings die Liste der KdArti-Bezs gelesen werden", context: "" },
    ART_GROE_READER_DESCR: { text: tHelp + "Aus dieser Variable soll aus den Bindings die Liste der ArtGroes aller aktuellen KdArtis gelesen werden", context: "" },
    AFTER_BENCHMARK_ACTION_DESCR: { text: tHelp + "Führt diese Webaktion aus, sobald der Benchmark fertig ist.", context: "" },
    AFTER_BENCHMARK_TIMEOUT_DESCR: { text: tHelp + "Verzögert die Ausführung der Webaktion um diese Zeit in Millisekunden.", context: "" },
    TRANSLATION_CONTEXT_DESCR: { text: tHelp + "Ein Kontext in dem dieser String benutzt wird, um Namens-Kollisionen zumeiden (z.B. Birne ist doppeldeutig, ein möglicher Kontext wäre hier \"Frucht\")", context: "" },
    ALLOW_MULTILINE_DESCR: { text: tHelp + "Das Label darf mehrzeilig sein", context: "" },
    AUTO_ADJUST_HEIGHT_DESCR: { text: tHelp + "Passt die Höhe dem Text an. Muss mehrzeilig sein.", context: "" },
    FORM_REQUIRED_DESCR: { text: tHelp + "Es wird eine Fehlermeldung angezeigt, sobald das Speichern des Auftrags fehlschlägt und dieses Feld leer ist. Die Prüfung muss serverseitig im Auftrag stattfinden.", context: "" },
    IGNORE_TRANSLATION_DESCR: { text: tHelp + "Darf der Inhalt übersetzt werden?", context: "" },
    IGNORE_CACHE_DESCR: { text: tHelp + "", context: "" },
    CAN_COLLAPSE_DESCR: { text: tHelp + "Groupbox kann geschlossen werden", context: "" },
    COLLAPSE_DIR_DESCR: { text: tHelp + "Richtung, in die die Groupbox geschlossen wird. (Box an rechtem Rand: rechts, Box an linkem Rand: links)", context: "" },
    ON_CLICK_ACTION_DESCR: { text: tHelp + "Webaktion, die ausgeführt wird, wenn diese Komponente geklickt wird", context: "" },
    NORMAL_FONT_WEIGHT_DESCR: { text: tHelp + "Der Inhalt soll mit einer normalen Text-Dicke angezeigt werden", context: "" },
    DUPLICATION_AMOUNT_DESCR: { text: tHelp + "Die Anzahl an Dublikationen, welche diese Komponente von ihren Kinder-Komponenten machen soll. Generell ist es ratsamer Bindings zu benutzen anstatt einen festgesetzten Wert zu nehmen", context: "" },
    DYNAMIC_PAGE_TITLE_DESCR: { text: tHelp + "Der Titel der dynamischen Seiten, welcher z.B. im Breadcrump angezeigt wird", context: "" },
    DYNAMIC_PAGE_SECONDARY_TITLE_DESCR: { text: tHelp + "Ein sekundärer Titel der dynamischen Seiten, welcher z.B. im Breadcrump als extra Information angezeigt wird. Nützlich um z.B. eine ID zu hinterlegen.", context: "" },
    DYNAMIC_CATEGORY_DESCR: { text: tHelp + "Die Kategorie der dynamischen Seite, welche Seiten mit gleicher Kategorie automatisch zusammenfasst", context: "" },
    DYNAMIC_MENUICON_DESCR: { text: tHelp + "Das Icon, welches in der Seite hinterlegt ist, z.B. für die Navigation", context: "" },
    DYNAMIC_NAV_ACTION_DESCR: { text: tHelp + "Die Webaktion, welche die Seite aus der Navigation aufruft", context: "" },
    ICON_ICON_DESCR: { text: tHelp + "Das Icon, welches diese Komponente anzeigen soll", context: "" },
    COMBOBOX_LABEL_DESCR: { text: tHelp + "Der Text, der die Combobox beschreibt", context: "" },
    COMBOBOX_VALUE_DESCR: { text: tHelp + "Der aktuelle Wert der Combobox", context: "" },
    COMBOBOX_ALLOWFREEFORM_DESCR: { text: tHelp + "Gibt an, ob das Eingabefeld der Combobox von den hinterlegten Combobox-Data-Items abweichen darf", context: "" },
    COMBOBOX_MULTI_SELECT_DESCR: { text: tHelp + "Gibt an, ob das eine Mehrfachauswahl der Items erlaubt ist. Diese ist Komma separiert.", context: "" },
    COMBOBOX_OPTIONS_DATA_DESCR: { text: tHelp + "Die Liste von Einträgen, welche die Combobox als Auswahl bereitstellt", context: "" },
    DROPDOWN_LABEL_DESCR: { text: tHelp + "Der Text, der die Dropdown beschreibt", context: "" },
    DROPDOWN_VALUE_DESCR: { text: tHelp + "Der aktuelle Wert der Dropdown, welcher mit einem Eintrag in den Dropdown-Data-Items übereinstimmen muss", context: "" },
    DROPDOWN_MULTI_SELECT_DESCR: { text: tHelp + "Gibt an, ob das eine Mehrfachauswahl der Items erlaubt ist. Diese ist Komma separiert.", context: "" },
    DROPDOWN_TEXT_ITEMS_DESCR: { text: tHelp + "Texte, welche die Dropdown-Data-Items beschriften (sollte gleiche Menge haben wie die Data-Items)", context: "" },
    DROPDOWN_DATA_ITEMS_DESCR: { text: tHelp + "Die Wertemenge der auswählbaren Dropdown-Einträge", context: "" },
    RADIOGROUP_LABEL_DESCR: { text: tHelp + "Ein Label, welches als eine Art Überschrift der Radiobox dient", context: "" },
    TEXTINPUT_LABEL_DESCR: { text: tHelp + "Der Text, der den Text-Input beschreibt", context: "" },
    TEXTINPUT_DESCRIPTION_DESCR: { text: tHelp + "Kleine Beschreibung unterhalb des Text-Inputs", context: "" },
    PLACEHOLDER_DESCR: { text: tHelp + "Platzhalter wenn leer.", context: "" },
    TEXTINPUT_VALUE_DESCR: { text: tHelp + "Der aktuelle Wert des Text-Inputs", context: "" },
    DATEPICKER_VALUE_FORMAT_DESCR: { text: tHelp + "Wie soll das Datum im Hintergrund formatiert werden?", context: "" },
    DATEPICKER_DISPLAY_FORMAT_DESCR: { text: tHelp + "Wie soll das Datum in der Anzeige formatiert werden?", context: "" },
    DATEPICKER_RANGE_TYPE_DESCR: { text: tHelp + "Wie soll die Auswahl im Kalendar dargestellt werden?", context: "" },
    DATEPICKER_VALUE_TODAY_DESCR: { text: tHelp + "Wählt automatisch heute aus", context: "" },
    DATEPICKER_MONTHPICK_VISIBLE_DESCR: { text: tHelp + "Soll die Monats-Auswahl sichtbar sein?", context: "" },
    TEXTINPUT_INPUT_TYPE_DESCR: { text: tHelp + "Der Datentyp des Text-Inputs. Überschrieben durch gebundene Werte.", context: "" },
    GRID_COLUMNS_DESCR: { text: tHelp + "Eine durch ein Leerzeichen getrennte Liste von Größen, welche die einzelnen Spalten abbilden", context: "" },
    GRID_ROWS_DESCR: { text: tHelp + "Eine durch ein Leerzeichen getrennte Liste von Größen, welche die einzelnen Zeilen abbilden", context: "" },
    GRID_VERTICAL_FILL_DESCR: { text: tHelp + "Fülle das Parent-Element vertikal zu 100%", context: "" },
    GRID_HORIZONTAL_FILL_DESCR: { text: tHelp + "Fülle das Parent-Element horizontal zu 100%", context: "" },
    GRID_ALIGN_ITEMS_DESCR: { text: tHelp + "Ausrichtung der Items (vertikal)", context: "" },
    GRID_COL_GAP_DESCR: { text: tHelp + "Abstand zwischen den Spalten", context: "" },
    GRID_ROW_GAP_DESCR: { text: tHelp + "Abstand zwischen den Zeilen", context: "" },
    GRID_ITEM_COLUMN_DESCR: { text: tHelp + "Der Index der Spalte des Grids, in welcher sich dieses Item befindet (One-Based-Indexing)", context: "" },
    GRID_ITEM_ROW_DESCR: { text: tHelp + "Der Index der Zeile des Grids, in welcher sich dieses Item befindet (One-Based-Indexing)", context: "" },
    GRID_ITEM_AS_FLEX_CONTAINER_DESCR: { text: tHelp + "Das Grid-Item soll sich wie ein Flex-Container verhalten, sodass auch die Stack-Item-Eigenschaften des Elements berücksichtigt werden.", context: "" },
    REACT_GRID_COLUMNS_DESCR: { text: tHelp + "Anzahl der Spalten", context: "" },
    REACT_GRID_ROWHEIGHT_DESCR: { text: tHelp + "Höhe einer Zeile des Grids", context: "" },
    REACT_GRID_LAYOUT_DESCR: { text: tHelp + "Das Layout der jeweiligen benannten Children-Items", context: "" },
    GROUPBOX_HEADER_DESCR: { text: tHelp + "Die Überschrift der Groupbox", context: "" },
    GROUPBOX_ADDITIONAL_HEADER_DESCR: { text: tHelp + "Zusatztext, der bei eingeklappter Groupbox angezeigt wird", context: "" },
    GROUPBOX_OPENED_DESCR: { text: tHelp + "Gibt an, ob die Groupbox geöffnet ist, also der Inhalt sichtbar ist", context: "" },
    GROUPBOX_CONTENT_AS_FLEX_CONTAINER_DESCR: { text: tHelp + "Lässt den Inhalt der Groupbox automatisch als Flex-Container handlen", context: "" },
    TAB_ITEM_HEADER_DESCR: { text: tHelp + "Die Überschrift des Tab-Items", context: "" },
    STACK_ITEM_GROW_RATIO_DESCR: { text: tHelp + "Gibt an in welchem Verhältnis das Element wächst im Vergleich zu anderen Elementen des Parent-Elements", context: "" },
    STACK_ITEM_SHRINK_RATIO_DESCR: { text: tHelp + "Gibt an in welchem Verhältnis das Element schrumpft im Vergleich zu anderen Elementen des Parent-Elements", context: "" },
    STACK_ITEM_ALIGNMENT_DESCR: { text: tHelp + "Die Ausrichtung dieses Elementes im Stack auf der X-Achse (für vertikale Stacks) bzw. Y-Achse (für horizontale Stacks)", context: "" },
    STACK_ITEM_FLEX_BASIS_DESCR: { text: tHelp + "Die Breite/Höhe (je nachdem ob der Stack horizontal ist) die das Element mindestens nimmt", context: "" },
    STACK_ITEM_MIN_WIDTH_DESCR: { text: tHelp + "Die minimale Breite, die das Element nimmt (im Normalfall setzt man diese Property nur für vertikalen Stacks)", context: "" },
    STACK_ITEM_MAX_WIDTH_DESCR: { text: tHelp + "Die maximale Breite, die das Element nimmt (im Normalfall setzt man diese Property nur für horizontale Stacks)", context: "" },
    STACK_ITEM_MIN_HEIGHT_DESCR: { text: tHelp + "Die minimale Höhe, die das Element nimmt (im Normalfall setzt man diese Property nur für horizontale Stacks)", context: "" },
    STACK_ITEM_MAX_HEIGHT_DESCR: { text: tHelp + "Die maximale Höhe, die das Element nimmt (im Normalfall setzt man diese Property nur für vertikalen Stacks)", context: "" },
    STACK_HORIZONTAL_DESCR: { text: tHelp + "Bestimmt ob der Stack horizontal aufgebaut wird (bzw. vertikal)", context: "" },
    STACK_WRAP_DESCR: { text: tHelp + "Bestimmt ob der Stack in eine neue Zeile/Spalte (je nachdem ob horizontal oder vertikal) geht sobald der ganze Platz verbraucht wurde", context: "" },
    STACK_VERTICAL_FILL_DESCR: { text: tHelp + "Bestimmt ob der Stack 100% der Höhe des Parent-Elements nimmt", context: "" },
    STACK_HORIZONTAL_ALIGN_DESCR: { text: tHelp + "Die horizontale Ausrichtung der Children-Elemente", context: "" },
    STACK_VERTICAL_ALIGN_DESCR: { text: tHelp + "Die vertikale Ausrichtung der Children-Elemente", context: "" },
    STACK_CHILDREN_GAP_DESCR: { text: tHelp + "Der Abstand zwischen den Children-Elemente", context: "" },
    WRITE_WEEK_DAY_DESCR: { text: tHelp + "Der im binding hinterlegte Binding-Typ wird benutzt, um den ausgewählten Wochentag zu setzen", context: "" },
    WRITE_DATE_PRETTY_DESCR: { text: tHelp + "Das ausgewählte Datum wird in das Binding geschrieben (lesbares Datum)", context: "" },
    READ_ONLY_DESCR: { text: tHelp + "Komponente ist schreibgeschützt. Wert kann sich nicht ändern.", context: "" },
    TEXTINPUT_AUTO_FOCUS: { text: tHelp + "Automatischer Fokus", context: "" },
    TEXTINPUT_AUTO_FOCUS_DESCR: { text: tHelp + "Der Fokus wird automatisch auf dieses Eingabefeld gesetzt.", context: "" },
    TEXTINPUT_CLEAR_SUBMIT: { text: tHelp + "Feld bei Submit leeren", context: "" },
    TEXTINPUT_CLEAR_SUBMIT_DESCR: { text: tHelp + "Das Feld wird nach einem Submit geleert.", context: "" },
    TEXTINPUT_MIN_NUMBER: { text: tHelp + "Number: MinValue", context: "" },
    TEXTINPUT_MIN_NUMBER_DESCR: { text: tHelp + "Kleinstmögliche Zahl", context: "" },
    TEXTINPUT_MAX_NUMBER: { text: tHelp + "Number: MaxValue", context: "" },
    TEXTINPUT_MAX_NUMBER_DESCR: { text: tHelp + "Größtmögliche Zahl", context: "" },

    INFO_WEBACTION_PAGE_FILTER: { text: tHelp + "Webaktionen dieser Seite auswählen", context: "" },
    INFO_WEBACTION_CATEGORY_FILTER: { text: tHelp + "Webaktionen, welche diese Kategorie hinterlegt haben, auswählen", context: "" },
    INFO_WEBACTION_DESCR: { text: tHelp + "Die Webaktion, welche ausgeführt wird, wenn der Button, welcher sie hinterlegt, geklickt wird", context: "" },

    PROVIDER_TO_EDIT: { text: tHelp + "Datenanbieter, der editierbar wird", context: "" },
    INFO_PROVIDER_TO_EDIT_DESCR: { text: tHelp + "Dieser Datenanbieter wird bei Ausführung der Aktion auf editierbar gestellt", context: "" },
    PROVIDER_TO_CANCEL: { text: tHelp + "Datenanbieter, der wieder readonly wird", context: "" },
    INFO_PROVIDER_TO_CANCEL_DESCR: { text: tHelp + "Dieser Datenanbieter wird bei Ausführung der Aktion wieder auf nicht editierbar gestellt", context: "" },
    CONTRACT_TO_INIT: { text: tHelp + "Auftrag, der initiailisiert wird", context: "" },
    INFO_CONTRACT_TO_INIT_DESCR: { text: tHelp + "Dieser Auftrag wird auf dem Server initialisiert, sodass eine Kommunikation zwischen Client und Server für diesen statt finden kann", context: "" },
    CONTRACT_TO_FULFILL: { text: tHelp + "Auftrag, der ausgeführt werden soll", context: "" },
    INFO_CONTRACT_TO_FULFILL_DESCR: { text: tHelp + "Dieser Auftrag wird versucht ausgeführt zu werden, sodass der Server den Auftragt abschließt bzw. mögliche Rückfragen an den Client gibt", context: "" },
    CONTRACT_FUNCTION_CALL: { text: tHelp + "Auftrag, der die auszuführende Funktion bereitstellt", context: "" },
    INFO_CONTRACT_FUNCTION_CALL_DESCR: { text: tHelp + "Dieser Auftrag muss die Funktion bereitstellen, welche ausgeführt werden soll", context: "" },
    CONTRACT_ADD_EDIT_RECORD: { text: tHelp + "Auftrag, für den der hinterlegte Dataprovider ein neuer Editier-Eintrag hinzu gefügt werden soll", context: "" },
    INFO_CONTRACT_ADD_EDIT_RECORD_DESCR: { text: tHelp + "Diesem Auftrag wird ein Editier-Eintrag hinzu gefügt", context: "" },
    PROVIDER_ADD_EDIT_RECORD: { text: tHelp + "Datenanbieter, der einen neuen Editier-Eintrag bekommt", context: "" },
    INFO_PROVIDER_ADD_EDIT_RECORD_DESCR: { text: tHelp + "Dieser Datenanbieter bekommt einen neuen Editier-Eintrag, um quasi eine Liste von editierten/angelegten Daten ab zu bilden", context: "" },

    // Datasource-Designer
    LINKS_OF_THIS_FIELD: { text: tHelp + "Verlinkungen dieses Feldes", context: "" },
    AGGR_FUNC_OF_THIS_FIELD: { text: tHelp + "Aggregierungs-Funktion auf dieses Feld anwenden", context: "" },
    HAVING_OF_FIELD: { text: tHelp + "HAVING ... (SQL)", context: "" },
    WHERE_OF_FIELD: { text: tHelp + "WHERE ... (SQL)", context: "" },
    SET_LINKS_OF_THIS_FIELD: { text: tHelp + "Setze Verlinkungen dieses Feldes", context: "" },
    FIELD_HAS_LINKS_TITLE: { text: tHelp + "Dieses Feld unterstützt automatische Verlinkungen", context: "" },
    SET_AGGREGATION_SETTINGS_OF_FIELD: { text: tHelp + "Setze Aggregierungs-Funktion dieses Feldes", context: "" },
    SET_SETTINGS_OF_FIELD: { text: tHelp + "Setze feldspezifische Optionen", context: "" },
    SET_LIVESICH_OF_FIELD: { text: tHelp + "Sichtbarkeiten-Beschränkung", context: "" },
    LINK: { text: tHelp + "Verlinkung", context: "" },
    LINK_ITERATABLE: { text: tHelp + "Verlinkung {}", context: "" },
    LINKED_TABLE: { text: tHelp + "Verlinkte Tabelle:", context: "" },
    LINKED_FIELD: { text: tHelp + "Verlinktes Feld:", context: "" },
    LINK_SQL_OP: { text: tHelp + "SQL-Opeartor:", context: "" },
    LINKING_METHOD: { text: tHelp + "Verlinkungs-Methode", context: "" },
    ADD_LINK: { text: tHelp + "Füge Verlinkung hinzu", context: "" },
    JOIN_LIST: { text: tHelp + "Aktive Joins", context: "" },
    FILTER_CATEGORIES: { text: tHelp + "Filter-Kategorien", context: "" },
    WITHOUT_FILTER_CATEGORY: { text: tHelp + "Ohne Kategorie", context: "" },
    ADD_FILTER_TO_CATEGORY: { text: tHelp + "Füge einen Filter zur Kategorie hinzu", context: "" },
    FILTER_LOGIC: { text: tHelp + "SQL (WHERE ...)", context: "" },
    FILTER_LOGIC_DESCR: { text: tHelp + "An diesen Filter gebundene Variablen, werden mit $VALUE$ eingefügt. Falls eine Liste gebunden wird, diese mit dem in-Schlüsselwort aber ohne Klammern einfügen.", context: "" },
    JOIN_COND_LOGIC: { text: tHelp + "SQL (JOIN xxx ON ...)", context: "" },
    FILTER_DEFAULT: { text: tHelp + "Standardmäßig aktiv", context: "" },
    FILTER_REQUIRED: { text: tHelp + "Filter notwendig (hover für mehr Informationen)", context: "" },
    FILTER_EARLY: { text: tHelp + "vor dem Gruppieren filtern", context: "" },
    FILTER_DEFAULT_TITLE: { text: tHelp + "Standardauswahl dieser Kategorie", context: "" },
    FILTER_REQUIRED_TITLE: { text: tHelp + "Dieser Filter ist notwendig, bevor Daten vom Server gesendet werden. Diese Option funktioniert nur für Filter, die von sich dynamisch ändernden Werten abhängen.", context: "" },
    FILTER_EARLY_TITLE: { text: tHelp + "Dieser Filter wird angewendet, bevor eine Aggregatsfunktion greift. Er kann auch halb-ausgewählte Felder beinhalten.", context: "" },
    NO_FILTER_SELECTED: { text: tHelp + "Keine Filter-Kategorie ausgewählt", context: "" },
    NO_JOIN_SELECTED: { text: tHelp + "Kein Join ausgewählt", context: "" },
    DATASOURCE_INLINE: { text: tHelp + "Datenquelle", context: "inline" },
    RESOURCE_INLINE: { text: tHelp + "Resource", context: "inline" },
    NEWS_INLINE: { text: tHelp + "News", context: "inline" },

    // Action
    ACTION_LOGIC_NAME: { text: tHelp + "Webaktion-Logik", context: "" },
    HINT: { text: tHelp + "Hinweis", context: "" },
    ACTION_QUESTION: { text: tHelp + "Abfrage vor Ausführung", context: "" },
    ACTION_PARAMETERS: { text: tHelp + "Aktion-Parameter", context: "plural" },
    ACTION_PARAMETER: { text: tHelp + "Aktion-Parameter", context: "" },
    ACTION_PARAMETER_ITERATABLE: { text: tHelp + "Aktion-Parameter {}", context: "" },
    ADD_ACTION_PARAMETER: { text: tHelp + "Füge Aktions Parameter hinzu", context: "" },
    HAS_DEFAULT_VALUE: { text: tHelp + "Hat Standardwert", context: "" },
    DEFAULT_VALUE: { text: tHelp + "Standardwert", context: "" },
    DATATYPE: { text: tHelp + "Datentyp", context: "" },
    DESCRIPTION: { text: tHelp + "Beschreibung", context: "" },
    IS_OPTIONAL: { text: tHelp + "Ist optional", context: "" },

    WEBACTION_INLINE: { text: tHelp + "Webaktion", context: "inline" },

    SYNC_PARAMETERS_TO_PAGE_VAR: { text: tHelp + "Synchronisiere Aktion-Parameter zu den Page-Variablen", context: "" },
    SYNC_PARAMETERS_TO_CONTRACT_INTERFACE: { text: tHelp + "Synchronisiere Aktion-Parameter zum Auftrags-Interface", context: "" },

    // Action-Logic
    ACTION_ADD: { text: tHelp + "Webaktion hinzufügen", context: "" },
    ACTION_LOGIC_OPEN_PAGE: { text: tHelp + "Öffne diese Seite", context: "" },
    ACTION_LOGIC_EDIT_PROVIDER: { text: tHelp + "Versuche eine Datenanbieter bearbeitbar zu machen", context: "" },
    ACTION_LOGIC_SAVE_PROVIDER: { text: tHelp + "Versuche die aktuellen Bearbeitungen des Datenanbieters zu speichern", context: "" },
    ACTION_LOGIC_TRY_INIT_CONTRACT: { text: tHelp + "Versuche eine Datenanbieter bearbeitbar zu machen und einen Auftrag zu starten", context: "" },
    ACTION_LOGIC_CANCEL_CONTRACT: { text: tHelp + "Brich einen Auftrag ab", context: "" },
    ACTION_LOGIC_TRY_CANCEL_CONTRACT: { text: tHelp + "Versucht einen Auftrag abzubrechen. Ohne Fehlermeldung falls Auftrag nicht gestartet.", context: "" },
    ACTION_LOGIC_EXEC_CONTRACT: { text: tHelp + "Initialisiere einen Auftrag und versuche ihn direkt ab zuschließen", context: "" },
    ACTION_LOGIC_CALL_CONTRACT_FUNC: { text: tHelp + "Rufe eine Funktion auf, welche der Auftrag bereitstellt", context: "" },
    ACTION_LOGIC_ADD_CONTRACT_EDIT_RECORD: { text: tHelp + "Füge einen editierbaren Datensatz zu dem aktuell editierbaren Auftrag hinzu (quasi für eine Liste von Datensätzen)", context: "" },
    ACTION_LOGIC_REM_CONTRACT_EDIT_RECORD: { text: tHelp + "Lösche den letzten editierbaren Datensatz, falls mindestens 2 vorhanden sind (quasi für eine Liste von Datensätzen)", context: "" },
    ACTION_LOGIC_BROWSER_BACK: { text: tHelp + "In der Browser History einen Schritt zurück gehen (Zurück-Klick, Client-Side only)", context: "" },
    ACTION_LOGIC_RETURN: { text: tHelp + "In der Browser History einen Schritt zurück gehen (Zurück-Klick, Client-Side only). Zusätzlich wird ein Wert in das Result-Register geschrieben.", context: "" },

    IMPINJ_PROTOTYP: { text: tHelp + "IMPINJ-Prototyp", context: "" },

    MAX_CONTENT: { text: tHelp + "Größter Inhalt", context: "" },
    MAX_CONTENT_PLUS_EXTRA_MARGIN: { text: tHelp + "Größter Inhalt plus extra Abstand", context: "" },

    TABLES: { text: tHelp + "Tabellen", context: "" },
    FILTER: { text: tHelp + "Filter", context: "" },

    ACCEPT: { text: tHelp + "Akzeptieren", context: "" },
    AUTOMATIC_LINKING: { text: tHelp + "Automatische Verknüpfung", context: "" },
    COPYME: { text: tHelp + "COPY", context: "" },

    ENABLES_DRAG_AND_DROP: { text: tHelp + "Ermöglicht Drag & Drop", context: "" },
};
