import { LAN } from "@data/language/strings";
import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import { OpenFileIcon } from "@themes/icons";
import { advcatch } from "@utils/logging";
import { useRef } from "react";
import { AdvButtonPure } from "../button/button-pure";

export const AdvFileInput = ({
    onFile,
    autoClearFile,
}: {
    onFile: (fileBuffer: ArrayBuffer, fileType: string) => void;
    autoClearFile?: boolean;
}) => {
    const filePropRef = useRef<HTMLInputElement>(null);

    const onChooseFileClick = useAdvCallback(() => {
        if (filePropRef.current != null) filePropRef.current.click();
    }, []);

    return (
        <>
            <input
                ref={filePropRef}
                style={{ display: "none" }}
                type="file"
                onChange={(e) => {
                    if (e.target.files?.length === 1) {
                        const fileType = e.target.files[0].type;
                        e.target.files[0]
                            .arrayBuffer()
                            .then((val) => {
                                onFile(val, fileType);
                                if (autoClearFile === true) e.target.value = "";
                            })
                            .catch((r) => advcatch("Could not load image file: ", r));
                    }
                }}
            ></input>
            <AdvButtonPure
                text={LAN.CHOOSE_FILE.text}
                translationContext={LAN.CHOOSE_FILE.context}
                iconName={OpenFileIcon.iconName}
                onClick={onChooseFileClick}
                primary
            ></AdvButtonPure>
        </>
    );
};
