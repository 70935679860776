import { LAN } from "@data/language/strings";
import { AdvProperty } from "@feature/Designer/utils";
import { toAdvText } from "@hooks/language/useTranslation";

enum EPropertyName {
    Horizontal = "horizontal",
    Wrap = "wrap",
    HorizontalAlign = "horizontalAlign",
    VerticalAlign = "verticalAlign",
    VerticalFill = "verticalFill",
    ChildrenGap = "childrenGap",
}

enum EPropertyCategory {
    Allgemeines = "Allgemeines",
    Fortgeschritten = "Fortgeschritten",
}

enum EPresetName {
    HorizontalPreset = "Horizontal Preset with Children",
    NotHorizontalPreset = "Not-Horizontal Preset",
}

const commonProperties = [
    AdvProperty.Boolean.createToggle(
        toAdvText(LAN.ORIENTATION),
        EPropertyName.Horizontal,
        toAdvText(LAN.GENERAL),
        toAdvText(LAN.STACK_HORIZONTAL_DESCR),
        false,
        "Horizontal",
        "Vertikal",
    ),
    AdvProperty.Boolean.create(
        toAdvText(LAN.WRAP),
        EPropertyName.Wrap,
        toAdvText(LAN.GENERAL),
        toAdvText(LAN.STACK_WRAP_DESCR),
        false,
    ),
    AdvProperty.Boolean.create(
        toAdvText(LAN.VERTICAL_FILL),
        EPropertyName.VerticalFill,
        toAdvText(LAN.GENERAL),
        toAdvText(LAN.STACK_VERTICAL_FILL_DESCR),
        false,
    ),
    AdvProperty.Text.createSelect(
        toAdvText(LAN.ALIGN_HORIZONTAL),
        EPropertyName.HorizontalAlign,
        toAdvText(LAN.ADVANCED),
        toAdvText(LAN.STACK_HORIZONTAL_ALIGN_DESCR),
        7,
        true,
        "start",
        "end",
        "center",
        "space-between",
        "space-around",
        "space-evenly",
        "baseline",
        "stretch",
    ),
    AdvProperty.Number.create(
        toAdvText(LAN.CHILDREN_GAP),
        EPropertyName.ChildrenGap,
        toAdvText(LAN.ADVANCED),
        toAdvText(LAN.STACK_CHILDREN_GAP_DESCR),
        4,
    ),
];

// Stack ist die Basis des Ui-Designers, deshalb hier als Objekt merken.
/*const registeredComponent = registerDesignableComponent({
    staticData: {
        name: LAN.STACK.text,
        translationContext: LAN.STACK.context,
        type: EComponentTypeLayout.Stack,
        supportsChildren: true,
        category: DefaultComponentCategory.Layout,
        icon: StackIcon,
    },
    properties: [
        ...commonProperties,
        ...AdvCommonComponentAttributes,
        ...AdvThemeProviderProperties,
        ...AdvStackItemDesignable.CommonProperties,
        ...AdvGridItemDesignable.CommonProperties,
    ],
    propertiesBuilders: [],
    presets: [
        {
            name: LAN.HORIZONTAL_PRESET_WITH_CHILDREN.text,
            translationContext: LAN.HORIZONTAL_PRESET_WITH_CHILDREN.context,
            properties: [{ name: EPropertyName.Horizontal, value: true }],
            children: [
                {
                    type: EComponentTypeLayout.StackItem,
                    properties: [{ name: AdvStackItemDesignable.PropertyName.Grow, value: true }],
                },
                {
                    type: EComponentTypeLayout.StackItem,
                    properties: [{ name: AdvStackItemDesignable.PropertyName.Shrink, value: true }],
                },
            ],
        },
        {
            name: LAN.NON_HORIZONTAL_PRESET.text,
            translationContext: LAN.NON_HORIZONTAL_PRESET.context,
            properties: [{ name: EPropertyName.Horizontal, value: false }],
        },
    ],
});

const AdvStackDesignable = {
    PropertyName: EPropertyName,
    PropertyCategory: EPropertyCategory,
    PresetName: EPresetName,
    Registered: registeredComponent,
    CommonProperties: commonProperties,
};

export default AdvStackDesignable;*/
