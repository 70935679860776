import {
    IPublicFilterCategory,
    TAdvFilterSelection,
} from "@components/dynamic/data-provider/types";
import AdvDropdown, { TAdvDropdownItem } from "@components/inputs/dropdown";
import AdvSearchInput from "@components/inputs/text-input/search-input";
import AdvStack from "@components/layout/stack";
import AdvStackItem from "@components/layout/stack/stack-item";
import { LAN } from "@data/language/strings";
import {
    CheckboxVisibility,
    ConstrainMode,
    DetailsList,
    DetailsListLayoutMode,
    IColumn,
    IDetailsHeaderProps,
    IDetailsListProps,
    IDetailsListStyleProps,
    IDetailsListStyles,
    IStyleFunctionOrObject,
    ResponsiveMode,
    ScrollablePane,
    ScrollbarVisibility,
    Sticky,
    StickyPositionType,
} from "@fluentui/react";
import { IObjectWithKey, Selection, SelectionMode } from "@fluentui/react/lib/Selection";
import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import useAdvComponent from "@hooks/useAdvComponent";
import { combineStyles } from "@utils/styling";
import React, { Dispatch, SetStateAction, useMemo, useRef, useState } from "react";
import { SizeMe } from "react-sizeme";

import { useLanguage } from "@hooks/language/useLanguages";
import useTranslationArray from "@hooks/language/useTranslationArray";
import useAdvTheme from "@hooks/useAdvTheme";
import { deepCompareJSXProps } from "@utils/deep-compare";
import deepCopy from "@utils/deep-copy";
import { nanoid } from "nanoid";
import { defaultTableStyles } from "./styles";
import { _copyAndSort } from "./utils";

export type TAdvLegacyTableStyles = Partial<IDetailsListStyles>; /* do not change */
export type TAdvLegacyTableStyleProps = IDetailsListStyleProps; /* do not change */
export type TAdvLegacyTableColumn = IColumn & {
    translationContext?: string;
    translatedLanguageID?: number;
    originalName?: string;
};
export type TAdvLegacyTableItem = IObjectWithKey;

export type TAdvLegacyTableProps = Omit<
    IDetailsListProps,
    "styles" | "columns" | "items" | "key" | "checkboxVisibility"
> & {
    styles?: IStyleFunctionOrObject<TAdvLegacyTableStyleProps, TAdvLegacyTableStyles>;

    columns: TAdvLegacyTableColumn[];
    items: TAdvLegacyTableItem[];

    /** Wird aufgerufen, wenn sich die Selection ändert. Funktioniert nur, wenn kein eigenes ``selection`` übergeben wurde. */
    onSelectionChanged?: (items: TAdvLegacyTableItem[]) => void;
    customFilterOptions?: Array<IPublicFilterCategory>;

    /** Default: FALSE */
    canFilter?: boolean;
    /** Default: TRUE */
    canSort?: boolean;

    /** Default: TRUE */
    drawTableHeader?: boolean;
    tableLeftHeaderItem?: React.JSX.Element;
    tableHeaderItem?: React.JSX.Element;

    /** Default: Hidden */
    checkboxVisibility?: CheckboxVisibility;
    ignoreTranslation?: boolean;

    onContextMenu?: React.MouseEventHandler<HTMLElement> | undefined;
};

export type TAdvLegacyTableImplProps = {
    searchText: string | undefined;
    setSearchText: (newSearchText: string | undefined) => void;
    filterSelection: TAdvFilterSelection | undefined;
    setFilterSelection: Dispatch<SetStateAction<TAdvFilterSelection>>;
    setSortColumns: Dispatch<SetStateAction<IColumn[]>>;
};

/**
 * This is the basic table implementation and should only be used by components
 * that implement it
 */
export const AdvLegacyTableImpl = ({
    styles,
    columns,
    items,
    selection,
    onSelectionChanged,
    onContextMenu,
    canFilter = true,
    canSort = true,
    drawTableHeader = true,
    tableLeftHeaderItem = <></>,
    tableHeaderItem = <></>,
    layoutMode = DetailsListLayoutMode.justified,
    checkboxVisibility = CheckboxVisibility.hidden,
    customFilterOptions,
    searchText,
    setKey,
    setSearchText,
    filterSelection,
    setFilterSelection,
    setSortColumns,
    onRenderDetailsHeader,
    ignoreTranslation = false,
    ...props
}: TAdvLegacyTableProps & TAdvLegacyTableImplProps) => {
    const theme = useAdvTheme();

    const filterSelections = useMemo<TAdvFilterSelection>(() => {
        const res: TAdvFilterSelection = {};
        if (customFilterOptions != undefined) {
            const keysSel = filterSelection != undefined ? Object.keys(filterSelection) : [];
            customFilterOptions.forEach((val) => {
                if (keysSel.includes(val.Name) && filterSelection != undefined) {
                    Object.assign(res, { ...res, [val.Name]: filterSelection[val.Name] });
                    return;
                }
                const defaultValIndex = val.Options.findIndex((valO) => valO.ActiveByDefault);
                if (defaultValIndex != -1)
                    Object.assign(res, { ...res, [val.Name]: defaultValIndex });
            });
        }
        return res;
    }, [customFilterOptions, filterSelection]);

    const customFilterOptionsEl = useMemo(() => {
        let filterOptionKey = 1;
        return customFilterOptions?.map(({ Name, Options }) => {
            const items: TAdvDropdownItem[] = Options.map((value, index) => {
                return { key: `filter_cbo_${index}`, text: value.Name };
            });

            const curItem = Object.keys(filterSelections).includes(Name)
                ? items[filterSelections[Name]]
                : undefined;

            return (
                <AdvStackItem align="end" key={"filteroptions2stack" + String(filterOptionKey++)}>
                    <AdvDropdown
                        key={"filteroptions2" + String(filterOptionKey++)}
                        onValueChanged={(newName) => {
                            if (newName !== undefined) {
                                const optionIndex = Options.findIndex((optionVal) => {
                                    return optionVal.Name == newName.text;
                                });
                                setFilterSelection((old) => {
                                    return { ...(old ?? {}), [Name]: optionIndex };
                                });
                            }
                        }}
                        options={items}
                        value={curItem}
                        label={Name}
                        dropdownWidth={"auto"}
                        responsiveMode={ResponsiveMode.large}
                        styles={{ root: { width: 200, marginRight: 3 } }}
                    />
                </AdvStackItem>
            );
        });
    }, [customFilterOptions, filterSelections, setFilterSelection]);

    const filterSearchEl = useMemo(() => {
        return canFilter ? (
            <AdvStackItem align="end">
                <AdvSearchInput
                    key="filter"
                    onValueChanged={setSearchText}
                    value={searchText}
                    placeholder={LAN.SEARCH_DOT_DOT_DOT.text}
                    placeholderTranslationContext={LAN.SEARCH_DOT_DOT_DOT.context}
                    showIcon
                />
            </AdvStackItem>
        ) : (
            <></>
        );
    }, [canFilter, searchText, setSearchText]);

    const tableHeader = useMemo(() => {
        return (
            <AdvStack
                horizontalAlign="start"
                horizontal={true}
                wrap
                tokens={{ childrenGap: 0 }}
                styles={{ root: { width: "100%" } }}
            >
                <AdvStackItem shrink={0} align="end">
                    {tableLeftHeaderItem}
                </AdvStackItem>
                <AdvStackItem grow align="end">
                    {tableHeaderItem}
                </AdvStackItem>
                {customFilterOptionsEl}
                {filterSearchEl}
            </AdvStack>
        );
    }, [customFilterOptionsEl, filterSearchEl, tableHeaderItem, tableLeftHeaderItem]);

    /**
     * Zusätzlich soll, falls vorhanden, die ``ActionBar`` hier angezeigt werden.
     */
    const onRenderDetailsHeaderOverload = useAdvCallback(
        function (
            props?: IDetailsHeaderProps,
            defaultRender?: (props?: IDetailsHeaderProps) => React.JSX.Element | null,
        ): React.JSX.Element | null {
            // Standardmäßíg hat der DetailsHeader (DefaultRender) ein PaddingTop, welches hier natürlich nicht schön ist
            // (zwischen ActionBar und Überschriften). Entsprechend entfernen, siehe detailsHeaderStyles.
            if (typeof props != "undefined")
                props.styles = combineStyles(defaultTableStyles.header);

            return (
                <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
                    <AdvStack
                        verticalAlign="space-between"
                        horizontalAlign="space-evenly"
                        horizontal={false}
                        wrap={false}
                    >
                        <AdvStackItem>
                            <AdvStack
                                horizontalAlign="start"
                                horizontal={true}
                                wrap
                                tokens={{ childrenGap: 0 }}
                            >
                                {typeof onRenderDetailsHeader != "undefined" &&
                                    onRenderDetailsHeader(props, defaultRender)}
                            </AdvStack>
                        </AdvStackItem>
                        <AdvStackItem align="stretch" styles={{ root: { overflow: "hidden" } }}>
                            {typeof defaultRender != "undefined" &&
                                drawTableHeader &&
                                defaultRender(props)}
                        </AdvStackItem>
                    </AdvStack>
                </Sticky>
            );
        },
        [drawTableHeader, onRenderDetailsHeader],
    );

    const mySelection = useRef(
        selection ??
            new Selection({
                selectionMode: props.selectionMode ?? SelectionMode.single,
                onSelectionChanged: () => {
                    if (onSelectionChanged) {
                        const newSelection = mySelection.current.getSelection();
                        onSelectionChanged(newSelection);
                    }
                },
            }),
    );

    const selectionSetKey = useMemo(() => {
        if (setKey != undefined) return setKey;
        return nanoid();
    }, [setKey]);

    /** Bei Klick auf eine Überschrift soll die entsprechende Spalte sortiert werden.*/
    const onColumnHeaderClick = useAdvCallback(
        function (ev?: React.MouseEvent<HTMLElement>, clickedColumn?: IColumn): void {
            if (typeof ev == "undefined" || typeof clickedColumn == "undefined" || !canSort) return;

            const isAppendMode = ev.ctrlKey || ev.shiftKey;
            setSortColumns((old) => {
                let newSortColumns = deepCopy(old, true);
                // if column exists, just change sorting
                const foundColumn = newSortColumns?.find(
                    (valS) => (valS.isSorted ?? false) && valS.key === clickedColumn?.key,
                );
                if (foundColumn != undefined) {
                    foundColumn.isSortedDescending = !(foundColumn.isSortedDescending ?? true);
                    if (!isAppendMode) newSortColumns = [foundColumn];
                } else {
                    const newCol = deepCopy(clickedColumn, true);
                    newCol.isSorted = true;
                    newCol.isSortedDescending = true;
                    if (isAppendMode) newSortColumns.push(newCol);
                    else newSortColumns = [newCol];
                }
                return newSortColumns;
            });
        },
        [canSort, setSortColumns],
    );

    const untranslatedColumnNames = useMemo(() => {
        return columns.map((col) => col.originalName ?? col.name);
    }, [columns]);
    const translatedColumnNames = useTranslationArray(untranslatedColumnNames).map(
        (transItem) => transItem.text,
    );
    const currentLanguage = useLanguage();

    const translatedColumns = useMemo(() => {
        if (translatedColumnNames === undefined || ignoreTranslation) return columns;

        const myColumns = [];
        for (let i = 0; i < translatedColumnNames.length; i++) {
            myColumns[i] = { ...columns[i] };

            const column = myColumns[i];
            const translation = translatedColumnNames[i];
            if (
                column.translatedLanguageID !== undefined &&
                column.translatedLanguageID == currentLanguage.ID
            )
                continue; // Nur übersetzen wenn noch nicht geschehen

            if (column.originalName === undefined) column.originalName = column.name;

            if (translation === undefined || translation == "") {
                column.name = column.originalName;
            } else {
                column.name = translation;
            }
            column.translatedLanguageID = currentLanguage.ID;
        }
        return myColumns;
    }, [translatedColumnNames, ignoreTranslation, columns, currentLanguage.ID]);

    // const handleRenderHeader = (
    //     props?: IDetailsGroupDividerProps,
    //     defaultRender?: (props?: IDetailsGroupDividerProps | undefined) => React.JSX.Element | null
    // ) => {
    //     if (props === undefined || defaultRender === undefined) return null;
    //     return <Sticky stickyPosition={StickyPositionType.Header}>{defaultRender(props)}</Sticky>;
    // };

    return (
        <AdvStack
            verticalFill
            onContextMenu={onContextMenu}
            styles={{ root: { backgroundColor: theme.palette.white } }}
        >
            <AdvStackItem shrink={0}>{tableHeader}</AdvStackItem>
            <AdvStackItem grow shrink={0}>
                <SizeMe monitorHeight monitorWidth>
                    {({ size }) => {
                        const { width, height } = size;
                        return (
                            <div
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    minHeight: 200,
                                    position: "relative",
                                }}
                            >
                                <div
                                    style={{
                                        position: "absolute",
                                        width: width ?? "100%",
                                        height: height ?? "100%",
                                        overflow: "auto",
                                    }}
                                >
                                    <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                                        <DetailsList
                                            {...props}
                                            columns={translatedColumns}
                                            groupProps={{
                                                ...props.groupProps,
                                                // onRenderHeader: handleRenderHeader,
                                            }}
                                            items={items}
                                            styles={combineStyles(
                                                defaultTableStyles.list(
                                                    width ?? "100%",
                                                    height ?? "100%",
                                                ),
                                                styles,
                                            )}
                                            onRenderDetailsHeader={onRenderDetailsHeaderOverload}
                                            layoutMode={layoutMode}
                                            constrainMode={ConstrainMode.unconstrained}
                                            onColumnHeaderClick={onColumnHeaderClick}
                                            selection={mySelection.current}
                                            checkboxVisibility={checkboxVisibility}
                                            setKey={selectionSetKey}
                                        />
                                    </ScrollablePane>
                                </div>
                            </div>
                        );
                    }}
                </SizeMe>
            </AdvStackItem>
        </AdvStack>
    );
};

/**
 * @summary Wrapper für ``DetailsList``
 * @summary Zusätzliche Funktionen: Sortieren, Scrollen, Actionbar
 * @link https://developer.microsoft.com/en-us/fluentui#/controls/web/detailslist
 */
const AdvLegacyTableComp = ({
    columns,
    items,
    canFilter = true,
    canSort = true,
    canSearchItem,
    ...props
}: TAdvLegacyTableProps & {
    canSearchItem?: (fieldName: string) => boolean;
}) => {
    useAdvComponent(AdvLegacyTableComp, props);

    const [searchText, setSearchText] = useState<string | undefined>();
    const [filterSelectionsInternal, setFilterSelectionsInternal] = useState<TAdvFilterSelection>(
        {},
    );
    const [lastSortColums, setLastSortColums] = useState<IColumn[]>([]);
    const sortedCols = useMemo(() => {
        const res = deepCopy(columns, true);
        lastSortColums.forEach((valS) => {
            const foundCol = res.find((val1) => val1.key === valS.key);
            if (foundCol != undefined) {
                foundCol.isSorted = valS.isSorted;
                foundCol.isSortedDescending = valS.isSortedDescending;
            }
        });
        return res;
    }, [columns, lastSortColums]);

    const renderItems = useMemo(() => {
        if (canSort === true || canFilter === true) {
            let retItems = deepCopy(items, true);

            const sortColumns = lastSortColums.filter((col) => col.isSorted);
            if (canSort === true && sortColumns.length > 0)
                retItems = _copyAndSort(
                    items,
                    sortColumns.map((val) => {
                        return {
                            columnKey: val.fieldName ?? "",
                            isSortedDescending: val.isSortedDescending,
                        };
                    }),
                );
            return canFilter === true
                ? retItems.filter((item) => {
                      if (item == null) return true;
                      const itemKeys = Object.keys(item);
                      for (const itemKey of itemKeys) {
                          if (
                              itemKey != "key" &&
                              searchText != undefined &&
                              (canSearchItem == undefined || canSearchItem(itemKey)) &&
                              JSON.stringify((item as any)[itemKey])
                                  .toLowerCase()
                                  .includes(searchText.toLowerCase())
                          )
                              return true;
                      }
                      return searchText == undefined;
                  })
                : retItems;
        }
        return items;
    }, [canSort, canFilter, items, lastSortColums, searchText, canSearchItem]);

    return (
        <AdvLegacyTableImpl
            {...props}
            canSort={canSort}
            canFilter={canFilter}
            columns={sortedCols}
            items={renderItems}
            searchText={searchText}
            setSearchText={setSearchText}
            filterSelection={filterSelectionsInternal}
            setFilterSelection={setFilterSelectionsInternal}
            setSortColumns={setLastSortColums}
        ></AdvLegacyTableImpl>
    );
};

const AdvLegacyTable = React.memo(AdvLegacyTableComp, deepCompareJSXProps);
export default AdvLegacyTable;
