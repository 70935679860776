import { LAN } from "@data/language/strings";
import { AdvProperty } from "@feature/Designer/utils";
import { toAdvText } from "@hooks/language/useTranslation";

enum EPropertyName {
    // Besondere Properties, die (vorerst) direkt im ElementContainer / DesignerComponent zugewiesen werden:
    // (TODO: Einen anderen, schöneren Weg finden)
    FlexBasis = "basis",
    MinWidth = "minWidth",
    MaxWidth = "maxWidth",
    MinHeight = "minHeight",
    MaxHeight = "maxHeight",
    Grow = "grow",
    Shrink = "shrink",
    Align = "align",
}

enum EPropertyCategory {
    StackItem = "StackItem",
}

enum EPresetName {}

const commonProperties = [
    AdvProperty.Text.create(
        toAdvText(LAN.FLEX_BASIS),
        EPropertyName.FlexBasis,
        toAdvText(LAN.STACK_ITEM),
        toAdvText(LAN.STACK_ITEM_FLEX_BASIS_DESCR),
        "auto",
    ),
    AdvProperty.Text.create(
        toAdvText(LAN.MIN_WIDTH),
        EPropertyName.MinWidth,
        toAdvText(LAN.STACK_ITEM),
        toAdvText(LAN.STACK_ITEM_MIN_WIDTH_DESCR),
        "",
    ),
    AdvProperty.Text.create(
        toAdvText(LAN.MAX_WIDTH),
        EPropertyName.MaxWidth,
        toAdvText(LAN.STACK_ITEM),
        toAdvText(LAN.STACK_ITEM_MAX_WIDTH_DESCR),
        "",
    ),
    AdvProperty.Text.create(
        toAdvText(LAN.MIN_HEIGHT),
        EPropertyName.MinHeight,
        toAdvText(LAN.STACK_ITEM),
        toAdvText(LAN.STACK_ITEM_MIN_HEIGHT_DESCR),
        "",
    ),
    AdvProperty.Text.create(
        toAdvText(LAN.MAX_HEIGHT),
        EPropertyName.MaxHeight,
        toAdvText(LAN.STACK_ITEM),
        toAdvText(LAN.STACK_ITEM_MAX_HEIGHT_DESCR),
        "",
    ),
    AdvProperty.Number.create(
        toAdvText(LAN.GROW_RATIO),
        EPropertyName.Grow,
        toAdvText(LAN.STACK_ITEM),
        toAdvText(LAN.STACK_ITEM_GROW_RATIO_DESCR),
        0,
    ),
    AdvProperty.Number.create(
        toAdvText(LAN.SHRINK_RATIO),
        EPropertyName.Shrink,
        toAdvText(LAN.STACK_ITEM),
        toAdvText(LAN.STACK_ITEM_SHRINK_RATIO_DESCR),
        0,
    ),
    AdvProperty.Text.createSelect(
        toAdvText(LAN.ALIGNMENT),
        EPropertyName.Align,
        toAdvText(LAN.STACK_ITEM),
        toAdvText(LAN.STACK_ITEM_ALIGNMENT_DESCR),
        0,
        true,
        "auto",
        "stretch",
        "baseline",
        "start",
        "center",
        "end",
    ),
];

/*
const registeredComponent = registerDesignableComponent({
    staticData: {
        name: LAN.STACK_ITEM.text,
        translationContext: LAN.STACK_ITEM.context,
        type: EComponentTypeLayout.StackItem,
        supportsChildren: true,
        category: DefaultComponentCategory.Layout,
        icon: StackItemIcon,
    },
    propertiesBuilders: [],
    properties: [...commonProperties],
    presets: [],
});
*/

const AdvStackItemDesignable = {
    PropertyName: EPropertyName,
    PropertyCategory: EPropertyCategory,
    PresetName: EPresetName,
    //Registered: registeredComponent,
    CommonProperties: commonProperties,
};

export default AdvStackItemDesignable;
