import {
    DateRangeType,
    DayOfWeek,
    FirstWeekOfYear,
    IDatePickerProps,
    IDatePickerStrings,
    IDatePickerStyleProps,
    IDatePickerStyles,
    IStyleFunctionOrObject,
} from "@fluentui/react";
import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import useAdvComponent from "@hooks/useAdvComponent";
import { deepCompareJSXProps } from "@utils/deep-compare";
import React, { useMemo, useRef } from "react";

import AdvGridItemDesignable from "@components/layout/grid/grid-item/designable";
import AdvStackItemDesignable from "@components/layout/stack/stack-item/designable";
import {
    AdvCommonComponentAttributes,
    AdvThemeProviderProperties,
    TAdvCommonProperties,
} from "@components/other/common-properties";
import { LAN } from "@data/language/strings";
import { DefaultComponentCategory } from "@feature/Designer/types/category";
import { TAdvDesignerComponentProps } from "@feature/Designer/types/component-props";
import { EComponentTypeInput } from "@feature/Designer/types/component-type";
import { AdvProperty, registerDesignableComponent } from "@feature/Designer/utils";
import { Field, FluentProvider, InfoLabel, Label } from "@fluentui/react-components";
import { DatePicker, DatePickerProps } from "@fluentui/react-datepicker-compat";
import { createV9Theme } from "@fluentui/react-migration-v8-v9";
import {
    TAdvValueBindingParams,
    useAdvValueBinderNoDataType,
} from "@hooks/dynamic/useAdvValueBinder";
import { useAdvWebAction } from "@hooks/dynamic/useAdvWebAction";
import { TAdvWebActionParams } from "@hooks/dynamic/useAdvWebAction.types";
import { toAdvText, useT } from "@hooks/language/useTranslation";
import { useAdvEffect } from "@hooks/react-overload/useAdvEffect";
import useAdvTheme from "@hooks/useAdvTheme";
import { CalendarTodayIcon, CloseIcon, TextInputIcon } from "@themes/icons";
import { EAdvValueDataTypes } from "@utils/data-types";
import { DateToUnix } from "@utils/date";
import { mergeObjects } from "@utils/styling";
import { nanoid } from "nanoid";
import { TCommonValueProps } from "..";
import AdvButton from "../button/button";
import { EAdvButtonType } from "../button/button-pure";
import { localizationCalendarStrings } from "../calendar";
import {
    DateToStr,
    StrToDate,
    TAdvDatePickerDisplayFormat,
    TAdvDatePickerRangeType,
    TAdvDatePickerValueFormat,
} from "./utils";

require("datejs");

const localizationDatePickerStrings: IDatePickerStrings | undefined =
    typeof window === "undefined" || localizationCalendarStrings === undefined
        ? undefined
        : {
              ...localizationCalendarStrings,
              //     /**
              //  * Error message to render for TextField if isRequired validation fails.
              //  */
              // isRequiredErrorMessage?: string;
              // /**
              //  * Error message to render for TextField if input date string parsing fails.
              //  */
              // invalidInputErrorMessage?: string;
              // /**
              //  * Error message to render for TextField if date boundary (minDate, maxDate) validation fails.
              //  */
              // isOutOfBoundsErrorMessage?: string;
              // /**
              //  * Status message to render for TextField the input date parsing fails,
              //  * and the typed value is cleared and reset to the previous value.
              //  *  e.g. "Invalid entry `{0}`, date reset to `{1}`"
              //  */
              // isResetStatusMessage?: string;
          };

export type TAdvDatePickerStyles = IDatePickerStyles; /* do not change */
export type TAdvDatePickerStyleProps = IDatePickerStyleProps; /* do not change */

export type TAdvDatePickerProps = Pick<IDatePickerProps, "calendarProps" | "textField"> &
    Omit<DatePickerProps, "styles" | "label" | "value" | "onChange"> &
    TAdvCommonProperties &
    TAdvDesignerComponentProps &
    TCommonValueProps<string> & {
        styles?: IStyleFunctionOrObject<TAdvDatePickerStyleProps, TAdvDatePickerStyles>;
        label: string;
        description?: string;

        ignoreTranslation?: boolean;
        valueBindingParams?: TAdvValueBindingParams;

        rangeType?: "day" | "week" | "month" | "workweek";
        valueFormat?: TAdvDatePickerValueFormat;
        displayFormat?: TAdvDatePickerDisplayFormat;

        /** Falls kein Datum angegeben (null/undefined/parse fehler) dann Heute als Fallabck-Datum nehmen */
        defaultToday?: boolean;
        submitAction?: TAdvWebActionParams;
        keyRef?: string;

        disabledBindingParams?: TAdvValueBindingParams;
    };
/** Legt fest, wie das ausgewählte Datum angezeigt werden soll */
function DefaultFormatDate(format: TAdvDatePickerDisplayFormat, date: Date | undefined): string {
    if (date === undefined || date == null) return "Select a date";
    switch (format) {
        case TAdvDatePickerDisplayFormat.LocaleDate:
            return date.toLocaleDateString();
        case TAdvDatePickerDisplayFormat.Week:
            console.log(date, date.getWeek(), new Date(date).getISOWeek());
            return `${date.getFullYear()}/${date.getWeek().toString().padStart(2, "0")}`;
        case TAdvDatePickerDisplayFormat.Month:
            // Bei getMonth ist Januar = 0, in AdvanTex ist Januar aber 1, deswegen hier 1 aufschlagen
            return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}`;
        case TAdvDatePickerDisplayFormat.Unix:
            return DateToUnix(date).toString();
        default:
            throw Error(`DatePickerDisplayFormat ${String(format)} not implemented!`);
    }
}

const AdvDatePickerComp = ({
    valueBindingParams,
    label,
    ignoreTranslation = false,
    value,
    dataArrayIndex = 0,
    advhide,
    advhideBindingParams,
    designerProps,
    designerData,
    valueFormat = TAdvDatePickerValueFormat.SQL,
    displayFormat = TAdvDatePickerDisplayFormat.LocaleDate,
    rangeType = "day",
    defaultToday = false,
    formatDate,
    calendarProps: propCalendarProps,
    placeholder,
    description,
    disabled = false,
    disabledBindingParams,
    required,
    submitAction,
    keyRef,
    onValueChanged,
    ...props
}: TAdvDatePickerProps) => {
    useAdvComponent(AdvDatePickerComp, props);

    const [, , submitActionFunc, shouldUseBoundValueSubmit] = useAdvWebAction(
        keyRef ?? "",
        dataArrayIndex,
        submitAction,
    );
    const [isDisabled] = useAdvValueBinderNoDataType(
        disabledBindingParams,
        disabled,
        EAdvValueDataTypes.Boolean,
        dataArrayIndex ?? 0,
    );
    const [shouldHide] = useAdvValueBinderNoDataType(
        advhideBindingParams,
        advhide,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );

    const { t: translatedLabel } = useT(label, undefined, ignoreTranslation);
    const { t: translatedPlaceholder } = useT(placeholder, undefined, ignoreTranslation);

    const theme = useAdvTheme();

    const [stringValue, setCurrentValue, currentValueAttributes] = useAdvValueBinderNoDataType(
        valueBindingParams,
        value,
        EAdvValueDataTypes.String,
        dataArrayIndex,
    );

    const dateValue = useMemo(() => {
        const val = StrToDate(valueFormat, stringValue);
        return val;
    }, [valueFormat, stringValue]);

    const myCalendarProps = useMemo(() => {
        function toRangeType(p: typeof rangeType) {
            switch (p) {
                case "day":
                    return DateRangeType.Day;
                case "week":
                    return DateRangeType.Week;
                case "month":
                    return DateRangeType.Month;
                case "workweek":
                    return DateRangeType.WorkWeek;
            }
        }

        // Beachte: Einige Properties werden nicht aus CalendarProps sondern DataPickerProps genommen
        // siehe https://github.com/sopranopillow/fluentui/blob/master/packages/react/src/components/DatePicker/DatePicker.base.tsx
        const customCalendarProps = {
            dateRangeType: toRangeType(rangeType),
        };
        if (propCalendarProps === undefined) return customCalendarProps;
        else return mergeObjects(propCalendarProps, customCalendarProps);
    }, [propCalendarProps, rangeType]);

    const handleDateSelected = useAdvCallback(
        (date: Date | null | undefined) => {
            if (
                !(date === undefined || date == null) &&
                rangeType == TAdvDatePickerRangeType.Month
            ) {
                //falls Range nur Monat ist, auch nur Monat speichern
                date = new Date(date.getFullYear(), date.getMonth(), 1);
            }
            const newDate = date === undefined || date == null ? "" : DateToStr(valueFormat, date);
            setCurrentValue(newDate);

            if (shouldUseBoundValueSubmit) submitActionFunc([newDate]);

            if (onValueChanged != undefined) onValueChanged(newDate);
        },
        [
            rangeType,
            valueFormat,
            setCurrentValue,
            shouldUseBoundValueSubmit,
            submitActionFunc,
            onValueChanged,
        ],
    );

    useAdvEffect(() => {
        if (designerData !== undefined) return;
        // Kein Datum und standardmäßig auf Heute -> Auch das Binding entsprecend setzen
        if ((dateValue === undefined || dateValue == null) && defaultToday) {
            handleDateSelected(new Date());
        }
    }, [dateValue, defaultToday, designerData, handleDateSelected]);

    const uniqueID = useMemo(() => nanoid(), []);

    const lastValue = useRef("");
    const triggerSubmit = useAdvCallback(
        (data: string) => {
            if (data != lastValue.current) {
                lastValue.current = data;
                submitActionFunc([data]);
            }
        },
        [submitActionFunc],
    );

    if (shouldHide === true && designerProps === undefined) return <></>;
    return (
        <FluentProvider theme={createV9Theme(theme)} style={{ backgroundColor: "transparent" }}>
            <Label required={required} disabled={disabled || !currentValueAttributes.isEditable}>
                <InfoLabel info={description}>{translatedLabel}</InfoLabel>
            </Label>
            <Field style={{ maxWidth: "100%" }}>
                <DatePicker
                    key={
                        uniqueID +
                        "__" +
                        dateValue?.toString().length.toString() +
                        dateValue?.toString()
                    }
                    value={dateValue}
                    onSelectDate={handleDateSelected}
                    //styles={myStyles}
                    formatDate={(date) => {
                        if (formatDate !== undefined) return formatDate(date);
                        return DefaultFormatDate(displayFormat, date);
                    }}
                    //calendarProps={myCalendarProps}
                    calendar={myCalendarProps}
                    strings={localizationDatePickerStrings}
                    showWeekNumbers
                    firstDayOfWeek={DayOfWeek.Monday}
                    firstWeekOfYear={FirstWeekOfYear.FirstFourDayWeek}
                    placeholder={translatedPlaceholder}
                    showMonthPickerAsOverlay
                    isMonthPickerVisible
                    contentBefore={
                        dateValue == undefined || dateValue == null ? undefined : (
                            <AdvButton
                                disabled={isDisabled || !currentValueAttributes.isEditable}
                                iconName={
                                    !defaultToday ? CloseIcon.iconName : CalendarTodayIcon.iconName
                                }
                                buttonType={EAdvButtonType.Action}
                                styles={{ root: { padding: 0, margin: 0, height: "auto" } }}
                                onClick={() => handleDateSelected(undefined)}
                                title={!defaultToday ? "Zurücksetzen" : "Gehe zu heute"}
                            />
                        )
                    }
                    //textField={myTextfieldProps}
                    disabled={isDisabled || !currentValueAttributes.isEditable}
                    required={required}
                    onBlur={(ev) => {
                        if (props.onBlur !== undefined) props.onBlur(ev);

                        // Nur wenn wir auch die Daten im Submit brauchen,
                        // dann wollen wir beim verlassen des Inputs nochmal das Submit auslösen.
                        // Außerdem nur, wenn der User auch was geändert hat
                        if (shouldUseBoundValueSubmit && value != undefined) {
                            triggerSubmit(value);
                        }
                    }}
                    onKeyUp={(ev) => {
                        // emulate submit
                        if (ev.key == "Enter" && shouldUseBoundValueSubmit && value != undefined) {
                            triggerSubmit(value);
                        }
                    }}
                    {...props}
                ></DatePicker>
            </Field>
        </FluentProvider>
    );
};

const AdvDatePicker = React.memo(AdvDatePickerComp, deepCompareJSXProps);
export default AdvDatePicker;

registerDesignableComponent({
    staticData: {
        name: LAN.DATEPICKER.text,
        translationContext: LAN.DATEPICKER.context,
        type: EComponentTypeInput.DatePicker,
        supportsChildren: false,
        category: DefaultComponentCategory.Input,
        icon: TextInputIcon,
    },
    properties: [
        AdvProperty.Text.createSuggestion(
            toAdvText(LAN.LABEL),
            "label",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.TEXTINPUT_LABEL_DESCR),
            "DatePicker-Label",
        ),
        AdvProperty.Text.create(
            toAdvText(LAN.VALUE),
            "value",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.TEXTINPUT_VALUE_DESCR),
            DateToStr(TAdvDatePickerValueFormat.SQL, new Date()) ?? "",
        ),
        AdvProperty.Text.createSelect(
            toAdvText(LAN.DATEPICKER_VALUE_FORMAT),
            "valueFormat",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.DATEPICKER_VALUE_FORMAT_DESCR),
            0,
            false,
            "sql",
            "unix",
            "week",
            "month",
        ),
        AdvProperty.Text.createSelect(
            toAdvText(LAN.DATEPICKER_DISPLAY_FORMAT),
            "displayFormat",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.DATEPICKER_DISPLAY_FORMAT_DESCR),
            0,
            false,
            "localeDate",
            "week",
            "month",
            "unix",
        ),
        AdvProperty.Text.createSelect(
            toAdvText(LAN.DATEPICKER_RANGE_TYPE),
            "rangeType",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.DATEPICKER_RANGE_TYPE_DESCR),
            0,
            false,
            "day",
            "week",
            "month",
            "workweek",
        ),
        AdvProperty.Boolean.create(
            toAdvText(LAN.DATEPICKER_VALUE_TODAY),
            "defaultToday",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.DATEPICKER_VALUE_TODAY_DESCR),
            false,
        ),
        // AdvProperty.Boolean.create(
        //     toAdvText(LAN.DATEPICKER_MONTHPICK_VISIBLE),
        //     "isMonthPickerVisible",
        //     toAdvText(LAN.GENERAL),
        //     toAdvText(LAN.DATEPICKER_VALUE_TODAY_DESCR),
        //     false
        // ),
        AdvProperty.Boolean.create(
            toAdvText(LAN.FORM_REQUIRED),
            "required",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.FORM_REQUIRED_DESCR),
            false,
        ),
        AdvProperty.Boolean.create(
            toAdvText(LAN.DISABLED),
            "disabled",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.DROPDOWN_DISABLED_DESCR),
            false,
        ),
        AdvProperty.Text.createSuggestion(
            toAdvText(LAN.PLACEHOLDER),
            "placeholder",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.PLACEHOLDER_DESCR),
            "",
        ),
        AdvProperty.Text.createSuggestion(
            toAdvText(LAN.TEXTINPUT_DESCRIPTION),
            "description",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.TEXTINPUT_DESCRIPTION_DESCR),
            "",
        ),
        AdvProperty.Boolean.create(
            toAdvText(LAN.IGNORE_TRANSLATION),
            "ignoreTranslation",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.IGNORE_TRANSLATION_DESCR),
            false,
        ),
        ...AdvCommonComponentAttributes,
        ...AdvThemeProviderProperties,
        ...AdvStackItemDesignable.CommonProperties,
        ...AdvGridItemDesignable.CommonProperties,
    ],
    propertiesBuilders: [],
    presets: [],
});
