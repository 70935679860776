import AdvText, { AdvHeadline } from "@components/data/text";
import AdvButton from "@components/inputs/button";
import AdvPage from "@components/layout/page";
import { LAN } from "@data/language/strings";
import { Separator } from "@fluentui/react";
import { NextPage } from "next";
import { useRouter } from "next/router";
import { ReactNode } from "react";

export type TPageNotFoundProps = {
    children: ReactNode | ReactNode[] | undefined;
};
const PageNotFound: NextPage<TPageNotFoundProps> = ({ children }: TPageNotFoundProps) => {
    const router = useRouter();

    const onNavigateBack = () => {
        router.back();
        // history.back();
    };

    return (
        <AdvPage title={LAN.PAGE_NOT_FOUND.text} translationContext={LAN.PAGE_NOT_FOUND.context}>
            <AdvHeadline>404 - Seite nicht gefunden</AdvHeadline>
            <Separator />
            <AdvText>Aktueller Pfad: {router.asPath}</AdvText>
            <Separator />
            {children}
            <Separator />
            <AdvButton text="Zurück" onClick={onNavigateBack}></AdvButton>
        </AdvPage>
    );
};

export default PageNotFound;
