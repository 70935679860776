import { TAdvEndlessTableColumnDefinitions } from "@components/data/table-new/endless/types";
import { TAdvProgressBarDataDefinitions } from "@components/progress";
import { TAdvValueBindingParams } from "@hooks/dynamic/useAdvValueBinder";
import { TAdvWebActionParams } from "@hooks/dynamic/useAdvWebAction.types";
import { TAdvTranslationText } from "@hooks/language/useTranslation";

/** (Primitive) Property-Typen */
export enum EPropertyType {
    /** Eine ganz normale Text-Property. */
    Text = "text",
    /** Eine ganz normale Boolean-Property. */
    Boolean = "boolean",
    /** Eine ganz normale Number-Property. */
    Number = "number",
    /** Binding-Property. */
    Bindings = "bindings",
    List = "list",
    Action = "action",
    Object = "object",
    Columns = "columns",
}

/** Legt fest, wie eine Property zu pflegen ist. */
export enum EPropertyInputType {
    /** Eingabefeld (TextField, Checkbox, ...) abhängig vom {@link EPropertyType} */
    Default,
    /** Eingabefeld (TextField, Checkbox, ...) mit Suggestions von der Localizer Tabelle */
    Suggestions,
    /** Dropdown */
    Select,
    /** Checkbox / ToggleButton (wenn Boolean) */
    Toggle,
    /** Radiobuttons */
    Radio,
    /** Combobox (Select mit der Möglichkeit eigene Werte einzugeben) */
    Combobox,
    /** Chart */
    Chart,
    /** Eingabefeld, welches Hotkeys tracked */
    Hotkeys,
    /** File-Input */
    Resource,
    /** Theme-Swizzle */
    ThemeSwizzle,
    /** Theme-Font-Overwrite */
    ThemeFontOverwrite,
    /** Endless-Table Autogrouping */
    AutoGrouping,
    /** Endless-Table Header */
    TableHeader,
    /** Endless-Table Dropdown-Filter */
    TableFilters,
    /** Endless-Table Header */
    DataPositioning,
    /** Actions */
    Simplified,
    /** Logic */
    LogicOnly,
    ProgressData,
}

export interface IProperty<TProp extends EPropertyType, TValue = any> {
    /**
     * Name der Property
     * @important **Muss eine existierende Property der Komponente sein. Groß-und Kleinschreibung ist wichtig!**
     * @important Kann auch der Wert eines Unter-Objekts sein, z.B. ``layout.x`` / ``layout.y``
     */
    name: string; // the name of this field is used in the server code, in case of rename, also do it in the server code
    allowsBindings: boolean;
    /**
     * Die bindings der Property
     */
    bindingParams: TAdvValueBindingParams; // the name of this field is used in the server code, in case of rename, also do it in the server code
    /** Aktueller Wert der Eigenschaft. Eine DesignableComponent kann hier einen *Default-Wert* hinterlegen. */
    value?: TValue; // the name and parts of the type structure of this field are used in the server code, in case of rename, also do it in the server code
    /**
     * Art der Property (``Text``, ``Boolean``, ...)
     * @see {@link EPropertyType}
     */
    type: TProp;
    /**
     * Art der Pflege (default, select, ...)
     * @see {@link EPropertyInputType}
     */
    inputType?: EPropertyInputType;
}

export interface IPropertyBoolean extends IProperty<EPropertyType.Boolean, boolean> {}
export interface IPropertyText extends IProperty<EPropertyType.Text, string> {}
export interface IPropertyNumber extends IProperty<EPropertyType.Number, number> {
    minValue?: number;
    maxValue?: number;
}
export interface IPropertyAction extends IProperty<EPropertyType.Action, TAdvWebActionParams> {}
export interface IPropertyActionSimple
    extends IProperty<EPropertyType.Action, TAdvWebActionParams> {}
export interface IPropertyActionLogicOnly
    extends IProperty<EPropertyType.Action, TAdvWebActionParams> {}
export interface IPropertyBindings
    extends IProperty<EPropertyType.Bindings, TAdvValueBindingParams> {}
export type TPropertyListItem = any;
export type TPropertyList = TPropertyListItem[];
export interface IPropertyList extends IProperty<EPropertyType.List, TPropertyList> {
    displayTextDataInput: TAdvTranslationText;
}
export interface IPropertyColumns
    extends IProperty<EPropertyType.Columns, TAdvEndlessTableColumnDefinitions> {}
// TODO: Kann eigentlich raus?
export interface IPropertyObject extends IProperty<EPropertyType.Object, object> {}
export interface IPropertyFile extends IProperty<EPropertyType.Object, object> {}
export interface IPropertyProgressData
    extends IProperty<EPropertyType.Object, TAdvProgressBarDataDefinitions> {}
export interface IPropertyIcons extends IProperty<EPropertyType.Text, string> {
    icons: string[];
}

/** Dropdown / Select zur Pflege */
export interface IPropertyWithSelect<TValue> {
    values: { text: string; value: TValue }[];
}

/** Dropdown / Select zur Pflege. Values kommen aus einem RecoilState */
export interface IPropertyWithRecoilSelect {
    recoilStateKey: string;
}

/** Combobox zur Pflege. Vorgeschlagene Werte komen aus einem RecoilState. Eigene Eingaben möglicH! */
export interface IPropertyWithRecoilCombo {
    recoilStateKey: string;
}

/** Checkbox zur Pflege */
export interface IPropertyWithToggle<TValue> {
    /** Der Wert, wenn die Checkbox *checked* ist. */
    trueValue: TValue;
    /** Der Wert, wenn die Checkbox *unchecked* ist. */
    falseValue: TValue;
    /** Text der angezeigt wird wenn TRUE */
    trueDisplay: string;
    /** Text der angezeigt wird wenn FALSE */
    falseDisplay: string;
}

/** Radiogroup zur Pflege */
export interface IPropertyWithRadio<TValue> {
    values: TValue[];
}
