import { DYNAMIC_PAGE_DEFAULT_ICON } from "@components/dynamic/dynamic-page/designable";
import { renderLanguageItem } from "@components/inputs/dropdown/language-dropdown";
import AdvCallout from "@components/layout/callout/callout";
import { recoilAction } from "@data/action";
import { SessionPermissionConfig } from "@data/actions/session-permission-config";
import { cacheGet, cacheSet } from "@data/cache/cache";
import { gNavFilePrefix } from "@data/designer/file";
import { recoilKeystorage } from "@data/key-storage";
import { LAN } from "@data/language/strings";
import { IWebLink, defaultStartPage, sessionAddInfosAtom } from "@data/session";
import { themeSelectorImpl } from "@data/theme/atoms";
import {
    TAdvNavStorageItem,
    TAdvNavStorageItemGroup,
    TAdvNavStorageItemItem,
} from "@feature/nav/types";
import { BaseButton, DirectionalHint, Persona, PersonaSize } from "@fluentui/react";
import { useBoolean, useId } from "@fluentui/react-hooks";
import { INavLink, INavLinkGroup } from "@fluentui/react/lib/Nav";
import { executeWebAction } from "@hooks/dynamic/useAdvWebAction";
import { EAdvWebActionType } from "@hooks/dynamic/useAdvWebAction.types";
import { useLanguages } from "@hooks/language/useLanguages";
import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import { useAdvEffect } from "@hooks/react-overload/useAdvEffect";
import useAdvRecoilValue from "@hooks/recoil-overload/useAdvRecoilValue";
import useAdvComponent from "@hooks/useAdvComponent";
import { ExternalLinkIcon, LanguageIcon, MailIcon, SettingsIcon } from "@themes/icons";
import { deepCompareJSXProps } from "@utils/deep-compare";
import deepCopy from "@utils/deep-copy";
import { advcatch } from "@utils/logging";
import { gAdvBreadcrumbIgnoreKey, gAdvDynPageName } from "@utils/page-parser";
import assert from "assert";
import { useRouter } from "next/router";
import React, { MouseEvent, useMemo, useRef, useState } from "react";
import { selector } from "recoil";

import AdvText from "@components/data/text/text";
import { AdvButtonPure } from "@components/inputs/button/button-pure";
import AdvCustomerDropdown, {
    AdvCustomerSearchComp,
    TCustomer,
    useCustomers,
} from "@components/inputs/dropdown/customer-dropdown/customer-dropdown";
import { AdvContextualMenu } from "@components/layout/contextual-menu/contextual-menu";
import AdvStack from "@components/layout/stack/stack";
import { EPageComponentSizeType } from "@components/page-component";
import AdvWebOrderButton from "@feature/web-order/web-order";
import useAdvTheme from "@hooks/useAdvTheme";
import getDefaultNavGroup, { getPersonaNavGroup } from "../defaultNavGroup";
import AdvNavHistory, { activePageName } from "../history";
import { TAdvNavLink } from "../link";
import { AdvNavEl } from "./nav-el";
import { AdvNavLinks } from "./nav-links";
import { AdvNavTitle } from "./nav-title";

export interface IAdvNavItemGroup extends Omit<INavLinkGroup, "links"> {
    links: TAdvNavLink[];
}

export type TAdvNavProps = {
    navigationItems?: TAdvNavLink[];
    hideNav?: boolean;
    children?: React.JSX.Element;
    elRef?: React.LegacyRef<HTMLDivElement>;
    pageLayout: EPageComponentSizeType;
};

const themeTopBarSelector = selector({
    key: "app_themeTopBarSelector",
    get: ({ get }) => {
        return get(themeSelectorImpl({ themeName: "topBar" })).theme;
    },
});

const themeNavSelector = selector({
    key: "app_themeNavSelector",
    get: ({ get }) => {
        return get(themeSelectorImpl({ themeName: "nav" })).theme;
    },
});

function linkNeedsCustomer(url: string) {
    return url.indexOf("$_KDNR_$") > -1;
}

/**
 * Navigation-Bar, bestehend aus der Navigation und allem was sich darunter / darüber befindet.
 */
const AdvNavComp = ({
    navigationItems,
    hideNav,
    elRef,
    pageLayout,
    children,
    ...props
}: TAdvNavProps) => {
    useAdvComponent(AdvNavComp, props);

    const session = useAdvRecoilValue(sessionAddInfosAtom);

    const navigation = useAdvRecoilValue(
        recoilKeystorage(false).NavValues(
            gNavFilePrefix + (session.Navbar != undefined ? session.Navbar : "default"),
        ),
    );

    const listOfActions = useAdvCallback(
        (main: TAdvNavStorageItem<TAdvNavStorageItemGroup>): string[] => {
            const actionNames = new Set<string>();

            const listOfActionsOfGroup = (group: TAdvNavStorageItem<TAdvNavStorageItemGroup>) => {
                group.typeOptions.items.forEach((i) => {
                    switch (i.type) {
                        case "group":
                            {
                                listOfActionsOfGroup(
                                    i as TAdvNavStorageItem<TAdvNavStorageItemGroup>,
                                );
                            }
                            break;
                        case "navaction": {
                            actionNames.add(
                                (i as TAdvNavStorageItem<TAdvNavStorageItemItem>).typeOptions
                                    .navAction,
                            );
                        }
                    }
                });
            };
            listOfActionsOfGroup(main);

            return Array.from(actionNames);
        },
        [],
    );
    const webactions = useAdvRecoilValue(
        recoilAction.actionArrayByNames(
            navigation.IsLoaded() ? listOfActions(navigation.Get().Value.main) : [],
        ),
    );

    const themeTopBar = useAdvRecoilValue(themeTopBarSelector);
    const themeNav = useAdvRecoilValue(themeNavSelector);

    const [navGroup, setNavGroup] = useState<IAdvNavItemGroup>({ links: [] });

    type TStorageItem = { isCollapsed: boolean };
    const onGroupClick = useAdvCallback(
        (
            setterFunc: React.Dispatch<React.SetStateAction<IAdvNavItemGroup>>,
            setCache: boolean,
            cacheStorageKey: string,
            itemParam: TAdvNavLink | undefined,
        ) => {
            if (itemParam != undefined) {
                const itemParamKey = itemParam.key;
                const isExpanded = !(itemParam.isExpanded ?? false);
                // purposely trigger a state change
                setterFunc((old) => {
                    const findItem = (curItem: TAdvNavLink) => {
                        if (curItem.key == itemParamKey) {
                            curItem.isExpanded = isExpanded;
                            return;
                        }

                        if (curItem.links != undefined) curItem.links.forEach((l) => findItem(l));
                    };
                    const newLinks = deepCopy(old.links, true);
                    newLinks.forEach((l) => findItem(l));
                    return {
                        links: newLinks,
                    };
                });
                if (setCache) {
                    cacheSet<TStorageItem>(cacheStorageKey, { isCollapsed: !isExpanded }, 0).catch(
                        advcatch,
                    );
                }
            }
        },
        [],
    );

    useAdvEffect(() => {
        const createNavGroups = async () => {
            const tempNavGroups: TAdvNavLink[] = [];

            const mapItem = async (item: TAdvNavStorageItem<any>): Promise<TAdvNavLink> => {
                const pageNavLinks: TAdvNavLink[] = [];
                let actionName = "";
                let itemName = item.name;
                const getItemKey = () => {
                    return (
                        "dynamic_nav_" + (item.type == "group" ? "group" : "item") + "_" + item.key
                    );
                };
                let iconName: string | undefined = item.iconName;
                let onClick: TAdvNavLink["onClick"] = undefined;
                let isExpanded = true;

                switch (item.type) {
                    case "group":
                        {
                            const navItem = item as TAdvNavStorageItem<TAdvNavStorageItemGroup>;
                            isExpanded = !navItem.typeOptions.isDefaultCollpased;
                            const storageKey = "advnavgroup_" + navItem.key;
                            const storageItem = await cacheGet<TStorageItem>(storageKey);
                            if (storageItem.item != null)
                                isExpanded = !storageItem.item.isCollapsed;

                            onClick = (_, itemParam) =>
                                onGroupClick(
                                    setNavGroup,
                                    true,
                                    storageKey,
                                    itemParam as TAdvNavLink | undefined,
                                );

                            const mappedItems = navItem.typeOptions.items
                                .filter((i) => {
                                    switch (i.type) {
                                        case "group":
                                            return true;
                                        case "navaction": {
                                            const navItem =
                                                i as TAdvNavStorageItem<TAdvNavStorageItemItem>;
                                            const navActionName = navItem.typeOptions.navAction;
                                            if (navActionName == "") return false;
                                            const navAction = webactions.find(
                                                (w) => w.Name === navActionName,
                                            );
                                            return navAction !== undefined;
                                        }
                                    }
                                })
                                .map((i) => {
                                    return mapItem(i);
                                });
                            const pushItems: TAdvNavLink[] = [];
                            for (const mappedItem of mappedItems) {
                                pushItems.push(await mappedItem);
                            }
                            pageNavLinks.push(
                                ...pushItems.filter((l) => {
                                    if ((l.key ?? "").startsWith("dynamic_nav_group_")) {
                                        if (l.links.length == 0) return false;
                                    }
                                    return true;
                                }),
                            );
                        }
                        break;
                    case "navaction":
                        {
                            const navItem = item as TAdvNavStorageItem<TAdvNavStorageItemItem>;
                            actionName = navItem.typeOptions.navAction;
                            const navAction = webactions.find(
                                (w) => w.Name === navItem.typeOptions.navAction,
                            );
                            if (navAction != undefined) {
                                itemName = navAction.ActionLabel;
                                iconName = navAction.IconName ?? DYNAMIC_PAGE_DEFAULT_ICON;
                                onClick = () => {
                                    executeWebAction(
                                        navItem.key,
                                        0,
                                        [],
                                        {
                                            actionName: navAction.Name,
                                            actionParams: {},
                                            actionType: EAdvWebActionType.WebActionTypeNormal,
                                        },
                                        navAction,
                                        [],
                                        [],
                                        [],
                                    );
                                };
                            }
                        }
                        break;
                }

                return {
                    links: pageNavLinks,
                    name: itemName,
                    icon: iconName,
                    url: "",
                    key: getItemKey(),
                    actionName: actionName,
                    isExpanded: isExpanded,
                    onClick: onClick,
                    forceAnchor: true,
                    renderAs: item.type == "group" ? "group" : "item",
                } as TAdvNavLink;
            };

            const group: TAdvNavLink | undefined = navigation.IsLoaded()
                ? await mapItem(navigation.Get().Value.main)
                : undefined;

            if (group != undefined) tempNavGroups.push(...group.links);

            if (navigationItems) tempNavGroups.push(...navigationItems);

            const storageKey = "advnavgroup_" + "__designer_group";
            const storageItem = await cacheGet<TStorageItem>(storageKey);
            tempNavGroups.push(
                ...getDefaultNavGroup(!(storageItem.item?.isCollapsed ?? false), (_, itemParam) =>
                    onGroupClick(
                        setNavGroup,
                        true,
                        storageKey,
                        itemParam as TAdvNavLink | undefined,
                    ),
                ),
            );

            return {
                links: tempNavGroups,
            } as IAdvNavItemGroup;
        };
        createNavGroups()
            .then((val) => {
                setNavGroup(val);
            })
            .catch(advcatch);
    }, [navigation, navigationItems, onGroupClick, webactions]);

    const { languages, currentLanguage, setCurrentLanguageById } = useLanguages();

    const handleOnLanguageClick = useAdvCallback(
        (ev: React.MouseEvent<HTMLElement>, navItem: INavLink) => {
            if (navItem.key === undefined) return;
            const key = navItem.key;
            const id = typeof key == "number" ? key : Number(key.replace("navlang_", ""));
            setCurrentLanguageById(id);
        },
        [setCurrentLanguageById],
    );

    const { customers, currentCustomers } = useCustomers();
    const [isSearchOpen, setSearchOpen] = useState<boolean>(false);
    const [selectedLink, setSelectedLink] = useState<IWebLink | undefined>(undefined);

    const openLink = useAdvCallback(
        (customer?: TCustomer, link?: IWebLink) => {
            if (link == undefined) link = selectedLink;

            if (link != undefined) {
                if (linkNeedsCustomer(link.URL) && customer == undefined) {
                    return;
                }

                let url = link.URL;
                if (customer != undefined) {
                    url = url.replace("$_KDNR_$", customer.Nr);
                }

                if (session.UserInfos != undefined) {
                    url = url.replace(
                        "$_LANGUAGE_$",
                        encodeURIComponent(session.UserInfos.WebLanguage),
                    );
                    url = url.replace(
                        "$_SALUTATION_$",
                        encodeURIComponent(session.UserInfos.Anrede),
                    );
                    url = url.replace("$_COUNTRY_$", encodeURIComponent(session.UserInfos.Land));
                    url = url.replace("$_EMAIL_$", encodeURIComponent(session.UserInfos.eMail));
                    url = url.replace("$_FULLNAME_$", encodeURIComponent(session.UserInfos.Name));
                }

                window.open(url, "_blank")?.focus();
            }
        },
        [selectedLink, session.UserInfos],
    );
    const customersForSearch = useMemo(() => {
        return customers.filter(
            (customer) => selectedLink?.FirmaID == -1 || selectedLink?.FirmaID == customer.FirmaID,
        );
    }, [customers, selectedLink?.FirmaID]);
    const handleSearchDismissed = useAdvCallback(
        (selectedCustomers: TCustomer[]) => {
            // Wenn sich die Suche schließt
            setSearchOpen(false);
            openLink(selectedCustomers[0]);
        },
        [openLink],
    );
    const customerSearch = useMemo(() => {
        return (
            <AdvCustomerSearchComp
                customers={customersForSearch}
                isOpen={isSearchOpen}
                onDismiss={handleSearchDismissed}
                selectionmode="single"
                text="Bitte wählen Sie den Kunden, für welchen Sie den Link öffnen möchten"
                closeOnSelect={true}
            />
        );
    }, [customersForSearch, handleSearchDismissed, isSearchOpen]);

    const handleOnWeblinkClick = useAdvCallback(
        (link: IWebLink) => {
            if (linkNeedsCustomer(link.URL)) {
                if (link.KundenID != -1) {
                    openLink(customers.filter((item) => item.ID == link.KundenID)[0], link);
                } else if (currentCustomers.length == 1) {
                    openLink(currentCustomers[0], link);
                } else {
                    setSelectedLink(link);
                    setSearchOpen(true);
                }
            } else {
                openLink(undefined, link);
            }
        },
        [currentCustomers, customers, openLink],
    );

    const customerDropdown = useMemo(() => {
        return <AdvCustomerDropdown />;
    }, []);

    const webAuftragButton = useMemo(() => {
        return <AdvWebOrderButton styles={{ root: { maxWidth: "75px" } }} />;
    }, []);

    const theme = useAdvTheme();
    const mainFontSize = 28;
    const mainPadding = 8;

    const breadcrumbPageName = useAdvRecoilValue(activePageName);
    const mailButtonClick = useAdvCallback(() => {
        window.location.href =
            "mailto:" +
            session.ServiceMail +
            "?subject=" +
            LAN.MAIL_SUPPORT.text +
            ": " +
            breadcrumbPageName;
    }, [breadcrumbPageName, session.ServiceMail]);

    const mailButton = useMemo(() => {
        if (session.ServiceMail == "") return false;

        return (
            <AdvButtonPure
                iconName={MailIcon.iconName}
                iconProps={{
                    color: theme.palette.themePrimary,
                }}
                styles={{
                    icon: {
                        color: theme.palette.themePrimary,
                        fontSize: mainFontSize,
                    },
                    root: {
                        borderWidth: 0,
                        paddingLeft: mainPadding,
                        paddingRight: mainPadding,
                        minWidth: 0,
                    },
                }}
                onClick={mailButtonClick}
            />
        );
    }, [mailButtonClick, session.ServiceMail, theme.palette.themePrimary]);

    const [personaNavGroups, setPersonaNavGroups] = useState<IAdvNavItemGroup>({ links: [] });
    useAdvEffect(() => {
        setPersonaNavGroups(() => {
            const languagesNavItem: TAdvNavLink = {
                name: LAN.SWITCH_LANGUAGE.text,
                translationContext: LAN.SWITCH_LANGUAGE.context,
                forceAnchor: true,
                url: "",
                key: "languages",
                icon: LanguageIcon.iconName,
                onClick: (_, itemParam) =>
                    onGroupClick(
                        setPersonaNavGroups,
                        false,
                        "",
                        itemParam as TAdvNavLink | undefined,
                    ),
                isExpanded: false,
                // icon: LanguagesIcon.iconName,
                links: languages.map((lang) => {
                    return {
                        name: lang.Language,
                        url: "",
                        key: `navlang_${lang.ID}`,
                        onClick: handleOnLanguageClick,
                        disabled: currentLanguage.ID == lang.ID,
                    } as TAdvNavLink;
                }),
                renderAs: "group",
            };

            let weblinksNavItem: TAdvNavLink | false;

            if (session.WebLinks == undefined || session.WebLinks.length == 0)
                weblinksNavItem = false;
            else {
                weblinksNavItem = {
                    name: LAN.WEBLINK_LANGUAGE.text,
                    translationContext: LAN.WEBLINK_LANGUAGE.context,
                    forceAnchor: true,
                    url: "",
                    key: "weblinks",
                    icon: ExternalLinkIcon.iconName,
                    onClick: (_, itemParam) =>
                        onGroupClick(
                            setPersonaNavGroups,
                            false,
                            "",
                            itemParam as TAdvNavLink | undefined,
                        ),
                    isExpanded: false,
                    // icon: LanguagesIcon.iconName,
                    links: session.WebLinks.map((link) => {
                        return {
                            name: link.Bez,
                            url: "",
                            key: `navlink_${link.URL}`,
                            onClick: () => handleOnWeblinkClick(link),
                            disabled: false,
                        } as TAdvNavLink;
                    }),
                    renderAs: "group",
                };
            }

            const defaultNavGroup = getPersonaNavGroup();
            assert(defaultNavGroup.length >= 1);
            defaultNavGroup.push(languagesNavItem);

            if (weblinksNavItem != false) defaultNavGroup.push(weblinksNavItem);

            return { links: defaultNavGroup } as IAdvNavItemGroup;
        });
    }, [
        currentLanguage.ID,
        handleOnLanguageClick,
        handleOnWeblinkClick,
        languages,
        onGroupClick,
        session.WebLinks,
    ]);

    const buttonRef = useRef<any>(null);
    const [isPersonContextMenuHidden, setIsPersonContextMenuHidden] = useState(true);
    const handleContextClick = useAdvCallback(
        (
            e: MouseEvent<
                | HTMLAnchorElement
                | HTMLButtonElement
                | HTMLDivElement
                | BaseButton
                | HTMLSpanElement
            >,
        ) => {
            setIsPersonContextMenuHidden(false);
            buttonRef.current = e.target;
            e.preventDefault();
        },
        [],
    );

    const [isPersonConfigHidden, setIsPersonConfigHidden] = useState(true);
    const handleContextItemMenuChangePermClick = useAdvCallback(() => {
        setIsPersonConfigHidden(false);
    }, []);

    const [isOpen, { toggle: toggleIsOpen, setFalse: closeIsOpen }] = useBoolean(false);
    const id = useId("callout");

    const persona = useMemo(
        () => (
            <>
                <Persona
                    text={String(session.Vorname) + " " + String(session.Nachname)}
                    size={PersonaSize.size32}
                    onContextMenu={
                        session.CanEditPermissions === true ? handleContextClick : undefined
                    }
                    styles={{
                        root: {
                            flexDirection: "row-reverse",
                            cursor: "pointer",
                            userSelect: "none",
                        },
                    }}
                    id={id}
                    onClick={toggleIsOpen}
                    unselectable={"on"}
                    onRenderPrimaryText={(props, defaultRender) => {
                        if (
                            defaultRender != undefined &&
                            pageLayout > EPageComponentSizeType.Mobile
                        )
                            return defaultRender(props);
                        return <></>;
                    }}
                />
                {isOpen && (
                    <AdvCallout
                        target={`#${id}`}
                        styles={{ root: { padding: 10 } }}
                        onDismiss={closeIsOpen}
                        directionalHint={DirectionalHint.bottomRightEdge}
                    >
                        <AdvStack horizontalAlign="center">
                            {pageLayout <= EPageComponentSizeType.Mobile && (
                                <>
                                    <AdvText>
                                        {String(session.Vorname) + " " + String(session.Nachname)}
                                    </AdvText>
                                    {webAuftragButton}
                                    {mailButton}
                                    {customerDropdown}
                                </>
                            )}
                            <AdvNavLinks
                                navGroup={personaNavGroups}
                                ignoreSelectedKey
                                renderLinkOverwrite={(props, defaultRender) => {
                                    if (
                                        props != undefined &&
                                        (props.key ?? "").startsWith("navlang_")
                                    ) {
                                        const foundLang = languages.find(
                                            (l) => l.Language == props.name,
                                        );
                                        return renderLanguageItem({
                                            key: props.key ?? "",
                                            text: props.name,
                                            data:
                                                foundLang?.ISO.substring(
                                                    (foundLang?.ISO.indexOf("_") ?? 0) + 1,
                                                ) ?? "",
                                        });
                                    }

                                    return defaultRender != undefined ? (
                                        defaultRender(props) ?? <></>
                                    ) : (
                                        <></>
                                    );
                                }}
                            ></AdvNavLinks>
                        </AdvStack>
                    </AdvCallout>
                )}
                <AdvContextualMenu
                    items={[
                        {
                            key: "actPermission",
                            text: LAN.CONFIG_SESSION_PERM.text,
                            iconProps: {
                                iconName: SettingsIcon.iconName,
                            },
                            onClick: handleContextItemMenuChangePermClick,
                        },
                    ]}
                    hidden={isPersonContextMenuHidden}
                    target={buttonRef.current}
                    onItemClick={() => setIsPersonContextMenuHidden(true)}
                    onDismiss={() => setIsPersonContextMenuHidden(true)}
                />
                <SessionPermissionConfig
                    isOpen={!isPersonConfigHidden}
                    onDismiss={() => setIsPersonConfigHidden(true)}
                ></SessionPermissionConfig>
            </>
        ),
        [
            session.Vorname,
            session.Nachname,
            session.CanEditPermissions,
            handleContextClick,
            id,
            toggleIsOpen,
            isOpen,
            closeIsOpen,
            pageLayout,
            webAuftragButton,
            mailButton,
            customerDropdown,
            personaNavGroups,
            handleContextItemMenuChangePermClick,
            isPersonContextMenuHidden,
            isPersonConfigHidden,
            languages,
        ],
    );

    const navigationItem = useMemo(() => <AdvNavHistory pageLayout={pageLayout} />, [pageLayout]);

    const router = useRouter();
    const titleText = useMemo(
        () => (
            <AdvNavTitle
                onClick={() => {
                    router
                        .push(
                            "/dynamic/?" +
                                gAdvDynPageName +
                                "=" +
                                (session.Startpage != undefined
                                    ? session.Startpage
                                    : defaultStartPage) +
                                "&" +
                                gAdvBreadcrumbIgnoreKey +
                                "=1",
                        )
                        .catch(advcatch);
                }}
            ></AdvNavTitle>
        ),
        [router, session.Startpage],
    );

    return (
        <AdvNavEl
            navGroup={navGroup}
            persona={persona}
            navHistory={navigationItem}
            titleText={titleText}
            customerDropdown={customerDropdown}
            customerLinkSearch={customerSearch}
            webAuftragButton={webAuftragButton}
            mailButton={mailButton}
            hideNav={hideNav}
            themeNav={themeNav}
            themeTopBar={themeTopBar}
            elRef={elRef}
            pageLayout={pageLayout}
        >
            {children}
        </AdvNavEl>
    );
};

const AdvNav = React.memo(AdvNavComp, deepCompareJSXProps);
export default AdvNav;
