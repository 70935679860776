import recoilSettings from "@data/settings";
import { useT } from "@hooks/language/useTranslation";
import useAdvRecoilValue from "@hooks/recoil-overload/useAdvRecoilValue";
import { deepCompareJSXProps } from "@utils/deep-compare";
import { NextPage } from "next";
import Head from "next/head";
import React, { ReactNode } from "react";

export type TAdvPageProps = {
    title: string;
    // a context specializing the translation used
    translationContext?: string;
    children?: ReactNode | ReactNode[];
    secondaryTitle?: string;
};
/**
 * @summary Wrapper für Next/Head.
 * @description Fügt einen NextJS-Head hinzu, wodurch der Titel und das Icon im Browser gesetzt werden.
 * @link https://nextjs.org/docs/api-reference/next/head
 */
const AdvPageComp: NextPage<TAdvPageProps> = ({
    title,
    translationContext,
    children,
    secondaryTitle,
}: TAdvPageProps) => {
    const appTitle = useAdvRecoilValue(recoilSettings.Global.appTitle);
    const appIcon = useAdvRecoilValue(recoilSettings.Global.appIcon);

    const { t } = useT(title, translationContext);

    return (
        <>
            <Head>
                <title>
                    {(appTitle.IsLoaded() && appTitle.Get().Value != ""
                        ? appTitle.Get().Value
                        : "AdvanTex Online") +
                        " - " +
                        ((t ?? "") +
                            (secondaryTitle != undefined && secondaryTitle != ""
                                ? " [" + secondaryTitle + "]"
                                : ""))}
                </title>
                <meta
                    name="description"
                    content={
                        appTitle.IsLoaded() && appTitle.Get().Value != ""
                            ? appTitle.Get().Value
                            : "AdvanTex Online"
                    }
                />
                {!appIcon.IsLoaded() ? (
                    <link rel="icon" href="images/favicon.ico" />
                ) : (
                    <link
                        rel="icon"
                        type={appIcon.Get().Value.type}
                        href={"data:image/png;base64," + appIcon.Get().Value.base64Str}
                    />
                )}
            </Head>

            {children}
        </>
    );
};

const AdvPage = React.memo(AdvPageComp, deepCompareJSXProps);
export default AdvPage;
