import AdvGridItemDesignable from "@components/layout/grid/grid-item/designable";
import AdvStackItemDesignable from "@components/layout/stack/stack-item/designable";
import {
    AdvCommonComponentAttributes,
    AdvThemeProviderProperties,
} from "@components/other/common-properties";
import { LAN } from "@data/language/strings";
import { DefaultComponentCategory } from "@feature/Designer/types/category";
import { EComponentTypeData } from "@feature/Designer/types/component-type";
import { AdvProperty, registerDesignableComponent } from "@feature/Designer/utils";
import { SelectionMode } from "@fluentui/react";
import { toAdvText } from "@hooks/language/useTranslation";
import { TableIcon } from "@themes/icons";

enum EPropertyName {
    Name = "name",
    NameTitle = "nameTitle",
    NameSubTitle = "nameSubtitle",
    ProviderKey = "providerKey",
    CanFilter = "canFilter",
    CanSort = "canSort",
    Columns = "customColumnDefinitions",
    CanExcelExport = "canExcelExport",
    TableHeader = "tableHeaderActions",
    TableFilters = "tableHeaderFilters",
    DefaultAction = "defaultActionParams",
    DataPositioning = "dataPositioning",
}

enum EPropertyCategory {
    Allgemeines = "Allgemeines",
}

enum EPresetName {}

export const endlessTableProps = [
    AdvProperty.Text.create(
        toAdvText(LAN.GENERAL_NAME),
        EPropertyName.Name,
        toAdvText(LAN.GENERAL),
        toAdvText(LAN.ENDLESS_TABLE_NAME_DESCR),
        "",
    ),
    AdvProperty.Text.create(
        toAdvText(LAN.NAME_TITLE),
        EPropertyName.NameTitle,
        toAdvText(LAN.GENERAL),
        toAdvText(LAN.ENDLESS_TABLE_NAME_TITLE_DESCR),
        "",
    ),
    AdvProperty.Text.create(
        toAdvText(LAN.NAME_SUBTITLE),
        EPropertyName.NameSubTitle,
        toAdvText(LAN.GENERAL),
        toAdvText(LAN.ENDLESS_TABLE_NAME_SUBTITLE_DESCR),
        "",
    ),
    AdvProperty.Text.createRecoilSelectProvider(
        toAdvText(LAN.PROVIDER),
        EPropertyName.ProviderKey,
        toAdvText(LAN.GENERAL),
        toAdvText(LAN.ENDLESS_TABLE_PROVIDER_KEY_DESCR),
    ),
    AdvProperty.Boolean.create(
        toAdvText(LAN.DO_FILTER),
        EPropertyName.CanFilter,
        toAdvText(LAN.GENERAL),
        toAdvText(LAN.ENDLESS_TABLE_CAN_FILTER_DESCR),
        true,
    ),
    AdvProperty.Boolean.create(
        toAdvText(LAN.SORT),
        EPropertyName.CanSort,
        toAdvText(LAN.GENERAL),
        toAdvText(LAN.ENDLESS_TABLE_CAN_SORT_DESCR),
        true,
    ),
    AdvProperty.Boolean.create(
        toAdvText(LAN.EXCEL_EXPORT),
        EPropertyName.CanExcelExport,
        toAdvText(LAN.GENERAL),
        toAdvText(LAN.ENDLESS_TABLE_EXCEL_EXPORT_DESCR),
        true,
        false,
    ),
    AdvProperty.Object.createColumns(
        toAdvText(LAN.COLUMNS),
        EPropertyName.Columns,
        toAdvText(LAN.GENERAL),
        toAdvText(LAN.ENDLESS_TABLE_CUSTOM_COLUMN_DEFINITION_DESCR),
        [],
    ),
    AdvProperty.Object.createTableHeader(
        toAdvText(LAN.TABLE_HEADER),
        EPropertyName.TableHeader,
        toAdvText(LAN.GENERAL),
        toAdvText(LAN.ENDLESS_TABLE_HEADER_DEFINITION_DESCR),
        [],
    ),
    AdvProperty.Object.createTableFilters(
        toAdvText(LAN.TABLE_FILTERS),
        EPropertyName.TableFilters,
        toAdvText(LAN.GENERAL),
        toAdvText(LAN.ENDLESS_TABLE_FILTERS_DEFINITION_DESCR),
        [],
    ),
    AdvProperty.Action.create(
        toAdvText(LAN.TABLE_DEFAULT_ACTION),
        EPropertyName.DefaultAction,
        toAdvText(LAN.GENERAL),
        toAdvText(LAN.ENDLESS_TABLE_DEFAULT_ACTION_DESCR),
    ),
    AdvProperty.Number.createSelect(
        toAdvText(LAN.SELECTION_MODE),
        "selectionMode",
        toAdvText(LAN.SPECIAL),
        toAdvText(LAN.ENDLESS_TABLE_SELECTION_MODE_DESCR),
        0,
        false,
        { text: "single", value: SelectionMode.single },
        { text: "multi", value: SelectionMode.multiple },
        { text: "none", value: SelectionMode.none },
    ),
    AdvProperty.Object.createAutoGrouping(
        toAdvText(LAN.AUTO_GROUPING),
        "autoGrouping",
        toAdvText(LAN.SPECIAL),
        toAdvText(LAN.ENDLESS_TABLE_AUTO_GROUPING_DESCR),
    ),
    AdvProperty.Object.createDataPositioning(
        toAdvText(LAN.DATA_POSITIONING),
        EPropertyName.DataPositioning,
        toAdvText(LAN.SPECIAL),
        toAdvText(LAN.ENDLESS_TABLE_DATA_POSITIONING_DESCR),
    ),
    ...AdvCommonComponentAttributes,
    ...AdvThemeProviderProperties,
    ...AdvStackItemDesignable.CommonProperties,
    ...AdvGridItemDesignable.CommonProperties,
];

const registeredComponent = registerDesignableComponent({
    staticData: {
        name: LAN.ENDLESS_TABLE.text,
        translationContext: LAN.ENDLESS_TABLE.context,
        type: EComponentTypeData.EndlessTable,
        supportsChildren: true,
        hideDropAreas: true,
        category: DefaultComponentCategory.Misc,
        icon: TableIcon,
    },
    properties: endlessTableProps,
    propertiesBuilders: [],
    presets: [
        {
            name: LAN.DEFAULT_PRESET.text,
            translationContext: LAN.DEFAULT_PRESET.context,
            default: true,
        },
    ],
});

const AdvEndlessTableDesignable = {
    PropertyName: EPropertyName,
    PropertyCategory: EPropertyCategory,
    PresetName: EPresetName,
    Registered: registeredComponent,
};

export default AdvEndlessTableDesignable;
