import AdvLegacyTable from "@components/data/table";
import { legacyEndlessTableProps } from "@components/data/table/endless/designable";
import AdvText from "@components/data/text/text";
import AdvTextInput, { TAdvTextInputProps } from "@components/inputs/text-input/text-input";
import AdvGrid from "@components/layout/grid/grid";
import AdvGridItem from "@components/layout/grid/grid-item/grid-item";
import AdvStack from "@components/layout/stack/stack";
import AdvStackItem from "@components/layout/stack/stack-item/stack-item";
import { TAdvCommonProperties } from "@components/other/common-properties";
import { AdvTooltip } from "@components/other/info-label";
import { LAN } from "@data/language/strings";
import { DefaultComponentCategory } from "@feature/Designer/types/category";
import { TAdvDesignerComponentProps } from "@feature/Designer/types/component-props";
import { EComponentTypeCustom } from "@feature/Designer/types/component-type";
import { AdvProperty, registerDesignableComponent } from "@feature/Designer/utils";
import {
    FocusZoneTabbableElements,
    IDetailsColumnFieldProps,
    IDetailsRowProps,
    KeyCodes,
} from "@fluentui/react";
import {
    TAdvValueBindingParams,
    useAdvValueBinder,
    useAdvValueBinderAsArrayNoDataType,
    useAdvValueBinderNoDataType,
} from "@hooks/dynamic/useAdvValueBinder";
import { toAdvText } from "@hooks/language/useTranslation";
import { useAdvEffect } from "@hooks/react-overload/useAdvEffect";
import useAdvComponent from "@hooks/useAdvComponent";
import { useAdvObjMemo } from "@hooks/useAdvObjMemo";
import { OrderArticlesIcon, WarningIcon, WarningRoundIcon } from "@themes/icons";
import { EAdvValueDataTypes } from "@utils/data-types";
import { deepCompareJSXProps } from "@utils/deep-compare";
import { str_times } from "@utils/string";
import React, { useState } from "react";
import { TCommonValueProps } from "../../../components/inputs";
import { ORDER_PAGE_BINDINGS, TOrderArtikel, TOrderArtikelBase } from "../const";

require("datejs");

export type TAdvOrderEndlessArticlesStyles = {}; /* do not change */

type TAdvOrderMengeInputProps = TAdvTextInputProps & { artikel: TOrderArtikel };
const AdvOrderMengeInput = ({
    onFocus,
    onBlur,
    onKeyDown,
    artikel,
    ...props
}: TAdvOrderMengeInputProps) => {
    const [hasFocus, setFocus] = useState(false);
    return (
        <AdvStack
            horizontal
            verticalFill
            verticalAlign="center"
            tokens={{ childrenGap: "8px", padding: "0px 8px 0px 12px" }}
        >
            <AdvStackItem>
                <AdvTextInput
                    {...props}
                    styles={{
                        root: { alignSelf: "center", width: "75px" },
                    }}
                    value={hasFocus && artikel.Menge == 0 ? "" : String(artikel.Menge)}
                    // defaultValue={hasFocus && artikel.Menge == 0 ? "" : String(artikel.Menge)}
                    type="number"
                    min={0}
                    step={artikel.PackMenge}
                    max={artikel.MaxBestellMenge > 0 ? artikel.MaxBestellMenge : undefined}
                    onKeyDown={(
                        event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
                    ) => {
                        if (onKeyDown !== undefined) onKeyDown(event);
                        // Nicht schön aber funktioniert; Enter drücken = Nächstes Element (wie bei Tab)
                        if (event.key.toLowerCase() == "enter") {
                            event.target.dispatchEvent(
                                new KeyboardEvent("keydown", {
                                    key: "tab",
                                    keyCode: KeyCodes.tab,
                                    which: KeyCodes.tab,
                                    altKey: false,
                                    shiftKey: false,
                                    ctrlKey: false,
                                    bubbles: true,
                                    cancelable: true,
                                }),
                            );
                        }
                    }}
                    onFocus={(event) => {
                        if (onFocus !== undefined) onFocus(event);
                        setFocus(true);
                        if (artikel.Menge > 0) event.target.select(); // Bereits vorhanden Menge selektieren
                    }}
                    onBlur={(event) => {
                        if (onBlur !== undefined) onBlur(event);
                        setFocus(false);
                    }}
                />
            </AdvStackItem>
            {artikel.Menge > artikel.DurchschnittTag * 2 && artikel.DurchschnittTag > 0 ? (
                <AdvStackItem>
                    <AdvTooltip
                        info={{ text: "Durchschnittliche Menge überschritten" }}
                        icon={WarningIcon}
                    ></AdvTooltip>
                </AdvStackItem>
            ) : null}
            {artikel.MaxBestellMenge < 0 && artikel.Menge > Math.abs(artikel.MaxBestellMenge) ? (
                <AdvStackItem>
                    <AdvTooltip
                        info={{
                            text: `Maximale Bestellmenge überschritten (Max: ${Math.abs(
                                artikel.MaxBestellMenge,
                            )})`,
                        }}
                        icon={WarningRoundIcon}
                    />
                </AdvStackItem>
            ) : null}
        </AdvStack>
    );
};

export type TAdvOrderEndlessArticlesProps = TAdvDesignerComponentProps &
    TAdvCommonProperties &
    TCommonValueProps<string> & {
        listWriter?: string;
        listWriterBindingParams?: TAdvValueBindingParams;
        providerData?: string;
        providerDataBindingParams?: TAdvValueBindingParams;
        controlBindingParams?: TAdvValueBindingParams;
    };
const AdvOrderEndlessArticlesComp = ({
    advhide,
    advhideBindingParams,
    providerDataBindingParams,
    controlBindingParams,
    designerProps,
    designerData,
    ...props
}: TAdvOrderEndlessArticlesProps) => {
    useAdvComponent(AdvOrderEndlessArticlesComp, props);

    const [shouldHide] = useAdvValueBinderNoDataType(
        advhideBindingParams,
        advhide,
        EAdvValueDataTypes.Any,
        0,
    );

    const [isControl] = useAdvValueBinderNoDataType(
        controlBindingParams,
        false,
        EAdvValueDataTypes.Boolean,
        0,
    );

    // JSON-serialisiert: TOrderArtikel[]
    const [, setOrderedArticles] = useAdvValueBinder(
        ORDER_PAGE_BINDINGS.ArticleList,
        "[]",
        EAdvValueDataTypes.String,
        0,
    );

    // ProviderData = string[], jedes Array-Element: TOrderArtikel (JSON-Serialisiert)
    const [providerData] = useAdvValueBinderAsArrayNoDataType(
        providerDataBindingParams,
        [],
        EAdvValueDataTypes.Any,
    );

    const memoData = useAdvObjMemo(() => providerData, [providerData]); // ProviderData ändert sich immer (andere Referenz)

    const [localData, setLocalData] = useState([] as TOrderArtikel[]);
    useAdvEffect(() => {
        if (memoData === undefined) setLocalData([]);
        else {
            setLocalData(
                memoData.map((jsonData) => {
                    const obj = JSON.parse(jsonData) as TOrderArtikel;
                    return {
                        ...obj,
                        key: `${obj.AnfPoID == -999 ? obj.KdArtiID : obj.AnfPoID}_${obj.ArtGroeID}`,
                    };
                }),
            );
        }
    }, [memoData]);

    // LocalData geändert -> in PageVariable schreiben
    useAdvEffect(() => {
        if (designerData !== undefined) return; // Im Designer nichts an den Server schicken

        // Nicht alles (Bez etc.) verschicken, nur das was auch wichtig ist (Menge, IDs)!
        const baseData: TOrderArtikelBase[] = localData.map((d) => {
            return {
                AnfPoID: d.AnfPoID,
                ArtGroeID: d.ArtGroeID,
                KdArtiID: d.KdArtiID,
                Menge: d.Menge,
                VsaAnfID: d.VsaAnfID,
            };
        });
        setOrderedArticles(JSON.stringify(baseData));
    }, [designerData, localData, setOrderedArticles]);

    if (shouldHide === true && designerProps === undefined) return <></>;
    return isControl == false ? (
        <AdvLegacyTable
            {...props}
            items={localData}
            initialFocusedIndex={0}
            focusZoneProps={{
                handleTabKey: FocusZoneTabbableElements.all,
                isCircularNavigation: true,
            }}
            onRenderRow={(
                props?: IDetailsRowProps | undefined,
                defaultRender?:
                    | ((props?: IDetailsRowProps | undefined) => React.JSX.Element | null)
                    | undefined,
            ) => {
                if (props === undefined || defaultRender === undefined) return null;
                return defaultRender({
                    ...props,
                    focusZoneProps: {
                        defaultTabbableElement: "input",
                        shouldFocusInnerElementWhenReceivedFocus: true,
                    },
                });
            }}
            columns={[
                {
                    key: "col1",
                    minWidth: 100,
                    maxWidth: 200,
                    name: LAN.ARTIKELNR_SHORT.text,
                    fieldName: "ArtikelNr",
                },
                {
                    key: "col2",
                    minWidth: 250,
                    maxWidth: 600,
                    name: LAN.BEZ.text,
                    fieldName: "ArtikelBez",
                },
                {
                    key: "col3",
                    minWidth: 100,
                    maxWidth: 150,
                    name: LAN.ARTIKELGROESSE.text,
                    fieldName: "Groesse",
                },
                // {
                //     key: "col4",
                //     minWidth: 100,
                //     maxWidth: 150,
                //     name: LAN.AVG_COUNT.text,
                //     fieldName: "Durchschnitt",
                // },
                {
                    key: "col5",
                    minWidth: 100,
                    maxWidth: 150,
                    name: LAN.AVG_COUNT_DAY.text,
                    fieldName: "DurchschnittTag",
                },
                {
                    key: "col6",
                    minWidth: 50,
                    maxWidth: 100,
                    name: LAN.VPE_SHORT.text,
                    fieldName: "VPE",
                },
                {
                    key: "col7",
                    minWidth: 50,
                    maxWidth: 75,
                    name: LAN.FESTEMENGE.text,
                    fieldName: "FesteMenge",
                },
                {
                    key: "col8",
                    minWidth: 150,
                    maxWidth: 250,
                    name: LAN.BESTMENGE.text,
                    onRenderField: (
                        props?: IDetailsColumnFieldProps,
                        defaultRender?: (
                            props?: IDetailsColumnFieldProps,
                        ) => React.JSX.Element | null,
                    ) => {
                        if (props?.item === undefined || defaultRender === undefined) return null;
                        const article = props.item as TOrderArtikel;
                        return (
                            <AdvOrderMengeInput
                                key={`ordermenge_${article.AnfPoID}`}
                                artikel={article}
                                onValueChanged={(newValue) => {
                                    const newValueNumber = Number(newValue);
                                    if (newValue !== undefined && !isNaN(newValueNumber)) {
                                        setLocalData((oldValue) => {
                                            return oldValue.map((oldItem, index) => {
                                                if (index == props.itemIndex) {
                                                    return { ...oldItem, Menge: newValueNumber };
                                                } else return oldItem;
                                            });
                                        });
                                    }
                                }}
                                onBlur={() => {
                                    // Wenn wir den Fokus verlieren: Eingabe validieren
                                    let newValueNumber = article.Menge;
                                    // Menge muss immer positiv sein
                                    if (newValueNumber < 0) newValueNumber = 0;
                                    // Menge muss vielfaches der Packmenge sein
                                    if (!Number.isInteger(newValueNumber / article.PackMenge)) {
                                        const tmp = Math.max(
                                            1,
                                            Math.ceil(newValueNumber / article.PackMenge),
                                        );
                                        newValueNumber = tmp * article.PackMenge;
                                    }
                                    // Menge darf die maximale Bestellmenge nicht überschreiten
                                    if (
                                        article.MaxBestellMenge > 0 &&
                                        newValueNumber > article.MaxBestellMenge
                                    ) {
                                        newValueNumber = article.MaxBestellMenge;
                                    }
                                    // Nur state ändern wenn nötig
                                    if (newValueNumber != article.Menge) {
                                        setLocalData((oldValue) => {
                                            return oldValue.map((oldItem, index) => {
                                                if (index == props.itemIndex) {
                                                    return { ...oldItem, Menge: newValueNumber };
                                                } else return oldItem;
                                            });
                                        });
                                    }
                                }}
                            />
                        );
                    },
                },
            ]}
        ></AdvLegacyTable>
    ) : (
        <AdvGrid
            columns="auto auto auto auto 1fr"
            rows={str_times("auto", localData.length, " ") + " 1fr"}
            colGap="16px"
        >
            {localData.map((data, index) => {
                return (
                    <>
                        <AdvGridItem
                            key={`localData_${index}_${data.KdArtiID}_${data.ArtGroeID}_0`}
                            column={1 + 0}
                            row={1 + index}
                        >
                            <AdvText variant="mediumPlus" label={`${data.ArtikelNr}`} />
                        </AdvGridItem>
                        <AdvGridItem
                            key={`localData_${index}_${data.KdArtiID}_${data.ArtGroeID}_1`}
                            column={1 + 1}
                            row={1 + index}
                        >
                            <AdvText variant="mediumPlus" label={`${data.ArtikelBez}`} />
                        </AdvGridItem>
                        <AdvGridItem
                            key={`localData_${index}_${data.KdArtiID}_${data.ArtGroeID}_2`}
                            column={1 + 2}
                            row={1 + index}
                        >
                            <AdvText variant="mediumPlus" label={`${data.Groesse}`} />
                        </AdvGridItem>
                        <AdvGridItem
                            key={`localData_${index}_${data.KdArtiID}_${data.ArtGroeID}_3`}
                            column={1 + 3}
                            row={1 + index}
                        >
                            <AdvText variant="mediumPlus" label={`Menge: ${data.Menge}`} />
                        </AdvGridItem>
                    </>
                );
            })}
        </AdvGrid>
    );
};

const AdvOrderEndlessArticles = React.memo(AdvOrderEndlessArticlesComp, deepCompareJSXProps);
export default AdvOrderEndlessArticles;

const orderArticlesProps = [
    AdvProperty.Text.create(
        toAdvText(LAN.PROVIDER_ORDERS),
        "providerData",
        toAdvText(LAN.GENERAL),
        toAdvText(LAN.ORDERS_ENDLESS_TABLE_PROVIDER_DATA_DESCR),
        "",
    ),
    AdvProperty.Boolean.create(
        toAdvText(LAN.CONTROL_ORDERS),
        "control",
        toAdvText(LAN.GENERAL),
        toAdvText(LAN.ORDERS_ENDLESS_TABLE_CONTROL_DESCR),
        false,
    ),
    ...legacyEndlessTableProps,
];

registerDesignableComponent({
    staticData: {
        name: LAN.ENDLESS_TABLE_ARTICLES.text,
        translationContext: LAN.ENDLESS_TABLE_ARTICLES.context,
        type: EComponentTypeCustom.OrderEndlessArticles,
        supportsChildren: false,
        category: DefaultComponentCategory.Misc,
        icon: OrderArticlesIcon,
    },
    properties: orderArticlesProps,
    propertiesBuilders: [],
    presets: [],
});
