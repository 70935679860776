import { EFieldSettingsFieldTypes } from "@components/dynamic/data-provider/types";
import { TAdvCommonProperties } from "@components/other/common-properties";
import { AdvRenderLabelTextInput } from "@components/other/info-label";
import { recoilPageState } from "@data/dynamic-page";
import { LAN } from "@data/language/strings";
import { buildPageIDForVariableID } from "@data/parameters";
import { TAdvDesignerComponentProps } from "@feature/Designer/types/component-props";
import { getDesignerModeComponentStyle, getSelectedComponentStyle } from "@feature/Designer/utils";
import {
    IStyleFunctionOrObject,
    ITextFieldProps,
    ITextFieldStyleProps,
    ITextFieldStyles,
    TextField,
} from "@fluentui/react";
import {
    EAdvValueBinderType,
    GetValueBindingType,
    IsValueBindingTrivial,
    TAdvValueBinderAttributes,
    TAdvValueBindingParams,
    useAdvValueBinderNoDataType,
} from "@hooks/dynamic/useAdvValueBinder";
import { useAdvValueBinderProvider } from "@hooks/dynamic/useAdvValueBinderExt";
import { TAdvTranslationText, useT } from "@hooks/language/useTranslation";
import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import useAdvRecoilValue from "@hooks/recoil-overload/useAdvRecoilValue";
import useAdvComponent from "@hooks/useAdvComponent";
import { useAdvMemoWithUpdater } from "@hooks/useAdvMemoWithUpdater";
import useAdvTheme from "@hooks/useAdvTheme";
import { EAdvValueDataTypes } from "@utils/data-types";
import {
    DateStrFluentToServerStr,
    DateTimeStrFluentToServerStr,
    ServerStrToDateStrFluent,
    ServerStrToDateTimeStrFluent,
} from "@utils/date";
import { deepCompareJSXProps } from "@utils/deep-compare";
import { combineStyles } from "@utils/styling";
import { useRouter } from "next/router";
import React, { useMemo, useState } from "react";

import { useAdvWebAction } from "@hooks/dynamic/useAdvWebAction";
import { TAdvWebActionParams } from "@hooks/dynamic/useAdvWebAction.types";
import { TCommonValueProps } from "..";

export type TAdvTextInputStyles = ITextFieldStyles; /* do not change */
export type TAdvTextInputStyleProps = ITextFieldStyleProps; /* do not change */

export type TAdvTextInputProps = Omit<
    ITextFieldProps,
    | "styles"
    | "label"
    | "type"
    | "value"
    | "onChange"
    | "key"
    | "errorMessage"
    | "disabled"
    | "readOnly"
> &
    TAdvDesignerComponentProps &
    TAdvCommonProperties &
    TCommonValueProps<string> & {
        styles?: IStyleFunctionOrObject<TAdvTextInputStyleProps, TAdvTextInputStyles>;
        label?: string;
        labelBindingParams?: TAdvValueBindingParams;
        valueBindingParams?: TAdvValueBindingParams;

        multilineBindingParams?: TAdvValueBindingParams;

        // a context specializing the translation used
        translationContext?: string;
        ignoreTranslation?: boolean;

        errorMsgTranslationContext?: string;

        /**
         * Verfügbare InputTypes, die einigermaßen in FluentUI implementiert sind.
         * @link https://www.w3schools.com/html/html_form_input_types.asp
         */
        type?:
            | "color"
            | "date"
            | "datetime-local"
            | "hidden"
            | "month"
            | "number"
            | "password"
            | "search"
            | "text"
            | "time"
            | "week";

        errorMessage?: string;

        disabled?: boolean;
        disabledBindingParams?: TAdvValueBindingParams;
        readOnly?: boolean;
        readOnlyBindingParams?: TAdvValueBindingParams;

        submitAction?: TAdvWebActionParams;

        keyRef?: string;

        /**
         * Zeigt ein Infoicon, welches detalierte Informationen gibt, sobald der User mit dem Cursor drüber geht
         */
        info?: TAdvTranslationText;
    };

const AdvTextInputImplComp = ({
    label,
    advhide,
    ignoreTranslation = false,
    styles: propStyles,
    info,
    onRenderLabel,
    designerData,
    designerProps,
    errorMessage,
    placeholder,
    description,
    submitAction,
    keyRef,
    dataArrayIndex = 0,
    suffix,
    prefix,
    ...props
}: TAdvTextInputProps & Pick<ITextFieldProps, "onChange">) => {
    useAdvComponent(AdvTextInputImplComp, props);

    const [, , actionFunc, shouldUseBoundValue] = useAdvWebAction(
        keyRef ?? "",
        dataArrayIndex,
        submitAction,
    );

    const router = useRouter();
    const variableID = useMemo(
        () => buildPageIDForVariableID(router.pathname, router.query),
        [router.pathname, router.query],
    );
    const pageState = useAdvRecoilValue(recoilPageState(variableID));
    const [wasInteractedWith, setWasInteractedWith] = useState<boolean>(false);
    const myErrorMessage = useMemo(() => {
        if (errorMessage !== undefined) return errorMessage;
        if (props.readOnly !== undefined && props.readOnly) return undefined;
        if (props.disabled !== undefined && props.disabled) return undefined;
        if (props.required === undefined || props.required == false) return undefined;
        if (wasInteractedWith == false && pageState.ShowRequiredErrors == false) return undefined;
        if (props.value === undefined || props.value == "") return LAN.FORM_REQUIRED_ERROR.text;
    }, [
        errorMessage,
        pageState.ShowRequiredErrors,
        props.disabled,
        props.readOnly,
        props.required,
        props.value,
        wasInteractedWith,
    ]);

    const theme = useAdvTheme();
    const { t: translatedLabel, hasErr: hasLabelError } = useT(
        label,
        props.translationContext,
        ignoreTranslation,
    );
    const { t: translatedErrorMessage, hasErr: hasErrorMessageError } = useT(
        myErrorMessage,
        props.errorMsgTranslationContext,
        ignoreTranslation,
    );
    const { t: translatedPlaceholder } = useT(
        placeholder,
        props.translationContext,
        ignoreTranslation,
    );
    const { t: translatedDescription, hasErr: hasDescriptionError } = useT(
        description,
        props.translationContext,
        ignoreTranslation,
    );

    const styles = useMemo(() => {
        const { isSelected = false, renderAsDesigner: shouldRenderAsDesigner = false } =
            designerData ?? {};
        let styles = propStyles;
        if (hasLabelError)
            styles = combineStyles(styles, {
                wrapper: {
                    "& > .ms-Label": {
                        ...theme.custom.textNotTranslated,
                    },
                },
            });
        if (hasErrorMessageError)
            styles = combineStyles(styles, {
                errorMessage: { ...theme.custom.textNotTranslated },
            });
        if (hasDescriptionError)
            styles = combineStyles(styles, {
                description: { ...theme.custom.textNotTranslated },
            });
        if (shouldRenderAsDesigner) {
            styles = combineStyles(styles, { root: getDesignerModeComponentStyle(theme) });
            if (isSelected)
                styles = combineStyles(styles, { root: getSelectedComponentStyle(theme, true) });
        } else if (
            (props.readOnly !== undefined && props.readOnly) ||
            (props.disabled !== undefined && props.disabled)
        ) {
            // styles = combineStyles(styles, {
            //     fieldGroup: {
            //         background: "transparent !important",
            //         border: "transparent !important",
            //         // minHeight: "32px",
            //     },
            //     field: {
            //         background: "transparent !important",
            //         border: "transparent !important",
            //         // display: "inline-block",
            //     },
            //     description: {
            //         paddingLeft: "8px",
            //     },
            //     // wrapper: {
            //     //     "& > .ms-Label": {
            //     //         display: "inline-block",
            //     //     },
            //     // },
            // });
        }
        if (props.disabled !== undefined && props.disabled) {
            styles = combineStyles(styles, {
                field: {
                    background: theme.palette.white + " !important",
                    color: theme.palette.neutralPrimaryAlt + " !important",
                },
                subComponentStyles: {
                    label: { root: { color: theme.palette.neutralPrimaryAlt + " !important" } },
                },
            });
        }
        return styles;
    }, [
        designerData,
        hasDescriptionError,
        hasErrorMessageError,
        hasLabelError,
        propStyles,
        props.disabled,
        props.readOnly,
        theme,
    ]);

    if (advhide === true && designerProps === undefined) return <></>;
    // if (
    //     (props.readOnly !== undefined && props.readOnly) ||
    //     (props.disabled !== undefined && props.disabled)
    // ) {
    //     return (
    //         <AdvStack tokens={{ childrenGap: 0 }}>
    //             <AdvStackItem>
    //                 <AdvLabel label={translatedLabel} styles={{ root: {} }} />
    //             </AdvStackItem>
    //             <AdvStackItem>
    //                 <AdvText
    //                     ignoreTranslation
    //                     label={props.value ?? translatedPlaceholder}
    //                     styles={props.value !== undefined ? {} : { root: { color: "gray" } }}
    //                 />
    //             </AdvStackItem>
    //         </AdvStack>
    //     );
    // }
    return (
        <TextField
            {...props}
            {...designerProps}
            styles={styles}
            autoComplete="none"
            label={translatedLabel}
            placeholder={translatedPlaceholder}
            errorMessage={translatedErrorMessage}
            description={translatedDescription}
            onRenderLabel={
                info != undefined
                    ? AdvRenderLabelTextInput.bind(null, info, onRenderLabel)
                    : onRenderLabel
            }
            onBlur={(ev) => {
                if (props.onBlur !== undefined) props.onBlur(ev);
                setWasInteractedWith(true);
            }}
            onKeyUp={(ev) => {
                // emulate submit
                if (ev.key == "Enter" && shouldUseBoundValue) {
                    actionFunc();
                }
            }}
            suffix={suffix == undefined || suffix.trim() == "" ? undefined : suffix}
            prefix={prefix == undefined || prefix.trim() == "" ? undefined : prefix}
        />
    );
};
const AdvTextInputImpl = React.memo(AdvTextInputImplComp, deepCompareJSXProps);

const AdvTextInputSimple = ({ onValueChanged, value, ...props }: TAdvTextInputProps) => {
    const [curVal, , setCurVal] = useAdvMemoWithUpdater(() => value, [value]);

    const handleChange = useAdvCallback(
        (event: any, newValue?: string | undefined) => {
            setCurVal(newValue);
            if (onValueChanged != undefined) onValueChanged(newValue, event);
        },
        [onValueChanged, setCurVal],
    );

    return <AdvTextInputImpl {...props} onChange={handleChange} value={curVal}></AdvTextInputImpl>;
};

const useLocalInputComplexValueFuncs = (
    setCurrentValue: (newVal: string | undefined) => boolean,
    onValueChanged:
        | ((newValue: string | undefined, oldValue: string | undefined) => void)
        | undefined,
    type: TAdvTextInputProps["type"],
    valueText: string | undefined,
    attributes: TAdvValueBinderAttributes,
) => {
    const textToServerText = useAdvCallback(
        (aText: string): string => {
            switch (attributes.fieldType) {
                case EFieldSettingsFieldTypes.default:
                case EFieldSettingsFieldTypes.string:
                    if (type == "week" && aText !== undefined && aText.indexOf("W") == 5) {
                        // HTML Format: 2017-W01, AdvanTex Format: 2017/01
                        return `${aText.substring(0, 4)}/${aText.substring(6, 8)}`;
                    } else return aText;
                case EFieldSettingsFieldTypes.boolean:
                case EFieldSettingsFieldTypes.currency:
                    return aText;
                case EFieldSettingsFieldTypes.memo:
                    return aText === undefined ? "" : aText;
                case EFieldSettingsFieldTypes.date:
                    return DateStrFluentToServerStr(aText);
                case EFieldSettingsFieldTypes.datetime:
                    return DateTimeStrFluentToServerStr(aText);
                case EFieldSettingsFieldTypes.number:
                    return aText;
                default:
                    return aText;
            }
        },
        [attributes.fieldType, type],
    );

    const handleChange = useAdvCallback(
        (event: any, newValue?: string | undefined) => {
            let canSet = true;
            if (newValue != undefined) {
                canSet = setCurrentValue(textToServerText(newValue));
            }
            if (canSet && onValueChanged != undefined) onValueChanged(newValue, event);
        },
        [onValueChanged, setCurrentValue, textToServerText],
    );

    const inpType = useMemo<TAdvTextInputProps["type"]>(() => {
        switch (attributes.fieldType) {
            case EFieldSettingsFieldTypes.default:
            case EFieldSettingsFieldTypes.memo:
            case EFieldSettingsFieldTypes.boolean:
            case EFieldSettingsFieldTypes.currency:
            case EFieldSettingsFieldTypes.string:
                return type;
            case EFieldSettingsFieldTypes.date:
                return "date";
            case EFieldSettingsFieldTypes.datetime:
                return "datetime-local";
            case EFieldSettingsFieldTypes.number:
                return "number";
        }
    }, [attributes.fieldType, type]);

    // ServerValue -> ClientValue
    const inpValue = useMemo<typeof valueText>(() => {
        if (!attributes.isLoaded) return "";
        switch (attributes.fieldType) {
            case EFieldSettingsFieldTypes.default:
            case EFieldSettingsFieldTypes.string:
                if (type == "week" && valueText !== undefined && valueText.indexOf("/") == 4) {
                    // AdvanTex Format: 2017/01, HTML Format: 2017-W01
                    return `${valueText.substring(0, 4)}-W${valueText.substring(5, 7)}`;
                } else return valueText;
            case EFieldSettingsFieldTypes.boolean:
            case EFieldSettingsFieldTypes.currency:
                return valueText;
            case EFieldSettingsFieldTypes.memo:
                return valueText === undefined ? "" : valueText;
            case EFieldSettingsFieldTypes.date:
                return ServerStrToDateStrFluent(valueText ?? "");
            case EFieldSettingsFieldTypes.datetime:
                return ServerStrToDateTimeStrFluent(valueText ?? "");
            case EFieldSettingsFieldTypes.number:
                return valueText;
        }
    }, [attributes.fieldType, attributes.isLoaded, type, valueText]);

    const isMultiLine = useMemo(() => {
        return attributes.fieldType == EFieldSettingsFieldTypes.memo;
    }, [attributes.fieldType]);

    const isVisible = useMemo(() => attributes.isVisible, [attributes.isVisible]);

    return { handleChange, inpType, inpValue, isMultiLine, isVisible };
};

const AdvTextInputComplexValueProvider = ({
    valueBindingParams,
    value,
    onValueChanged,
    disabled = false,
    type,
    maxLength,
    multiline = false,
    dataArrayIndex = 0,
    ...props
}: TAdvTextInputProps) => {
    const [valueText, setCurrentValue, attributes] = useAdvValueBinderProvider(
        valueBindingParams,
        value,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );

    const { handleChange, inpType, inpValue, isMultiLine, isVisible } =
        useLocalInputComplexValueFuncs(
            setCurrentValue,
            onValueChanged,
            type,
            valueText,
            attributes,
        );

    if (isVisible)
        return (
            <AdvTextInputImpl
                {...props}
                valueBindingParams={valueBindingParams}
                value={inpValue}
                onChange={handleChange}
                disabled={!attributes.isEditable || disabled}
                dataArrayIndex={dataArrayIndex}
                type={inpType}
                maxLength={attributes.len != 0 ? attributes.len : maxLength}
                multiline={(isMultiLine ?? false) || multiline}
            ></AdvTextInputImpl>
        );
    else return <></>;
};

const AdvTextInputComplexValuePageVarOrUnknown = ({
    valueBindingParams,
    value,
    onValueChanged,
    disabled,
    type,
    maxLength,
    multiline,
    dataArrayIndex = 0,
    ...props
}: TAdvTextInputProps) => {
    const [valueText, setCurrentValue, attributes] = useAdvValueBinderNoDataType(
        valueBindingParams,
        value,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );

    const { handleChange, inpType, inpValue, isMultiLine, isVisible } =
        useLocalInputComplexValueFuncs(
            setCurrentValue,
            onValueChanged,
            type,
            valueText,
            attributes,
        );

    if (isVisible)
        return (
            <AdvTextInputImpl
                {...props}
                valueBindingParams={valueBindingParams}
                value={inpValue}
                onChange={handleChange}
                disabled={!attributes.isEditable || (disabled ?? false)}
                type={inpType}
                maxLength={attributes.len != 0 ? attributes.len : maxLength}
                dataArrayIndex={dataArrayIndex}
                multiline={(isMultiLine ?? false) || (multiline ?? false)}
            ></AdvTextInputImpl>
        );
    else return <></>;
};

const AdvTextInputComplexValue = ({ valueBindingParams, ...props }: TAdvTextInputProps) => {
    if (GetValueBindingType(valueBindingParams) == EAdvValueBinderType.BinderTypeDataProvider)
        return (
            <AdvTextInputComplexValueProvider
                {...props}
                valueBindingParams={valueBindingParams}
            ></AdvTextInputComplexValueProvider>
        );
    else
        return (
            <AdvTextInputComplexValuePageVarOrUnknown
                {...props}
                valueBindingParams={valueBindingParams}
            ></AdvTextInputComplexValuePageVarOrUnknown>
        );
};

const AdvTextInputComplexLabel = ({
    valueBindingParams,
    multilineBindingParams,
    multiline = false,
    labelBindingParams,
    label,
    advhide,
    advhideBindingParams,
    disabledBindingParams,
    readOnlyBindingParams,
    onValueChanged,
    dataArrayIndex = 0,
    ...props
}: TAdvTextInputProps) => {
    const [labelText] = useAdvValueBinderNoDataType(
        labelBindingParams,
        label,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );

    const handleChange = useAdvCallback(
        (event: any, newValue?: string | undefined) => {
            if (onValueChanged != undefined) onValueChanged(newValue, event);
        },
        [onValueChanged],
    );

    const [shouldHide] = useAdvValueBinderNoDataType(
        advhideBindingParams,
        advhide,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );

    const [isMultilineAllowed] = useAdvValueBinderNoDataType(
        multilineBindingParams,
        multiline,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );

    if (!IsValueBindingTrivial(valueBindingParams))
        return (
            <AdvTextInputComplexValue
                {...props}
                valueBindingParams={valueBindingParams}
                multilineBindingParams={multilineBindingParams}
                labelBindingParams={labelBindingParams}
                disabledBindingParams={disabledBindingParams}
                readOnlyBindingParams={readOnlyBindingParams}
                label={labelText}
                advhide={shouldHide}
                advhideBindingParams={advhideBindingParams}
                onValueChanged={onValueChanged}
                dataArrayIndex={dataArrayIndex}
                multiline={isMultilineAllowed}
            ></AdvTextInputComplexValue>
        );
    else
        return (
            <AdvTextInputImpl
                {...props}
                valueBindingParams={valueBindingParams}
                multilineBindingParams={multilineBindingParams}
                labelBindingParams={labelBindingParams}
                disabledBindingParams={disabledBindingParams}
                readOnlyBindingParams={readOnlyBindingParams}
                label={labelText}
                advhide={shouldHide}
                advhideBindingParams={advhideBindingParams}
                onChange={handleChange}
                dataArrayIndex={dataArrayIndex}
            ></AdvTextInputImpl>
        );
};

const AdvTextInputComplex = ({
    labelBindingParams,
    advhideBindingParams,
    multilineBindingParams,
    disabledBindingParams,
    readOnlyBindingParams,
    disabled,
    readOnly,
    ...props
}: TAdvTextInputProps) => {
    const [isDisabled] = useAdvValueBinderNoDataType(
        disabledBindingParams,
        disabled,
        EAdvValueDataTypes.Boolean,
        props.dataArrayIndex ?? 0,
    );

    const [isReadonly] = useAdvValueBinderNoDataType(
        readOnlyBindingParams,
        readOnly,
        EAdvValueDataTypes.Boolean,
        props.dataArrayIndex ?? 0,
    );

    if (
        !IsValueBindingTrivial(labelBindingParams) ||
        !IsValueBindingTrivial(advhideBindingParams) ||
        !IsValueBindingTrivial(multilineBindingParams)
    )
        return (
            <AdvTextInputComplexLabel
                {...props}
                labelBindingParams={labelBindingParams}
                advhideBindingParams={advhideBindingParams}
                multilineBindingParams={multilineBindingParams}
                disabledBindingParams={disabledBindingParams}
                readOnlyBindingParams={readOnlyBindingParams}
                disabled={isDisabled}
                readOnly={isReadonly}
            ></AdvTextInputComplexLabel>
        );
    else
        return (
            <AdvTextInputComplexValue
                {...props}
                labelBindingParams={labelBindingParams}
                advhideBindingParams={advhideBindingParams}
                multilineBindingParams={multilineBindingParams}
                disabledBindingParams={disabledBindingParams}
                readOnlyBindingParams={readOnlyBindingParams}
                disabled={isDisabled}
                readOnly={isReadonly}
            ></AdvTextInputComplexValue>
        );
};

/**
 * @summary Wrapper für ``TextField``
 * @link https://developer.microsoft.com/en-us/fluentui#/controls/web/textfield
 */
const AdvTextInputComp = ({
    valueBindingParams,
    labelBindingParams,
    advhideBindingParams,
    multilineBindingParams,
    disabledBindingParams,
    readOnlyBindingParams,
    ...props
}: TAdvTextInputProps) => {
    if (
        IsValueBindingTrivial(valueBindingParams) &&
        IsValueBindingTrivial(multilineBindingParams) &&
        IsValueBindingTrivial(labelBindingParams) &&
        IsValueBindingTrivial(advhideBindingParams) &&
        IsValueBindingTrivial(disabledBindingParams) &&
        IsValueBindingTrivial(readOnlyBindingParams)
    )
        return (
            <AdvTextInputSimple
                {...props}
                valueBindingParams={valueBindingParams}
                multilineBindingParams={multilineBindingParams}
                labelBindingParams={labelBindingParams}
                advhideBindingParams={advhideBindingParams}
                disabledBindingParams={disabledBindingParams}
                readOnlyBindingParams={readOnlyBindingParams}
            ></AdvTextInputSimple>
        );
    else
        return (
            <AdvTextInputComplex
                {...props}
                valueBindingParams={valueBindingParams}
                multilineBindingParams={multilineBindingParams}
                labelBindingParams={labelBindingParams}
                advhideBindingParams={advhideBindingParams}
                disabledBindingParams={disabledBindingParams}
                readOnlyBindingParams={readOnlyBindingParams}
            ></AdvTextInputComplex>
        );
};

const AdvTextInput = React.memo(AdvTextInputComp, deepCompareJSXProps);
export default AdvTextInput;

/*registerDesignableComponent({
    staticData: {
        name: LAN.TEXT_INPUT.text,
        translationContext: LAN.TEXT_INPUT.context,
        type: EComponentTypeInput.TextInput,
        supportsChildren: false,
        category: DefaultComponentCategory.Input,
        icon: TextInputIcon,
    },
    properties: [
        AdvProperty.Boolean.create(
            toAdvText(LAN.ALLOW_MULTILINE),
            "multiline",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.ALLOW_MULTILINE_DESCR),
            false,
        ),
        AdvProperty.Boolean.create(
            toAdvText(LAN.AUTO_ADJUST_HEIGHT),
            "autoAdjustHeight",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.AUTO_ADJUST_HEIGHT_DESCR),
            false,
        ),
        AdvProperty.Text.createSuggestion(
            toAdvText(LAN.LABEL),
            "label",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.TEXTINPUT_LABEL_DESCR),
            "TextInput-Label",
        ),
        AdvProperty.Text.create(
            toAdvText(LAN.TRANSLATION_CONTEXT),
            "translationContext",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.TRANSLATION_CONTEXT_DESCR),
            "",
            false,
        ),
        AdvProperty.Text.create(
            toAdvText(LAN.VALUE),
            "value",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.TEXTINPUT_VALUE_DESCR),
            "TestValue",
        ),
        AdvProperty.Text.createSelect(
            toAdvText(LAN.INPUT_TYPE),
            "type",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.TEXTINPUT_INPUT_TYPE_DESCR),
            0,
            false,
            "text",
            "color",
            "date",
            "datetime-local",
            "hidden",
            "month",
            "number",
            "password",
            "search",
            "time",
            "week",
        ),
        AdvProperty.Boolean.create(
            toAdvText(LAN.FORM_REQUIRED),
            "required",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.FORM_REQUIRED_DESCR),
            false,
        ),
        AdvProperty.Text.createSuggestion(
            toAdvText(LAN.PLACEHOLDER),
            "placeholder",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.PLACEHOLDER_DESCR),
            "",
        ),
        AdvProperty.Text.createSuggestion(
            toAdvText(LAN.TEXTINPUT_DESCRIPTION),
            "description",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.TEXTINPUT_DESCRIPTION_DESCR),
            "",
        ),
        AdvProperty.Boolean.create(
            toAdvText(LAN.DISABLED),
            "disabled",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.TEXT_INPUT_DISABLED_DESCR),
            false,
        ),
        AdvProperty.Action.create(
            toAdvText(LAN.SUBMIT_ACTION),
            "submitAction",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.TEXT_INPUT_SUBMIT_ACTION_DESCR),
        ),
        ...AdvCommonComponentAttributes,
        ...AdvThemeProviderProperties,
        ...AdvStackItemDesignable.CommonProperties,
        ...AdvGridItemDesignable.CommonProperties,
    ],
    propertiesBuilders: [],
    presets: [],
});
*/
