import AdvSearchInput from "@components/inputs/text-input-new/search-input";
import AdvStack from "@components/layout/stack";
import AdvStackItem from "@components/layout/stack/stack-item";
import { LAN } from "@data/language/strings";
import { useMemo, useState } from "react";

import {
    DataGrid,
    DataGridBody,
    DataGridCell,
    DataGridHeader,
    DataGridHeaderCell,
    DataGridRow,
    RowRenderer,
} from "@fluentui-contrib/react-data-grid-react-window";
import {
    DataGridProps,
    FluentProvider,
    TableColumnSizingOptions,
    TableRowId,
} from "@fluentui/react-components";
import { createV9Theme } from "@fluentui/react-migration-v8-v9";
import { useDebounce } from "@hooks/misc/useDebounce";
import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import useAdvTheme from "@hooks/useAdvTheme";
import { ResizerFlex } from "./endless/resizer-flex";
import {
    TAdvTableCellProps,
    TAdvTableHeaderCellProps,
    TAdvTableHeaderProps,
    TAdvTableProps,
    TAdvTableRowProps,
} from "./types";

export const AdvTableHeader = (props: TAdvTableHeaderProps) => {
    return <DataGridHeader {...props}></DataGridHeader>;
};
export const AdvTableHeaderCell = (props: TAdvTableHeaderCellProps) => {
    return <DataGridHeaderCell {...props}></DataGridHeaderCell>;
};
export const AdvTableHeaderRow = <TItem extends object>(props: TAdvTableRowProps<TItem>) => {
    return <DataGridRow<TItem> {...props}></DataGridRow>;
};
export const AdvTableRow = <TItem extends object>(props: TAdvTableRowProps<TItem>) => {
    return <DataGridRow<TItem> {...props}></DataGridRow>;
};
export const AdvTableCell = (props: TAdvTableCellProps) => {
    return <DataGridCell {...props}></DataGridCell>;
};

export const AdvTable = <TItem extends object>({
    columns,
    items,
    canFilter = true,
    selectedItems,
    onSelectionChange,
    actionbar,
    getRowId,
    columnSizingOptions: propColumnSizingOptions,
    headerAs = AdvTableHeader,
    headerCellAs = AdvTableHeaderCell,
    headerRowAs = AdvTableHeaderRow<TItem>,
    rowAs = AdvTableRow<TItem>,
    cellAs = AdvTableCell,
    itemHeight = 50,
    ...props
}: TAdvTableProps<TItem> & { itemHeight?: number }) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const HeaderComponent = headerAs;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const HeaderCellComponent = headerCellAs;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const HeaderRowComponent = headerRowAs;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const RowComponent = rowAs;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const CellComponent = cellAs;

    const theme = useAdvTheme();
    const v9Theme = useMemo(() => {
        return createV9Theme(theme);
    }, [theme]);

    const [searchText, setSearchText] = useState<string | undefined>(undefined);
    const searchbar = useMemo(() => {
        return canFilter ? (
            <AdvSearchInput
                key="filter"
                onValueChanged={setSearchText}
                value={searchText}
                placeholder={LAN.SEARCH_DOT_DOT_DOT.text}
                placeholderTranslationContext={LAN.SEARCH_DOT_DOT_DOT.context}
            />
        ) : null;
    }, [canFilter, searchText, setSearchText]);

    const debouncedSearchText = useDebounce(searchText, 250);
    const filteredItems = useMemo(() => {
        if (items.length === 0) return [];
        if (debouncedSearchText === undefined || debouncedSearchText == "") return items;

        const filtered = items
            .filter((item) => {
                return columns.some((column) => column.contains(item, debouncedSearchText));
            })
            .sort((a, b) => {
                let compareValA = 0;
                let compareValB = 0;
                columns.forEach((column) => {
                    if (!(column.searchtermCorrespondence === undefined)) {
                        compareValA = Math.max(
                            compareValA,
                            column.searchtermCorrespondence(a, debouncedSearchText),
                        );
                        compareValB = Math.max(
                            compareValB,
                            column.searchtermCorrespondence(b, debouncedSearchText),
                        );
                    }
                });
                return compareValB - compareValA; //hoehere Werte heisst bessere Uebereinstimmung
            });

        return filtered;
    }, [columns, items, debouncedSearchText]);

    const selectedItemIDs = useMemo<TableRowId[] | undefined>(() => {
        if (selectedItems === undefined) return undefined;
        if (getRowId === undefined)
            return selectedItems.map((selectedItem) => filteredItems.indexOf(selectedItem));
        else return selectedItems.map((selectedItem) => getRowId(selectedItem));
    }, [filteredItems, getRowId, selectedItems]);

    const handleSelectionChange: DataGridProps["onSelectionChange"] = (_, { selectedItems }) => {
        if (onSelectionChange === undefined) return;

        const itemIDs: TableRowId[] = Array.from(selectedItems.values());
        if (getRowId === undefined) {
            onSelectionChange(filteredItems.filter((_, index) => itemIDs.indexOf(index) >= 0));
        } else {
            onSelectionChange(filteredItems.filter((item) => itemIDs.indexOf(getRowId(item)) >= 0));
        }
    };

    const columnSizingOptions = useMemo<TableColumnSizingOptions | undefined>(() => {
        if (propColumnSizingOptions !== undefined) return propColumnSizingOptions;

        let res: TableColumnSizingOptions = {};
        columns.forEach((column) => {
            res = {
                ...res,
                [column.columnId]: {
                    minWidth: 1,
                    idealWidth: undefined,
                    defaultWidth: undefined,
                },
            };
        });
        return res;
    }, [columns, propColumnSizingOptions]);

    const renderRow: RowRenderer<TItem> = useAdvCallback(
        ({ rowId, item }, style) => {
            return (
                <RowComponent key={rowId} style={style} item={item}>
                    {({ renderCell }) => <CellComponent>{renderCell(item)}</CellComponent>}
                </RowComponent>
            );
        },
        [CellComponent, RowComponent],
    );

    return (
        <AdvStack verticalFill>
            {/* <ContentBoxCreator calculatedColumnSize={500}>
                {() => ( */}
            <FluentProvider theme={v9Theme} style={{ flexGrow: 1 }}>
                <DataGrid
                    {...props}
                    items={filteredItems}
                    columns={columns}
                    selectedItems={selectedItemIDs}
                    onSelectionChange={handleSelectionChange}
                    getRowId={getRowId}
                    columnSizingOptions={columnSizingOptions}
                    resizableColumns
                    focusMode={"none"}
                    style={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <HeaderComponent>
                        <AdvStack
                            horizontal
                            horizontalAlign="space-between"
                            wrap
                            tokens={{ childrenGap: 0 }}
                            styles={{ root: { width: "100%" } }}
                        >
                            <AdvStackItem grow shrink={0}>
                                {actionbar ?? <div />}
                            </AdvStackItem>
                            <AdvStackItem shrink={1} align="end">
                                {searchbar}
                            </AdvStackItem>
                        </AdvStack>
                        <HeaderRowComponent>
                            {({ renderHeaderCell }) => (
                                <HeaderCellComponent>{renderHeaderCell()}</HeaderCellComponent>
                            )}
                        </HeaderRowComponent>
                    </HeaderComponent>
                    <ResizerFlex>
                        {(height) => (
                            <DataGridBody<TItem>
                                height={height}
                                style={{ overflowY: "scroll" }}
                                itemSize={itemHeight}
                            >
                                {renderRow}
                            </DataGridBody>
                        )}
                    </ResizerFlex>
                </DataGrid>
            </FluentProvider>
            {/* )}
            </ContentBoxCreator> */}
        </AdvStack>
    );
};

export default AdvTable;
