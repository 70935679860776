import { EFieldSettingsFieldTypes } from "@components/dynamic/data-provider/types";
import AdvGridItemDesignable from "@components/layout/grid/grid-item/designable";
import AdvStackItemDesignable from "@components/layout/stack/stack-item/designable";
import {
    AdvCommonComponentAttributes,
    AdvThemeProviderProperties,
    TAdvCommonProperties,
} from "@components/other/common-properties";
import { LAN } from "@data/language/strings";
import { DefaultComponentCategory } from "@feature/Designer/types/category";
import { TAdvDesignerComponentProps } from "@feature/Designer/types/component-props";
import { EComponentTypeData } from "@feature/Designer/types/component-type";
import {
    AdvProperty,
    getDesignerModeComponentStyle,
    getSelectedComponentStyle,
    registerDesignableComponent,
} from "@feature/Designer/utils";
import { ILabelProps, ILabelStyles, IStyleFunctionOrObject, Label, Shimmer } from "@fluentui/react";
import {
    IsValueBindingTrivial,
    TAdvValueBindingParams,
    useAdvValueBinderNoDataType,
} from "@hooks/dynamic/useAdvValueBinder";
import { useLanguages } from "@hooks/language/useLanguages";
import { toAdvText, useT } from "@hooks/language/useTranslation";
import useAdvComponent from "@hooks/useAdvComponent";
import useAdvTheme from "@hooks/useAdvTheme";
import { LabelIcon } from "@themes/icons";
import { EAdvValueDataTypes } from "@utils/data-types";
import { ServerStrToLocalDateStr, ServerStrToLocalDateTimeStr } from "@utils/date";
import { deepCompareJSXProps } from "@utils/deep-compare";
import { combineStyles } from "@utils/styling";
import React, { useMemo } from "react";

export type TAdvLabelStyles = ILabelStyles; /* do not change */

export type TAdvLabelProps = Omit<ILabelProps, "styles"> &
    TAdvDesignerComponentProps &
    TAdvCommonProperties & {
        children?: string;
        // label has higher prio than children if used
        label?: string;
        labelBindingParams?: TAdvValueBindingParams;

        // a context specializing the translation used
        translationContext?: string;

        styles?: IStyleFunctionOrObject<ILabelProps, TAdvLabelStyles>;

        ignoreTranslation?: boolean;

        allowMultiline?: boolean;
        allowMultilineBindingParams?: TAdvValueBindingParams;

        normalFontWeight?: boolean;
    };

const AdvLabelImplComp = ({
    ignoreTranslation = false,
    translationContext,
    styles: propStyles,
    label,
    advhide,
    children,
    designerData,
    designerProps,
    allowMultiline = false,
    normalFontWeight = false,
    ...props
}: TAdvLabelProps) => {
    useAdvComponent(AdvLabelImplComp, props);

    const theme = useAdvTheme();
    const { formatInteger } = useLanguages();

    const realText = label != undefined ? label : children;

    const { t, hasErr } = useT(
        !ignoreTranslation && typeof realText == "string" ? realText : undefined,
        translationContext,
    );

    const styles = useMemo(() => {
        let styles = propStyles;
        if (allowMultiline) styles = combineStyles(styles, { root: { whiteSpace: "pre-wrap" } });
        if (hasErr)
            styles = combineStyles(styles, {
                root: { ...theme.custom.textNotTranslated },
            });
        if ((designerData?.isSelected ?? false) && (designerData?.renderAsDesigner ?? false))
            styles = combineStyles(styles, { root: getSelectedComponentStyle(theme, true) });
        if (designerData?.renderAsDesigner ?? false)
            styles = combineStyles(styles, { root: getDesignerModeComponentStyle(theme) });
        if (normalFontWeight) styles = combineStyles(styles, { root: { fontWeight: "normal" } });
        return styles;
    }, [
        allowMultiline,
        designerData?.isSelected,
        designerData?.renderAsDesigner,
        hasErr,
        normalFontWeight,
        propStyles,
        theme,
    ]);

    if (advhide === true && designerProps === undefined) return <></>;
    return (
        <Label {...props} {...designerProps} styles={styles}>
            {!ignoreTranslation && typeof realText == "string" ? t : realText}
        </Label>
    );
};
const AdvLabelImpl = React.memo(AdvLabelImplComp, deepCompareJSXProps);

const AdvLabelSimple = (props: TAdvLabelProps) => {
    return <AdvLabelImpl {...props}></AdvLabelImpl>;
};

const AdvLabelComplex = ({
    label,
    labelBindingParams,
    advhide,
    advhideBindingParams,
    dataArrayIndex = 0,
    allowMultiline,
    allowMultilineBindingParams,
    ...props
}: TAdvLabelProps) => {
    const [labelText, , attributes] = useAdvValueBinderNoDataType(
        labelBindingParams,
        label,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );
    const [shouldHide] = useAdvValueBinderNoDataType(
        advhideBindingParams,
        advhide,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );
    const [isMultilineAllowed] = useAdvValueBinderNoDataType(
        allowMultilineBindingParams,
        allowMultiline,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );

    const convertedText = useMemo(() => {
        if (attributes.isLoading || !attributes.isLoaded) return "";
        else if (attributes.fieldType == EFieldSettingsFieldTypes.date) {
            return ServerStrToLocalDateStr(labelText ?? "");
        } else if (attributes.fieldType == EFieldSettingsFieldTypes.datetime) {
            return ServerStrToLocalDateTimeStr(labelText ?? "");
        }
        return labelText;
    }, [attributes.fieldType, attributes.isLoaded, attributes.isLoading, labelText]);

    if (attributes.isLoading) return <Shimmer width="max(100%, 50px)" />;
    return (
        <AdvLabelImpl
            {...props}
            dataArrayIndex={dataArrayIndex}
            label={convertedText}
            labelBindingParams={labelBindingParams}
            advhide={shouldHide}
            advhideBindingParams={advhideBindingParams}
            allowMultiline={isMultilineAllowed}
            allowMultilineBindingParams={allowMultilineBindingParams}
        ></AdvLabelImpl>
    );
};

/**
 * @summary Wrapper für ``Label``
 * @description Labels give a name or title to a control or group of controls, including text fields, check boxes, combo boxes, radio buttons, and drop-down menus
 * @important Oft haben Elemente bereits eine ``Label``-Property!
 * @link https://developer.microsoft.com/en-us/fluentui#/controls/web/label
 */
const AdvLabelComp = ({
    labelBindingParams,
    advhideBindingParams,
    allowMultilineBindingParams,
    ...props
}: TAdvLabelProps) => {
    if (
        IsValueBindingTrivial(labelBindingParams) &&
        IsValueBindingTrivial(advhideBindingParams) &&
        IsValueBindingTrivial(allowMultilineBindingParams)
    )
        return (
            <AdvLabelSimple
                {...props}
                labelBindingParams={labelBindingParams}
                advhideBindingParams={advhideBindingParams}
                allowMultilineBindingParams={allowMultilineBindingParams}
            ></AdvLabelSimple>
        );
    else
        return (
            <AdvLabelComplex
                {...props}
                labelBindingParams={labelBindingParams}
                advhideBindingParams={advhideBindingParams}
                allowMultilineBindingParams={allowMultilineBindingParams}
            ></AdvLabelComplex>
        );
};

const AdvLabel = React.memo(AdvLabelComp, deepCompareJSXProps);
export default AdvLabel;

registerDesignableComponent({
    staticData: {
        name: LAN.LABEL.text,
        translationContext: LAN.LABEL.context,
        type: EComponentTypeData.Label,
        supportsChildren: false,
        category: DefaultComponentCategory.Display,
        icon: LabelIcon,
    },
    properties: [
        AdvProperty.Text.createSuggestion(
            toAdvText(LAN.LABEL),
            "label",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.LABEL_TEXT_DESCR),
            "Label-Text",
        ),
        AdvProperty.Text.create(
            toAdvText(LAN.TRANSLATION_CONTEXT),
            "translationContext",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.TRANSLATION_CONTEXT_DESCR),
            "",
            false,
        ),
        AdvProperty.Boolean.create(
            toAdvText(LAN.ALLOW_MULTILINE),
            "allowMultiline",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.ALLOW_MULTILINE_DESCR),
            false,
        ),
        AdvProperty.Boolean.create(
            toAdvText(LAN.IGNORE_TRANSLATION),
            "ignoreTranslation",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.IGNORE_TRANSLATION_DESCR),
            false,
        ),
        AdvProperty.Boolean.create(
            toAdvText(LAN.NORMAL_FONT_WEIGHT),
            "normalFontWeight",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.NORMAL_FONT_WEIGHT_DESCR),
            false,
            false,
        ),
        ...AdvCommonComponentAttributes,
        ...AdvThemeProviderProperties,
        ...AdvStackItemDesignable.CommonProperties,
        ...AdvGridItemDesignable.CommonProperties,
    ],
    propertiesBuilders: [],
    presets: [],
});
