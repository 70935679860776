import {
    defaultStartPage,
    IUserInfo,
    IWebLink,
    sessionAddInfosAtom,
    sessionAtom,
    TLoginResultExtraTypes,
    TLoginResultTypes,
} from "@data/session";
import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import { advcatch, advlog } from "@utils/logging";
import {
    buildPageParserParam,
    buildQueryAsStr,
    gAdvDynPageName,
    gAdvPageAccessKey,
    gDefaultIgnoreParamKeys,
    getQueryValue,
    parsePageName,
    TPageParserParam,
} from "@utils/page-parser";
import { useRouter } from "next/router";
import { useLanguages } from "./language/useLanguages";
import useAdvRecoilState from "./recoil-overload/useAdvRecoilState";

import { SESSIONSTORAGE_CUSTOMERS } from "@components/inputs/dropdown/customer-dropdown";
import { useSessionStorage } from "./misc/useSessionStorage";
import { useAdvRecoilTransaction } from "./recoil-overload/useAdvRecoilTransaction";
import useAdvResetRecoilState from "./recoil-overload/useAdvResetRecoilState";
import { netCloseSocketForced } from "./useAdvSocket";
import { TSuccessClass, useAdvSocketCallback } from "./useAdvSocketCallback";
import { useWaitForLogin } from "./useWaitForLogin";

export enum EUsesOTP {
    unknown = 0,
    yes = 1,
    no = 2,
}

type TInitConnectionInfo = {
    KundenLiveSichIDs: Array<number>;
};

type TInitConnectionInput = {
    GUID: string;

    FirstPage: string;
    FirstPageActionToken: string;
    FirstPageQueryStr: string;

    Info: TInitConnectionInfo;
};

export interface IServerLoginResponse {
    Response: TLoginResultTypes;
    ResponseExtra: TLoginResultExtraTypes;
    Vorname: string;
    Nachname: string;
    OTPSecret: string;
    EditPermissions: boolean;
    DesignerPermission: boolean;
    Session: string;
    ValidUntil: number;
    LanguageID: number;
    Force2FA: boolean;
    Navbar: string;
    Startpage: string;
    ServiceMail: string;
    WebLinks?: IWebLink[];
    UserInfos?: IUserInfo;
}

export function useAdvLogin() {
    const [sessionAddInfos, setSessionAddInfosInternal] = useAdvRecoilState(sessionAddInfosAtom);
    const [session, setSession] = useAdvRecoilState(sessionAtom);
    const resetSessionAddInfo = useAdvResetRecoilState(sessionAddInfosAtom);
    const resetSession = useAdvResetRecoilState(sessionAtom);

    const { sendCallbackRequest } = useAdvSocketCallback();
    const resetSocket = useAdvRecoilTransaction(netCloseSocketForced, []);
    const router = useRouter();

    const { isLoggedIn, setLoggedIn, setSessionSet } = useWaitForLogin();

    const getPageName = useAdvCallback(() => {
        return parsePageName(router.pathname, router.query);
    }, [router]);

    const { currentLanguage, browserLanguage, setCurrentLanguageById, resetCurrentLanguage } =
        useLanguages();

    const setSessionAddInfos = useAdvCallback(
        (response: IServerLoginResponse) => {
            setSessionAddInfosInternal({
                ...sessionAddInfos,
                Username: "TODO USERNAME",
                Vorname: response.Vorname,
                Nachname: response.Nachname,
                LoginStatus: response.Response,
                LoginValidUntil: response.ValidUntil,
                Navbar: response.Navbar,
                Startpage: response.Startpage,
                ServiceMail: response.ServiceMail,
                CanEditPermissions: response.EditPermissions === true ? true : undefined,
                LanguageID: response.LanguageID,
                WebLinks: response.WebLinks,
                UserInfos: response.UserInfos,
            });
        },
        [sessionAddInfos, setSessionAddInfosInternal],
    );

    const login = useAdvCallback(
        async function (
            username: string,
            password: string,
            keepLoggedIn: boolean,
            firstPage: string,
            firstPageParameters: string,
            firstPageActionToken: string,
            otp: string = "",
            shouldUseOTP: EUsesOTP = EUsesOTP.unknown,
            otpSecret: string = "",
        ) {
            const res: IServerLoginResponse = await sendCallbackRequest(
                "session",
                "login",
                {
                    Username: username,
                    Password: password,
                    KeepLoggedin: keepLoggedIn,
                    FirstPage: firstPage,
                    FirstPageQueryStr: firstPageParameters,
                    FirstPageActionToken: firstPageActionToken,
                    ShouldUseOTP: shouldUseOTP,
                    OTP: otp,
                    OtpSecret: otpSecret,
                    // Nur eine Sprache mitschicken, wenn wir eine abweichende ausgewählt haben
                    LanguageID:
                        browserLanguage !== undefined && currentLanguage.ID == browserLanguage.ID
                            ? -999
                            : currentLanguage.ID,
                },
                true,
            );

            if (res.Response != TLoginResultTypes.lrtLoginOK) {
                return {
                    result: res.Response,
                    resultExtra: res.ResponseExtra,
                    otpSecret: res.OTPSecret,
                    force2FA: res.Force2FA,
                    data: res,
                };
            } else {
                setSession({
                    ...session,
                    GUID: res.Session,
                });
                setSessionAddInfos(res);
                setCurrentLanguageById(res.LanguageID);
                setLoggedIn(true);
                setSessionSet(true);
                return { result: res.Response, resultExtra: res.ResponseExtra, data: res };
            }
        },
        [
            browserLanguage,
            currentLanguage.ID,
            sendCallbackRequest,
            session,
            setCurrentLanguageById,
            setLoggedIn,
            setSession,
            setSessionAddInfos,
            setSessionSet,
        ],
    );

    /** Setzt Session zurück und navigiert zur Hauptseite / Loginseite */
    const logout = useAdvCallback(
        async function (wasForcedLogout: boolean) {
            const wasLoggedIn = isLoggedIn();
            if (wasLoggedIn) {
                const res: TSuccessClass = await sendCallbackRequest("session", "logout", {});
                if (!res.Success) advlog("Logout failed.");
            }
            resetSession();
            resetSessionAddInfo();
            setLoggedIn(false);
            setSessionSet(false);
            resetCurrentLanguage();
            sessionStorage.removeItem(SESSIONSTORAGE_CUSTOMERS);

            if (wasForcedLogout) {
                resetSocket();
            } else {
                await router.replace("/", undefined, { unstable_skipClientCache: true });
                router.reload();
            }
        },
        [
            resetSocket,
            isLoggedIn,
            resetCurrentLanguage,
            resetSession,
            resetSessionAddInfo,
            router,
            sendCallbackRequest,
            setLoggedIn,
            setSessionSet,
        ],
    );

    const [currentCustomerIDs] = useSessionStorage(SESSIONSTORAGE_CUSTOMERS, [] as number[]);

    const tryRestoreSession = useAdvCallback(
        async function () {
            function _onInvalid() {
                // Session einfach durch ein leerer object überschreibene, da wir nicht eingeloggt sind
                setSessionAddInfosInternal({});
                setLoggedIn(false);
                if (["/token", "/token/", "token"].indexOf(router.pathname) == -1)
                    router.replace("/").catch(advcatch);
            }

            // Session nicht restoren wenn wir auf der Token-Page sind (sonst würden wir zum Dashboard weitergeleitet werden)
            if (
                typeof session.GUID == "undefined" ||
                ["/token", "/token/", "token"].indexOf(router.pathname) >= 0
            ) {
                _onInvalid();
                return false;
            }

            const curQueryParams: TPageParserParam[] = buildPageParserParam(
                router.query,
                gDefaultIgnoreParamKeys,
            );

            const res = await sendCallbackRequest<
                TInitConnectionInput,
                {
                    Success: boolean;
                    ValidUntil: number;
                    Redirect: string;
                    LoginDetails: IServerLoginResponse;
                }
            >(
                "init",
                "init",
                {
                    GUID: session.GUID,
                    FirstPage:
                        getPageName() == ""
                            ? sessionAddInfos.Startpage != undefined
                                ? sessionAddInfos.Startpage
                                : defaultStartPage
                            : getPageName(),
                    FirstPageQueryStr: buildQueryAsStr(curQueryParams),
                    FirstPageActionToken: getQueryValue(router.query, gAdvPageAccessKey),

                    Info: {
                        KundenLiveSichIDs: currentCustomerIDs,
                    },
                },
                true,
            );

            setSessionSet(true);
            if (res.Success) {
                setSessionAddInfos(res.LoginDetails);
                setLoggedIn(true);
                if (sessionAddInfos.LanguageID !== undefined)
                    setCurrentLanguageById(sessionAddInfos.LanguageID);

                if (router.asPath == "/") {
                    router
                        .push(
                            "/dynamic/?" +
                                gAdvDynPageName +
                                "=" +
                                (sessionAddInfos.Startpage != undefined
                                    ? sessionAddInfos.Startpage
                                    : defaultStartPage),
                        )
                        .catch(advcatch);
                }

                if (res.Redirect != "") {
                    router.push("/" + res.Redirect).catch(advcatch);
                }

                return true;
            } else {
                _onInvalid();
                return false;
            }
        },
        [
            currentCustomerIDs,
            getPageName,
            router,
            sendCallbackRequest,
            session.GUID,
            sessionAddInfos,
            setCurrentLanguageById,
            setLoggedIn,
            setSessionAddInfos,
            setSessionAddInfosInternal,
            setSessionSet,
        ],
    );

    const resetPassword = useAdvCallback(
        async function (usernameOrEmail: string, resetToken?: string, newPassword?: string) {
            const res: TSuccessClass = await sendCallbackRequest(
                "session",
                "reset",
                {
                    UsernameOrEmail: usernameOrEmail,
                    ResetToken: resetToken,
                    NewPassword: newPassword,
                },
                true,
            );

            return res.Success;
        },
        [sendCallbackRequest],
    );

    return { login, tryRestoreSession, logout, resetPassword };
}
