import AdvGridItemDesignable from "@components/layout/grid/grid-item/designable";
import AdvStackItemDesignable from "@components/layout/stack/stack-item/designable";
import {
    AdvCommonComponentAttributes,
    AdvThemeProviderProperties,
    TAdvCommonProperties,
} from "@components/other/common-properties";
import { AdvRenderLabelTextInput } from "@components/other/info-label";
import { LAN } from "@data/language/strings";
import { DefaultComponentCategory } from "@feature/Designer/types/category";
import { TAdvDesignerComponentProps } from "@feature/Designer/types/component-props";
import { EComponentTypeInput } from "@feature/Designer/types/component-type";
import {
    AdvProperty,
    getDesignerModeComponentStyle,
    getSelectedComponentStyle,
    registerDesignableComponent,
} from "@feature/Designer/utils";
import {
    Checkbox,
    ICheckboxProps,
    ICheckboxStyleProps,
    ICheckboxStyles,
    IStyleFunctionOrObject,
} from "@fluentui/react";
import {
    EAdvValueBinderType,
    GetValueBindingType,
    IsValueBindingTrivial,
    TAdvValueBindingParams,
    useAdvValueBinderNoDataType,
} from "@hooks/dynamic/useAdvValueBinder";
import { useAdvValueBinderProvider } from "@hooks/dynamic/useAdvValueBinderExt";
import { TAdvTranslationText, toAdvText, useT } from "@hooks/language/useTranslation";
import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import useAdvComponent from "@hooks/useAdvComponent";
import useAdvTheme from "@hooks/useAdvTheme";
import { CheckboxIcon } from "@themes/icons";
import { EAdvValueDataTypes } from "@utils/data-types";
import { deepCompareJSXProps } from "@utils/deep-compare";
import { combineStyles } from "@utils/styling";
import React, { useMemo } from "react";

import { TCommonValueProps } from "..";

export type TAdvCheckboxStyles = ICheckboxStyles; /* do not change */
export type TAdvCheckboxStyleProps = ICheckboxStyleProps; /* do not change */

export type TAdvCheckboxProps = Omit<
    ICheckboxProps,
    "styles" | "label" | "onChange" | "checked" | "defaultValue"
> &
    TAdvDesignerComponentProps &
    TAdvCommonProperties &
    TCommonValueProps<boolean> & {
        styles?: IStyleFunctionOrObject<TAdvCheckboxStyleProps, TAdvCheckboxStyles>;
        label: string;
        // a context specializing the translation used
        translationContext?: string;
        // a context specializing the translation used for the title
        titleTranslationContext?: string;

        labelBindingParams?: TAdvValueBindingParams;
        valueBindingParams?: TAdvValueBindingParams;
        disabledBindingParams?: TAdvValueBindingParams;

        ignoreTranslation?: boolean;

        /**
         * Zeigt ein Infoicon, welches detalierte Informationen gibt, sobald der User mit dem Cursor drüber geht
         */
        info?: TAdvTranslationText;
    };

const AdvCheckboxImplComp = ({
    ignoreTranslation: ignoreTranslations = false,
    title,
    titleTranslationContext,
    label,
    advhide,
    translationContext,
    info,
    onRenderLabel,
    styles: propStyles,
    inputProps,
    value,
    designerData,
    designerProps,
    ...props
}: TAdvCheckboxProps & Pick<ICheckboxProps, "onChange">) => {
    useAdvComponent(AdvCheckboxImplComp, props);

    const theme = useAdvTheme();

    const { t, hasErr } = useT(ignoreTranslations ? undefined : label, translationContext);
    const { t: titleT } = useT(ignoreTranslations ? undefined : title, titleTranslationContext);

    const myInputProps = useMemo(() => {
        return { ...inputProps, ...designerProps };
    }, [designerProps, inputProps]);

    const styles = useMemo(() => {
        let styles = propStyles;
        if (hasErr)
            styles = combineStyles(styles, {
                label: { ...theme.custom.textNotTranslated },
            });
        if ((designerData?.isSelected ?? false) && (designerData?.renderAsDesigner ?? false))
            styles = combineStyles(styles, { root: getSelectedComponentStyle(theme, true) });
        if (designerData?.renderAsDesigner ?? false)
            styles = combineStyles(styles, { root: getDesignerModeComponentStyle(theme) });
        return styles;
    }, [designerData?.isSelected, designerData?.renderAsDesigner, hasErr, propStyles, theme]);

    if (advhide === true && designerProps === undefined) return <></>;
    return (
        <Checkbox
            {...props}
            {...designerProps}
            styles={styles}
            inputProps={myInputProps}
            checked={value}
            label={ignoreTranslations ? label : t}
            title={ignoreTranslations ? title : titleT}
            onRenderLabel={
                info != undefined
                    ? AdvRenderLabelTextInput.bind(null, info, onRenderLabel)
                    : onRenderLabel
            }
        />
    );
};
const AdvCheckboxImpl = React.memo(AdvCheckboxImplComp, deepCompareJSXProps);

const AdvCheckboxSimple = ({ onValueChanged, ...props }: TAdvCheckboxProps) => {
    const handleChange = useAdvCallback(
        (ev?: any, checked?: boolean | undefined) => {
            if (typeof onValueChanged != "undefined") onValueChanged(checked, ev);
        },
        [onValueChanged],
    );
    return <AdvCheckboxImpl {...props} onChange={handleChange}></AdvCheckboxImpl>;
};

const AdvCheckboxComplexValueProvider = ({
    valueBindingParams,
    disabled,
    value,
    onValueChanged,
    dataArrayIndex = 0,
    ...props
}: TAdvCheckboxProps) => {
    const [isCurrentValueChecked, setCurrentValue, attributes] = useAdvValueBinderProvider(
        valueBindingParams,
        value,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );

    const handleChange = useAdvCallback(
        (ev?: any, checked?: boolean | undefined) => {
            let canSet = true;
            if (checked != undefined) canSet = setCurrentValue(checked);
            if (canSet && typeof onValueChanged != "undefined") onValueChanged(checked, ev);
        },
        [onValueChanged, setCurrentValue],
    );

    if (attributes.isVisible)
        return (
            <AdvCheckboxImpl
                {...props}
                valueBindingParams={valueBindingParams}
                disabled={(disabled ?? false) || !attributes.isEditable}
                value={isCurrentValueChecked}
                onChange={handleChange}
                dataArrayIndex={dataArrayIndex}
            ></AdvCheckboxImpl>
        );
    else return <></>;
};

const AdvCheckboxComplexValuePageVarOrUnknown = ({
    valueBindingParams,
    disabled,
    value,
    onValueChanged,
    dataArrayIndex = 0,
    ...props
}: TAdvCheckboxProps) => {
    const [isCurrentValueChecked, setCurrentValue, attributes] = useAdvValueBinderNoDataType(
        valueBindingParams,
        value,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );

    const handleChange = useAdvCallback(
        (ev?: any, checked?: boolean | undefined) => {
            let canSet = true;
            if (checked != undefined) canSet = setCurrentValue(checked);
            if (canSet && typeof onValueChanged != "undefined") onValueChanged(checked, ev);
        },
        [onValueChanged, setCurrentValue],
    );

    if (attributes.isVisible)
        return (
            <AdvCheckboxImpl
                {...props}
                valueBindingParams={valueBindingParams}
                disabled={(disabled ?? false) || !attributes.isEditable}
                value={isCurrentValueChecked}
                onChange={handleChange}
                dataArrayIndex={dataArrayIndex}
            ></AdvCheckboxImpl>
        );
    else return <></>;
};

const AdvCheckboxComplexValue = ({ valueBindingParams, ...props }: TAdvCheckboxProps) => {
    if (GetValueBindingType(valueBindingParams) == EAdvValueBinderType.BinderTypeDataProvider)
        return (
            <AdvCheckboxComplexValueProvider
                {...props}
                valueBindingParams={valueBindingParams}
            ></AdvCheckboxComplexValueProvider>
        );
    else
        return (
            <AdvCheckboxComplexValuePageVarOrUnknown
                {...props}
                valueBindingParams={valueBindingParams}
            ></AdvCheckboxComplexValuePageVarOrUnknown>
        );
};

const AdvCheckboxComplexLabel = ({
    label,
    valueBindingParams,
    labelBindingParams,
    onValueChanged,
    dataArrayIndex = 0,
    ...props
}: TAdvCheckboxProps) => {
    const [labelBindingText] = useAdvValueBinderNoDataType(
        labelBindingParams,
        label,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );

    const handleChange = useAdvCallback(
        (ev?: any, checked?: boolean | undefined) => {
            if (typeof onValueChanged != "undefined") onValueChanged(checked, ev);
        },
        [onValueChanged],
    );

    if (!IsValueBindingTrivial(valueBindingParams))
        return (
            <AdvCheckboxComplexValue
                {...props}
                valueBindingParams={valueBindingParams}
                labelBindingParams={labelBindingParams}
                label={labelBindingText}
                onValueChanged={onValueChanged}
                dataArrayIndex={dataArrayIndex}
            ></AdvCheckboxComplexValue>
        );
    else
        return (
            <AdvCheckboxImpl
                {...props}
                valueBindingParams={valueBindingParams}
                labelBindingParams={labelBindingParams}
                label={labelBindingText}
                onChange={handleChange}
                dataArrayIndex={dataArrayIndex}
            ></AdvCheckboxImpl>
        );
};

const AdvCheckboxComplexDisabledAndHide = ({
    disabledBindingParams,
    valueBindingParams,
    labelBindingParams,
    advhide,
    advhideBindingParams,
    disabled,
    onValueChanged,
    dataArrayIndex = 0,
    ...props
}: TAdvCheckboxProps) => {
    const [isDisabledValue] = useAdvValueBinderNoDataType(
        disabledBindingParams,
        disabled != undefined && disabled ? true : false,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );
    const [shouldHide] = useAdvValueBinderNoDataType(
        advhideBindingParams,
        advhide,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );

    const handleChange = useAdvCallback(
        (ev?: any, checked?: boolean | undefined) => {
            if (typeof onValueChanged != "undefined") onValueChanged(checked, ev);
        },
        [onValueChanged],
    );

    if (!IsValueBindingTrivial(labelBindingParams))
        return (
            <AdvCheckboxComplexLabel
                {...props}
                disabledBindingParams={disabledBindingParams}
                valueBindingParams={valueBindingParams}
                labelBindingParams={labelBindingParams}
                advhide={shouldHide}
                advhideBindingParams={advhideBindingParams}
                disabled={isDisabledValue}
                onValueChanged={onValueChanged}
                dataArrayIndex={dataArrayIndex}
            ></AdvCheckboxComplexLabel>
        );
    else if (!IsValueBindingTrivial(valueBindingParams))
        return (
            <AdvCheckboxComplexValue
                {...props}
                disabledBindingParams={disabledBindingParams}
                valueBindingParams={valueBindingParams}
                labelBindingParams={labelBindingParams}
                advhide={shouldHide}
                advhideBindingParams={advhideBindingParams}
                disabled={isDisabledValue}
                onValueChanged={onValueChanged}
                dataArrayIndex={dataArrayIndex}
            ></AdvCheckboxComplexValue>
        );
    return (
        <AdvCheckboxImpl
            {...props}
            disabledBindingParams={disabledBindingParams}
            valueBindingParams={valueBindingParams}
            labelBindingParams={labelBindingParams}
            advhide={shouldHide}
            advhideBindingParams={advhideBindingParams}
            disabled={isDisabledValue}
            onChange={handleChange}
            dataArrayIndex={dataArrayIndex}
        ></AdvCheckboxImpl>
    );
};

const AdvCheckboxComplex = ({
    disabledBindingParams,
    valueBindingParams,
    labelBindingParams,
    advhideBindingParams,
    ...props
}: TAdvCheckboxProps) => {
    if (
        !IsValueBindingTrivial(disabledBindingParams) ||
        !IsValueBindingTrivial(advhideBindingParams)
    )
        return (
            <AdvCheckboxComplexDisabledAndHide
                {...props}
                disabledBindingParams={disabledBindingParams}
                valueBindingParams={valueBindingParams}
                labelBindingParams={labelBindingParams}
                advhideBindingParams={advhideBindingParams}
            ></AdvCheckboxComplexDisabledAndHide>
        );
    else if (!IsValueBindingTrivial(labelBindingParams))
        return (
            <AdvCheckboxComplexLabel
                {...props}
                disabledBindingParams={disabledBindingParams}
                valueBindingParams={valueBindingParams}
                labelBindingParams={labelBindingParams}
                advhideBindingParams={advhideBindingParams}
            ></AdvCheckboxComplexLabel>
        );
    else
        return (
            <AdvCheckboxComplexValue
                {...props}
                disabledBindingParams={disabledBindingParams}
                valueBindingParams={valueBindingParams}
                labelBindingParams={labelBindingParams}
                advhideBindingParams={advhideBindingParams}
            ></AdvCheckboxComplexValue>
        );
};

/**
 * @summary Wrapper für ``Checkbox``
 * @link https://developer.microsoft.com/en-us/fluentui#/controls/web/checkbox
 */
const AdvCheckboxComp = ({
    disabledBindingParams,
    valueBindingParams,
    labelBindingParams,
    advhideBindingParams,
    ...props
}: TAdvCheckboxProps) => {
    if (
        IsValueBindingTrivial(disabledBindingParams) &&
        IsValueBindingTrivial(valueBindingParams) &&
        IsValueBindingTrivial(labelBindingParams) &&
        IsValueBindingTrivial(advhideBindingParams)
    )
        return (
            <AdvCheckboxSimple
                {...props}
                disabledBindingParams={disabledBindingParams}
                valueBindingParams={valueBindingParams}
                labelBindingParams={labelBindingParams}
                advhideBindingParams={advhideBindingParams}
            ></AdvCheckboxSimple>
        );
    else
        return (
            <AdvCheckboxComplex
                {...props}
                disabledBindingParams={disabledBindingParams}
                valueBindingParams={valueBindingParams}
                labelBindingParams={labelBindingParams}
                advhideBindingParams={advhideBindingParams}
            ></AdvCheckboxComplex>
        );
};

const AdvCheckbox = React.memo(AdvCheckboxComp, deepCompareJSXProps);
export default AdvCheckbox;

// export const getStyles = (props: TAdvCheckboxStyleProps): TAdvCheckboxStyles => { advlog("DP", props); return {}; };
// const StyledAdvCheckbox: React.FunctionComponent<TAdvCheckboxProps> = styled<
//     TAdvCheckboxProps,
//     TAdvCheckboxStyleProps,
//     TAdvCheckboxStyles
// >(AdvCheckbox, getStyles, undefined, { scope: 'AdvCheckbox' });

// export { StyledAdvCheckbox as default };

registerDesignableComponent({
    staticData: {
        name: LAN.CHECKBOX.text,
        translationContext: LAN.CHECKBOX.context,
        type: EComponentTypeInput.Checkbox,
        supportsChildren: false,
        category: DefaultComponentCategory.Input,
        icon: CheckboxIcon,
    },
    properties: [
        AdvProperty.Text.createSuggestion(
            toAdvText(LAN.LABEL),
            "label",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.CHECKBOX_LABEL_DESCR),
            "Checkbox-Label",
        ),
        AdvProperty.Text.create(
            toAdvText(LAN.TRANSLATION_CONTEXT),
            "translationContext",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.TRANSLATION_CONTEXT_DESCR),
            "",
            false,
        ),
        AdvProperty.Boolean.create(
            toAdvText(LAN.CHECKED),
            "value",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.CHECKBOX_VALUE_DESCR),
            false,
        ),
        AdvProperty.Boolean.create(
            toAdvText(LAN.DISABLED),
            "disabled",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.CHECKBOX_DISABLED_DESCR),
            false,
        ),
        ...AdvCommonComponentAttributes,
        ...AdvThemeProviderProperties,
        ...AdvStackItemDesignable.CommonProperties,
        ...AdvGridItemDesignable.CommonProperties,
    ],
    propertiesBuilders: [],
    presets: [],
});
