import {
    buildUniqueContractID,
    gActiveContracts,
    getContractFieldValuesSelector,
} from "@components/dynamic/contracts/contracts";
import {
    EFieldSettingsFieldDomain,
    EFieldSettingsFieldTypes,
    IFieldSettings,
} from "@components/dynamic/data-provider/types";
import { DateToStr, TAdvDatePickerValueFormat } from "@components/inputs/datepicker/utils";
import { recoilDataProvider } from "@data/dataprovider";
import {
    useDataproviderFieldsDataRecords,
    useDataproviderID,
    useDataproviderIsEOF,
    useDataproviderIsEditable,
    useDataproviderIsLoaded,
    useDataproviderLifetimeID,
    useDataproviderSetCurrentRecords,
    useDataproviderSetEditData,
} from "@data/dataprovider/data-provider-client";
import { buildUniqueProviderID } from "@data/dataprovider/data-provider-server";
import { replaceDatasourcePrefix } from "@data/designer/file";
import {
    buildPageIDForVariableID,
    recoilPageVariables,
    recoilParameterListOfCurrentPage,
} from "@data/parameters";
import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import {
    TAdvTransactionInterface,
    useAdvRecoilTransaction,
} from "@hooks/recoil-overload/useAdvRecoilTransaction";
import useAdvRecoilValue from "@hooks/recoil-overload/useAdvRecoilValue";
import { useAdvMemoWithUpdater } from "@hooks/useAdvMemoWithUpdater";
import deepCopy from "@utils/deep-copy";
import { advlog } from "@utils/logging";
import { convertToTypeOfVal } from "@utils/to-type";
import { useRouter } from "next/router";
import { useMemo } from "react";
import { EAdvValueDataTypes } from "../../utils/data-types";

export enum EAdvValueBinderType {
    // Bindet einen vom user gewünschten Wert, diese Eigenschaft kann genutzt werden, um keinen besonderen Wert zu binden
    BinderTypeUser = "None", // the name & value of this field is used in the server code, in case of rename, also do it in the server code
    // Bindet einen Page-Variable
    // Benutze bindingParams["getPageVar"] für den Variable-Namen
    BinderTypePageVariable = "PageVar",
    // Bindet den Wert eines Dataproviders
    // Benutze bindingParams["provider"] für Namen/Schlüssel des Dataproviders
    // Benutze bindingParams["providerField"] um das Feld des Dataproviders festzulegen
    BinderTypeDataProvider = "Prov",
    // Bindet den Wert eines Contracts
    // Benutze bindingParams["contract"] für Namen/Schlüssel des Contracts
    // Benutze bindingParams["contractField"] um das Feld des Contracts festzulegen
    BinderTypeContract = "Contract",
    BinderTypeDynamic = "Dynamic",
}

export enum EAdvValueBinderDynamicTypes {
    CurrentDate = "CurrentDate",
}

export enum EAdvValueBinderLogicType {
    // plain value
    Value = 0,
    IsDefined,
    IsUndefined,
    EqualsValue,
    NotEqualsValue,
    // has either a value or gets the default value provided
    ValueOr,
    GreaterThan,
    LessThan,
    ValueFormat,
    ValueIfPageParameterExist,
}

// the name of these fields are used in the server code, in case of rename, also do it in the server code
export type TAdvValueBindingParams = {
    bindingTypeName: string | EAdvValueBinderType;
    bindingParams: Record<string, { value: string; options: Record<string, any> }>;
    bindingLogic: string | EAdvValueBinderLogicType;
    bindingLogicValue: string | undefined;
    bindingUndefinedFallback?: string;
};

export const AdvValueBindingDefaultValue: TAdvValueBindingParams = {
    bindingTypeName: EAdvValueBinderType.BinderTypeUser,
    bindingParams: {},
    bindingLogic: EAdvValueBinderLogicType.Value,
    bindingLogicValue: "",
};

export const AdvValueBinderStringToEnum = (binderStr: string | EAdvValueBinderType) => {
    if (typeof binderStr == "string") {
        const index = Object.values(EAdvValueBinderType).findIndex((val) => val == binderStr);
        return index == -1
            ? EAdvValueBinderType.BinderTypeUser
            : (binderStr as EAdvValueBinderType);
    } else {
        return binderStr;
    }
};

export const AdvValueBinderLogicStringToEnum = (binderStr: string | EAdvValueBinderLogicType) => {
    if (typeof binderStr == "string") {
        const index = Object.values(EAdvValueBinderLogicType).findIndex((val) => val === binderStr);
        return index == -1 ? EAdvValueBinderLogicType.Value : (index as EAdvValueBinderLogicType);
    } else {
        return binderStr;
    }
};

export const IsValueBindingTrivial = (params: TAdvValueBindingParams | undefined) => {
    return (
        params == undefined ||
        AdvValueBinderStringToEnum(params.bindingTypeName) == EAdvValueBinderType.BinderTypeUser
    );
};

export const GetValueBindingType = (params: TAdvValueBindingParams | undefined) => {
    return params == undefined
        ? EAdvValueBinderType.BinderTypeUser
        : AdvValueBinderStringToEnum(params.bindingTypeName);
};

const assignParamIfNotExists = (webActionParam: TAdvValueBindingParams, name: string) => {
    if (!Object.keys(webActionParam.bindingParams).includes(name))
        Object.assign(webActionParam.bindingParams, {
            ...webActionParam.bindingParams,
            [name]: { value: "", options: {} },
        });
};

export const defaultBindingParametersPerType = (bindingParam: TAdvValueBindingParams) => {
    bindingParam.bindingParams = {};
    const webActionType =
        typeof bindingParam.bindingTypeName == "string"
            ? AdvValueBinderStringToEnum(bindingParam.bindingTypeName)
            : bindingParam.bindingTypeName;
    switch (webActionType) {
        case EAdvValueBinderType.BinderTypeUser:
            break;
        case EAdvValueBinderType.BinderTypePageVariable:
            assignParamIfNotExists(bindingParam, "getPageVar");
            break;
        case EAdvValueBinderType.BinderTypeDataProvider:
            assignParamIfNotExists(bindingParam, "provider");
            assignParamIfNotExists(bindingParam, "providerField");
            break;
        case EAdvValueBinderType.BinderTypeContract:
            assignParamIfNotExists(bindingParam, "contract");
            assignParamIfNotExists(bindingParam, "contractField");
        case EAdvValueBinderType.BinderTypeDynamic:
            assignParamIfNotExists(bindingParam, "dynamic");
            break;
    }
};

export const isBindingAValue = (bindingParams: TAdvValueBindingParams | undefined) => {
    return (
        bindingParams != undefined &&
        ((typeof bindingParams.bindingTypeName == "string" &&
            EAdvValueBinderType.BinderTypeUser !=
                AdvValueBinderStringToEnum(bindingParams.bindingTypeName)) ||
            (typeof bindingParams.bindingTypeName != "string" &&
                EAdvValueBinderType.BinderTypeUser != bindingParams.bindingTypeName))
    );
};

export enum EValueBindingMode {
    SingleRecordOrData = 0,
    MultiRecord,
    AllData,
}

/**
 * The value binder can expose additional attributes
 * which are useful for some users
 */
export type TAdvValueBinderAttributes = {
    isEditable: boolean;
    usesBoundValue: boolean;

    isVisible: boolean;
    isLoading: boolean;
    isLoaded: boolean;

    isMultiLangField: boolean;
    fieldType: EFieldSettingsFieldTypes;
    len: number;
};

const constArrayDummy: string[] = [];

const useBindingTypeLocal = (bindings: TAdvValueBindingParams | undefined) => {
    const bindingType = useMemo(
        () =>
            bindings != undefined
                ? typeof bindings.bindingTypeName == "string"
                    ? AdvValueBinderStringToEnum(bindings.bindingTypeName)
                    : (bindings.bindingTypeName as EAdvValueBinderType)
                : EAdvValueBinderType.BinderTypeUser,
        [bindings],
    );
    const shouldUseBoundValue = useMemo(
        () => bindingType != EAdvValueBinderType.BinderTypeUser,
        [bindingType],
    );
    const paramKeys = useMemo(() => {
        return bindings && bindings.bindingParams != undefined
            ? Object.keys(bindings.bindingParams)
            : constArrayDummy;
    }, [bindings]);
    return { bindingType, shouldUseBoundValue, paramKeys };
};

export type TAdvValueReturnType<T> = {
    val: T;
    dataType: EAdvValueDataTypes;
};

const useBindingFuncsLocal = <T>(
    bindings: TAdvValueBindingParams | undefined,
    userValues: T[],
    expectedDataType: EAdvValueDataTypes,
) => {
    const stringMsgToInternalType = useAdvCallback(
        (newVal: string): { canUpdate: boolean; newVal: T[] } => {
            if (typeof userValues[0] == "string") {
                return { canUpdate: true, newVal: [newVal as unknown as T] };
            } else {
                advlog("Tried to set info text: " + newVal);
                return { canUpdate: false, newVal: [] };
            }
        },
        [userValues],
    );

    const logicAllowsUndefined = useAdvCallback(() => {
        if (bindings?.bindingLogic != undefined) {
            const logicEnum = AdvValueBinderLogicStringToEnum(bindings.bindingLogic);
            switch (logicEnum) {
                case EAdvValueBinderLogicType.IsDefined:
                case EAdvValueBinderLogicType.IsUndefined:
                case EAdvValueBinderLogicType.ValueOr:
                    return true;
            }
        }
        return false;
    }, [bindings?.bindingLogic]);

    const { query } = useRouter();

    const convertValuesWithLogic = useAdvCallback(
        function <TTarget>(value: any): TTarget {
            // logic
            let checkVal = value;

            if (bindings?.bindingLogic != undefined && bindings?.bindingLogicValue != undefined) {
                const logicEnum = AdvValueBinderLogicStringToEnum(bindings.bindingLogic);
                if (logicEnum == EAdvValueBinderLogicType.IsDefined) {
                    if (typeof value != "undefined") checkVal = true;
                    else checkVal = false;
                } else if (logicEnum == EAdvValueBinderLogicType.IsUndefined) {
                    if (typeof value != "undefined") checkVal = false;
                    else checkVal = true;
                } else if (
                    [
                        EAdvValueBinderLogicType.EqualsValue,
                        EAdvValueBinderLogicType.NotEqualsValue,
                    ].includes(logicEnum)
                ) {
                    checkVal = false;
                    let compVal = typeof value == "string" ? value : JSON.stringify(value);
                    if (
                        (compVal == undefined || (typeof compVal == "string" && compVal == "")) &&
                        bindings?.bindingUndefinedFallback != undefined
                    )
                        compVal = bindings?.bindingUndefinedFallback;

                    if (
                        logicEnum == EAdvValueBinderLogicType.EqualsValue &&
                        compVal === bindings.bindingLogicValue
                    )
                        checkVal = true;
                    else if (
                        logicEnum == EAdvValueBinderLogicType.NotEqualsValue &&
                        compVal !== bindings.bindingLogicValue
                    )
                        checkVal = true;
                } else if ([EAdvValueBinderLogicType.ValueOr].includes(logicEnum)) {
                    if (typeof checkVal == "undefined") checkVal = bindings.bindingLogicValue;
                } else if (
                    [
                        EAdvValueBinderLogicType.GreaterThan,
                        EAdvValueBinderLogicType.LessThan,
                    ].includes(logicEnum)
                ) {
                    const isNumeric =
                        isNaN(Number(value)) == false &&
                        isNaN(Number(bindings.bindingLogicValue)) == false;
                    const myValue: number | string = isNumeric ? Number(value) : String(value);
                    const myBindingValue: number | string = isNumeric
                        ? Number(bindings.bindingLogicValue)
                        : String(bindings.bindingLogicValue);
                    if (logicEnum == EAdvValueBinderLogicType.GreaterThan)
                        checkVal = myValue > myBindingValue;
                    else if (logicEnum == EAdvValueBinderLogicType.LessThan)
                        checkVal = myValue < myBindingValue;
                } else if (logicEnum == EAdvValueBinderLogicType.ValueFormat) {
                    checkVal = bindings.bindingLogicValue?.replace("{0}", value) ?? value;
                } else if (logicEnum == EAdvValueBinderLogicType.ValueIfPageParameterExist) {
                    checkVal = Object.keys(query).includes(bindings.bindingLogicValue)
                        ? checkVal
                        : undefined;
                }
            }

            // convert
            return convertToTypeOfVal(checkVal, expectedDataType);
        },
        [
            bindings?.bindingLogic,
            bindings?.bindingLogicValue,
            bindings?.bindingUndefinedFallback,
            expectedDataType,
            query,
        ],
    );

    return {
        stringMsgToInternalType,
        logicAllowsUndefined,
        convertValuesWithLogic,
    };
};

const useAdvValueBinderDynamicImpl = function <T>(
    bindings: TAdvValueBindingParams | undefined,
    userValues: T[],
    expectedDataType: EAdvValueDataTypes,
): [
    TAdvValueReturnType<T>[],
    (
        setValues: (
            newVal:
                | TAdvValueReturnType<T>[]
                | ((old: TAdvValueReturnType<T>[]) => TAdvValueReturnType<T>[]),
        ) => void,
        newVal: T[],
    ) => boolean,
    TAdvValueBinderAttributes,
] {
    const { shouldUseBoundValue, paramKeys } = useBindingTypeLocal(bindings);

    const values = useMemo<TNewPageVarVal<T>>(() => {
        let foundValue: T = "" as T;
        if (bindings != undefined) {
            const realBindType =
                typeof bindings.bindingTypeName == "string"
                    ? AdvValueBinderStringToEnum(bindings.bindingTypeName)
                    : (bindings.bindingTypeName as EAdvValueBinderType);
            if (realBindType == EAdvValueBinderType.BinderTypeUser) {
                return {
                    values: userValues.map<TAdvValueReturnType<T>>((v) => {
                        return {
                            val: convertToTypeOfVal<T>(v, expectedDataType),
                            dataType: expectedDataType,
                        };
                    }),
                    isLoaded: true,
                };
            } else if (realBindType == EAdvValueBinderType.BinderTypeDynamic) {
                if (paramKeys.includes("dynamic")) {
                    const dynamicType: EAdvValueBinderDynamicTypes = bindings.bindingParams[
                        "dynamic"
                    ].value as EAdvValueBinderDynamicTypes;

                    if (dynamicType == EAdvValueBinderDynamicTypes.CurrentDate) {
                        foundValue = convertToTypeOfVal<T>(
                            DateToStr(TAdvDatePickerValueFormat.SQL, new Date()),
                            expectedDataType,
                        );
                    }
                }
            }
        }

        return {
            values: [
                {
                    val: foundValue,
                    dataType: expectedDataType,
                },
            ],
            isLoaded: true,
        };
    }, [bindings, expectedDataType, paramKeys, userValues]);

    const isEditable = true;

    /**
     * @param newVal the new value, that should be tried to be set
     * @returns true, if the value was changed
     */
    const setValueWrapper = useAdvCallback(
        (
            setValues: (
                newVal:
                    | TAdvValueReturnType<T>[]
                    | ((old: TAdvValueReturnType<T>[]) => TAdvValueReturnType<T>[]),
            ) => void,
            newVal: T[],
        ): boolean => {
            if (!isEditable) return false;

            setValues(
                newVal.map((v) => {
                    return {
                        val: convertToTypeOfVal<T>(v, expectedDataType),
                        dataType: expectedDataType,
                    };
                }),
            );
            return true;
        },
        [expectedDataType, isEditable],
    );

    const attributes = useMemo<TAdvValueBinderAttributes>(() => {
        const res: TAdvValueBinderAttributes = {
            isEditable: isEditable,
            usesBoundValue: shouldUseBoundValue,

            isVisible: true,
            isLoading: false,
            isLoaded: values.isLoaded,

            isMultiLangField: false,
            fieldType: EFieldSettingsFieldTypes.default,
            len: 0,
        };
        return res;
    }, [isEditable, shouldUseBoundValue, values.isLoaded]);

    // it's recommended to check setValueWrapper's return type
    return [values.values, setValueWrapper, attributes];
};

type TNewPageVarVal<T> = {
    values: TAdvValueReturnType<T>[];
    isLoaded: boolean;
};
const useAdvValueBinderPageVarImpl = function <T>(
    bindings: TAdvValueBindingParams | undefined,
    userValues: T[],
    expectedDataType: EAdvValueDataTypes,
    bindingMode: EValueBindingMode,
    doValueCheck: boolean,
    dataArrayIndex: number,
    allowUndefined?: boolean,
): [
    TAdvValueReturnType<T>[],
    (
        setValues: (
            newVal:
                | TAdvValueReturnType<T>[]
                | ((old: TAdvValueReturnType<T>[]) => TAdvValueReturnType<T>[]),
        ) => void,
        newVal: T[],
    ) => boolean,
    TAdvValueBinderAttributes,
] {
    const { query, pathname } = useRouter();

    const { bindingType, shouldUseBoundValue, paramKeys } = useBindingTypeLocal(bindings);

    // page variable related
    const variableID = useMemo(() => buildPageIDForVariableID(pathname, query), [pathname, query]);
    const pageParameters = useAdvRecoilValue(recoilParameterListOfCurrentPage(variableID));
    // page variable related end

    const { stringMsgToInternalType, logicAllowsUndefined, convertValuesWithLogic } =
        useBindingFuncsLocal(bindings, userValues, expectedDataType);

    const values = useMemo<TNewPageVarVal<T>>(() => {
        if (bindings != undefined) {
            const realBindType =
                typeof bindings.bindingTypeName == "string"
                    ? AdvValueBinderStringToEnum(bindings.bindingTypeName)
                    : (bindings.bindingTypeName as EAdvValueBinderType);
            if (realBindType == EAdvValueBinderType.BinderTypeUser) {
                return {
                    values: userValues.map<TAdvValueReturnType<T>>((v) => {
                        return {
                            val: convertToTypeOfVal<T>(v, expectedDataType),
                            dataType: expectedDataType,
                        };
                    }),
                    isLoaded: true,
                };
            } else if (realBindType == EAdvValueBinderType.BinderTypePageVariable) {
                if (paramKeys.includes("getPageVar")) {
                    const pageParamName = bindings.bindingParams["getPageVar"].value;

                    if (pageParameters.has(pageParamName)) {
                        const routerParams = pageParameters.get(pageParamName);
                        let isError = 0;
                        if (
                            routerParams != undefined &&
                            (bindingMode == EValueBindingMode.AllData ||
                                bindingMode == EValueBindingMode.MultiRecord)
                        ) {
                            const paramVal = routerParams.val;
                            if (typeof paramVal == "string") {
                                const converted = convertValuesWithLogic<T>(paramVal);
                                const logicEnum = AdvValueBinderLogicStringToEnum(
                                    bindings.bindingLogic,
                                );
                                let dataType = expectedDataType;
                                if (
                                    expectedDataType == EAdvValueDataTypes.Any &&
                                    logicEnum == EAdvValueBinderLogicType.Value
                                )
                                    dataType = routerParams.dataType;
                                return {
                                    values: [
                                        {
                                            val: converted,
                                            dataType: dataType,
                                        },
                                    ],
                                    isLoaded: true,
                                };
                            } else if (typeof routerParams.val == "undefined") {
                                isError = routerParams.isRequired ? 2 : 1;
                            } else if (Array.isArray(paramVal)) {
                                const valCheck: string[] = [];
                                paramVal.forEach((val) => {
                                    if (
                                        (!doValueCheck && typeof val == "string") ||
                                        (typeof val == "string" && !valCheck.includes(val))
                                    )
                                        valCheck.push(val);
                                });
                                return {
                                    values: valCheck.map<TAdvValueReturnType<T>>((val) => {
                                        const converted = convertValuesWithLogic<T>(val);
                                        return {
                                            val: converted,
                                            dataType: expectedDataType,
                                        };
                                    }),
                                    isLoaded: true,
                                };
                            }
                        } else if (routerParams != undefined) {
                            if (typeof routerParams.val == "undefined") {
                                isError = routerParams.isRequired ? 2 : 1;
                            } else {
                                const dataIndex = Math.min(
                                    routerParams.val.length - 1,
                                    dataArrayIndex,
                                );
                                const routerSingle = Array.isArray(routerParams.val)
                                    ? routerParams.val[dataIndex]
                                    : routerParams.val;
                                return {
                                    values: [
                                        {
                                            val: convertValuesWithLogic<T>(routerSingle),
                                            dataType: expectedDataType,
                                        },
                                    ],
                                    isLoaded: true,
                                };
                            }
                        }

                        if (isError != 0) {
                            if (isError == 1) {
                                if (logicAllowsUndefined() || allowUndefined == true) {
                                    return {
                                        values: [
                                            {
                                                val: convertValuesWithLogic<T>(undefined),
                                                dataType: expectedDataType,
                                            },
                                        ],
                                        isLoaded: false,
                                    };
                                } else {
                                    const updateVal = stringMsgToInternalType(
                                        "Page variable '" +
                                            pageParamName +
                                            "' has no value (undefined)",
                                    );
                                    if (updateVal.canUpdate)
                                        return {
                                            values: updateVal.newVal.map((v) => {
                                                return {
                                                    val: v,
                                                    dataType: expectedDataType,
                                                };
                                            }),
                                            isLoaded: false,
                                        };
                                }
                            } else if (isError == 2) {
                                const updateVal = stringMsgToInternalType(
                                    "Fatal error: page variable '" +
                                        pageParamName +
                                        "' has no value, but is required to have one.",
                                );
                                if (updateVal.canUpdate)
                                    return {
                                        values: updateVal.newVal.map((v) => {
                                            return { val: v, dataType: expectedDataType };
                                        }),
                                        isLoaded: false,
                                    };
                            }
                        }
                    } else {
                        const updateVal = stringMsgToInternalType(
                            "Page variable '" + pageParamName + "' does not exists",
                        );
                        if (updateVal.canUpdate)
                            return {
                                values: updateVal.newVal.map((v) => {
                                    return { val: v, dataType: expectedDataType };
                                }),
                                isLoaded: false,
                            };
                    }
                }
                return {
                    values: userValues.map<TAdvValueReturnType<T>>((v) => {
                        return { val: convertValuesWithLogic<T>(v), dataType: expectedDataType };
                    }),
                    isLoaded: true,
                };
            }
        }
        return {
            values: userValues.map<TAdvValueReturnType<T>>((v) => {
                return {
                    val: convertToTypeOfVal<T>(v, expectedDataType),
                    dataType: expectedDataType,
                };
            }),
            isLoaded: true,
        };
    }, [
        allowUndefined,
        bindingMode,
        bindings,
        convertValuesWithLogic,
        dataArrayIndex,
        doValueCheck,
        expectedDataType,
        logicAllowsUndefined,
        pageParameters,
        paramKeys,
        stringMsgToInternalType,
        userValues,
    ]);

    const isEditable = useMemo(() => {
        if ([EAdvValueBinderType.BinderTypePageVariable].includes(bindingType)) {
            return true;
        }
        return true;
    }, [bindingType]);

    const setValuePageParamTrans = useAdvCallback(
        (tb: TAdvTransactionInterface) => (value: string | string[]) => {
            if (bindings != undefined) {
                if (paramKeys.includes("getPageVar")) {
                    const pageParamValues = deepCopy(tb.get(recoilPageVariables(variableID)));
                    const pageParamName = bindings.bindingParams["getPageVar"].value;
                    const valueFound = pageParamValues.find(
                        (val) => val.pageParamName == pageParamName,
                    );
                    const convertedValue = value;
                    if (valueFound == undefined)
                        pageParamValues.push({
                            pageParamName: pageParamName,
                            value: convertedValue,
                            dataType: expectedDataType,
                        });
                    else {
                        valueFound.value = convertedValue;
                        valueFound.dataType = expectedDataType;
                    }
                    tb.set(recoilPageVariables(variableID), pageParamValues);
                }
            }
        },
        [bindings, expectedDataType, paramKeys, variableID],
    );
    const setValuePageParam = useAdvRecoilTransaction(setValuePageParamTrans, [
        setValuePageParamTrans,
    ]);

    /**
     * @param newVal the new value, that should be tried to be set
     * @returns true, if the value was changed
     */
    const setValueWrapper = useAdvCallback(
        (
            setValues: (
                newVal:
                    | TAdvValueReturnType<T>[]
                    | ((old: TAdvValueReturnType<T>[]) => TAdvValueReturnType<T>[]),
            ) => void,
            newVal: T[],
        ): boolean => {
            if (!isEditable) return false;
            if ([EAdvValueBinderType.BinderTypePageVariable].includes(bindingType)) {
                if (bindingMode == EValueBindingMode.SingleRecordOrData) {
                    setValuePageParam(newVal[0] as string);
                } else if (
                    bindingMode == EValueBindingMode.MultiRecord ||
                    bindingMode == EValueBindingMode.AllData
                ) {
                    setValuePageParam(newVal as string[]);
                }
            }
            setValues(
                newVal.map((v) => {
                    return {
                        val: convertToTypeOfVal<T>(v, expectedDataType),
                        dataType: expectedDataType,
                    };
                }),
            );
            return true;
        },
        [bindingMode, bindingType, expectedDataType, isEditable, setValuePageParam],
    );

    const attributes = useMemo<TAdvValueBinderAttributes>(() => {
        const res: TAdvValueBinderAttributes = {
            isEditable: isEditable,
            usesBoundValue: shouldUseBoundValue,

            isVisible: true,
            isLoading: false,
            isLoaded: values.isLoaded,

            isMultiLangField: false,
            fieldType: EFieldSettingsFieldTypes.default,
            len: 0,
        };
        return res;
    }, [isEditable, shouldUseBoundValue, values.isLoaded]);

    // it's recommended to check setValueWrapper's return type
    return [values.values, setValueWrapper, attributes];
};

const gDefaultFieldSettings: IFieldSettings = {
    FieldType: EFieldSettingsFieldTypes.default,
    IsMultiLangField: false,
    Len: 0,
    FieldDomain: EFieldSettingsFieldDomain.unknown,
};
type TNewProviderVal<T> = {
    values: TAdvValueReturnType<T>[];
    fieldSetting: IFieldSettings;
    isVisible: boolean;
    isLoading: boolean;
    isLoaded: boolean;
};
const useAdvValueBinderProviderImpl = function <T>(
    bindings: TAdvValueBindingParams | undefined,
    userValues: T[],
    expectedDataType: EAdvValueDataTypes,
    bindingMode: EValueBindingMode,
    doValueCheck: boolean,
    dataArrayIndex: number,
): [
    TAdvValueReturnType<T>[],
    (
        setterFunc: (
            newVal:
                | TAdvValueReturnType<T>[]
                | ((old: TAdvValueReturnType<T>[]) => TAdvValueReturnType<T>[]),
        ) => void,
        newVal: T[],
    ) => boolean,
    TAdvValueBinderAttributes,
] {
    const { bindingType, shouldUseBoundValue, paramKeys } = useBindingTypeLocal(bindings);

    // usually used dataprovider
    const dataProviderName = useMemo(() => {
        if (
            bindings == undefined ||
            bindings.bindingParams == undefined ||
            !paramKeys.includes("provider")
        )
            return "";
        return [EAdvValueBinderType.BinderTypeDataProvider].includes(bindingType)
            ? bindings.bindingParams["provider"].value
            : "";
    }, [bindingType, bindings, paramKeys]);

    const providerField = useMemo(() => {
        if (
            bindings == undefined ||
            bindings.bindingParams == undefined ||
            !paramKeys.includes("providerField")
        )
            return "";
        return [EAdvValueBinderType.BinderTypeDataProvider].includes(bindingType)
            ? bindings.bindingParams["providerField"].value
            : "";
    }, [bindingType, bindings, paramKeys]);

    const { providerID, query, pathname } = useDataproviderID(dataProviderName);
    const providerLifetimeID = useDataproviderLifetimeID(providerID);
    const dpIsLoaded = useDataproviderIsLoaded(providerID);
    const dpIsEOF = useDataproviderIsEOF(providerID);
    const dpIsEditable = useDataproviderIsEditable(providerID);
    const fieldsDataRecords = useDataproviderFieldsDataRecords(
        providerID,
        pathname,
        query,
        dataProviderName,
        providerLifetimeID,
        bindingMode == EValueBindingMode.AllData,
    );
    const dpSetEditData = useDataproviderSetEditData(pathname, query, dataProviderName);
    const dpSetCurrentRecords = useDataproviderSetCurrentRecords(
        pathname,
        query,
        providerID,
        dataProviderName,
        providerLifetimeID,
    );

    const isProviderLoaded = useMemo(() => {
        return dpIsLoaded();
    }, [dpIsLoaded]);
    const providerFields = useMemo(() => {
        return isProviderLoaded ? fieldsDataRecords?.fields : undefined;
    }, [fieldsDataRecords?.fields, isProviderLoaded]);
    const providerData = useMemo(() => {
        return isProviderLoaded ? fieldsDataRecords?.data : undefined;
    }, [fieldsDataRecords?.data, isProviderLoaded]);
    const currentRecords = useMemo(() => {
        return isProviderLoaded ? fieldsDataRecords?.curRecords : undefined;
    }, [fieldsDataRecords?.curRecords, isProviderLoaded]);
    // dataprovider stuff end

    const { stringMsgToInternalType, convertValuesWithLogic, logicAllowsUndefined } =
        useBindingFuncsLocal(bindings, userValues, expectedDataType);

    const valuesAndFieldSettings = useMemo<TNewProviderVal<T>>(() => {
        if (bindings != undefined) {
            const realBindType =
                typeof bindings.bindingTypeName == "string"
                    ? AdvValueBinderStringToEnum(bindings.bindingTypeName)
                    : (bindings.bindingTypeName as EAdvValueBinderType);
            if (realBindType == EAdvValueBinderType.BinderTypeUser) {
                return {
                    values: userValues.map((v) => {
                        return {
                            val: convertToTypeOfVal<T>(v, expectedDataType),
                            dataType: expectedDataType,
                        };
                    }),
                    fieldSetting: gDefaultFieldSettings,
                    isVisible: true,
                    isLoading: false,
                    isLoaded: true,
                };
            } else if (
                realBindType == EAdvValueBinderType.BinderTypeDataProvider &&
                paramKeys.includes("providerField")
            ) {
                if (!isProviderLoaded) {
                    const updateVal = stringMsgToInternalType("Loading...");
                    if (updateVal.canUpdate)
                        return {
                            values: updateVal.newVal.map((v) => {
                                return {
                                    val: v,
                                    dataType: expectedDataType,
                                };
                            }),
                            fieldSetting: gDefaultFieldSettings,
                            isVisible: true,
                            isLoading: true,
                            isLoaded: false,
                        };
                } else {
                    if (
                        currentRecords != undefined &&
                        providerData != undefined &&
                        providerFields != undefined
                    ) {
                        if (Object.keys(providerFields).indexOf(providerField) != -1) {
                            const fieldSettings = providerFields[providerField].Settings;
                            if (bindingMode == EValueBindingMode.SingleRecordOrData) {
                                if (currentRecords.length == 0) {
                                    if (!logicAllowsUndefined()) {
                                        const updateVal = stringMsgToInternalType(
                                            "No data was selected/loaded",
                                        );
                                        if (updateVal.canUpdate)
                                            return {
                                                values: updateVal.newVal.map((v) => {
                                                    return {
                                                        val: v,
                                                        dataType: expectedDataType,
                                                    };
                                                }),
                                                fieldSetting: fieldSettings,
                                                isVisible: true,
                                                isLoading: false,
                                                isLoaded: false,
                                            };
                                    } else {
                                        return {
                                            values: [
                                                {
                                                    val: convertValuesWithLogic<T>(undefined),
                                                    dataType: expectedDataType,
                                                },
                                            ],
                                            fieldSetting: fieldSettings,
                                            isVisible: true,
                                            isLoading: false,
                                            isLoaded: true,
                                        };
                                    }
                                } else {
                                    const isVisible =
                                        typeof currentRecords[
                                            Math.min(currentRecords.length - 1, dataArrayIndex)
                                        ][providerField] != "undefined";
                                    return {
                                        values: (isVisible
                                            ? [
                                                  convertValuesWithLogic<T>(
                                                      currentRecords[
                                                          Math.min(
                                                              currentRecords.length - 1,
                                                              dataArrayIndex,
                                                          )
                                                      ][providerField],
                                                  ),
                                              ]
                                            : []
                                        ).map((v) => {
                                            return {
                                                val: v,
                                                dataType: expectedDataType,
                                            };
                                        }),
                                        fieldSetting: fieldSettings,
                                        isVisible,
                                        isLoading: false,
                                        isLoaded: true,
                                    };
                                }
                            } else {
                                if (bindingMode == EValueBindingMode.AllData) {
                                    const valCheck: any[] = [];
                                    const isVisible =
                                        Object.keys(providerData).find(
                                            (provKey) => providerData[provKey].Values.length > 0,
                                        ) == undefined ||
                                        providerData[providerField].Values.length > 0;
                                    providerData[providerField].Values.forEach((val) => {
                                        if (!doValueCheck || !valCheck.includes(val))
                                            valCheck.push(val);
                                    });
                                    return {
                                        values: valCheck
                                            .map((val) => {
                                                return val as T;
                                            })
                                            .map((v) => {
                                                return {
                                                    val: convertToTypeOfVal<T>(v, expectedDataType),
                                                    dataType: expectedDataType,
                                                };
                                            }),
                                        fieldSetting: fieldSettings,
                                        isVisible,
                                        isLoading: false,
                                        isLoaded: true,
                                    };
                                } else if (bindingMode == EValueBindingMode.MultiRecord) {
                                    const values = currentRecords
                                        .filter(
                                            (val) =>
                                                val[providerField] != undefined ||
                                                typeof val[providerField] == typeof userValues[0],
                                        )
                                        .map((val) =>
                                            convertValuesWithLogic<T>(val[providerField]),
                                        );
                                    const isVisible =
                                        values.length == 0 ||
                                        values.find(
                                            (val) =>
                                                val != undefined ||
                                                typeof val == typeof userValues[0],
                                        ) != undefined;
                                    return {
                                        values: values.map((v) => {
                                            return {
                                                val: v,
                                                dataType: expectedDataType,
                                            };
                                        }),
                                        fieldSetting: fieldSettings,
                                        isVisible,
                                        isLoading: false,
                                        isLoaded: true,
                                    };
                                }
                            }
                        } else {
                            const updateVal = stringMsgToInternalType(
                                "Unknown field: '" +
                                    providerField +
                                    "' in provider fields: " +
                                    JSON.stringify(Object.keys(providerFields)) +
                                    " selected value: " +
                                    JSON.stringify(currentRecords.map((val) => Object.keys(val))),
                            );
                            if (updateVal.canUpdate)
                                return {
                                    values: updateVal.newVal.map((v) => {
                                        return {
                                            val: v,
                                            dataType: expectedDataType,
                                        };
                                    }),
                                    fieldSetting: gDefaultFieldSettings,
                                    isVisible: true,
                                    isLoading: false,
                                    isLoaded: false,
                                };
                        }
                    } else {
                        if (!logicAllowsUndefined()) {
                            const updateVal = dpIsEOF.isEOF
                                ? stringMsgToInternalType("No data was selected/loaded")
                                : stringMsgToInternalType("Loading...");
                            if (updateVal.canUpdate)
                                return {
                                    values: updateVal.newVal.map((v) => {
                                        return {
                                            val: v,
                                            dataType: expectedDataType,
                                        };
                                    }),
                                    fieldSetting: gDefaultFieldSettings,
                                    isVisible: true,
                                    isLoading: !dpIsEOF.isEOF,
                                    isLoaded: false,
                                };
                        } else {
                            return {
                                values: [
                                    {
                                        val: convertValuesWithLogic<T>(undefined),
                                        dataType: expectedDataType,
                                    },
                                ],
                                fieldSetting: gDefaultFieldSettings,
                                isVisible: true,
                                isLoading: false,
                                isLoaded: true,
                            };
                        }
                    }
                }
            }
        }
        return {
            values: userValues.map((v) => {
                return {
                    val: convertToTypeOfVal<T>(v, expectedDataType),
                    dataType: expectedDataType,
                };
            }),
            fieldSetting: gDefaultFieldSettings,
            isVisible: true,
            isLoading: false,
            isLoaded: true,
        };
    }, [
        bindingMode,
        bindings,
        convertValuesWithLogic,
        currentRecords,
        dataArrayIndex,
        doValueCheck,
        dpIsEOF.isEOF,
        expectedDataType,
        isProviderLoaded,
        logicAllowsUndefined,
        paramKeys,
        providerData,
        providerField,
        providerFields,
        stringMsgToInternalType,
        userValues,
    ]);

    const isEditable = useMemo(() => {
        if ([EAdvValueBinderType.BinderTypeDataProvider].includes(bindingType)) {
            return dpIsEditable();
        }
        return true;
    }, [bindingType, dpIsEditable]);

    /**
     * @param newVal the new value, that should be tried to be set
     * @returns true, if the value was changed
     */
    const setValueWrapper = useAdvCallback(
        (
            setValues: (
                newVal:
                    | TAdvValueReturnType<T>[]
                    | ((old: TAdvValueReturnType<T>[]) => TAdvValueReturnType<T>[]),
            ) => void,
            newVal: T[],
        ): boolean => {
            if (!isEditable) {
                // Wenn wir nicht bearbeiten können, dann aber vielleicht umpositionieren.
                // Macht nur Sinn bei Dropdowns.
                // TODO: Implementierung verbessern
                if (
                    expectedDataType != EAdvValueDataTypes.Number &&
                    newVal.length > 0 &&
                    typeof newVal[0] == "number"
                ) {
                    return dpSetCurrentRecords(newVal as number[]);
                } else return false;
            }
            if ([EAdvValueBinderType.BinderTypeDataProvider].includes(bindingType)) {
                if (bindingMode == EValueBindingMode.SingleRecordOrData) {
                    dpSetEditData(providerField, newVal[0], dataArrayIndex);
                } else if (
                    bindingMode == EValueBindingMode.MultiRecord ||
                    bindingMode == EValueBindingMode.AllData
                ) {
                    dpSetEditData(providerField, newVal, dataArrayIndex);
                }
            }
            setValues(
                newVal.map((v) => {
                    return {
                        val: convertToTypeOfVal<T>(v, expectedDataType),
                        dataType: expectedDataType,
                    };
                }),
            );
            return true;
        },
        [
            isEditable,
            bindingType,
            dpSetCurrentRecords,
            bindingMode,
            dpSetEditData,
            providerField,
            dataArrayIndex,
            expectedDataType,
        ],
    );

    const attributes = useMemo<TAdvValueBinderAttributes>(() => {
        const res: TAdvValueBinderAttributes = {
            isEditable: isEditable,
            usesBoundValue: shouldUseBoundValue,

            isVisible: valuesAndFieldSettings.isVisible,
            isLoading: valuesAndFieldSettings.isLoading,
            isLoaded: valuesAndFieldSettings.isLoaded,

            isMultiLangField: valuesAndFieldSettings.fieldSetting.IsMultiLangField,
            fieldType: valuesAndFieldSettings.fieldSetting.FieldType,
            len: valuesAndFieldSettings.fieldSetting.Len,
        };
        return res;
    }, [
        isEditable,
        shouldUseBoundValue,
        valuesAndFieldSettings.isVisible,
        valuesAndFieldSettings.isLoading,
        valuesAndFieldSettings.isLoaded,
        valuesAndFieldSettings.fieldSetting.IsMultiLangField,
        valuesAndFieldSettings.fieldSetting.FieldType,
        valuesAndFieldSettings.fieldSetting.Len,
    ]);

    // it's recommended to check setValueWrapper's return type
    return [valuesAndFieldSettings.values, setValueWrapper, attributes];
};

type TNewContractVarVal<T> = {
    values: TAdvValueReturnType<T>[];
    isLoaded: boolean;
};
const useAdvValueBinderContractImpl = function <T>(
    bindings: TAdvValueBindingParams | undefined,
    userValues: T[],
    expectedDataType: EAdvValueDataTypes,
    bindingMode: EValueBindingMode,
    doValueCheck: boolean,
    dataArrayIndex: number,
): [
    TAdvValueReturnType<T>[],
    (
        setterFunc: (
            newVal:
                | TAdvValueReturnType<T>[]
                | ((old: TAdvValueReturnType<T>[]) => TAdvValueReturnType<T>[]),
        ) => void,
        newVal: T[],
    ) => boolean,
    TAdvValueBinderAttributes,
] {
    const { query, pathname } = useRouter();
    const { bindingType, shouldUseBoundValue, paramKeys } = useBindingTypeLocal(bindings);

    // usually used contract
    const contractName = useMemo(() => {
        if (
            bindings == undefined ||
            bindings.bindingParams == undefined ||
            !paramKeys.includes("contract")
        )
            return "";
        return [EAdvValueBinderType.BinderTypeContract].includes(bindingType)
            ? bindings.bindingParams["contract"].value
            : "";
    }, [bindingType, bindings, paramKeys]);

    const contractFieldName = useMemo(() => {
        if (
            bindings == undefined ||
            bindings.bindingParams == undefined ||
            !paramKeys.includes("contractField")
        )
            return "";
        return [EAdvValueBinderType.BinderTypeContract].includes(bindingType)
            ? bindings.bindingParams["contractField"].value
            : "";
    }, [bindingType, bindings, paramKeys]);

    const contracts = useAdvRecoilValue(gActiveContracts);

    const isContractUsed = useMemo(() => {
        return (
            contracts.find((val) => {
                const contractID = buildUniqueContractID(pathname, query, contractName);
                return JSON.stringify(val.contractID) == JSON.stringify(contractID);
            }) != undefined
        );
    }, [contractName, contracts, pathname, query]);

    const contractID = useMemo(() => {
        return buildUniqueContractID(pathname, query, contractName);
    }, [contractName, pathname, query]);

    const contractData = useAdvRecoilValue(
        getContractFieldValuesSelector({
            contractID: contractID,
            contractIndex: dataArrayIndex,
            fieldName: contractFieldName,
        }),
    );

    const { stringMsgToInternalType, convertValuesWithLogic } = useBindingFuncsLocal(
        bindings,
        userValues,
        expectedDataType,
    );

    const values = useMemo<TNewContractVarVal<T>>(() => {
        if (bindings != undefined) {
            const realBindType =
                typeof bindings.bindingTypeName == "string"
                    ? AdvValueBinderStringToEnum(bindings.bindingTypeName)
                    : (bindings.bindingTypeName as EAdvValueBinderType);
            if (realBindType == EAdvValueBinderType.BinderTypeUser) {
                return {
                    isLoaded: true,
                    values: userValues.map((v) => {
                        return {
                            val: convertToTypeOfVal<T>(v, expectedDataType),
                            dataType: expectedDataType,
                        };
                    }),
                };
            } else if (
                realBindType == EAdvValueBinderType.BinderTypeContract &&
                paramKeys.includes("contractField")
            ) {
                if (!isContractUsed) {
                    const updateVal = stringMsgToInternalType("Contract not loaded...");
                    if (updateVal.canUpdate)
                        return {
                            isLoaded: false,
                            values: updateVal.newVal.map((v) => {
                                return {
                                    val: v,
                                    dataType: expectedDataType,
                                };
                            }),
                        };
                } else {
                    if (bindingMode == EValueBindingMode.SingleRecordOrData) {
                        const singleData = contractData.length == 0 ? undefined : contractData[0];
                        if (contractData.length == 0 || singleData == undefined) {
                            // fallthrough
                        } else {
                            return {
                                isLoaded: true,
                                values: [convertValuesWithLogic<T>(singleData)].map((v) => {
                                    return {
                                        val: v,
                                        dataType: expectedDataType,
                                    };
                                }),
                            };
                        }
                    } else {
                        if (
                            bindingMode == EValueBindingMode.AllData ||
                            bindingMode == EValueBindingMode.MultiRecord
                        ) {
                            return {
                                isLoaded: true,
                                values: contractData
                                    .map((v) => convertValuesWithLogic<T>(v))
                                    .map((v) => {
                                        return {
                                            val: v,
                                            dataType: expectedDataType,
                                        };
                                    }),
                            };
                        }
                    }
                }
            }
        }
        return {
            isLoaded: true,
            values: userValues.map((v) => {
                return {
                    val: convertValuesWithLogic(convertToTypeOfVal<T>(v, expectedDataType)),
                    dataType: expectedDataType,
                };
            }),
        };
    }, [
        bindings,
        userValues,
        paramKeys,
        expectedDataType,
        isContractUsed,
        stringMsgToInternalType,
        bindingMode,
        contractData,
        convertValuesWithLogic,
    ]);

    const isEditable = useMemo(() => {
        return values.isLoaded;
    }, [values.isLoaded]);

    /**
     * @param newVal the new value, that should be tried to be set
     * @returns true, if the value was changed
     */
    const setValueWrapper = useAdvCallback(
        (
            setValues: (
                newVal:
                    | TAdvValueReturnType<T>[]
                    | ((old: TAdvValueReturnType<T>[]) => TAdvValueReturnType<T>[]),
            ) => void,
            newVal: T[],
        ): boolean => {
            if (!isEditable) return false;
            setValues(
                newVal.map((v) => {
                    return {
                        val: convertToTypeOfVal<T>(v, expectedDataType),
                        dataType: expectedDataType,
                    };
                }),
            );
            return true;
        },
        [expectedDataType, isEditable],
    );

    const attributes = useMemo<TAdvValueBinderAttributes>(() => {
        const res: TAdvValueBinderAttributes = {
            isEditable: isEditable,
            usesBoundValue: shouldUseBoundValue,

            isVisible: true,
            isLoading: !values.isLoaded,
            isLoaded: values.isLoaded,

            isMultiLangField: false,
            fieldType: EFieldSettingsFieldTypes.default,
            len: 0,
        };
        return res;
    }, [isEditable, shouldUseBoundValue, values.isLoaded]);

    // it's recommended to check setValueWrapper's return type
    return [values.values, setValueWrapper, attributes];
};

const useAdvValueBinderImpl = function <T>(
    bindings: TAdvValueBindingParams | undefined,
    userValues: T[],
    expectedDataType: EAdvValueDataTypes,
    bindingMode: EValueBindingMode,
    dataArrayIndex: number,
    doValueCheck: boolean,
    allowUndefined?: boolean,
): [TAdvValueReturnType<T>[], (newVal: T[]) => boolean, TAdvValueBinderAttributes] {
    const [valuesPage, setValueWrapperPage, attributesPage] = useAdvValueBinderPageVarImpl(
        bindings,
        userValues,
        expectedDataType,
        bindingMode,
        doValueCheck,
        dataArrayIndex,
        allowUndefined,
    );
    const [valuesProv, setValueWrapperProv, attributesProv] = useAdvValueBinderProviderImpl(
        bindings,
        userValues,
        expectedDataType,
        bindingMode,
        doValueCheck,
        dataArrayIndex,
    );
    const [valuesContract, setValueWrapperContract, attributesContract] =
        useAdvValueBinderContractImpl(
            bindings,
            userValues,
            expectedDataType,
            bindingMode,
            doValueCheck,
            dataArrayIndex,
        );

    const [valuesDynamic, setValueWrapperDynamic, attributesDynamic] = useAdvValueBinderDynamicImpl(
        bindings,
        userValues,
        expectedDataType,
    );

    const { bindingType } = useBindingTypeLocal(bindings);
    const [values, , setValues] = useAdvMemoWithUpdater<TAdvValueReturnType<T>[]>(() => {
        if ([EAdvValueBinderType.BinderTypeDataProvider].includes(bindingType)) {
            return valuesProv;
        }
        if ([EAdvValueBinderType.BinderTypePageVariable].includes(bindingType)) {
            return valuesPage;
        }
        if ([EAdvValueBinderType.BinderTypeContract].includes(bindingType)) {
            return valuesContract;
        }
        if ([EAdvValueBinderType.BinderTypeDynamic].includes(bindingType)) {
            return valuesDynamic;
        }
        return valuesProv;
    }, [bindingType, valuesContract, valuesDynamic, valuesPage, valuesProv]);

    const isEditable = useMemo(() => {
        if ([EAdvValueBinderType.BinderTypeDataProvider].includes(bindingType)) {
            return attributesProv.isEditable;
        }
        if ([EAdvValueBinderType.BinderTypePageVariable].includes(bindingType)) {
            return attributesPage.isEditable;
        }
        if ([EAdvValueBinderType.BinderTypeContract].includes(bindingType)) {
            return attributesContract.isEditable;
        }
        if ([EAdvValueBinderType.BinderTypeDynamic].includes(bindingType)) {
            return attributesDynamic.isEditable;
        }
        return true;
    }, [
        attributesContract.isEditable,
        attributesDynamic.isEditable,
        attributesPage.isEditable,
        attributesProv.isEditable,
        bindingType,
    ]);

    /**
     * @param newVal the new value, that should be tried to be set
     * @returns true, if the value was changed
     */
    const setValueWrapper = useAdvCallback(
        (newVal: T[]): boolean => {
            if ([EAdvValueBinderType.BinderTypeDataProvider].includes(bindingType)) {
                return setValueWrapperProv(setValues, newVal);
            } else if ([EAdvValueBinderType.BinderTypePageVariable].includes(bindingType)) {
                if (!isEditable) return false;
                return setValueWrapperPage(setValues, newVal);
            } else if ([EAdvValueBinderType.BinderTypeContract].includes(bindingType)) {
                if (!isEditable) return false;
                return setValueWrapperContract(setValues, newVal);
            } else if ([EAdvValueBinderType.BinderTypeDynamic].includes(bindingType)) {
                if (!isEditable) return false;
                return setValueWrapperDynamic(setValues, newVal);
            } else
                setValues(
                    newVal.map((v) => {
                        return {
                            val: convertToTypeOfVal<T>(v, expectedDataType),
                            dataType: expectedDataType,
                        };
                    }),
                );
            return true;
        },
        [
            bindingType,
            expectedDataType,
            isEditable,
            setValueWrapperContract,
            setValueWrapperDynamic,
            setValueWrapperPage,
            setValueWrapperProv,
            setValues,
        ],
    );

    const attributes = useMemo<TAdvValueBinderAttributes>(() => {
        if ([EAdvValueBinderType.BinderTypeDataProvider].includes(bindingType)) {
            return attributesProv;
        } else if ([EAdvValueBinderType.BinderTypePageVariable].includes(bindingType)) {
            return attributesPage;
        } else if ([EAdvValueBinderType.BinderTypeContract].includes(bindingType)) {
            return attributesContract;
        } else if ([EAdvValueBinderType.BinderTypeDynamic].includes(bindingType)) {
            return attributesDynamic;
        }
        return attributesProv;
    }, [attributesContract, attributesDynamic, attributesPage, attributesProv, bindingType]);

    // it's recommended to check setValueWrapper's return type
    return [values, setValueWrapper, attributes];
};

export const useAdvValueBinderImplPageVar = function <T>(
    bindings: TAdvValueBindingParams | undefined,
    userValues: T[],
    expectedDataType: EAdvValueDataTypes,
    bindingMode: EValueBindingMode,
    doValueCheck: boolean,
    dataArrayIndex: number,
): [TAdvValueReturnType<T>[], (newVal: T[]) => boolean, TAdvValueBinderAttributes] {
    const [valuesPage, setValueWrapper, attributes] = useAdvValueBinderPageVarImpl(
        bindings,
        userValues,
        expectedDataType,
        bindingMode,
        doValueCheck,
        dataArrayIndex,
    );

    const [values, , setValues] = useAdvMemoWithUpdater<TAdvValueReturnType<T>[]>(
        () => valuesPage,
        [valuesPage],
    );

    const setValuesRet = useAdvCallback(
        (newVal: T[]) => {
            return setValueWrapper(setValues, newVal);
        },
        [setValueWrapper, setValues],
    );

    // it's recommended to check setValueWrapper's return type
    return [values, setValuesRet, attributes];
};

export const useAdvValueBinderImplProvider = function <T>(
    bindings: TAdvValueBindingParams | undefined,
    userValues: T[],
    expectedDataType: EAdvValueDataTypes,
    bindingMode: EValueBindingMode,
    doValueCheck: boolean,
    dataArrayIndex: number,
): [TAdvValueReturnType<T>[], (newVal: T[]) => boolean, TAdvValueBinderAttributes] {
    const [valuesProv, setValueWrapper, attributes] = useAdvValueBinderProviderImpl(
        bindings,
        userValues,
        expectedDataType,
        bindingMode,
        doValueCheck,
        dataArrayIndex,
    );
    const [values, , setValues] = useAdvMemoWithUpdater<TAdvValueReturnType<T>[]>(
        () => valuesProv,
        [valuesProv],
    );

    const setValuesRet = useAdvCallback(
        (newVal: T[]) => {
            return setValueWrapper(setValues, newVal);
        },
        [setValueWrapper, setValues],
    );

    // it's recommended to check setValueWrapper's return type
    return [values, setValuesRet, attributes];
};

/**
 * Bindet ein Wert je nach type definiert durch @see EAdvValueBinderType an den hook
 * @param bindings die bindings, die genutzt werden sollen, oder undefined, wenn nichts benutzt werden soll
 * @returns ein Array bestehend aus:
 *      den aktuellen Werten,
 *      einem Setter um die aktuellen Werte zu bearbeiten,
 *      einem Boolean, das aussagt, ob der Setter neue Werte akzeptiert,
 *      einem Boolean, das aussagt, ob der aktuelle Wert benutzt werden sollte,
 */
export const useAdvValueBinderNoDataType = function <T>(
    bindings: TAdvValueBindingParams | undefined,
    userVal: T,
    expectedDataType: EAdvValueDataTypes,
    dataArrayIndex: number,
    allowUndefined?: boolean,
): [T, (newVal: T) => boolean, TAdvValueBinderAttributes] {
    const useValAsArr = useMemo(() => [userVal], [userVal]);
    const [res0, res1, res2] = useAdvValueBinderImpl(
        bindings,
        useValAsArr,
        expectedDataType,
        EValueBindingMode.SingleRecordOrData,
        dataArrayIndex,
        true,
        allowUndefined,
    );

    const setValueWrapper = useAdvCallback(
        (newVal: T): boolean => {
            return res1([newVal]);
        },
        [res1],
    );

    return [res0[0].val, setValueWrapper, res2];
};
export const useAdvValueBinder = function <T>(
    bindings: TAdvValueBindingParams | undefined,
    userVal: T,
    expectedDataType: EAdvValueDataTypes,
    dataArrayIndex: number,
): [TAdvValueReturnType<T>, (newVal: T) => boolean, TAdvValueBinderAttributes] {
    const useValAsArr = useMemo(() => [userVal], [userVal]);

    const [res0, res1, res2] = useAdvValueBinderImpl(
        bindings,
        useValAsArr,
        expectedDataType,
        EValueBindingMode.SingleRecordOrData,
        dataArrayIndex,
        true,
    );

    const setValueWrapper = useAdvCallback(
        (newVal: T): boolean => {
            return res1([newVal]);
        },
        [res1],
    );

    return [res0[0], setValueWrapper, res2];
};

/**
 * Gives the bound values as a list. Also returns all data found in a data provider binding, instead of the selected records
 * @param bindings The bindings to use
 * @param userVal The current user specified value
 * @returns a list, that consists of a the data
 */
export const useAdvValueBinderAsArrayNoDataType = function (
    bindings: TAdvValueBindingParams | undefined,
    userVal: any[],
    expectedDataType: EAdvValueDataTypes,
    doValueCheck: boolean = false,
    dataArrayIndex: number = 0,
    allowUndefined?: boolean,
): [any[], (newVal: any[]) => boolean, TAdvValueBinderAttributes] {
    const res = useAdvValueBinderImpl(
        bindings,
        userVal,
        expectedDataType,
        EValueBindingMode.AllData,
        dataArrayIndex,
        doValueCheck,
        allowUndefined,
    );
    return [res[0].map((v) => v.val), res[1], res[2]];
};

/**
 * Gives the bound values as an array. Only returns selected records in a dataprovider
 * @param bindings The bindings to use
 * @param userVal The current user specified value array
 * @returns The array of data found
 */
export const useAdvValueBinderMulti = function (
    bindings: TAdvValueBindingParams | undefined,
    userVal: any[],
    expectedDataType: EAdvValueDataTypes,
    dataArrayIndex: number = 0,
): [TAdvValueReturnType<any>[], (newVal: any[]) => boolean, TAdvValueBinderAttributes] {
    const res = useAdvValueBinderImpl(
        bindings,
        userVal,
        expectedDataType,
        EValueBindingMode.MultiRecord,
        dataArrayIndex,
        true,
    );
    return [res[0], res[1], res[2]];
};
export const useAdvValueBinderMultiNoDataType = function (
    bindings: TAdvValueBindingParams | undefined,
    userVal: any[],
    expectedDataType: EAdvValueDataTypes,
    dataArrayIndex: number = 0,
): [any[], (newVal: any[]) => boolean, TAdvValueBinderAttributes] {
    const res = useAdvValueBinderMulti(bindings, userVal, expectedDataType, dataArrayIndex);
    return [res[0].map((v) => v.val), res[1], res[2]];
};

export const useAdvValueBinderInfoText = function (
    bindings: TAdvValueBindingParams | undefined,
): string | undefined {
    const bindingType = useMemo(
        () =>
            bindings != undefined
                ? typeof bindings.bindingTypeName == "string"
                    ? AdvValueBinderStringToEnum(bindings.bindingTypeName)
                    : (bindings.bindingTypeName as EAdvValueBinderType)
                : EAdvValueBinderType.BinderTypeUser,
        [bindings],
    );

    const { pathname, query } = useRouter();

    const providerID = useMemo(
        () =>
            buildUniqueProviderID(
                pathname,
                query,
                bindings != undefined &&
                    [EAdvValueBinderType.BinderTypeDataProvider].includes(bindingType)
                    ? bindings.bindingParams["provider"].value
                    : "",
            ),
        [bindingType, bindings, pathname, query],
    );
    const provider = useAdvRecoilValue(recoilDataProvider.getDataproviderDefinition(providerID));

    const infoText = useMemo(() => {
        if (bindings) {
            switch (bindingType) {
                case EAdvValueBinderType.BinderTypeUser:
                    return undefined;
                case EAdvValueBinderType.BinderTypePageVariable:
                    return "[Page-Parameter]: " + bindings.bindingParams["getPageVar"].value;
                case EAdvValueBinderType.BinderTypeDynamic:
                    return "[Dynamic-Value]: " + bindings.bindingParams["dynamic"].value;
                case EAdvValueBinderType.BinderTypeDataProvider:
                    return (
                        "[Provider-Field]: " +
                        (provider.IsLoaded()
                            ? replaceDatasourcePrefix(provider.Get().datasourceName)
                            : "") +
                        "." +
                        bindings.bindingParams["providerField"].value
                    );
                case EAdvValueBinderType.BinderTypeContract:
                    return "[Contract-Field]: " + bindings.bindingParams["contractField"].value;
            }
        }
    }, [bindingType, bindings, provider]);

    return infoText;
};
