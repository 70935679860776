import AdvStack from "@components/layout/stack";
import { replacePagePrefix } from "@data/designer/file";
import { TAdvDesignerComponentProps } from "@feature/Designer/types/component-props";
import {
    BaseButton,
    IBreadcrumbItem,
    IBreadcrumbProps,
    IBreadcrumbStyleProps,
    IBreadcrumbStyles,
    IStyleFunctionOrObject,
} from "@fluentui/react";
import { usePageTitle } from "@hooks/dynamic/usePageTitle";
import { useT } from "@hooks/language/useTranslation";
import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import { useAdvEffect } from "@hooks/react-overload/useAdvEffect";
import useAdvComponent from "@hooks/useAdvComponent";
import useAdvTheme from "@hooks/useAdvTheme";
import { EllipsisIcon, SettingsIcon } from "@themes/icons";
import { deepCompareJSXProps } from "@utils/deep-compare";
import deepCopy from "@utils/deep-copy";
import { advcatch } from "@utils/logging";
import {
    buildPageParserParam,
    buildQueryAsStr,
    gAdvBreadcrumbIgnoreKey,
    gAdvDesignerPageName,
    gAdvDynPageName,
} from "@utils/page-parser";
import { capitalize } from "@utils/string";
import { nanoid } from "nanoid";
import { useRouter } from "next/router";
import React, { MouseEvent, useMemo, useRef, useState } from "react";

import { AdvContextualMenu } from "@components/layout/contextual-menu/contextual-menu";
import AdvIcon from "@components/other/icon/icon";
import { EPageComponentSizeType } from "@components/page-component";
import { defaultStartPage, sessionAddInfosAtom } from "@data/session";
import {
    Breadcrumb,
    BreadcrumbDivider,
    BreadcrumbItem,
    PartitionBreadcrumbItems,
} from "@fluentui/react-breadcrumb-preview";
import {
    Button,
    FluentProvider,
    Menu,
    MenuItemLink,
    MenuList,
    MenuPopover,
    MenuTrigger,
    Overflow,
    useIsOverflowItemVisible,
    useOverflowMenu,
} from "@fluentui/react-components";
import { createV9Theme } from "@fluentui/react-migration-v8-v9";
import useAdvRecoilState from "@hooks/recoil-overload/useAdvRecoilState";
import { TAdvTransactionInterface } from "@hooks/recoil-overload/useAdvRecoilTransaction";
import useAdvRecoilValue from "@hooks/recoil-overload/useAdvRecoilValue";
import { atom, selector } from "recoil";

export type TAdvNavHistoryStyles = IBreadcrumbStyles; /* do not change */
export type TAdvNavHistoryItem = Omit<IBreadcrumbItem, "href">;

export type TAdvNavHistoryProps = Omit<IBreadcrumbProps, "styles" | "items"> &
    TAdvDesignerComponentProps & {
        styles?: IStyleFunctionOrObject<IBreadcrumbStyleProps, TAdvNavHistoryStyles>;
        // items: AdvNavHistoryItem[];
    } & {
        pageLayout: EPageComponentSizeType;
    };

enum EHistoryPageType {
    Normal = 0,
    Dynamic,
    UI,
}

type THistoryItem = {
    pagename: string;
    href: string;
    pageType: EHistoryPageType;
    item: IBreadcrumbItem;
    key: number | string;
};

const AdvHistoryItem = ({
    pageName,
    isDynamicPage,
    defaultRender,
    text,
    ...props
}: IBreadcrumbItem & {
    pageName: string;
    isDynamicPage: boolean;
    defaultRender: (props?: IBreadcrumbItem | undefined) => React.JSX.Element | null;
}) => {
    const { push } = useRouter();
    const buttonRef = useRef<any>(null);
    const [isContextMenuHidden, setIsContextMenuHidden] = useState(true);
    const handleContextMenuClick = useAdvCallback(
        (
            e: MouseEvent<
                | HTMLAnchorElement
                | HTMLButtonElement
                | HTMLDivElement
                | BaseButton
                | HTMLSpanElement
            >,
        ) => {
            buttonRef.current = e.target;
            setIsContextMenuHidden(false);
            e.preventDefault();
        },
        [],
    );

    /**
     * Der text besteht entweder aus "<Titel>" oder "<Titel> [<subTitle>]".
     *  Beispiel "Versandanschriften [KLEINEBREZEL]"
     * Wir wollen jedoch nur den <Titel> übersetzen, deshalb hier aufsplitten falls nötig.
     */
    const [title, subTitle] = useMemo(() => {
        if (text.indexOf("[") < 0) return [text, ""];
        else
            return [
                text.substring(0, text.indexOf("[")).trim(),
                text.substring(text.indexOf("[")).trim(),
            ];
    }, [text]);

    const { t: translatedTitle, hasErr } = useT(title);
    const theme = useAdvTheme();

    const myProps = useMemo(() => {
        const res: Omit<IBreadcrumbItem, "onRender"> = {
            ...props,
            text: `${translatedTitle ?? title} ${subTitle}`.trim(),
        };
        if (hasErr) res.style = { ...res.style, ...theme.custom.textNotTranslated };
        return res;
    }, [hasErr, props, subTitle, theme.custom.textNotTranslated, title, translatedTitle]);

    return (
        <>
            {defaultRender({
                ...myProps,
                onContextMenu: (e) => {
                    if (isDynamicPage) handleContextMenuClick(e);
                },
            })}
            {isDynamicPage == true ? (
                <AdvContextualMenu
                    items={[
                        {
                            key: "goDesignerPage",
                            text: "Open Page in UI-Designer",
                            iconProps: {
                                iconName: SettingsIcon.iconName,
                            },
                            onClick: () => {
                                push(
                                    "/designer/ui/?" + gAdvDesignerPageName + "=" + pageName,
                                ).catch((r) => advcatch("Could not open URL: ", r));
                            },
                        },
                    ]}
                    hidden={isContextMenuHidden}
                    target={buttonRef.current}
                    onItemClick={() => setIsContextMenuHidden(true)}
                    onDismiss={() => setIsContextMenuHidden(true)}
                />
            ) : null}
        </>
    );
};

const AdvHistoryItem2 = ({ item, isLastItem }: { item: THistoryItem; isLastItem: boolean }) => {
    const { push } = useRouter();
    const buttonRef = useRef<any>(null);
    const [isContextMenuHidden, setIsContextMenuHidden] = useState(true);
    const handleContextMenuClick = useAdvCallback(
        (
            e: MouseEvent<
                | HTMLAnchorElement
                | HTMLButtonElement
                | HTMLDivElement
                | BaseButton
                | HTMLSpanElement
            >,
        ) => {
            buttonRef.current = e.target;
            setIsContextMenuHidden(false);
            e.preventDefault();
        },
        [],
    );

    /**
     * Der text besteht entweder aus "<Titel>" oder "<Titel> [<subTitle>]".
     *  Beispiel "Versandanschriften [KLEINEBREZEL]"
     * Wir wollen jedoch nur den <Titel> übersetzen, deshalb hier aufsplitten falls nötig.
     */
    const [title, subTitle] = useMemo(() => {
        const text = item.item.text;
        if (text.indexOf("[") < 0) return [text, ""];
        else
            return [
                text.substring(0, text.indexOf("[")).trim(),
                text.substring(text.indexOf("[")).trim(),
            ];
    }, [item.item.text]);

    const { t: translatedTitle } = useT(title);

    return (
        <React.Fragment key={`item-${item.item.key}`}>
            <BreadcrumbItem
                //current={item.item.isCurrentItem}
                onContextMenu={handleContextMenuClick}
                onClick={() => {
                    push(item.href).catch((r) => advcatch("Could not open URL: ", r));
                }}
                style={{
                    cursor: "pointer",
                    fontWeight: item.item.isCurrentItem === true ? "bold" : undefined,
                }}
            >
                <div className="adv-fui-BreadcrumbItem">{translatedTitle + subTitle}</div>
            </BreadcrumbItem>
            {(item.pageType == EHistoryPageType.Dynamic) == true ? (
                <AdvContextualMenu
                    items={[
                        {
                            key: "goDesignerPage",
                            text: "Open Page in UI-Designer",
                            iconProps: {
                                iconName: SettingsIcon.iconName,
                            },
                            onClick: () => {
                                push(
                                    "/designer/ui/?" + gAdvDesignerPageName + "=" + item.pagename,
                                ).catch((r) => advcatch("Could not open URL: ", r));
                            },
                        },
                    ]}
                    hidden={isContextMenuHidden}
                    target={buttonRef.current}
                    onItemClick={() => setIsContextMenuHidden(true)}
                    onDismiss={() => setIsContextMenuHidden(true)}
                />
            ) : null}
            {!isLastItem && <BreadcrumbDivider />}
        </React.Fragment>
    );
};

function onRenderItemContent(
    props?: TAdvNavHistoryItem,
    defaultRender?: (props?: TAdvNavHistoryItem) => React.JSX.Element | null,
): React.JSX.Element | null {
    if (typeof defaultRender == "undefined" || typeof props == "undefined") return <></>;
    return (
        <div
            style={{ fontWeight: props.isCurrentItem ?? false ? 700 : 400 }}
            onClick={props.onClick}
        >
            {defaultRender(props)}
        </div>
    );
}

const onRenderItem = (
    pageName: string,
    isDynamicPage: boolean,
    props?: IBreadcrumbItem,
    defaultRender?: (props?: IBreadcrumbItem | undefined) => React.JSX.Element | null,
) => {
    if (props != undefined && defaultRender != undefined) {
        return (
            <AdvHistoryItem
                pageName={pageName}
                isDynamicPage={isDynamicPage}
                defaultRender={defaultRender}
                {...props}
            ></AdvHistoryItem>
        );
    }
    return <></>;
};

const generateTitle = (t: string | undefined, s: string | undefined, def: string) => {
    if (t != undefined && s != "") {
        return t + (s != undefined && s != "" ? " [" + s + "]" : "");
    } else if (t != "" && t != undefined) {
        return t;
    }
    return def;
};

const historyItems = atom<Array<THistoryItem>>({
    key: "history_breadcrumb_ItemsAtom",
    default: [],
});

export const popLastHistoryItemTrans = (tb: TAdvTransactionInterface) => (href: string) => {
    const items = tb.get(historyItems);
    if (items.length > 1) {
        const newItems = deepCopy(items, true);
        const foundItemIndex = newItems.findIndex((i) => i.href == href);
        if (foundItemIndex != -1) {
            newItems.splice(foundItemIndex, 1);
            tb.set(historyItems, newItems);
        }
    }
};

export const activePageName = selector<string>({
    key: "active_history_breadcrumb_ItemsAtom",
    get: ({ get }) => {
        const items = get(historyItems);
        if (items.length > 1) {
            return items[items.length - 1].item.text;
        }
        return "";
    },
});

const MenuItem: React.FC<{ id: string; item: THistoryItem }> = (props) => {
    const { item, id } = props;
    const isVisible = useIsOverflowItemVisible(id);
    const href = item.href || "";
    const router = useRouter();

    if (isVisible) {
        return null;
    }
    return (
        <MenuItemLink
            href={"#"}
            onClick={() => {
                router.push(href).catch(advcatch);
            }}
        >
            {item.item.text}
        </MenuItemLink>
    );
};

const OverflowMenu = (props: PartitionBreadcrumbItems<THistoryItem>) => {
    const { overflowItems, startDisplayedItems, endDisplayedItems } = props;
    const { ref, isOverflowing, overflowCount } = useOverflowMenu<HTMLButtonElement>();

    if (!isOverflowing && overflowItems && overflowItems.length === 0) {
        return null;
    }

    const overflowItemsCount = overflowItems ? overflowItems.length + overflowCount : overflowCount;

    return (
        <BreadcrumbItem>
            <Menu hasIcons>
                <MenuTrigger disableButtonEnhancement>
                    <Button
                        appearance="subtle"
                        ref={ref}
                        icon={<AdvIcon iconName={EllipsisIcon.iconName}></AdvIcon>}
                        aria-label={`${overflowItemsCount} more items`}
                        role="button"
                    />
                </MenuTrigger>
                <MenuPopover>
                    <MenuList>
                        {isOverflowing &&
                            startDisplayedItems.map((item: THistoryItem) => (
                                <MenuItem
                                    id={item.item.key.toString()}
                                    item={item}
                                    key={item.item.key}
                                />
                            ))}
                        {overflowItems &&
                            overflowItems.map((item: THistoryItem) => (
                                <MenuItem
                                    id={item.item.key.toString()}
                                    item={item}
                                    key={item.item.key}
                                />
                            ))}
                        {isOverflowing &&
                            endDisplayedItems &&
                            endDisplayedItems.map((item: THistoryItem) => (
                                <MenuItem
                                    id={item.item.key.toString()}
                                    item={item}
                                    key={item.item.key}
                                />
                            ))}
                    </MenuList>
                </MenuPopover>
            </Menu>
        </BreadcrumbItem>
    );
};

/**
 * @summary Wrapper für ``Breadcrumb``
 * @summary Soll später quasi die History des Browsers darstellen und einfaches zurück-navigieren ermöglichen.
 * @link https://developer.microsoft.com/en-us/fluentui#/controls/web/Breadcrumb
 */
export const AdvNavHistoryComp = ({ pageLayout, ...props }: TAdvNavHistoryProps) => {
    useAdvComponent(AdvNavHistoryComp, props);

    const [items, setItems] = useAdvRecoilState(historyItems);

    const activeDynamicPage = useMemo<{ pgName: string; index: number }>(() => {
        const foundItemIndex = items.findIndex(
            (val) => val.pageType == EHistoryPageType.Dynamic && val.item.isCurrentItem,
        );
        if (foundItemIndex != -1)
            return { pgName: items[foundItemIndex].pagename, index: foundItemIndex };
        return { pgName: "", index: -1 };
    }, [items]);

    const { title, sectitle } = usePageTitle(activeDynamicPage.pgName);
    const { title: welcomeTitle, sectitle: welcomeSectitle } = usePageTitle(defaultStartPage);

    const router = useRouter();
    const session = useAdvRecoilValue(sessionAddInfosAtom);

    useAdvEffect(() => {
        if (!router.isReady) return;
        // router stuff
        const url = router.pathname;
        const params = router.query;
        const paramKeys = Object.keys(params);

        setItems((old) => {
            const doesIgnorePrevBreadcrumb =
                paramKeys.includes(gAdvBreadcrumbIgnoreKey) &&
                params[gAdvBreadcrumbIgnoreKey] !== "";
            const itemsCopy = doesIgnorePrevBreadcrumb ? [] : deepCopy(old, true);

            const onClick = function (href: string) {
                router.push(href).catch(advcatch);
            };

            // actual page name
            let pagename = url.substring(1);
            if (pagename.length == 0) {
                return old;
            }

            const isDynPage = pagename == "dynamic";
            const isUiDesigner = pagename == "designer/ui";

            if (isDynPage) {
                if (paramKeys.includes(gAdvDynPageName))
                    pagename = (params[gAdvDynPageName] as string) ?? "";
                else {
                    return old;
                }
            } else if (isUiDesigner) {
                if (paramKeys.includes(gAdvDesignerPageName))
                    pagename = (params[gAdvDesignerPageName] as string) ?? "";
                else {
                    return old;
                }
            }

            let builtHref =
                "/" + (isDynPage ? "dynamic" : isUiDesigner ? "designer/ui" : pagename) + "/";
            if (paramKeys.length > 0) {
                const href = buildQueryAsStr(
                    buildPageParserParam(params, [gAdvBreadcrumbIgnoreKey]),
                );
                builtHref += href;
            }

            const startPage = session.Startpage != undefined ? session.Startpage : defaultStartPage;
            const newHref = builtHref;
            const getHistoryText = () => {
                const res = capitalize(
                    pagename.includes("/")
                        ? pagename.substring(pagename.lastIndexOf("/") + 1)
                        : replacePagePrefix(pagename),
                );
                if (capitalize(startPage) == capitalize(pagename)) {
                    return generateTitle(welcomeTitle, welcomeSectitle, "Startseite");
                }
                if (isUiDesigner) return "UI [" + res + "]";
                if (isDynPage) {
                    return generateTitle(title, sectitle, res);
                }
                return res;
            };

            const historyText = getHistoryText();

            let hasItemsChanged = false;
            if (itemsCopy.length == 0) {
                const welcomeHref =
                    "/dynamic/?" +
                    gAdvDynPageName +
                    "=" +
                    (session.Startpage != undefined ? session.Startpage : defaultStartPage);
                itemsCopy.push({
                    pagename: startPage,
                    href: welcomeHref,
                    pageType: EHistoryPageType.Dynamic,
                    item: {
                        key: nanoid(),
                        text: generateTitle(welcomeTitle, welcomeSectitle, "Startseite"),
                        onClick: onClick.bind(null, welcomeHref),
                        isCurrentItem: true,
                        onRender: onRenderItem.bind(null, pagename, false),
                    },
                    key: itemsCopy.length,
                });
                hasItemsChanged = true;
            }

            // determine the index of the current URL
            const indexInBreadcrumb = itemsCopy.findIndex((valC) => valC.href == newHref);

            // if current index is not -1, make the current item the active one
            if (indexInBreadcrumb != -1) {
                for (let i = 0; i < itemsCopy.length; ++i) {
                    const item = itemsCopy[i];
                    const wasCurrentItem = item.item.isCurrentItem;
                    item.item.isCurrentItem = i == indexInBreadcrumb;
                    hasItemsChanged = hasItemsChanged || wasCurrentItem !== item.item.isCurrentItem;

                    // "Loading" -> "<Value>" ist ebenfalls eine Änderung
                    if (
                        item.item.isCurrentItem &&
                        item.item.text != historyText &&
                        item.pageType == EHistoryPageType.Dynamic &&
                        i == activeDynamicPage.index
                    ) {
                        hasItemsChanged = true;
                        item.item.text = historyText;
                        item.item.key = nanoid();
                    }
                }
            }

            // if item didnt exist add it
            if (indexInBreadcrumb == -1) {
                // get current active item index
                let currentActiveItem = -1;
                for (let i = 0; i < itemsCopy.length; ++i) {
                    const item = itemsCopy[i];
                    if (item.item.isCurrentItem ?? false) currentActiveItem = i;
                }

                if (old.length == 0 || currentActiveItem != -1) {
                    for (const item of itemsCopy) {
                        item.item.isCurrentItem = false;
                    }
                    // new item will be added, drop all old items that are not valid anymore
                    // if not last item, then delete all items after this
                    if (currentActiveItem < itemsCopy.length - 1) {
                        itemsCopy.splice(
                            currentActiveItem + 1,
                            itemsCopy.length - (currentActiveItem + 1),
                        );
                    }

                    itemsCopy.push({
                        pagename: pagename,
                        href: newHref,
                        pageType: isDynPage
                            ? EHistoryPageType.Dynamic
                            : isUiDesigner
                            ? EHistoryPageType.UI
                            : EHistoryPageType.Normal,
                        item: {
                            key: nanoid(),
                            text: historyText,
                            onClick: onClick.bind(null, newHref),
                            isCurrentItem: true,
                            onRender: onRenderItem.bind(null, pagename, isDynPage),
                        },
                        key: itemsCopy.length,
                    });
                    hasItemsChanged = true;
                }
            }

            if (!hasItemsChanged) return old;
            return itemsCopy;
        });
    }, [
        activeDynamicPage.index,
        router,
        sectitle,
        session.Startpage,
        setItems,
        title,
        welcomeSectitle,
        welcomeTitle,
    ]);

    const theme = useAdvTheme();

    /*const {
        startDisplayedItems,
        overflowItems,
        endDisplayedItems,
    }: PartitionBreadcrumbItems<THistoryItem> = partitionBreadcrumbItems({
        items,
        maxDisplayedItems: Math.min(items.length - 1, 16),
    });*/

    const isMobile = pageLayout <= EPageComponentSizeType.Mobile;

    const { startDisplayedItems, overflowItems, endDisplayedItems } = useMemo(() => {
        if (isMobile) {
            return {
                startDisplayedItems: [],
                overflowItems: items.slice(0, items.length - 1),
                endDisplayedItems: items.slice(items.length - 1, items.length),
            };
        } else {
            return {
                startDisplayedItems: items,
                overflowItems: [],
                endDisplayedItems: [],
            };
        }
    }, [isMobile, items]);

    const v9Theme = useMemo(() => {
        return createV9Theme(theme);
    }, [theme]);

    return (
        <AdvStack styles={{ root: { width: "100%" } }} horizontal grow>
            {/*<Breadcrumb
                {...props}
                theme={theme}
                styles={combineStyles(defaultBreadcrumbStyles, styles)}
                onRenderItemContent={onRenderItemContent}
                items={items.map((val) => {
                    return val.item;
                })}
                maxDisplayedItems={5}
                overflowIndex={items.length > 5 ? 1 : 0}
            />*/}
            <FluentProvider theme={v9Theme} style={{ flexGrow: 1 }}>
                <Overflow>
                    <Breadcrumb size="large" style={{ color: theme.palette.neutralPrimary }}>
                        {startDisplayedItems.map((item: THistoryItem, index) => {
                            const isLastItem = index === items.length - 1;
                            return (
                                <AdvHistoryItem2
                                    key={item.item.key}
                                    item={item}
                                    isLastItem={isLastItem}
                                ></AdvHistoryItem2>
                            );
                        })}
                        {isMobile && (overflowItems?.length ?? 0) > 0 ? (
                            <>
                                <OverflowMenu
                                    overflowItems={overflowItems}
                                    startDisplayedItems={startDisplayedItems}
                                    endDisplayedItems={endDisplayedItems}
                                />
                                <BreadcrumbDivider />
                            </>
                        ) : (
                            <></>
                        )}
                        {isMobile &&
                        endDisplayedItems != undefined &&
                        (endDisplayedItems?.length ?? 0) > 0 ? (
                            endDisplayedItems.map((item: THistoryItem, index) => {
                                const isLastItem =
                                    startDisplayedItems.length +
                                        (overflowItems?.length ?? 0) +
                                        index ===
                                    items.length - 1;
                                return (
                                    <AdvHistoryItem2
                                        key={item.item.key}
                                        item={item}
                                        isLastItem={isLastItem}
                                    ></AdvHistoryItem2>
                                );
                            })
                        ) : (
                            <></>
                        )}
                    </Breadcrumb>
                </Overflow>
            </FluentProvider>
        </AdvStack>
    );
};

const AdvNavHistory = React.memo(AdvNavHistoryComp, deepCompareJSXProps);
export default AdvNavHistory;
