import { TAdvTextStyles, TAdvTextTokens } from "@components/data/text";
import { IModalProps, IModalStyles, IStyleFunctionOrObject, ITextProps } from "@fluentui/react";
import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import useAdvComponent from "@hooks/useAdvComponent";
import { useAdvMemoWithUpdater } from "@hooks/useAdvMemoWithUpdater";
import useAdvTheme from "@hooks/useAdvTheme";
import { CloseIcon } from "@themes/icons";
import { IStylesFunctionOrObject } from "@typings/fluent";
import { deepCompareJSXProps } from "@utils/deep-compare";
import { combineStyles } from "@utils/styling";
import React, { ReactNode, useMemo } from "react";

import AdvIcon from "@components/other/icon/icon";
import {
    Button,
    Dialog,
    DialogBody,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    FluentProvider,
} from "@fluentui/react-components";
import { createV9Theme } from "@fluentui/react-migration-v8-v9";
import AdvGrid from "../grid";
import AdvGridItem from "../grid/grid-item";
import { TAdvGridItemStyleProps, TAdvGridItemStyles } from "../grid/grid-item/types";
import { TAdvGridStyleProps, TAdvGridStyles } from "../grid/types";
import AdvStack from "../stack-new/stack";
import { defaultModalStyles } from "./styles";

export type TAdvModalStyles = IModalStyles; /* do not change */

export type TAdvModalStyleSet = {
    root?: IStyleFunctionOrObject<IModalProps, TAdvModalStyles>;
    container?: IStyleFunctionOrObject<TAdvGridStyleProps, TAdvGridStyles>;
    headerContainer?: IStyleFunctionOrObject<TAdvGridItemStyleProps, TAdvGridItemStyles>;
    contentContainer?: IStyleFunctionOrObject<TAdvGridItemStyleProps, TAdvGridItemStyles>;
    header?: IStylesFunctionOrObject<ITextProps, TAdvTextTokens, TAdvTextStyles>;
};

export type TAdvModalProps = Omit<
    IModalProps,
    "styles" | "children" | "key" | "onDismiss" | "componentRef" | "ref"
> & {
    styles?: TAdvModalStyleSet;
    children: ReactNode | ReactNode[];

    /** Überschrift des Fensters / Modals */
    headline: string;
    translationContext?: string;
    ignoreTranslation?: boolean;
    /** Property, die steuert ob das Modal offen (sichtbar) ist oder nicht. */
    isOpen: boolean;
    /** Wird ausgeführt, wenn sich das Fenster schließt.
     * @important Muss isOpen zurücksetzen (wenn es ein State ist). */
    onDismiss: () => void;

    /**
     * Heißt auch, dass der isOpen-State nicht vom Modal selber gemanaged wird
     */
    hideCloseButton?: boolean;

    // make the content grid item act as flex container
    contentAsFlexContainer?: boolean;

    // key: Key;
};

/**
 * @summary Wrapper für ``Modal``
 * @important Class-Component, da wir direkt nach dem Mount ein DOM Element ändern müssen (bevor das erste Render läuft).
 * @link https://developer.microsoft.com/en-us/fluentui#/controls/web/modal
 */
const AdvModalComp = ({
    styles,
    children,
    headline,
    translationContext,
    ignoreTranslation,
    onDismiss,
    isOpen,
    hideCloseButton: shouldHideCloseButton = false,
    contentAsFlexContainer = false,
    ...props
}: TAdvModalProps) => {
    useAdvComponent(AdvModalComp, props);

    const theme = useAdvTheme();
    const [isOpenInternal, , setIsOpenInternal] = useAdvMemoWithUpdater(() => isOpen, [isOpen]);

    const internalOnDismiss = useAdvCallback(() => {
        if (shouldHideCloseButton !== true) setIsOpenInternal(false);
        if (onDismiss != undefined) {
            onDismiss();
        }
    }, [onDismiss, setIsOpenInternal, shouldHideCloseButton]);

    const contentStyle = useMemo(() => {
        const res = combineStyles(defaultModalStyles.contentContainer, styles?.contentContainer);
        if (contentAsFlexContainer) return combineStyles(res, { root: { display: "flex" } });
        return res;
    }, [contentAsFlexContainer, styles?.contentContainer]);

    const closeButtonIconProps = useMemo(() => {
        return { ...CloseIcon, color: theme.palette.themeDark };
    }, [theme.palette.themeDark]);

    const closeButtonStyles = useMemo(() => {
        return defaultModalStyles.closeButton(theme);
    }, [theme]);

    const v9Theme = useMemo(() => createV9Theme(theme), [theme]);

    return (
        <FluentProvider theme={v9Theme}>
            <Dialog
                {...props}
                //onDismiss={internalOnDismiss}
                open={isOpenInternal}
                onOpenChange={(_, data) => {
                    if (!data.open) internalOnDismiss();
                }}
                //focusTrapZoneProps={{ style: { display: "flex" } }}
                modalType="alert"
            >
                <DialogSurface
                    style={{
                        ...((styles as any)?.root?.main ?? {}),
                        display: "flex",
                    }}
                >
                    <DialogBody
                        style={{
                            display: "flex",
                            width: "100%",
                            maxHeight: "100%",
                            flexDirection: "column",
                        }}
                    >
                        <AdvStack horizontal horizontalAlign="space-between">
                            <DialogTitle
                                action={
                                    shouldHideCloseButton ? undefined : (
                                        <DialogTrigger action="close">
                                            <Button
                                                appearance="subtle"
                                                aria-label="close"
                                                icon={<AdvIcon iconName={CloseIcon.iconName} />}
                                            />
                                        </DialogTrigger>
                                    )
                                }
                            >
                                {headline}
                            </DialogTitle>
                        </AdvStack>
                        <AdvGrid
                            rows="auto 1fr"
                            columns="1fr"
                            styles={combineStyles(
                                combineStyles(defaultModalStyles.container, styles?.container),
                                {
                                    root: {
                                        overflowY: "auto",
                                    },
                                },
                            )}
                            verticalFill
                        >
                            <AdvGridItem column="1 / 2" row="1 / 2">
                                {/*<AdvHeadline
                                    styles={combineStylesWithToken(
                                        defaultModalStyles.headline,
                                        styles?.header,
                                    )}
                                    translationContext={translationContext}
                                    ignoreTranslation={ignoreTranslation}
                                >
                                    {headline}
                                </AdvHeadline>*/}
                                {/*shouldHideCloseButton == false && (
                                    <AdvButtonPure
                                        iconProps={closeButtonIconProps}
                                        iconType={EAdvButtonIconType.Normal}
                                        buttonType={EAdvButtonType.Action}
                                        onClick={internalOnDismiss}
                                        styles={closeButtonStyles}
                                    />
                                )*/}
                            </AdvGridItem>
                            <AdvGridItem column="1 / 2" row="2 / 3" styles={contentStyle}>
                                {children}
                            </AdvGridItem>
                        </AdvGrid>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </FluentProvider>
    );
};

const AdvModal = React.memo(AdvModalComp, deepCompareJSXProps);
export default AdvModal;
