import { remContractsTrans } from "@components/dynamic/contracts/contracts";
import { resetPageParameters } from "@components/dynamic/parameter-mapping/page-parameter";
import { recoilDataProvider } from "@data/dataprovider";
import { useProvider } from "@data/dataprovider/data-provider-server";
import { gPageFilePrefix } from "@data/designer/file";
import { buildPageIDForVariableID, recoilParameters } from "@data/parameters";
import { defaultStartPage, sessionAddInfosAtom } from "@data/session";
import useAdvError from "@hooks/dialogs/useAdvError";
import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import {
    TAdvTransactionInterface,
    useAdvRecoilTransaction,
} from "@hooks/recoil-overload/useAdvRecoilTransaction";
import useAdvRecoilValue from "@hooks/recoil-overload/useAdvRecoilValue";
import { useAdvEvent } from "@hooks/useAdvEvent";
import { gResetDynamicPageTrans } from "@pages/dynamic";
import { buildUniquePageID, gAdvDynPageName, parseQueryString } from "@utils/page-parser";
import { useRouter } from "next/router";

type TCloseEvent = {
    Page: string;
    QueryStr: string;
};

type TErrAndWarnLog = {
    Logs: Array<string>;
};

export const usePageFunctions = () => {
    const router = useRouter();

    const { reloadAllDataprovider } = useProvider();

    const reloadPageData = useAdvCallback(() => {
        reloadAllDataprovider(router.pathname, router.query);
    }, [reloadAllDataprovider, router.pathname, router.query]);

    return { reloadPageData };
};

export const usePageListener = () => {
    const { registerGlobalEventHandler } = useAdvEvent();

    const { showError } = useAdvError();

    const resetTrans = recoilDataProvider.resetAndRemoveAllDataprovider;
    const resetAllDataprovider = useAdvRecoilTransaction(resetTrans, [resetTrans]);

    const resetParametersTrans = useAdvCallback(
        (tb: TAdvTransactionInterface) =>
            (pathname: string, query: Record<string, string | string[] | undefined>) => {
                const variableID = buildPageIDForVariableID(pathname, query);
                tb.reset(recoilParameters(variableID));
                resetPageParameters(tb)(variableID);
            },
        [],
    );
    const resetParameters = useAdvRecoilTransaction(resetParametersTrans, [resetParametersTrans]);

    const resetContractsTrans = useAdvCallback(
        (tb: TAdvTransactionInterface) =>
            (pathname: string, query: Record<string, string | string[] | undefined>) => {
                remContractsTrans(tb)(pathname, query);
            },
        [],
    );
    const resetContracts = useAdvRecoilTransaction(resetContractsTrans, [resetContractsTrans]);

    const resetPageUniqueness = useAdvRecoilTransaction(gResetDynamicPageTrans, [
        gResetDynamicPageTrans,
    ]);

    const session = useAdvRecoilValue(sessionAddInfosAtom);

    registerGlobalEventHandler<TCloseEvent>("page", "close", (data) => {
        const queryFromStr = parseQueryString(data.QueryStr);
        // close dataprovider and other stuff related to that page
        if (
            data.Page.startsWith(gPageFilePrefix) ||
            data.Page == (session.Startpage != undefined ? session.Startpage : defaultStartPage)
        ) {
            resetAllDataprovider("/dynamic", { ...queryFromStr, [gAdvDynPageName]: data.Page });
            resetParameters("/dynamic", { ...queryFromStr, [gAdvDynPageName]: data.Page });
            const pageID = buildUniquePageID("/dynamic", {
                ...queryFromStr,
                [gAdvDynPageName]: data.Page,
            });
            resetPageUniqueness(pageID);
            resetContracts("/dynamic", { ...queryFromStr, [gAdvDynPageName]: data.Page });
        } else if (data.Page.startsWith("designer/")) {
            // TODO: should designer pages ever be closed? what would be an advantage of it
            // but check if another session changes a page if it will reinitialize
            // all page stuff on the current session
        } else {
            resetAllDataprovider("/" + data.Page, queryFromStr);
            resetParameters("/" + data.Page, queryFromStr);
            resetContracts("/" + data.Page, queryFromStr);
        }
    });

    registerGlobalEventHandler<TErrAndWarnLog>("connection", "log", (data) => {
        if (data.Logs.length > 0) {
            showError(data.Logs.join("\n"));
        }
    });
};
