import { LAN } from "@data/language/strings";

export interface ICategory {
    name: string;
    // TODO! respect everywhere & use it for uniquness check in e.g. main menu & nav
    translationContext?: string;
}

export const DefaultComponentCategory: Record<string, ICategory> = {
    Layout: { name: LAN.LAYOUT.text, translationContext: LAN.LAYOUT.context },
    Input: { name: LAN.INPUT.text, translationContext: LAN.INPUT.context },
    Display: { name: LAN.PRESENTATION.text, translationContext: LAN.PRESENTATION.context },
    Misc: { name: LAN.MISCELLANEOUS.text, translationContext: LAN.MISCELLANEOUS.context },
};

export interface IDesignableComponentCategory extends ICategory {}
export interface IDynamicPageCategory extends ICategory {}
export interface IPropertyCategory extends ICategory {}
