import AdvGridItemDesignable from "@components/layout/grid/grid-item/designable";
import AdvStackItemDesignable from "@components/layout/stack/stack-item/designable";
import { LAN } from "@data/language/strings";
import { DefaultComponentCategory } from "@feature/Designer/types/category";
import { TAdvDesignerComponentProps } from "@feature/Designer/types/component-props";
import { EComponentTypeData } from "@feature/Designer/types/component-type";
import {
    AdvProperty,
    getDesignerModeComponentStyle,
    getSelectedComponentStyle,
    registerDesignableComponent,
} from "@feature/Designer/utils";
import { FontIcon, IFontIconProps, IIconProps, IIconStyles, Icon } from "@fluentui/react";
import { useAdvValueBinderNoDataType } from "@hooks/dynamic/useAdvValueBinder";
import { toAdvText } from "@hooks/language/useTranslation";
import useAdvComponent from "@hooks/useAdvComponent";
import useAdvTheme from "@hooks/useAdvTheme";
import { IconIcon } from "@themes/icons";
import { getAdvIconNames } from "@themes/icons_base";
import { EAdvValueDataTypes } from "@utils/data-types";
import { deepCompareJSXProps } from "@utils/deep-compare";
import { combineStyles } from "@utils/styling";
import React, { useMemo } from "react";
import {
    AdvCommonComponentAttributes,
    AdvThemeProviderProperties,
    TAdvCommonProperties,
} from "../common-properties";

import { defaultIconStyle } from "./styles";

export type TAdvIconStyles = IIconStyles; /* do not change */

export type TAdvIconProps = Omit<IIconProps, "label"> &
    TAdvDesignerComponentProps &
    TAdvCommonProperties;

/**
 * @summary Wrapper für ``Icon``
 * @summary Es kann die ``Icon`` Property genutzt werden.
 * @link https://developer.microsoft.com/en-us/fluentui#/controls/web/icon
 */
const AdvIconComp = ({
    designerData,
    designerProps,
    styles: propStyles,
    advhide = false,
    advhideBindingParams,
    dataArrayIndex = 0,
    iconName,
    ...props
}: TAdvIconProps) => {
    useAdvComponent(AdvIconComp, props);

    const [isHidden] = useAdvValueBinderNoDataType(
        advhideBindingParams,
        advhide,
        EAdvValueDataTypes.Boolean,
        dataArrayIndex,
    );

    const theme = useAdvTheme();
    const styles = useMemo(() => {
        let style = combineStyles(propStyles, defaultIconStyle);

        if ((designerData?.isSelected ?? false) && (designerData?.renderAsDesigner ?? false))
            style = combineStyles(style, {
                root: getSelectedComponentStyle(theme, true),
            });
        if (designerData?.renderAsDesigner ?? false)
            style = combineStyles(style, { root: getDesignerModeComponentStyle(theme) });
        if (designerData != undefined)
            style = combineStyles(style, { root: { minWidth: 5, minHeight: 5 } });

        const fontSize = theme.fonts.medium.fontSize;
        if (fontSize != undefined)
            style = combineStyles({ root: { width: fontSize, height: fontSize } }, style);

        return style;
    }, [designerData, propStyles, theme]);

    if (designerProps == undefined && isHidden) return <></>;
    // Um die Größe zu ändern: FontSize = Width = Height = 24
    return <Icon {...props} {...designerProps} styles={styles} iconName={iconName} />;
};

const AdvIcon = React.memo(AdvIconComp, deepCompareJSXProps);
export default AdvIcon;

const advIconNames = getAdvIconNames();
registerDesignableComponent({
    staticData: {
        name: LAN.ICON.text,
        translationContext: LAN.ICON.context,
        type: EComponentTypeData.Icon,
        supportsChildren: false,
        category: DefaultComponentCategory.Display,
        icon: IconIcon,
    },
    properties: [
        AdvProperty.Special.createIconSelect(
            toAdvText(LAN.ICON),
            "iconName",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.ICON_ICON_DESCR),
            "",
            ...advIconNames,
        ),
        ...AdvCommonComponentAttributes,
        ...AdvThemeProviderProperties,
        ...AdvStackItemDesignable.CommonProperties,
        ...AdvGridItemDesignable.CommonProperties,
    ],
    propertiesBuilders: [],
    presets: [],
});

export type TAdvFontIconProps = TAdvDesignerComponentProps & IFontIconProps;
/**
 * @summary Wrapper für ``FontIcon``
 * @summary Es kann die ``FontIcon`` Property genutzt werden.
 * @link https://developer.microsoft.com/en-us/fluentui#/controls/web/fonticon
 */
export const AdvFontIconComp = ({ ...props }: TAdvFontIconProps) => {
    return <FontIcon {...props} />;
};
export const AdvFontIcon = React.memo(AdvFontIconComp, deepCompareJSXProps);
