import { AdvButtonPure } from "@components/inputs/button/button-pure";
import AdvDialog, { TAdvDialogContentProps } from "@components/layout/dialog";
import AdvStack from "@components/layout/stack";
import AdvStackItem from "@components/layout/stack/stack-item";
import { DialogType, IDialogContentStyles } from "@fluentui/react";
import { useT } from "@hooks/language/useTranslation";
import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import useAdvComponent from "@hooks/useAdvComponent";
import useAdvTheme from "@hooks/useAdvTheme";
import { deepCompareJSXProps } from "@utils/deep-compare";
import React, { useMemo } from "react";

// type TTranslationContext<TPropName extends string> = `${TPropName}TranslationContext`;
// type TTranslatedProperty<TPropertyName extends string, TPropertyType = string> = {
//     [K in TPropertyName]: TPropertyType;
// } & {
//     [K in TTranslationContext<TPropertyName>]?: TPropertyType;
// };

export type TAdvGenericDialogProps = {
    hidden: boolean;
    title: string;
    text: string;

    ignoreTranslation?: boolean;

    continueText?: string;
    cancelText?: string;

    canContinue?: boolean;
    canCancel?: boolean;

    hideContinue?: boolean;
    hideCancel?: boolean;

    titleTranslationContext?: string;
    textTranslationContext?: string;
    continueTextTranslationContext?: string;
    cancelTextTranslationContext?: string;

    children?: React.JSX.Element | React.JSX.Element[];

    minWidth?: string | number;
    maxWidth?: string | number;

    /** Wird aufgerufen, wenn der Dialog sich schließen soll. Diese Methode muss die ``hidden``-Property setzen */
    onClosed: (wasContinue: boolean) => void;
};

const AdvGenericDialogComp = ({
    title,
    text,
    onClosed,
    hidden,
    continueText = "Ok",
    cancelText = "Abbrechen",
    children,
    ignoreTranslation = false,
    continueTextTranslationContext,
    cancelTextTranslationContext,
    canContinue = true,
    canCancel = true,
    minWidth,
    maxWidth,
    hideContinue = false,
    hideCancel = false,
    ...props
}: TAdvGenericDialogProps) => {
    useAdvComponent(AdvGenericDialogComp, props);

    const theme = useAdvTheme();

    const { t: translatedTitle, hasErr: hasTitleError } = useT(
        title,
        props.titleTranslationContext,
        ignoreTranslation,
    );
    const { t: translatedText, hasErr: hasTextError } = useT(
        text,
        props.textTranslationContext,
        ignoreTranslation,
    );

    const contentProps: TAdvDialogContentProps = useMemo(() => {
        const styles: Partial<IDialogContentStyles> = {};
        styles.title = { whiteSpace: "pre-line" }; // Zeilenumbrüche unterstüzen
        styles.subText = { whiteSpace: "pre-line" }; // Zeilenumbrüche unterstüzen
        if (hasTitleError) styles.title = { ...styles.title, ...theme.custom.textNotTranslated };
        if (hasTextError) styles.subText = { ...styles.subText, ...theme.custom.textNotTranslated };
        return {
            title: translatedTitle,
            subText: translatedText,
            type: DialogType.normal,
            styles,
        } as TAdvDialogContentProps;
    }, [
        hasTextError,
        hasTitleError,
        theme.custom.textNotTranslated,
        translatedText,
        translatedTitle,
    ]);

    const continueAction = useAdvCallback(() => {
        onClosed(true);
    }, [onClosed]);

    const cancelAction = useAdvCallback(() => {
        onClosed(false);
    }, [onClosed]);

    const dialogButtons = useMemo(
        () => (
            <AdvStack horizontal>
                <AdvStackItem shrink align={"start"} key={"dialogbtn0"}>
                    {hideContinue ? null : (
                        <AdvButtonPure
                            text={continueText}
                            ignoreTranslation={ignoreTranslation}
                            translationContext={continueTextTranslationContext}
                            primary
                            onClick={continueAction}
                            disabled={!canContinue}
                        />
                    )}
                </AdvStackItem>
                <AdvStackItem grow align={"end"} key={"dialogbtn1"}>
                    {hideCancel ? null : (
                        <AdvButtonPure
                            text={cancelText}
                            ignoreTranslation={ignoreTranslation}
                            translationContext={cancelTextTranslationContext}
                            onClick={cancelAction}
                            disabled={!canCancel}
                        />
                    )}
                </AdvStackItem>
            </AdvStack>
        ),
        [
            hideContinue,
            continueText,
            ignoreTranslation,
            continueTextTranslationContext,
            continueAction,
            canContinue,
            hideCancel,
            cancelText,
            cancelTextTranslationContext,
            cancelAction,
            canCancel,
        ],
    );

    return (
        <AdvDialog
            dialogContentProps={contentProps}
            hidden={hidden}
            footer={dialogButtons}
            onDismiss={cancelAction}
            minWidth={minWidth}
            maxWidth={maxWidth}
        >
            {children}
        </AdvDialog>
    );
};

const AdvGenericDialog = React.memo(AdvGenericDialogComp, deepCompareJSXProps);
export default AdvGenericDialog;
