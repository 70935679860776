import { AdvHeadline } from "@components/data/text";
import { TAdvContractFieldDataValueChangedNtf } from "@components/dynamic/contracts/types";
import AdvButtonPureNew from "@components/inputs/button-new/button-pure";
import AdvButton from "@components/inputs/button/button";
import AdvDropdown from "@components/inputs/dropdown-new/dropdown";
import AdvTextInput from "@components/inputs/text-input-new/text-input";
import AdvGroupbox from "@components/layout/groupbox/groupbox";
import AdvStack from "@components/layout/stack/stack";
import AdvStackItem from "@components/layout/stack/stack-item/stack-item";
import {
    AdvCommonComponentAttributes,
    AdvThemeProviderProperties,
} from "@components/other/common-properties";
import { LAN } from "@data/language/strings";
import { DefaultComponentCategory } from "@feature/Designer/types/category";
import { EComponentTypeCustom } from "@feature/Designer/types/component-type";
import { AdvProperty, registerDesignableComponent } from "@feature/Designer/utils";
import {
    TAdvValueBindingParams,
    useAdvValueBinderAsArrayNoDataType,
    useAdvValueBinderNoDataType,
} from "@hooks/dynamic/useAdvValueBinder";
import { toAdvText } from "@hooks/language/useTranslation";
import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import { useAdvEffect } from "@hooks/react-overload/useAdvEffect";
import { useAdvObjMemo } from "@hooks/useAdvObjMemo";
import { TSuccessClass, useAdvSocketCallback } from "@hooks/useAdvSocketCallback";
import { CirclePlusIcon, DeleteIcon, TableIcon } from "@themes/icons";
import { EAdvValueDataTypes } from "@utils/data-types";
import { deepCompareJSXProps } from "@utils/deep-compare";
import deepCopy from "@utils/deep-copy";
import { advcatch } from "@utils/logging";
import assert from "assert";
import React, { useMemo, useRef, useState } from "react";

type TAussta = {
    ID: number;
    ArtikelNr: string;
    Bez: string;
    Variante: string;
    VariantBez: string;
    MinMengeArt: number;
    MaxMengeArt: number;
    MengeAendernWeb: boolean;
    Menge: number;
    DarfMaxMengeUeberschreiten: boolean;
    KdAusGrpID: number;
    KdAusGrpBez: string;
    MaxMengeGrp: number;
    KdArtiID: number;
};

type TArt = {
    kdArtiId: number;
    artGroeID: number;
    menge: number;
    ausstaGroupIndex: number;
    ausstaItemIndexInGroup: number;
};

const AdvTraeArtikel = ({
    index,
    canDelete,
    defaultAussta,
    onRemArticle,
    onKdArtiChange,
    onArtGroeChange,
    onMengeChange,
    artGroes = [],
    kdArtis = [],
    art,
    artis,
    curKdArtiID,
    curArtGroeID,
}: {
    index: number;
    canDelete: boolean;
    defaultAussta?: TAussta;
    artGroes?: Array<{ ArtGroeID: number; ArtGroeBez: string }>;
    kdArtis?: Array<{ id: number; name: string }>;
    onRemArticle: (index: number) => void;
    onKdArtiChange: (index: number, newKdArtiID: number) => void;
    onArtGroeChange: (index: number, newArtGroeID: number) => void;
    onMengeChange: (index: number, newMenge: number) => void;
    art: TArt;
    artis: TArt[];
    curKdArtiID: number;
    curArtGroeID: number;
    curMenge: number;
}) => {
    const kdArtiOptions = useMemo(
        () =>
            kdArtis
                .filter(
                    (val) =>
                        artis.find((a) => a.kdArtiId == val.id) == undefined ||
                        val.id == art.kdArtiId,
                )
                .map((val, index) => {
                    return {
                        key: index,
                        text: val.name,
                        data: val.id,
                    };
                }),
        [art.kdArtiId, artis, kdArtis],
    );

    const artGroeOptions = useMemo(
        () =>
            artGroes.map((val, index) => {
                return {
                    key: index,
                    text: val.ArtGroeBez,
                    data: val.ArtGroeID,
                };
            }),
        [artGroes],
    );

    return (
        <AdvStack horizontal verticalAlign="center" tokens={{ childrenGap: 15 }}>
            <AdvStackItem>
                {defaultAussta ? (
                    <AdvTextInput
                        label={"Artikel-Name"}
                        value={defaultAussta.Bez}
                        disabled
                        style={{ minWidth: 250 }}
                    ></AdvTextInput>
                ) : (
                    <AdvDropdown
                        label={"Artikel-Name"}
                        options={kdArtiOptions}
                        style={{ minWidth: 250 }}
                        value={kdArtiOptions.find((k) => k.data === curKdArtiID)}
                        onValueChanged={(newVal) => {
                            if (newVal != undefined) {
                                onKdArtiChange(index, newVal.data);
                            }
                        }}
                    ></AdvDropdown>
                )}
            </AdvStackItem>
            <AdvStackItem>
                <AdvDropdown
                    label="Größe"
                    options={artGroeOptions}
                    value={artGroeOptions.find((o) => o.data == curArtGroeID)}
                    style={{ minWidth: 100 }}
                    onValueChanged={(newVal) => {
                        if (newVal != undefined) {
                            onArtGroeChange(index, newVal.data);
                        }
                    }}
                ></AdvDropdown>
            </AdvStackItem>
            <AdvStackItem>
                <AdvTextInput
                    label="Menge"
                    type="number"
                    value={
                        defaultAussta != undefined && !defaultAussta.MengeAendernWeb
                            ? (defaultAussta?.Menge ?? 0).toString()
                            : art.menge.toString()
                    }
                    disabled={!(defaultAussta?.MengeAendernWeb ?? true)}
                    style={{ minWidth: 75 }}
                    min={defaultAussta?.MinMengeArt}
                    max={
                        defaultAussta?.DarfMaxMengeUeberschreiten === true
                            ? undefined
                            : defaultAussta?.KdAusGrpID !== -1 && defaultAussta?.KdAusGrpID !== -999
                            ? defaultAussta?.MaxMengeArt
                            : defaultAussta?.MaxMengeGrp
                    }
                    onValueChanged={(newVal) => {
                        if (newVal != undefined) {
                            onMengeChange(index, parseInt(newVal));
                        }
                    }}
                ></AdvTextInput>
            </AdvStackItem>
            {canDelete ? (
                <AdvStackItem align="end">
                    <AdvButtonPureNew
                        iconName={DeleteIcon.iconName}
                        onClick={() => onRemArticle(index)}
                    ></AdvButtonPureNew>
                </AdvStackItem>
            ) : (
                <></>
            )}
        </AdvStack>
    );
};

const AdvAddTraeArtisImpl = ({
    artiListReaderBindingParams,
    artiListWriterBindingParams,
    kdArtiIDReaderBindingParams,
    kdArtiBezReaderBindingParams,
    artGroeReaderBindingParams,
    dataArrayIndex = 0,
}: {
    artiListWriterBindingParams?: TAdvValueBindingParams;
    artiListReaderBindingParams?: TAdvValueBindingParams;
    kdArtiIDReaderBindingParams?: TAdvValueBindingParams;
    kdArtiBezReaderBindingParams?: TAdvValueBindingParams;
    artGroeReaderBindingParams?: TAdvValueBindingParams;
    dataArrayIndex?: number;
}) => {
    const [dateReader] = useAdvValueBinderAsArrayNoDataType(
        artiListReaderBindingParams,
        [],
        EAdvValueDataTypes.String,
        false,
        dataArrayIndex,
    );
    const [kdArtiIDReader] = useAdvValueBinderAsArrayNoDataType(
        kdArtiIDReaderBindingParams,
        [],
        EAdvValueDataTypes.String,
        false,
        dataArrayIndex,
    );
    const [kdArtiBezReader] = useAdvValueBinderAsArrayNoDataType(
        kdArtiBezReaderBindingParams,
        [],
        EAdvValueDataTypes.String,
        false,
        dataArrayIndex,
    );
    const [artiGroeListReader] = useAdvValueBinderAsArrayNoDataType(
        artGroeReaderBindingParams,
        [],
        EAdvValueDataTypes.String,
        false,
        dataArrayIndex,
    );
    const [, setArtiListWriter] = useAdvValueBinderNoDataType(
        artiListWriterBindingParams,
        "",
        EAdvValueDataTypes.String,
        dataArrayIndex,
    );

    const sets = useAdvObjMemo(() => {
        return dateReader.map((s) => JSON.parse(s) as TAussta);
    }, [dateReader]);

    const kdArtis = useAdvObjMemo(() => {
        const res: Array<{ name: string; id: number }> = [];

        kdArtiIDReader.forEach((val, index) => {
            res.push({ name: kdArtiBezReader[index], id: val });
        });

        return res;
    }, [kdArtiBezReader, kdArtiIDReader]);

    const artGroeListWrapper = useAdvObjMemo(() => artiGroeListReader, [artiGroeListReader]);

    // maps don't like useAdvObjMemo
    const artGroeList = useMemo(() => {
        const res: Record<
            number /* KdArtiID */,
            Array<{ ArtGroeID: number; ArtGroeBez: string }>
        > = {};

        artGroeListWrapper.forEach((val) => {
            const artGroe: { KdArtiID: number; ArtGroeID: number; ArtGroeBez: string } =
                JSON.parse(val);

            let curArr = res[artGroe.KdArtiID];
            if (curArr == undefined) {
                res[artGroe.KdArtiID] = [];
                curArr = res[artGroe.KdArtiID];
            }
            assert(curArr != undefined);
            curArr.push({ ArtGroeBez: artGroe.ArtGroeBez, ArtGroeID: artGroe.ArtGroeID });
        });

        return res;
    }, [artGroeListWrapper]);

    const grouppedSets = useMemo(() => {
        const res: Array<{ name: string; items: TAussta[]; id: number }> = [];
        sets.forEach((s) => {
            const foundG = res.find((g) => g.id == s.KdAusGrpID);
            if (foundG != undefined) {
                foundG.items.push(s);
            } else {
                res.push({ name: s.KdAusGrpBez, items: [s], id: s.KdAusGrpID });
            }
        });
        return res;
    }, [sets]);

    const { sendCallbackRequest } = useAdvSocketCallback();

    const [articles, setArticles] = useState<Array<TArt>>([]);

    useAdvEffect(() => {
        setArtiListWriter(
            JSON.stringify(
                articles.map((a) => {
                    return {
                        KdArtiID: a.kdArtiId,
                        ArtGroeID: a.artGroeID,
                        Menge: a.menge,
                    };
                }),
            ),
        );
    }, [articles, setArtiListWriter]);

    useAdvEffect(() => {
        setArticles((old) => {
            const next = deepCopy(old);

            grouppedSets.forEach((s, sIndex) => {
                s.items.forEach((i, iIndex) => {
                    const foundItem = next.find(
                        (n) => n.ausstaGroupIndex == sIndex && n.ausstaItemIndexInGroup == iIndex,
                    );
                    if (foundItem == undefined) {
                        next.push({
                            artGroeID: -999,
                            kdArtiId: i.KdArtiID,
                            menge: i.Menge,
                            ausstaGroupIndex: sIndex,
                            ausstaItemIndexInGroup: iIndex,
                        });
                    }
                });
            });

            return next;
        });
    }, [grouppedSets]);

    const onAddArticle = useAdvCallback(() => {
        setArticles((old) => {
            const next = deepCopy(old);
            next.push({
                artGroeID: -999,
                kdArtiId: -999,
                menge: 0,
                ausstaGroupIndex: -1,
                ausstaItemIndexInGroup: -1,
            });
            return next;
        });
    }, []);

    const lastSendSet = useRef<Array<number>>([]);
    useAdvEffect(() => {
        const idSet = new Set<number>();
        articles.forEach((a) => {
            if (a.kdArtiId != -1 && a.kdArtiId != -999 && !idSet.has(a.kdArtiId))
                idSet.add(a.kdArtiId);
        });
        if (
            idSet.size > 0 &&
            JSON.stringify(lastSendSet.current) != JSON.stringify(Array.from(idSet))
        ) {
            lastSendSet.current = Array.from(idSet);
            sendCallbackRequest<TAdvContractFieldDataValueChangedNtf, TSuccessClass>(
                "contract",
                "data_changed_ntf",
                {
                    ContractName: "ctTraeArti", // TODO:
                    GroupIndex: 0,
                    Field: "",
                    Prov: "",
                    FieldName: "ArtGroeList",
                    Value: JSON.stringify(Array.from(idSet)),
                },
            ).catch((r) => advcatch("Could not notify about the data change", r));
        }
    }, [articles, sendCallbackRequest]);

    const onRemArticle = useAdvCallback(
        (index: number) => {
            setArticles((old) => {
                const next = deepCopy(old);
                next.splice(index, 1);
                return next;
            });
        },
        [setArticles],
    );

    const onKdArtiChange = useAdvCallback((index: number, newKdArtiID: number) => {
        setArticles((old) => {
            const next = deepCopy(old);
            next[index].kdArtiId = newKdArtiID;
            next[index].artGroeID = -999;
            return next;
        });
    }, []);
    const onArtGroeChange = useAdvCallback((index: number, newArtGroeID: number) => {
        setArticles((old) => {
            const next = deepCopy(old);
            next[index].artGroeID = newArtGroeID;
            return next;
        });
    }, []);
    const onMengeChange = useAdvCallback((index: number, newMenge: number) => {
        setArticles((old) => {
            const next = deepCopy(old);
            next[index].menge = newMenge;
            return next;
        });
    }, []);

    return (
        <AdvStack horizontalAlign="start">
            <AdvStackItem>
                <AdvHeadline>{LAN.WEARER_ARTICLE.text}</AdvHeadline>
            </AdvStackItem>
            <AdvStackItem>
                {grouppedSets.length > 0 ? (
                    grouppedSets.map((s, gIndex) => {
                        return (
                            <AdvGroupbox
                                heading={s.items.length > 1 && s.id != -1 ? s.name : ""}
                                key={"standard_set_" + gIndex.toString()}
                                styles={{ root: { root: { minWidth: 650 } } }}
                            >
                                <AdvStack tokens={{ childrenGap: 15 }}>
                                    {s.items.map((i, iIndex) => {
                                        const foundArtIndex = articles.findIndex(
                                            (a) =>
                                                a.ausstaGroupIndex == gIndex &&
                                                a.ausstaItemIndexInGroup == iIndex,
                                        );
                                        const foundArt =
                                            foundArtIndex >= 0 && foundArtIndex < articles.length
                                                ? articles[foundArtIndex]
                                                : undefined;
                                        return (
                                            <AdvStackItem
                                                key={"standard_set_item" + iIndex.toString()}
                                            >
                                                <AdvTraeArtikel
                                                    index={foundArtIndex}
                                                    canDelete={false}
                                                    defaultAussta={i}
                                                    onRemArticle={onRemArticle}
                                                    art={
                                                        foundArt ?? {
                                                            artGroeID: -999,
                                                            kdArtiId: -999,
                                                            menge: 0,
                                                            ausstaGroupIndex: -1,
                                                            ausstaItemIndexInGroup: -1,
                                                        }
                                                    }
                                                    artis={articles}
                                                    artGroes={artGroeList[i.KdArtiID]}
                                                    kdArtis={kdArtis}
                                                    onKdArtiChange={onKdArtiChange}
                                                    onArtGroeChange={onArtGroeChange}
                                                    onMengeChange={onMengeChange}
                                                    curKdArtiID={foundArt?.kdArtiId ?? -999}
                                                    curArtGroeID={foundArt?.artGroeID ?? -999}
                                                    curMenge={foundArt?.menge ?? 0}
                                                ></AdvTraeArtikel>
                                            </AdvStackItem>
                                        );
                                    })}
                                </AdvStack>
                            </AdvGroupbox>
                        );
                    })
                ) : (
                    <></>
                )}
            </AdvStackItem>
            <AdvStackItem>
                <AdvGroupbox heading={""} styles={{ root: { root: { minWidth: 650 } } }}>
                    <AdvStack>
                        {articles
                            .map((a, index) => {
                                return { art: a, index };
                            })
                            .filter(
                                (a) =>
                                    a.art.ausstaGroupIndex == -1 &&
                                    a.art.ausstaItemIndexInGroup == -1,
                            )
                            .map((articleAndIndex) => {
                                const art = articleAndIndex.art;
                                const index = articleAndIndex.index;

                                return (
                                    <AdvStackItem key={"extra_art_" + index.toString()}>
                                        <AdvTraeArtikel
                                            index={index}
                                            canDelete
                                            onRemArticle={onRemArticle}
                                            art={art}
                                            artis={articles}
                                            artGroes={artGroeList[art.kdArtiId]}
                                            kdArtis={kdArtis}
                                            onKdArtiChange={onKdArtiChange}
                                            onArtGroeChange={onArtGroeChange}
                                            onMengeChange={onMengeChange}
                                            curKdArtiID={art.kdArtiId}
                                            curArtGroeID={art.artGroeID}
                                            curMenge={art.menge}
                                        ></AdvTraeArtikel>
                                    </AdvStackItem>
                                );
                            })}
                        <AdvStackItem>
                            <AdvButton
                                primary
                                text={LAN.ADD_MORE_WEARER_ARTICLE.text}
                                iconName={CirclePlusIcon.iconName}
                                onClick={onAddArticle}
                            ></AdvButton>
                        </AdvStackItem>
                    </AdvStack>
                </AdvGroupbox>
            </AdvStackItem>
        </AdvStack>
    );
};
const AdvAddTraeArtis = React.memo(AdvAddTraeArtisImpl, deepCompareJSXProps);
export default AdvAddTraeArtis;

const artiListProps = [
    AdvProperty.Text.create(
        toAdvText(LAN.AUSSTA_LIST_READER),
        "artiListReader",
        toAdvText(LAN.GENERAL),
        toAdvText(LAN.AUSSTA_LIST_READER_DESCR),
        "",
    ),
    AdvProperty.Text.create(
        toAdvText(LAN.AUSSTA_LIST_WRITER),
        "artiListWriter",
        toAdvText(LAN.GENERAL),
        toAdvText(LAN.AUSSTA_LIST_WRITER_DESCR),
        "",
    ),
    AdvProperty.Text.create(
        toAdvText(LAN.KDARTI_ID_READER),
        "kdArtiIDReader",
        toAdvText(LAN.GENERAL),
        toAdvText(LAN.KDARTI_ID_READER_DESCR),
        "",
    ),
    AdvProperty.Text.create(
        toAdvText(LAN.KDARTI_BEZ_READER),
        "kdArtiBezReader",
        toAdvText(LAN.GENERAL),
        toAdvText(LAN.KDARTI_BEZ_READER_DESCR),
        "",
    ),
    AdvProperty.Text.create(
        toAdvText(LAN.ART_GROE_READER),
        "artGroeReader",
        toAdvText(LAN.GENERAL),
        toAdvText(LAN.ART_GROE_READER_DESCR),
        "",
    ),
    ...AdvCommonComponentAttributes,
    ...AdvThemeProviderProperties,
];

registerDesignableComponent({
    staticData: {
        name: LAN.ARTI_LIST.text,
        translationContext: LAN.ARTI_LIST.context,
        type: EComponentTypeCustom.TraeArtiList,
        supportsChildren: false,
        category: DefaultComponentCategory.Misc,
        icon: TableIcon,
    },
    properties: artiListProps,
    propertiesBuilders: [],
    presets: [],
});
