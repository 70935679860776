import { TAdvTransactionInterface } from "@hooks/recoil-overload/useAdvRecoilTransaction";
import { buildUniquePageID, gAdvDesignerPageName, getQueryValue } from "@utils/page-parser";
import { ParsedUrlQuery } from "querystring";
import { atom, atomFamily, selectorFamily } from "recoil";

export type TContractID = { id: string; name: string; pageid: string };
export function buildPageIDForContractID(
    pathname: string,
    query: Record<string, string | string[] | undefined>,
) {
    return buildUniquePageID(
        pathname,
        pathname.startsWith("/designer/")
            ? { [gAdvDesignerPageName]: getQueryValue(query, gAdvDesignerPageName) }
            : query,
    );
}

/**
 * Generates an unique ID for a contract
 * @param pathname the current page
 * @param query the current query of the page, note for designer this parameter only parses the active file, the rest is ignored
 * @param contractName the name of the contract
 * @returns an unique identifier for the contract
 */
export function buildUniqueContractID(
    pathname: string,
    query: Record<string, string | string[] | undefined>,
    contractName: string,
): TContractID {
    const pageID = buildPageIDForContractID(pathname, query);
    return {
        id: pageID + contractName,
        name: contractName,
        pageid: pageID,
    };
}

export const gContracts = atom<
    {
        contractName: string;
        pathname: string;
        query: ParsedUrlQuery;
        contractID: TContractID;
    }[]
>({
    key: "global_Contracts",
    default: [],
});

export const gActiveContracts = atom<
    {
        contractID: TContractID;
    }[]
>({
    key: "global_ActiveContracts",
    default: [],
});

export const remContractsTrans =
    (tb: TAdvTransactionInterface) => (pathname: string, query: ParsedUrlQuery) => {
        const curContracts = tb.get(gContracts);
        tb.set(
            gContracts,
            curContracts.filter((val) => {
                const contractID = buildUniqueContractID(pathname, query, val.contractName);
                return JSON.stringify(val.contractID) != JSON.stringify(contractID);
            }),
        );
    };

export const contractFieldsAtom = atomFamily<
    Array<
        {
            notifyOnInterfaceEntryValueChange: string;
            notifyProviderName: string;
            fieldName: string;
            value: Array<any>;
        }[]
    >,
    TContractID
>({
    key: "contractFields_AtomFamily",
    default: [],
});

export const getContractFieldValuesSelector = selectorFamily<
    Array<any>,
    { contractID: TContractID; fieldName: string; contractIndex: number }
>({
    key: "getContractFieldsSelector_SeclectorFamily",
    get:
        (params) =>
        ({ get }) => {
            const contractFields = get(contractFieldsAtom(params.contractID));
            if (contractFields.length > 0 && params.contractIndex < contractFields.length) {
                const fields = contractFields[params.contractIndex];
                const fieldValues = fields.find((f) => f.fieldName == params.fieldName);
                if (fieldValues != undefined) {
                    return fieldValues.value;
                }
            }
            return [];
        },
});
