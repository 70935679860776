import AdvNav, { TAdvNavProps } from "@components/navigation/nav";
import { EPageComponentSizeType } from "@components/page-component";
import { useRouter } from "next/router";
import { useMemo } from "react";

type TPageLayoutWithNavProps = TAdvNavProps & {
    children: React.ReactNode;
    pageLayout: EPageComponentSizeType;
};
const PageLayoutWithNav = ({ children, elRef, ...navProps }: TPageLayoutWithNavProps) => {
    const { pathname } = useRouter();
    const shouldHideNav = useMemo(() => {
        return pathname == "/" || ["/token", "/token/", "token"].indexOf(pathname) > -1;
    }, [pathname]);

    return (
        <AdvNav {...navProps} elRef={elRef} hideNav={shouldHideNav}>
            {children}
        </AdvNav>
    );
};
export default PageLayoutWithNav;
