import { TPageComponentProps } from "@components/page-component";
import { LAN } from "@data/language/strings";
import { DefaultComponentCategory } from "@feature/Designer/types/category";
import { TAdvDesignerComponentProps } from "@feature/Designer/types/component-props";
import { EComponentTypeData } from "@feature/Designer/types/component-type";
import { IDesignerComponent } from "@feature/Designer/types/designer";
import { EPropertyInputType, EPropertyType } from "@feature/Designer/types/property";
import { AdvProperty, registerDesignableComponent } from "@feature/Designer/utils";
import {
    AdvValueBindingDefaultValue,
    IsValueBindingTrivial,
    TAdvValueBindingParams,
    useAdvValueBinderMultiNoDataType,
} from "@hooks/dynamic/useAdvValueBinder";
import { toAdvText } from "@hooks/language/useTranslation";
import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import useAdvComponent from "@hooks/useAdvComponent";
import { CopyIcon } from "@themes/icons";
import { EAdvValueDataTypes } from "@utils/data-types";
import { deepCompareJSXProps } from "@utils/deep-compare";
import deepCopy from "@utils/deep-copy";
import assert from "assert";
import { nanoid } from "nanoid";
import React, { useMemo } from "react";
import { TAdvDynamicComponentProps } from "../dynamic-component/types";

export type TAdvCopyCatProps = TAdvDesignerComponentProps & {
    /// the component to be copy cated
    /// note: this will also clone all children components
    component: IDesignerComponent;
    /// these are all components so it can create "fake" children components
    components: IDesignerComponent[];

    /// how often should the children components duplicate?
    duplicateAmount?: number;
    duplicateAmountBindingParams?: TAdvValueBindingParams;

    dynComp: React.MemoExoticComponent<
        ({ component, components, pageProps }: TAdvDynamicComponentProps) => React.JSX.Element
    >;

    pageProps: TPageComponentProps;
};

/**
 * The copy cat component is a special component only useful for bindings
 * Depending of the amount of data (e.g. an array of data) it can "clone"
 * itself and tell the underlying components which index of the data
 * they should bind.
 * Usage:
 *  Simply bind any ID field of a dataprovider, it will automatically know how often it should duplicate
 */
const AdvCopyCatComp = ({
    dynComp,
    component,
    components,
    duplicateAmount,
    duplicateAmountBindingParams,
    designerProps,
    pageProps,
    ...props
}: TAdvCopyCatProps) => {
    useAdvComponent(AdvCopyCatComp, props);

    const [fieldValues] = useAdvValueBinderMultiNoDataType(
        duplicateAmountBindingParams,
        [0],
        EAdvValueDataTypes.Any,
    );
    const dubCount =
        duplicateAmountBindingParams != undefined &&
        !IsValueBindingTrivial(duplicateAmountBindingParams)
            ? fieldValues.length
            : duplicateAmount ?? 0;

    const uniqueKey = useMemo(() => designerProps?.key ?? nanoid(), [designerProps?.key]);

    const deepCopyComp = useAdvCallback(
        (copyIndex: number) => {
            const adjustProperites = (originalCompKey: string, comp: IDesignerComponent) => {
                // properties also get new keys
                comp.properties.forEach((p) => (p.key = nanoid()));

                // the component gets a dataArrayIndex, and the original keyRef
                if (comp.properties.find((p) => p.name == "dataArrayIndex") == undefined) {
                    comp.properties.push({
                        allowsBindings: false,
                        bindingParams: deepCopy(AdvValueBindingDefaultValue),
                        category: { name: "" },
                        description: { text: "", ignoreTranslation: true },
                        displayName: { text: "", ignoreTranslation: true },
                        hidden: true,
                        key: nanoid(),
                        name: "dataArrayIndex",
                        type: EPropertyType.Number,
                        valueWriteable: true,
                        value: copyIndex,
                        inputType: EPropertyInputType.Default,
                    });
                }
                if (comp.properties.find((p) => p.name == "keyRef") == undefined) {
                    comp.properties.push({
                        allowsBindings: false,
                        bindingParams: deepCopy(AdvValueBindingDefaultValue),
                        category: { name: "" },
                        description: { text: "", ignoreTranslation: true },
                        displayName: { text: "", ignoreTranslation: true },
                        hidden: true,
                        key: nanoid(),
                        name: "keyRef",
                        type: EPropertyType.Text,
                        valueWriteable: true,
                        value: originalCompKey,
                        inputType: EPropertyInputType.Default,
                    });
                }
            };

            const adjustChildren = (
                compParent: IDesignerComponent,
                children: IDesignerComponent[],
            ) => {
                compParent.childrenKeys.forEach((key, cIndex) => {
                    const foundComp = components.find((c) => c.key == key);
                    assert(foundComp != undefined, "Copy cat could not find component");
                    if (foundComp != undefined) {
                        const comp = deepCopy(foundComp);

                        // switch to new keys
                        const originalKey = comp.key;
                        comp.key = nanoid();
                        comp.parentKey = compParent.key;
                        compParent.childrenKeys[cIndex] = comp.key;

                        adjustProperites(originalKey, comp);

                        children.push(comp);

                        adjustChildren(comp, children);
                    }
                });
            };

            const res: { comp: IDesignerComponent; children: IDesignerComponent[] } = {
                comp: deepCopy(component),
                children: [],
            };
            const originalKey = res.comp.key;
            res.comp.key = nanoid();
            adjustProperites(originalKey, res.comp);
            adjustChildren(res.comp, res.children);
            return res;
        },
        [component, components],
    );

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const DynComp = dynComp;

    const children = useMemo(() => {
        const res: React.JSX.Element[] = [];
        let d = 0;
        while (d < dubCount) {
            const compCopy = deepCopyComp(d);
            const componentAndChildrenComponentsAndParent = [compCopy.comp, ...compCopy.children];
            const foundParent = components.find((comp) => comp.key == compCopy.comp.parentKey);
            if (
                foundParent != undefined &&
                componentAndChildrenComponentsAndParent.find((c) => c.key == foundParent.key) ==
                    undefined
            )
                componentAndChildrenComponentsAndParent.push(foundParent);
            res.push(
                <DynComp
                    component={compCopy.comp}
                    components={componentAndChildrenComponentsAndParent}
                    pageProps={pageProps}
                    key={uniqueKey + "_host_" + d.toString()}
                ></DynComp>,
            );
            ++d;
        }
        return res;
    }, [DynComp, components, deepCopyComp, dubCount, pageProps, uniqueKey]);
    return <>{children}</>;
};

const AdvCopyCat = React.memo(AdvCopyCatComp, deepCompareJSXProps);
export default AdvCopyCat;

registerDesignableComponent({
    staticData: {
        name: LAN.COPY_CAT.text,
        translationContext: LAN.COPY_CAT.context,
        type: EComponentTypeData.CopyCat,
        supportsChildren: true,
        category: DefaultComponentCategory.Misc,
        icon: CopyIcon,
    },
    properties: [
        AdvProperty.Number.create(
            toAdvText(LAN.DUPLICATION_AMOUNT),
            "duplicateAmount",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.DUPLICATION_AMOUNT_DESCR),
            0,
        ),
    ],
    propertiesBuilders: [],
    presets: [],
});
