import { TCommonValueProps } from "@components/inputs";
import {
    ISearchBoxProps,
    ISearchBoxStyleProps,
    ISearchBoxStyles,
    IStyleFunctionOrObject,
    SearchBox,
} from "@fluentui/react";
import { useT } from "@hooks/language/useTranslation";
import useAdvComponent from "@hooks/useAdvComponent";
import useAdvTheme from "@hooks/useAdvTheme";
import { useAdvValue } from "@hooks/useAdvValue";
import { deepCompareJSXProps } from "@utils/deep-compare";
import { combineStyles } from "@utils/styling";
import React, { useMemo } from "react";

export type TAdvSearchInputStyles = ISearchBoxStyles; /* do not change */
export type TAdvSearchInputStyleProps = ISearchBoxStyleProps; /* do not change */

export type TAdvSearchInputProps = Omit<
    ISearchBoxProps,
    "styles" | "placeholder" | "onChange" | "value" | "defaultValue"
> &
    TCommonValueProps<string> & {
        styles?: IStyleFunctionOrObject<TAdvSearchInputStyleProps, TAdvSearchInputStyles>;
        placeholder: string;
        placeholderTranslationContext?: string;
        placeholderIgnoreTranslation?: boolean;
    };
/**
 * @summary Wrapper für ``SearchBox``
 * @description Alternative zu einem AdvTextInput
 * @important Hier gibt es nützliche Events: onClear, onEscape, onSearch
 * @link https://developer.microsoft.com/en-us/fluentui#/controls/web/SearchBox
 */
const AdvSearchInputComp = ({
    value,
    placeholder,
    placeholderTranslationContext,
    placeholderIgnoreTranslation = false,
    onValueChanged,
    styles,
    ...props
}: TAdvSearchInputProps) => {
    useAdvComponent(AdvSearchInputComp, props);

    const { internalValue, onInternalChange } = useAdvValue(value, onValueChanged);

    const { t: placeholderT, hasErr } = useT(
        placeholderIgnoreTranslation ? undefined : placeholder,
        placeholderTranslationContext,
    );

    const theme = useAdvTheme();
    const myStyles = useMemo(() => {
        if (hasErr) return combineStyles(styles, { field: theme.custom.textNotTranslated });
        else return styles;
    }, [hasErr, styles, theme.custom.textNotTranslated]);

    return (
        <SearchBox
            {...props}
            styles={myStyles}
            value={internalValue}
            onChange={onInternalChange}
            placeholder={placeholderIgnoreTranslation ? placeholder : placeholderT}
        />
    );
};

const AdvSearchInput = React.memo(AdvSearchInputComp, deepCompareJSXProps);
export default AdvSearchInput;
